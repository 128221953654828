const sliceName = "keno";
const initialState = {
	hoveredBalls: [],
	selectedBalls: [],
	activeBalls: [],
	lastAddedBalls: [],
	isCurrentBetsLoading: false,
	isLastBetsLoading: false,
	currentBets: [],
	lastBets: []
}

export { sliceName, initialState };