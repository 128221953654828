import useGlobalVariables from "hooks/useGlobalVariables";
import useAppSelector from "hooks/store/useAppSelector";

import moment from "moment";

import { createGivenGameCountdownSelector } from "store/slices/game/selectors";

import { isRacingGame } from "utils/common";
import { GAME_CYCLE_MINUTES, GAME_TYPE } from "constants/game.constants";
import { mergeClassNames } from "utils/common";

const GameCountDown = ({ game, currentTime, isFromModal, className, showLive = true }) => {
	const { isMobile } = useGlobalVariables();
	const countDown = useAppSelector(createGivenGameCountdownSelector(game.id));

	/** Get seconds bedore countdown end
	 * @function
	 * @returns {number}
	 * @memberOf Game
	 */
	const getCoundownSeconds = () => {
		let seconds = 0;
		let gameDuration = 90;
		if (game.type === GAME_TYPE.KENO) {
			gameDuration = 60;
		} else if (isRacingGame(game.type)) {
			if (game.cycleMinutes === GAME_CYCLE_MINUTES.CYCLE1MIN) {
				gameDuration = 45;
			}
		}

		if (countDown && countDown.nextEventStartTime) {
			seconds = Math.max(moment.utc(countDown.nextEventStartTime).local().unix() - currentTime, 0);
		} else {
			seconds = Math.max(moment.utc(game.nextEventStartTime).local().unix() - currentTime, 0);
		}

		let interval = seconds - game.cycleMinutes * 60 + gameDuration;

		if (interval < 0) {
			return seconds;
		}

		return 0;
	};

	return (
		<div>
			{getCoundownSeconds() !== 0 ? (
				isFromModal ? null : (
					<span className={mergeClassNames("vs--topBar-game-time-sec vs--font-smallest vs--font-regular", className)}>{moment.utc(getCoundownSeconds() * 1000).format("mm:ss")}</span>
				)
			) : (
				<div className={mergeClassNames(showLive && "vs--topBar-game-time-live", "vs--topBar-game-time", isMobile ? null : "vs--topBar-game-time-web")} />
			)}
		</div>
	);
};

export default GameCountDown;
