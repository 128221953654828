import PropTypes from "prop-types";

import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";

import Game from "components/topBar/game";

import { setCurrentGameTypeAction } from "store/slices/game/actions";
import { selectSessionGames } from "store/slices/auth/selectors";
import { selectCurrentGameType } from "store/slices/game/selectors";


const GameSelectionComponent = ({ onClickCallback }) => {
	const games = useAppSelector(selectSessionGames);
	const currentGameType = useAppSelector(selectCurrentGameType);

	const dispatch = useAppDispatch();

	const setCurrentGame = (...args) => dispatch(setCurrentGameTypeAction(...args));

	return (
		<div className="vs--game-list">
			{games.map((game, ind) => (
				<Game key={ind} game={game} isFromModal={true} onClickCallback={onClickCallback} selectedGame={currentGameType} setSelectedGame={setCurrentGame} />
			))}
		</div>
	);
};

GameSelectionComponent.propTypes = {
	/** React property function, callback of element on click */
	onClickCallback: PropTypes.func
};

export default GameSelectionComponent;
