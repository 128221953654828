import { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TreeRound16 from "./treeRound16";
import TreeQuarterFinals from "./treeQuarterFinals";
import TreeSemiFinals from "./treeSemiFinals";
import TreeFinal from "./treeFinal";
import { SEASONAL_PERIODS } from "constants/game.constants";

const TreeSimpleCup = ({ treeData }) => {
	const { t } = useTranslation();
	return (
		<Fragment>
			<TreeRound16 title={t(`common.${SEASONAL_PERIODS.ROUND_16}`) + " 16"} data={treeData[SEASONAL_PERIODS.ROUND_16]} />
			<TreeQuarterFinals title={t(`common.${SEASONAL_PERIODS.QUARTER_FINALS}`)} data={treeData[SEASONAL_PERIODS.QUARTER_FINALS]} />
			<TreeSemiFinals title={t(`common.${SEASONAL_PERIODS.SEMI_FINALS}`)} data={treeData[SEASONAL_PERIODS.SEMI_FINALS]} />
			<TreeFinal title={t(`common.${SEASONAL_PERIODS.FINAL}`)} data={treeData[SEASONAL_PERIODS.FINAL]} />
		</Fragment>
	);
};

TreeSimpleCup.propTypes = {
	treeData: PropTypes.object
};

export default TreeSimpleCup;
