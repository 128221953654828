import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** Empty View Component */
const NoData = ({ style }) => {
	const { t } = useTranslation();

	return (
		<div className="vs--no-data vs--pb-8 vs--pl-8 vs--pt-8 vs--pr-8 vs--flex vs--flex-col" style={style}>
			<div className="vs--no-data-content vs--flex vs--align-center vs--justify-center vs--flex-equal vs--flex-col">
				<i className="ic_search"></i>
				<span className="vs--title vs--font-bigest vs--font-regular vs--mt-16">{t("common.noData")}</span>
			</div>
		</div>
	);
};

/** NoData propTypes
 * PropTypes
 */
NoData.propTypes = {
	/** Wrapper css styles */
	style: PropTypes.object
};

export default NoData;
