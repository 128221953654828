import { createSlice } from "@reduxjs/toolkit";

import {
	addBonusHistoryCases,
	addUseBonusCases
} from "./extraReducers";
import { initialState, sliceName } from "./initialState";

const bonusSlice = createSlice({
	name: sliceName,
	initialState: initialState,
	extraReducers: (builder) => {
		addUseBonusCases(builder);
		addBonusHistoryCases(builder);
	}
});

export const bonusSliceName = sliceName;
export const bonusSliceReducer = bonusSlice.reducer;