import { useContext } from "react";
import { StoreContext } from "contexts/navbar";
import { isNullish, mergeClassNames } from "utils/common";

const DefaultContainer = ({ children, dir, containerClassName }) => {
	const [{ arrowButtons, isScrollable }] = useContext(StoreContext);
	return (
		<nav className={mergeClassNames("vs--navbar-container", arrowButtons === true && "vs--navbar-static-arrows", isScrollable && "vs--navbar-container-scrollable", containerClassName)} dir={dir}>
			{children}
		</nav>
	);
};

export default DefaultContainer;
