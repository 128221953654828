import "prop-types";
import { useTranslation } from "react-i18next";
import { isChampionsCupGame, isLeg2, isLeg2OrFinal } from "utils/common";
import eventType from "types/event.type";

const AdditionalInfoForChampionsCup = ({ round }) => {
	const { t } = useTranslation();
	if (!round) {
		return null;
	}
	if (!round.events) {
		return null;
	}
	const isRoundContainsInvalidEVents = round.events.some((event) => {
		if (!event) {
			return true;
		}
		if (!event.gameData) {
			return true;
		}
		if (!event.gameData.team1) {
			return true;
		}
		if (!event.gameData.team2) {
			return true;
		}
		if (!event.gameData.team1.countryCode) {
			return true;
		}
		if (!event.gameData.team2.countryCode) {
			return true;
		}
		return false;
	});
	if (isRoundContainsInvalidEVents) {
		return null;
	}
	if (!isChampionsCupGame(round.gameType)) {
		return null;
	}
	return (
		<div className="vs--season-structure-title-additional vs--flex vs--flex-row vs--justify-start vs--align-center vs--mb-8 vs--pt-4 vs--pb-4 vs--pr-4 vs--pl-16">
			<span className="vs--flex-inline vs--justify-center vs--align-center vs--font-small vs--font-medium">{t("statistics.participants")}</span>
			<span className="vs--flex-inline vs--justify-center vs--align-center vs--font-small vs--font-medium">{t("common.fullTimeShort")}</span>
			{isLeg2OrFinal(round.orderNumber, round.gameType) ? (
				<>
					<span className="vs--flex-inline vs--justify-center vs--align-center vs--font-small vs--font-medium">{t("common.OvertimeShort")}</span>
					{isLeg2(round.orderNumber) && <span className="vs--flex-inline vs--justify-center vs--align-center vs--font-small vs--font-medium">{t("common.aggShort")}</span>}
					<span className="vs--flex-inline vs--justify-center vs--align-center vs--font-small vs--font-medium">{t("common.penaltyShoutoutShort")}</span>
				</>
			) : null}
		</div>
	);
};

AdditionalInfoForChampionsCup.propTypes = {
	round: eventType
};

export default AdditionalInfoForChampionsCup;
