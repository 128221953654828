import { useCallback } from "react";

const useForkRef = (...refs) => {
	const refCallback = useCallback((element) => {
		refs.forEach((ref) => {
			if (!ref) {
				return;
			}

			if (typeof ref === "function") {
				ref(element);
			} else {
				ref.current = element;
			}
		});
	}, refs);

	return refCallback;
};

export default useForkRef;
