import { useEffect, useState, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Tooltip from "components/ui/tooltip";
import Modal from "components/ui/modal";
import ScrollBar from "components/ui/scrollBar";
import InfoTooltip from "components/ui/infoTooltip";

import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";

import { useBonusThunk } from "store/slices/bonus/thunks";
import { setBonusSeenAction } from "store/slices/auth/actions";
import {
	selectIsSoundOn,
	selectSessionId,
	selectSessionGames,
	selectSessionCurrency,
	selectSessionBonuses
} from "store/slices/auth/selectors";

import { formatDate, formatDateTime } from "utils/dateTime";
import { buildPathToStaticFolderOfCDN, playAudio } from "utils/common";
import localStorage from "utils/localStorage";
import { binaryToFlags } from "utils/binaryCalculations";
import { GAME_TYPE_MAPPER } from "constants/game.constants";
import { BONUS_TYPE } from "constants/bonus.constants";
import useGlobalVariables from "hooks/useGlobalVariables";
import vsToast from "utils/toast";

/* Bonus Activation Popup Component */
const BonusActivationPopup = () => {
	const { t } = useTranslation();
	const [showNotification, setShowNotification] = useState(false);
	const fieldsContainerRef = useRef(null);
	const imagesContainerRef = useRef(null);
	const { isMobile } = useGlobalVariables();

	const sessionId = useAppSelector(selectSessionId);
	const games = useAppSelector(selectSessionGames);
	const currency = useAppSelector(selectSessionCurrency);
	const bonuses = useAppSelector(selectSessionBonuses);
	const isSound = useAppSelector(selectIsSoundOn);

	const dispatch = useAppDispatch();

	const availableGamesTypes = games.map((game) => game.type);
	const currencyCode = currency.code ?? "";

	const newBonuses = bonuses.reduce((acc, bonus) => {
		if (!bonus.isNewBonus) {
			return acc;
		}

		acc.push({ ...bonus, gameTypes: binaryToFlags(availableGamesTypes, bonus.gameType) });
		return acc;
	}, []);

	const isCurrentBonus = newBonuses.some((b) => newBonuses[0] && b.bonusId === newBonuses[0].bonusId);

	const groupedNewBonuses = newBonuses.reduce(
		(acc, bonus, index) => {
			if (bonus.bonusType === BONUS_TYPE.FREEBET) {
				acc[bonus.bonusType].roundCount += bonus.roundCount;
			}
			if (index === newBonuses.length - 1) {
				acc.endDate = bonus.endDate;
			}
			acc[bonus.bonusType].amount += bonus.amount;
			acc[bonus.bonusType].bonuses.push(bonus);

			return acc;
		},
		{ [BONUS_TYPE.FREEBET]: { amount: 0, roundCount: 0, bonuses: [] }, [BONUS_TYPE.FREEAMOUNT]: { amount: 0, bonuses: [] }, endDate: null }
	);

	const fields = [
		...(isMobile
			? []
			: [
				{
					field: {
						name: "bonusType",
						render: (bonusType) => {
							const bonusTypeText = bonusType === BONUS_TYPE.FREEBET ? t("bonus.freeBet") : bonusType === BONUS_TYPE.FREEAMOUNT ? t("bonus.freeAmount") : "";

							return (
								<Tooltip overlayClassName="vs--info-tooltip" title={<span className="vs--bonus-notification-fields-table-tr-td-text">{bonusTypeText}</span>}>
									<span className="vs--bonus-notification-fields-table-tr-td-text">{bonusTypeText}</span>
								</Tooltip>
							);
						}
					},
					title: "bonusType"
				}
			]),
		{
			field: {
				name: "amount",
				render: (_, bonus) => {
					const bonusAmountText = `${bonus.bonusType === BONUS_TYPE.FREEBET ? `${bonus.roundCount}x` : ""}${bonus.amount} ${currencyCode}`;

					return (
						<Tooltip overlayClassName="vs--info-tooltip" title={<span className="vs--bonus-notification-fields-table-tr-td-text">{bonusAmountText}</span>}>
							<span className="vs--bonus-notification-fields-table-tr-td-text">{bonusAmountText}</span>
						</Tooltip>
					);
				}
			},
			title: "amount"
		},
		{
			field: {
				name: "gameTypes",
				render: (gameTypes) => {
					const getGamesText = () => {
						if (gameTypes.length === availableGamesTypes.length) {
							return t("common.allGames");
						}
						if (gameTypes.length === 1) {
							return t(`common.${GAME_TYPE_MAPPER[gameTypes[0]]}`);
						}

						return `${gameTypes.length} ${t("bonus.games")}`;
					};

					const content = getGamesText();

					return (
						<>
							<Tooltip overlayClassName="vs--info-tooltip" title={<span className="vs--bonus-notification-fields-table-tr-td-text">{content}</span>}>
								<span className="vs--bonus-notification-fields-table-tr-td-text">{content}</span>
							</Tooltip>
							{gameTypes.length !== availableGamesTypes.length && gameTypes.length > 1 && <InfoTooltip content={gameTypes.map((gameType) => t(`common.${GAME_TYPE_MAPPER[gameType]}`))} />}
						</>
					);
				}
			},
			title: "games"
		},
		{
			field: {
				name: "endDate",
				render: (date) => {
					const formattedDateTime = formatDateTime(date);

					return (
						<Tooltip overlayClassName="vs--info-tooltip" title={<span className="vs--bonus-notification-fields-table-tr-td-text">{formattedDateTime}</span>}>
							<span className="vs--bonus-notification-fields-table-tr-td-text">{formattedDateTime}</span>
						</Tooltip>
					);
				}
			},
			title: "end"
		}
	];

	/** Set classname by images count */
	const getImagesQuantityClassName = (qty) => {
		switch (true) {
			case qty === 1:
				return "vs--bonus-notification-images-image-qty-one";
			case qty === 2:
				return "vs--bonus-notification-images-image-qty-two";
			case qty === 3 && !isMobile:
				return "vs--bonus-notification-images-image-qty-three";
			default:
				return "vs--bonus-notification-images-image-qty-more";
		}
	};

	/** Set notification popup text by bonuses count */
	const getNotificationText = () => {
		if (groupedNewBonuses[BONUS_TYPE.FREEAMOUNT].bonuses.length > 0 && groupedNewBonuses[BONUS_TYPE.FREEBET].bonuses.length > 0) {
			return t("bonus.notificationTextBothBonuses").replace("%%amount%%", groupedNewBonuses[BONUS_TYPE.FREEAMOUNT].amount).replace("%%currency%%", currencyCode).replace("%%quantity%%", groupedNewBonuses[BONUS_TYPE.FREEBET].roundCount).replace("%%endDate%%", formatDate(groupedNewBonuses.endDate));
		} else if (groupedNewBonuses[BONUS_TYPE.FREEAMOUNT].bonuses.length > 1) {
			return t("bonus.notificationTextFewFreeAmounts").replace("%%amount%%", groupedNewBonuses[BONUS_TYPE.FREEAMOUNT].amount).replace("%%currency%%", currencyCode).replace("%%endDate%%", formatDate(groupedNewBonuses.endDate));
		} else if (groupedNewBonuses[BONUS_TYPE.FREEBET].bonuses.length > 1) {
			return t("bonus.notificationTextFewFreeBets").replace("%%quantity%%", groupedNewBonuses[BONUS_TYPE.FREEBET].roundCount).replace("%%endDate%%", formatDate(groupedNewBonuses.endDate));
		} else if (groupedNewBonuses[BONUS_TYPE.FREEAMOUNT].bonuses.length === 1 && groupedNewBonuses[BONUS_TYPE.FREEAMOUNT].bonuses[0].gameTypes.length === 1) {
			const gameType = groupedNewBonuses[BONUS_TYPE.FREEAMOUNT].bonuses[0].gameTypes[0];

			return t("bonus.notificationTextFreeAmountOneGame")
				.replace("%%amount%%", groupedNewBonuses[BONUS_TYPE.FREEAMOUNT].amount)
				.replace("%%currency%%", currencyCode)
				.replace("%%gameName%%", t(`common.${GAME_TYPE_MAPPER[gameType]}`))
				.replace("%%endDate%%", formatDate(groupedNewBonuses.endDate));
		} else if (groupedNewBonuses[BONUS_TYPE.FREEBET].bonuses.length === 1 && groupedNewBonuses[BONUS_TYPE.FREEBET].bonuses[0].gameTypes.length === 1) {
			const gameType = groupedNewBonuses[BONUS_TYPE.FREEBET].bonuses[0].gameTypes[0];

			return t("bonus.notificationTextFreeBetOneGame")
				.replace("%%quantity%%", groupedNewBonuses[BONUS_TYPE.FREEBET].roundCount)
				.replace("%%amount%%", groupedNewBonuses[BONUS_TYPE.FREEBET].amount)
				.replace("%%currency%%", currencyCode)
				.replace("%%gameName%%", t(`common.${GAME_TYPE_MAPPER[gameType]}`))
				.replace("%%endDate%%", formatDate(groupedNewBonuses.endDate));
		} else if (groupedNewBonuses[BONUS_TYPE.FREEAMOUNT].bonuses.length === 1 && groupedNewBonuses[BONUS_TYPE.FREEAMOUNT].bonuses[0].gameTypes.length !== availableGamesTypes.length) {
			return t("bonus.notificationTextFreeAmount")
				.replace("%%amount%%", groupedNewBonuses[BONUS_TYPE.FREEAMOUNT].amount)
				.replace("%%currency%%", currencyCode)
				.replace("%%gamesCount%%", groupedNewBonuses[BONUS_TYPE.FREEAMOUNT].bonuses[0].gameTypes.length)
				.replace("%%endDate%%", formatDate(groupedNewBonuses.endDate));
		} else if (groupedNewBonuses[BONUS_TYPE.FREEBET].bonuses.length === 1 && groupedNewBonuses[BONUS_TYPE.FREEBET].bonuses[0].gameTypes.length !== availableGamesTypes.length) {
			return t("bonus.notificationTextFreeBet")
				.replace("%%quantity%%", groupedNewBonuses[BONUS_TYPE.FREEBET].roundCount)
				.replace("%%amount%%", groupedNewBonuses[BONUS_TYPE.FREEBET].amount)
				.replace("%%currency%%", currencyCode)
				.replace("%%gamesCount%%", groupedNewBonuses[BONUS_TYPE.FREEBET].bonuses[0].gameTypes.length)
				.replace("%%endDate%%", formatDate(groupedNewBonuses.endDate));
		} else if (groupedNewBonuses[BONUS_TYPE.FREEAMOUNT].bonuses.length === 1) {
			return t("bonus.notificationTextFreeAmountAllGames").replace("%%amount%%", groupedNewBonuses[BONUS_TYPE.FREEAMOUNT].amount).replace("%%currency%%", currencyCode).replace("%%endDate%%", formatDate(groupedNewBonuses.endDate));
		} else if (groupedNewBonuses[BONUS_TYPE.FREEBET].bonuses.length === 1) {
			return t("bonus.notificationTextFreeBetAllGames")
				.replace("%%quantity%%", groupedNewBonuses[BONUS_TYPE.FREEBET].roundCount)
				.replace("%%amount%%", groupedNewBonuses[BONUS_TYPE.FREEBET].amount)
				.replace("%%currency%%", currencyCode)
				.replace("%%endDate%%", formatDate(groupedNewBonuses.endDate));
		} else {
			return "";
		}
	};

	const showPopup = isMobile ? localStorage.get(`vs__isNewBonus__${sessionId}`) : newBonuses.length > 0;

	const onCancel = () => {
		isMobile && localStorage.set(`vs__isNewBonus__${sessionId}`, false);
		dispatch(setBonusSeenAction());
	};

	/** Adding initial Animation for images container */
	useEffect(() => {
		if (!isMobile) {
			return;
		}
		if (newBonuses.length !== 1) {
			return;
		}
		if (newBonuses[0].gameTypes.length <= 3) {
			return;
		}
		if (!imagesContainerRef.current) {
			return;
		}
		imagesContainerRef.current.classList.add("vs--bonus-notification-images-animation");
	}, []);

	useEffect(() => {
		if (showNotification) {
			const onClose = () => setShowNotification(false)

			vsToast.success(t("bonus.bonusIsNowActive"), { onClose })

			return onClose
		}
	}, [showNotification, setShowNotification]);

	/** Play notification sound */
	useEffect(() => {
		if (showPopup) {
			if (isMobile && isSound) {
				playAudio(buildPathToStaticFolderOfCDN("media/bonus.mp3"));
			}
		}
	}, [showPopup]);

	return showPopup ? (
		<Modal coordinates={isMobile ? { clientX: "50vw", clientY: "50vh" } : undefined} isOpen={true} onCancel={onCancel} classNames={{ content: "vs--bonus-notification-modal", outside: "vs--bonus-notification-modal-outside" }} onOutsideClick={isMobile ? onCancel : () => { }}>
			<div className="vs--bonus-notification">
				<div className="vs--bonus-notification-header">
					<img src={buildPathToStaticFolderOfCDN("images/misc/bonus-present.png")} alt="bonus" className="vs--mt-16" />
					<div className={"vs--mb-16 vs--mt-16" + (isMobile ? ' vs--pl-8 vs--pr-8' : ' vs--pl-16 vs--pr-16')}>
						<span className={"vs--font-medium vs--text-center" + (isMobile ? ' vs--font-normal' : ' vs--font-big')}>
							{getNotificationText()}
							<br />
						</span>
					</div>
				</div>
				{newBonuses.length > 1 && (
					<ScrollBar className={isMobile ? "vs--bonus-notification-fields-scrollBar" : ""} vertical={true}>
						<div ref={fieldsContainerRef} className="vs--bonus-notification-fields">
							{Object.values(BONUS_TYPE).map((bonusType) => {
								return (
									<div className="vs--mb-16 vs--bonus-notification-fields-item" key={bonusType}>
										<div className="vs--bonus-notification-fields-title vs--mb-8">{groupedNewBonuses[bonusType].bonuses.length > 0 && <span className="vs--font-small vs--font-medium">{bonusType === BONUS_TYPE.FREEBET ? t("bonus.freeBet") : t("bonus.freeAmount")}</span>}</div>
										{groupedNewBonuses[bonusType].bonuses.map((bonus) => (
											<div className="vs--bonus-notification-fields-block" key={bonus.bonusId}>
												<div className="vs--bonus-notification-fields-table vs--flex vs--flex-col">
													<div className={`vs--bonus-notification-fields-table-tr vs--flex vs--flex-row vs--align-center vs--font-regular vs--font-normal`} key={bonus.bonusId}>
														{fields.map(({ field: { name, render } }) => (
															<div key={name} className="vs--bonus-notification-fields-table-tr-td vs--font-small vs--font-regular vs--pl-12 vs--pr-16">
																{render(bonus?.name, bonus)}
															</div>
														))}
													</div>
												</div>
											</div>
										))}
									</div>
								);
							})}
						</div>
					</ScrollBar>
				)}

				{newBonuses.length === 1 && (
					<ScrollBar horizontal={true}>
						<div ref={imagesContainerRef} className="vs--bonus-notification-images">
							{newBonuses[0].gameTypes.map((gameType, _, arr) => {
								return <div key={gameType} data-gametype={gameType} className={`vs--bonus-notification-images-image ${getImagesQuantityClassName(arr.length)}`} />;
							})}
						</div>
					</ScrollBar>
				)}

				<div className="vs--bonus-notification-footer">
					<button
						className="vs--button vs--title-white vs--font-medium vs--font-normal vs--mb-16 vs--mt-16 vs--pl-24 vs--pr-24 vs--pt-8 vs--pb-8"
						onClick={() => {
							if (isCurrentBonus) {
								dispatch(useBonusThunk(true));
								setShowNotification(true);
							}
							onCancel();
						}}
					>
						{isCurrentBonus ? t("bonus.useBonus") : t("common.ok")}
					</button>
				</div>
			</div>
		</Modal>
	)
		: <Fragment />
};

export default BonusActivationPopup;
