import PropTypes from "prop-types";
import InputRadio from "components/ui/input/inputRadio";

const InputRadioGroup = ({ className = "", side, options = [], value, onChange, disabled = false, name }) => {
	return (
		<div className={`vs--ui-input-radio-group ${className ? className : ""}`}>
			{options.map((option) => {
				return <InputRadio key={option.value} name={name} label={option.label} value={option.value} side={side} disabled={disabled} isChecked={value === option.value} onChange={onChange} />;
			})}
		</div>
	);
};

InputRadioGroup.propTypes = {
	/** Classname for Radio group content */
	className: PropTypes.string,
	/** Input label side */
	side: PropTypes.oneOf(["left", "right"]),
	/** Disabled state for radio input */
	disabled: PropTypes.bool,
	/** Radio buttons group options */
	options: PropTypes.array,
	/** Callback, that will be called on Radio button checked state change */
	onChange: PropTypes.func,
	/** Radio buttons group name */
	name: PropTypes.string
};

export default InputRadioGroup;
