import { createAsyncThunk } from "@reduxjs/toolkit";

import sessionService from "services/api/session";
import { getUser } from "utils/auth";
import { getCurrentSettings } from "utils/settings";
import localStorage from "utils/localStorage";

import { sliceName } from "./initialState";
import { selectSession, selectIsAuthorized, selectIsDemoSession, selectQuickBetAmount, selectSessionBonuses, selectIsQuickBetEnabled } from "./selectors";
import { selectUseBonus } from "../bonus/selectors";
import { BONUS_TYPE } from "constants/bonus.constants";
import { setStakeAction } from "../betslip/actions";
import { getUrlVars } from "utils/common";

export const createSessionThunk = createAsyncThunk(`${sliceName}/createSession`, async (_, { signal }) => {
	const { user, sign } = getUser();
	const response = await sessionService.createSession(user, sign, signal);

	return response.data;
});

export const createStreamAccessThunk = createAsyncThunk(`${sliceName}/createStreamAccess`, async (_, { signal }) => {
	const guid = getUrlVars()["guid"];
	const response = await sessionService.createStreamAccess(guid, signal);
	return response.data.value;
})

export const authenticateThunk = createAsyncThunk(`${sliceName}/authenticate`, async (sessionId, { signal }) => {
	const response = await sessionService.authenticate(sessionId, signal);

	return response.data.value;
});

// TODO: discussed with Narek, will be handled with Tokenmanager service in near future 
export const refreshTokenThunk = createAsyncThunk(`${sliceName}/refreshToken`, async ({ sessionId }, { signal }) => {
	const response = await sessionService.refreshToken(sessionId, signal);

	return response.data.value;
});

export const streamConfigurationThunk = createAsyncThunk(`${sliceName}/streamConfiguration`, async ({ id, fromStream = false }, { signal }) => {
	const response = await sessionService.streamConfiguration(id, fromStream, signal);

	return response.data.value;
})

export const sessionDetailsThunk = createAsyncThunk(`${sliceName}/sessionDetails`, async (globalVariables, { signal }) => {
	const response = await sessionService.details(signal);

	return { data: response.data.value, options: globalVariables };
});

export const updateSessionDetailsThunk = createAsyncThunk(`${sliceName}/updateSessionDetails`, async (globalVariables, { signal }) => {
	const response = await sessionService.details(signal);

	return { data: response.data.value, options: globalVariables };
});

export const switchToRealModeThunk = createAsyncThunk(`${sliceName}/switchToRealMode`, async (_, { signal }) => {
	const response = await sessionService.switchToRealMode(signal);

	return response.data.value;
});

export const saveSettingsThunk = createAsyncThunk(
	`${sliceName}/saveSettings`,
	async (settings, { signal, getState }) => {
		const state = getState();
		const isAuthorized = selectIsAuthorized(state);
		const isDemo = selectIsDemoSession(state);
		const settingsState = getCurrentSettings(selectSession(state));

		const newSettings = {
			...settingsState,
			...settings
		};

		if (!isAuthorized || isDemo) {
			return newSettings;
		}

		const response = await sessionService.saveSettings(newSettings, signal);

		return response.data.value;
	}
);

export const setBonusThunk = createAsyncThunk(`${sliceName}/setBonus`, async (
	{ bonus, sessionId, options = { isMobile: false } },
	{ getState, dispatch }
) => {
	const isNewBonus = bonus?.isNewBonus;

	if (options.isMobile && localStorage.get(`vs__isNewBonus__${sessionId}`) !== false && isNewBonus) {
		localStorage.set(`vs__isNewBonus__${sessionId}`, true);
	}

	const state = getState();
	const bonuses = selectSessionBonuses(state);
	const isQuickBetEnabled = selectIsQuickBetEnabled(state);
	const quickBetAmount = selectQuickBetAmount(state);
	const useBonus = selectUseBonus(state);

	let newBonuses = [...bonuses];

	if (newBonuses.some((b) => b.bonusId === bonus.bonusId)) {
		newBonuses = newBonuses.map((b) => (b.bonusId === bonus.bonusId ? { ...bonus } : b));
	} else {
		newBonuses = newBonuses.concat([bonus]);
	}

	newBonuses = newBonuses.filter((b) => {
		return b.bonusType === BONUS_TYPE.FREEBET ? b.roundCount > 0 : b.bonusType === BONUS_TYPE.FREEAMOUNT ? b.amount > 0 : false;
	});

	if (useBonus && isQuickBetEnabled && newBonuses[0] && newBonuses[0].bonusType === BONUS_TYPE.FREEBET && newBonuses[0].amount !== quickBetAmount) {
		dispatch(setStakeAction(newBonuses[0].amount))
		dispatch(saveSettingsThunk({ quickBetAmount: newBonuses[0].amount }));
	}

	return newBonuses;
});