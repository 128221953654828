import { Suspense } from "react";
import { Outlet } from "react-router-dom";

import Loader from "components/ui/loader";

const SuspenseLayout = (props) => {
	return (
		<Suspense fallback={<Loader full={true} />}>
			<Outlet {...props} />
		</Suspense>
	);
};

export default SuspenseLayout;
