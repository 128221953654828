import { useLayoutEffect } from "react";
import { Outlet } from "react-router-dom";

/** Stream Layout Component */
const Layout = () => {
	useLayoutEffect(() => {
		document.documentElement.setAttribute("data-stream-frame", 1);
		return () => {
			document.documentElement.removeAttribute("data-stream-frame");
		};
	}, []);
	return (
		<div className="vs--container vs--stream">
			<Outlet />
		</div>
	);
};

export default Layout;
