import { useContext, useImperativeHandle, useLayoutEffect, useMemo, useReducer } from "react";
import { StoreContext, EventTargetContext } from "contexts/navbar";
import { navbarReducer, createNavbarDefaultState, setMenuButton, setArrowButtons, setIsScrollable, setActiveElement, setAllowIntoView } from "contextStores/navbar.duck";
import { isNullish } from "utils/common";
import { VSEvent, VSEventTarget } from "helpers/customEvents";
import { NAVBAR_EVENT_TYPES, NAVBAR_SUBEVENT_TYPES } from "constants/navbar.constants";

export const EventTargetProvider = ({ children, eventTargetRef }) => {
	const eventTarget = useMemo(() => new VSEventTarget(), []);
	useImperativeHandle(eventTargetRef, () => eventTarget);

	return <EventTargetContext.Provider value={eventTarget}>{children}</EventTargetContext.Provider>;
};

export const StoreProvider = ({ children, allowIntoView = true, menuButton, arrowButtons }) => {
	const [navbarState, navbarStateDispatch] = useReducer(navbarReducer, createNavbarDefaultState());
	const eventTarget = useContext(EventTargetContext);

	useLayoutEffect(() => {
		navbarStateDispatch(setMenuButton(menuButton));
	}, [navbarStateDispatch, setMenuButton, menuButton]);

	useLayoutEffect(() => {
		navbarStateDispatch(setArrowButtons(arrowButtons));
	}, [navbarStateDispatch, setArrowButtons, arrowButtons]);

	useLayoutEffect(() => {
		navbarStateDispatch(setAllowIntoView(allowIntoView));
	}, [navbarStateDispatch, setAllowIntoView, allowIntoView]);

	useLayoutEffect(() => {

		const isScrollingHandler = (event) => {
			navbarStateDispatch(setIsScrollable(!event.data));
		};
		const setActiveListener = (event) => {
			navbarStateDispatch(setActiveElement(event.data));
		};

		eventTarget.addEventListener(NAVBAR_EVENT_TYPES.THERE_IS_NOT_ELEMENTS_OR_ALL_VISIBLE, isScrollingHandler);
		eventTarget.addEventListener(NAVBAR_EVENT_TYPES.ACTIVE_ELEMENT, setActiveListener);
		return () => {
			eventTarget.removeEventListener(NAVBAR_EVENT_TYPES.THERE_IS_NOT_ELEMENTS_OR_ALL_VISIBLE, isScrollingHandler);
			eventTarget.removeEventListener(NAVBAR_EVENT_TYPES.ACTIVE_ELEMENT, setActiveListener);
		}
	}, [navbarStateDispatch, eventTarget])

	useLayoutEffect(() => {
		if (isNullish(navbarState.activeElement)) {
			return;
		}
		const eventType = NAVBAR_EVENT_TYPES.MESSAGE_TO_ELEMENT + navbarState.activeElement;
		const subEventType = NAVBAR_SUBEVENT_TYPES[NAVBAR_EVENT_TYPES.MESSAGE_TO_ELEMENT].UPDATE_ELEMENT;
		eventTarget.dispatchEvent(new VSEvent(eventType, [{ type: subEventType, payload: { select: true } }]));
		return () => {
			eventTarget.dispatchEvent(new VSEvent(eventType, [{ type: subEventType, payload: { select: false } }]));
		};
	}, [navbarState]);

	const value = useMemo(() => {
		return [navbarState, navbarStateDispatch];
	}, [navbarState, navbarStateDispatch]);

	return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
};
