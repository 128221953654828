import { useEffect, Fragment, lazy } from "react";
import { RouterProvider, Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { Provider } from "react-redux";
import store from "store/configureStore";

const HomeRoute = lazy(() => import("routes/home.route"));
const ResultsRoute = lazy(() => import("routes/results.route").then(({ ResultsRoute }) => ({ default: ResultsRoute })));
const ResultsSeasonRoute = lazy(() => import("routes/results.route").then(({ ResultsSeasonRoute }) => ({ default: ResultsSeasonRoute })));
const ResultsSeasonStructureRoute = lazy(() => import("routes/results.route").then(({ ResultsSeasonStructureRoute }) => ({ default: ResultsSeasonStructureRoute })));
const StatisticsRoute = lazy(() => import("routes/statistics.route"));
const HistoryRoute = lazy(() => import("routes/history.route"));
const EventRoute = lazy(() => import("routes/event.route"));
const SeasonEventRoute = lazy(() => import("routes/seasonEvent.route"));
const RulesRoute = lazy(() => import("routes/rules.route"));
const SettingsRoute = lazy(() => import("routes/settings.route"));
const BonusRoute = lazy(() => import("routes/bonus.route"));
const StreamRoute = lazy(() => import("routes/stream.route"));
// Layouts
import SuspenseLayout from "layouts/suspense.layout";
import RootLayout from "layouts/root.layout";
import MainLayoutRoute from "layouts/main.layout";
import StreamLayoutRoute from "layouts/stream.layout";
import Paths from "constants/path.constants";
import { CLIENT_API } from "constants/integration.constants";
import { sendPostMessageToParent } from "utils/iframe";
import "translations/config";
import GlobalVariablesProvider from "providers/globalVariablesProvider";
import useGlobalVariables from "hooks/useGlobalVariables";
import { MessageContainer } from "utils/toast";

const App = () => {
	const router = createBrowserRouter(
		createRoutesFromElements(
			<Route path={Paths.HOME} element={<RootLayout />} errorElement={<Fragment />}>
				<Route path={Paths.HOME} element={<MainLayoutRoute />}>
					<Route path={Paths.HOME} element={<SuspenseLayout />}>
						<Route path={Paths.HOME} element={<HomeRoute />} />
						<Route path={Paths.RESULTS} element={<ResultsRoute />} />
						<Route path={Paths.RESULTS_SEASON} element={<ResultsSeasonRoute />} />
						<Route path={Paths.RESULTS_SEASON_STRUCTURE} element={<ResultsSeasonStructureRoute />} />
						<Route path={Paths.STATISTICS} element={<StatisticsRoute />} />
						<Route path={Paths.HISTORY} element={<HistoryRoute />} />
						<Route path={Paths.HISTORY_SINGLE} element={<HistoryRoute />} />
						<Route path={Paths.EVENT} element={<EventRoute />} />
						<Route path={Paths.SEASON_EVENT} element={<SeasonEventRoute />} />
						<Route path={Paths.RULES} element={<RulesRoute />} />
						<Route path={Paths.SETTINGS} element={<SettingsRoute />} />
						<Route path={Paths.BONUS} element={<BonusRoute />} />
					</Route>
				</Route>
				<Route path={Paths.STREAM} element={<StreamLayoutRoute />}>
					<Route path="" element={<SuspenseLayout />}>
						<Route path="" element={<StreamRoute />} />
					</Route>
				</Route>
			</Route>
		)
	);
	return (
		<GlobalVariablesProvider>
			<App.PreLoad />
			<Provider store={store}>
				<div className="App">
					<RouterProvider router={router} />
					<iframe id="vs--height-change-listener" className="vs--height-change-listener" tabIndex="-1"></iframe>
				</div>
				<MessageContainer />
			</Provider>
		</GlobalVariablesProvider>
	);
};

App.PreLoad = () => {
	const { isMobile } = useGlobalVariables();

	useEffect(() => {
		window.scrollTo(0, 0);

		if (isMobile) {
			document.documentElement.setAttribute("data-ismobile", 1)
			/** For RGS */
			sendPostMessageToParent({ type: CLIENT_API.FIT_FULL_HEIGHT });
		} else {
			document.documentElement.setAttribute("data-ismobile", 0);
		}
	}, [isMobile]);

	useEffect(() => {
		const heightChangeIframe = document.getElementById("vs--height-change-listener");
		if (heightChangeIframe) {
			heightChangeIframe.contentWindow.addEventListener("resize", () => {
				/** For RGS */
				sendPostMessageToParent({ type: CLIENT_API.HEIGHT_CHANGE, height: document.body.clientHeight });

				/** For Builder */
				sendPostMessageToParent({ eventName: "height-change", height: document.body.clientHeight });
			});
		}
	}, []);

	return null;
};

export default App;