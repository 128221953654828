import { Fragment, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { mergeClassNames } from "utils/common";
import { toggleExpandRow } from "utils/table";
import { COLLAPSE_ROW_CONFIG, DEFAULT_CLASSES } from "constants/table.constants";
import useEvent from "hooks/useEvent";

const ExpandableRow = ({ colSpan, alwaysExpanded, isExpanded, expandContentComponent: ExpandContentComponent, simpleRowComponent: SimpleRowComponent, ...props }) => {
	const [visibilityCollapse, setVisibilityCollapse] = useState(!alwaysExpanded);
	const recalculateHeights = useEvent(() => {
		toggleExpandRow(collapseRowRef.current, isExpanded, {
			collapseSelector: "[data-collapse]",
			collapseContentSelector: "[data-collapse-content]",
			collapseWrapperHideClass: DEFAULT_CLASSES.ROW_COLLAPSE_WRAPPER_HIDDEN
		});
	});

	const collapseRowRef = useRef();
	const firstRenderCheckingRef = useRef(false);

	useLayoutEffect(() => {
		if (alwaysExpanded) {
			return;
		}
		if (!firstRenderCheckingRef.current && isExpanded) {
			firstRenderCheckingRef.current = true;
		}
		if (isExpanded) {
			setVisibilityCollapse(!isExpanded);
		}
	}, [alwaysExpanded, isExpanded, setVisibilityCollapse]);

	useEffect(() => {
		if (alwaysExpanded) {
			return;
		}
		const timeoutId = setTimeout(recalculateHeights, COLLAPSE_ROW_CONFIG.REMOVE_HIDEN_CLASS_TIMEOUT);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [alwaysExpanded, isExpanded, setVisibilityCollapse]);

	useEffect(() => {
		if (alwaysExpanded || isExpanded) {
			return;
		}
		const timeoutId = setTimeout(setVisibilityCollapse, COLLAPSE_ROW_CONFIG.ADD_HIDEN_CLASS_TIMEOUT, !isExpanded);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [alwaysExpanded, isExpanded, setVisibilityCollapse]);

	const rowClasses = mergeClassNames(DEFAULT_CLASSES.BODY_ROW_CLASS, DEFAULT_CLASSES.ROW_COLLAPSE, visibilityCollapse ? DEFAULT_CLASSES.ROW_COLLAPSE_HIDDEN : DEFAULT_CLASSES.ROW_COLLAPSE_VISIBLE);

	const wrapperDivClasses = mergeClassNames(DEFAULT_CLASSES.ROW_COLLAPSE_WRAPPER, alwaysExpanded ? DEFAULT_CLASSES.ROW_COLLAPSE_WRAPPER_VISIBLE : DEFAULT_CLASSES.ROW_COLLAPSE_WRAPPER_HIDDEN);

	const expandProps = useMemo(() => ({ recalculateHeights }), [recalculateHeights]);

	return (
		<Fragment>
			<SimpleRowComponent {...props} />
			<tr ref={collapseRowRef} className={rowClasses}>
				<td className={DEFAULT_CLASSES.COLUMN_EXPANDABLE_CLASS} colSpan={colSpan}>
					<div data-collapse className={wrapperDivClasses}>
						<div data-collapse-content className={DEFAULT_CLASSES.ROW_COLLAPSE_CONTENT}>
							{alwaysExpanded || firstRenderCheckingRef.current ? <ExpandContentComponent {...props} expandProps={expandProps} /> : null}
						</div>
					</div>
				</td>
			</tr>
		</Fragment>
	);
};

export default ExpandableRow;
