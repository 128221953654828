
import { betEventHistoryThunk } from "./thunks";

export const addEventHistoryCase = (builder) => {
	builder.addCase(betEventHistoryThunk.pending, (state) => {
		state.loading = true;
	});

	builder.addCase(betEventHistoryThunk.fulfilled, (state, { payload: data, meta: { arg: id } }) => {
		state.history[id] = data;
		state.loading = false;
	});

	builder.addCase(betEventHistoryThunk.rejected, (state, { error, meta: { arg } }) => {
		state.loading = false;
	});
}