import { createSlice } from "@reduxjs/toolkit";

import {
	addCurrentTimeCases,
	addEventLiveInfoCases,
	addRulesCases,
	addStreamCases,
	addFeedbackCases,
	addMessageCases
} from "./extraReducers";
import { initialState, sliceName } from "./initialState";

const commonSlice = createSlice({
	name: sliceName,
	initialState: initialState,
	extraReducers: (builder) => {
		addCurrentTimeCases(builder);
		addEventLiveInfoCases(builder);
		addRulesCases(builder);
		addStreamCases(builder);
		addFeedbackCases(builder);
		addMessageCases(builder);
	}
});

export const commonSliceName = sliceName;
export const commonSliceReducer = commonSlice.reducer;