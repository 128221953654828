import { getDefaultDate } from "utils/dateTime";

const sliceName = "game";
const initialState = {
	isBonusBetDisabled: false,
	currentGameType: null,
	liveAndUpcomings: {
		loading: false,
		data: []
	},
	lastResults: {
		loading: false,
		data: []
	},
	results: {
		loading: false,
		data: [],
		filters: {
			limit: 20,
			page: 1,
			...getDefaultDate(),
			id: ""
		},
		total: 0
	},
	seasonResult: {
		seasonId: null,
		loading: false,
		data: {},
		filters: {
			id: ""
		},
		total: 0
	},
	matches: {
		loading: false,
		data: {}
	},
	currentEventId: null,
	gameCountDown: {},
	marketsActiveKeys: {}
}

export { sliceName, initialState };