import ReactGA from "react-ga";

export const initializeGA = (ga_id) => {
	ReactGA.initialize(ga_id);
};

export const sendGAPageView = () => {
	ReactGA.pageview(window.location.origin, null, "Virtual Sports");
};

export const sendGAEvent = (category, action, value) => {
	ReactGA.event({
		category: category,
		action: action,
		value: value
	});
};
