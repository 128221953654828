import i18n from "translations/config";

import BaseMarkets from "./baseMarkets";

import { PERIOD, RACING_MARKETS_TABS, RACING_MARKETS_GROUPS } from "constants/market.constants";

class RacingMarkets extends BaseMarkets {
	/** Get All possible tabs
	 * @function
	 * @param {array} rtps - the rtps for ordering
	 * @returns {object}
	 * @memberof RacingMarkets
	 */
	getGroups() {
		return Object.keys(this.getGroupsStructure());
	}

	/** Get All possible market tabs with their markets
	 * @function
	 * @returns {object}
	 * @memberof RacingMarkets
	 */
	getGroupsStructure() {
		return {
			[RACING_MARKETS_TABS.MAIN]: {
				groups: [
					{ group: RACING_MARKETS_GROUPS.WINNER, title: RACING_MARKETS_GROUPS.WINNER, period: PERIOD.NONE },
					{ group: RACING_MARKETS_GROUPS.PLACE, title: RACING_MARKETS_GROUPS.PLACE, period: PERIOD.NONE },
					{ group: RACING_MARKETS_GROUPS.SHOW, title: RACING_MARKETS_GROUPS.SHOW, period: PERIOD.NONE }
				]
			},
			[RACING_MARKETS_TABS.FORECAST]: {
				groups: [
					{ group: RACING_MARKETS_GROUPS.PAIR, title: RACING_MARKETS_GROUPS.PAIR, period: PERIOD.NONE },
					{ group: RACING_MARKETS_GROUPS.PAIR_ANY_ORDER, title: RACING_MARKETS_GROUPS.PAIR_ANY_ORDER, period: PERIOD.NONE }
				]
			},
			[RACING_MARKETS_TABS.TRICAST]: {
				groups: [
					{ group: RACING_MARKETS_GROUPS.TRIPLE, title: RACING_MARKETS_GROUPS.TRIPLE, period: PERIOD.NONE },
					{ group: RACING_MARKETS_GROUPS.TRIPLE_ANY_ORDER, title: RACING_MARKETS_GROUPS.TRIPLE_ANY_ORDER, period: PERIOD.NONE }
				]
			},
			[RACING_MARKETS_TABS.ODD_EVEN]: {
				groups: [{ group: RACING_MARKETS_GROUPS.RACE_EVEN_ODD, title: RACING_MARKETS_GROUPS.RACE_EVEN_ODD, period: PERIOD.NONE }]
			},
			[RACING_MARKETS_TABS.TOTAL]: {
				groups: [
					{ group: RACING_MARKETS_GROUPS.WINNER_NUMBER, title: RACING_MARKETS_GROUPS.WINNER_NUMBER, period: PERIOD.NONE },
					{ group: RACING_MARKETS_GROUPS.TOTAL_123_PLACED, title: RACING_MARKETS_GROUPS.TOTAL_123_PLACED, period: PERIOD.NONE }
				]
			},
			[RACING_MARKETS_TABS.EXTRA]: {
				groups: [
					{ group: RACING_MARKETS_GROUPS.WILL_NOT_FINISHERS, title: RACING_MARKETS_GROUPS.WILL_NOT_FINISHERS, period: PERIOD.NONE },
					{ group: RACING_MARKETS_GROUPS.TOTAL_NOT_FINISHERS, title: RACING_MARKETS_GROUPS.TOTAL_NOT_FINISHERS, period: PERIOD.NONE }
				]
			}
		};
	}

	/** Function to sort rtps data by order Number
	 * @function
	 * @param {array} markets - given markets
	 * @param {array} rtps
	 * @param {Callback} getRtp
	 * @returns {string} data - sorted array
	 */
	sortMarketsByOrderNumber = (markets, rtps, getRtp = Function.prototype, period = null) => {
		const groupedRtps = rtps.reduce((acc, rtp) => {
			if (period !== null) {
				if (rtp.period === period) {
					acc[rtp.name] = rtp;
				}
				return acc;
			}
			if (!acc[rtp.name]) {
				acc[rtp.name] = rtp;
			}

			return acc;
		}, {});

		return [...markets].sort((item1, item2) => {
			const market1 = getRtp(groupedRtps, item1);
			const market2 = getRtp(groupedRtps, item2);
			const m1OrderNumber = market1 ? market1.orderNumber : 0;
			const m2OrderNumber = market2 ? market2.orderNumber : 0;

			return m1OrderNumber > m2OrderNumber ? 1 : -1;
		});
	};

	/** Transform markets array to the array , useful for markets UI
	 * @function
	 * @param {array } data - data to transform
	 * @param {object} gameData - Event data
	 * @param {Array} rtps - Array of rtps to apply on markets
	 * @param {number} gameType - the gameType
	 * @returns {object}
	 * @memberof RacingMarkets
	 */
	makeMarketsArray(data, gameData, rtps, gameType) {
		const result = [];

		data.forEach((market) => {
			let item = {
				systemGroup: market.group,
				bets: [],
				group: market.group,
				argument: market.argument
			};

			if (market.group === RACING_MARKETS_GROUPS.WINNER_NUMBER) {
				market.oddList.forEach((odd) => {
					if ([3.5, 5.5, 7.5, 9.5].includes(market.argument)) {
						item.bets.push({
							name: odd.outcome + " " + market.argument,
							factor: this.applyRtp(odd.factor, market.group, market.period, market.argument, odd.outcome, rtps),
							oddState: odd.state,
							outcome: odd.outcome,
							argument: market.argument,
							id: odd.id,
							showName: this.makeName(odd.outcome + " " + market.argument, gameData, gameType, market.group)
						});
					}
				});
			} else if ([RACING_MARKETS_GROUPS.TOTAL_123_PLACED, RACING_MARKETS_GROUPS.TOTAL_NOT_FINISHERS].includes(market.group)) {
				market.oddList.forEach((odd) => {
					item.bets.push({
						name: odd.outcome + " " + market.argument,
						factor: this.applyRtp(odd.factor, market.group, market.period, market.argument, odd.outcome, rtps),
						oddState: odd.state,
						outcome: odd.outcome,
						argument: market.argument,
						id: odd.id,
						showName: this.makeName(odd.outcome + " " + market.argument, gameData, gameType, market.group)
					});
				});
			} else if (market.group === RACING_MARKETS_GROUPS.RACE_EVEN_ODD) {
				market.oddList.forEach((odd) => {
					item.bets.push({
						name: odd.outcome,
						factor: this.applyRtp(odd.factor, market.group, market.period, market.argument, odd.outcome, rtps),
						oddState: odd.state,
						outcome: odd.outcome,
						id: odd.id,
						showName: this.makeName(odd.outcome, gameData, gameType, market.group)
					});
				});
			} else {
				market.oddList.forEach((odd) => {
					item.bets.push({
						name: odd.outcome,
						factor: this.applyRtp(odd.factor, market.group, market.period, market.argument, odd.outcome, rtps),
						oddState: odd.state,
						outcome: odd.outcome,
						id: odd.id,
						showName: this.makeName(odd.outcome, gameData, gameType, market.group)
					});
				});
			}

			item.bets = item.bets.map((b) => (b.factor >= 1.01 ? b : { ...b, disabled: true }));

			result.push(item);
		});

		return result;
	}

	/** Add teams name into market name
	 * @function
	 * @param {string } str - market name
	 * @param {object} gameData - Game Data
	 * @param {number} gameType - Game type
	 * @param {number} group - market group
	 * @returns {string}
	 * @memberof RacingMarkets
	 */
	makeName(str, gameData, gameType, group) {
		let result = str;
		switch (true) {
			case str.includes("Even"):
				result = str.replace("Even", i18n.t(`markets.${gameType}.Even`));
				break;
			case str.includes("Over"):
				result = str.replace("Over", i18n.t(`markets.${gameType}.over`));
				break;
			case str.includes("Under"):
				result = str.replace("Under", i18n.t(`markets.${gameType}.under`));
				break;
			case str.includes("Odd"):
				result = str.replace("Odd", i18n.t(`markets.${gameType}.Odd`));
				break;
			case !isNaN(str):
				result = `#${Number(str)}`;
				break;
			case str.startsWith("Race "):
				result = `#${Number(str)}`;
				break;
			case /^[0-9]+-[0-9]+$/.test(str):
				const isNotPairAnyOrder = group !== RACING_MARKETS_GROUPS.PAIR_ANY_ORDER;
				result = `${isNotPairAnyOrder ? `${i18n.t(`markets.${gameType}.1st`)} ` : ""}#${Number(str.split("-")[0])}, ${isNotPairAnyOrder ? `${i18n.t(`markets.${gameType}.2nd`)}` : ""}#${Number(str.split("-")[1])}`;
				break;
			case /^[0-9]+-[0-9]+-[0-9]+$/.test(str):
				const isNotTripleAnyOrder = group !== RACING_MARKETS_GROUPS.TRIPLE_ANY_ORDER;
				result = `${isNotTripleAnyOrder ? `${i18n.t(`markets.${gameType}.1st`)} ` : ""}#${Number(str.split("-")[0])}, ${isNotTripleAnyOrder ? `${i18n.t(`markets.${gameType}.2nd`)}` : ""}#${Number(str.split("-")[1])}, ${isNotTripleAnyOrder ? `${i18n.t(`markets.${gameType}.3rd`)}` : ""}#${Number(str.split("-")[2])}`;
				break;
			default:
				break;
		}
		return result;
	}

	/** Make market beuty name from system name
	 * @function
	 * @param {object} market - the market object
	 * @returns {string}
	 * @memberof RacingMarkets
	 */
	makeGroupTitle(market) {
		let res = "";
		let gr = market.group;
		res += i18n.t(`markets.${market.gameType}.${gr}`) + (market.argument ? " " + market.argument : "");
		return res;
	}

	/** Make beuty text of market
	 * @function
	 * @param {object} market - market
	 * @returns {string}
	 * @memberof RacingMarkets
	 */
	makeBetText(market) {
		return this.makeGroupTitle({ group: market.group, argument: market.argument, gameType: market.gameType }) + ": " + this.makeName(market.outcome, market.gameData, market.gameType, market.group);
	}

	/** Make market beuty name, to show in winner market section
	 * @function
	 * @param {object} market - market
	 * @returns {string}
	 * @memberof RacingMarkets
	 */
	makeWinnerMarketName(market) {
		return this.makeName(market.outcome, market.gameData, market.gameType, market.group);
	}
}

export default RacingMarkets;
