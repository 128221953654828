import { isSeasonGame } from "utils/common";
import { sendPostMessageToParent } from "utils/iframe";
import { GAME_STATUSES, GAME_EVENT_TYPE } from "constants/game.constants";
import { CLIENT_API } from "constants/integration.constants";

/** Sending event/week status to the RGS, when getting upgrade from SignalR */
export const sendEventStatus = (event) => {
	if ((event.status === GAME_STATUSES.FINISHED || event.status === GAME_STATUSES.STARTED) && event.type !== GAME_EVENT_TYPE.LEAGUE) {
		if (isSeasonGame(event.gameType)) {
			if (event.type === GAME_EVENT_TYPE.WEEK) {
				sendPostMessageToParent({ type: CLIENT_API.EVENT, state: event.status === GAME_STATUSES.STARTED ? "start" : "finish" });
			}
		} else {
			sendPostMessageToParent({ type: CLIENT_API.EVENT, state: event.status === GAME_STATUSES.STARTED ? "start" : "finish" });
		}
	}
};
