class ModalService {
	static _instance = null;

	constructor() {
		if (ModalService._instance) {
			return ModalService._instance;
		}

		ModalService._instance = this;

		this.openedModals = [];
	}

	_handleKeyDown = (event) => {
		if (event.code === "Escape") {   // TODO: event keyCode is deprecated, should change in KEY_CODE constants
			const removingModal = this.openedModals.at(-1);

			if (removingModal.onEscapePress) {
				removingModal.onEscapePress();
			}
		}
	}

	add(element) {
		const newOpenedModals = [...this.openedModals];
		newOpenedModals.push(element);
		newOpenedModals.sort((item1, item2) => item1.zIndex - item2.zIndex);

		if(this.openedModals.length === 0  && newOpenedModals.length === 1) {
			document.addEventListener("keydown", this._handleKeyDown);
		}

		this.openedModals = newOpenedModals;
	}

	remove(id) {
		let newOpenedModals = [...this.openedModals];

		if (newOpenedModals.length === 0) {
			return;
		} else if (id) {
			newOpenedModals = this.openedModals.filter((el) => el.id !== id);
		} else {
			newOpenedModals.pop();
		}

		if(this.openedModals.length === 1 && newOpenedModals.length === 0) {
			document.removeEventListener("keydown", this._handleKeyDown);
		}

		this.openedModals = newOpenedModals;
	}
}

const modalServiceInstance = new ModalService();
export default modalServiceInstance;