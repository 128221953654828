import { useEffect } from 'react';
import PropTypes from 'prop-types';

const AnimationMarker = ({ onSuccess }) => {
	useEffect(() => {
		// Find the starting element and calculate its position
		const startElement = document.querySelector('.vs--rightBar-content');
		if (!startElement) return;

		const { left: startX, top: startY } = startElement.getBoundingClientRect();

		// Find the target element and calculate its position
		const targetElement = document.querySelector('[data-element-id="badge"]');
		if (!targetElement) return;

		const { left: targetX, top: targetY } = targetElement.getBoundingClientRect();

		// Create and style the marker
		const marker = document.createElement('div');
		marker.style.position = 'absolute';
		marker.style.left = `${startX}px`;
		marker.style.top = `${startY + 40}px`;
		marker.style.width = '130px';
		marker.style.height = '20px';
		marker.style.backgroundColor = 'var(--brand-color-1)';
		marker.style.zIndex = 1000;
		marker.style.transform = 'scale(1)';
		marker.style.transition = 'transform 0.6s, left 0.6s, top 0.6s';
		marker.className = 'vs--animatable-container vs--animatable-container-start-animation-betslip';

		// Append the marker to the body
		document.body.appendChild(marker);

		// Trigger animation
		requestAnimationFrame(() => {
			marker.style.transform = 'scale(0.4)';
			marker.style.transition = '0.7s';
			marker.style.left = `${targetX - 10}px`;
			marker.style.top = `${targetY + 2}px`;
			marker.style.width = '20px';

			// Add class to target element when marker hits it
			const addClassOnHit = () => {
				targetElement.classList.add('vs--target-animation');
				marker.removeEventListener('transitionend', addClassOnHit);
				setTimeout(() => {
					targetElement.classList.remove('vs--target-animation');
				}, 200);
			};

			marker.addEventListener('transitionend', addClassOnHit);

			// Remove the marker after animation ends
			const removeMarker = () => {
				marker.removeEventListener('transitionend', removeMarker);
				marker.remove();
				if (typeof onSuccess === 'function') {
					onSuccess();
				}
			};
			marker.addEventListener('transitionend', removeMarker);
		});
	}, [onSuccess]);

	return null;
};

AnimationMarker.propTypes = {
	onSuccess: PropTypes.func,
};

export default AnimationMarker;
