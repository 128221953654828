import PropTypes from "prop-types";

export default PropTypes.shape({
	countryName: PropTypes.string,
	countryCode: PropTypes.string,
	flagCode: PropTypes.string,
	goalCount: PropTypes.number,
	goalCount1: PropTypes.number,
	goalCount2: PropTypes.number
});
