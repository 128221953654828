import { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Flag from "components/ui/flag";
import { isChampionsCupGame } from "utils/common";
import Tooltip from "components/ui/tooltip";
import eventType from "types/event.type";

import { mergeClassNames } from "utils/common";

const TreeEvent = ({ event, defaultText = "" }) => {
	const { t } = useTranslation();
	const gameType = event.gameType;

	const getTeamName = (name) => {
		if (!name) {
			return defaultText;
		}

		if (isChampionsCupGame(gameType)) {
			return name;
		}

		return t(`countries.${name}`);
	};

	const collectedData = useMemo(() => {
		const data = {};
		const team1 = event?.gameData?.team1 ?? {};
		const team2 = event?.gameData?.team2 ?? {};

		data.flagCode1 = team1.flagCode;
		data.countryCode1 = team1.countryCode;
		data.countryName1 = team1.countryName;
		data.goalCount1 = team1.goalCount;
		data.overTimeGoalCount1 = team1.overTimeGoalCount;
		data.penaltyShootoutGoalCount1 = team1.penaltyShootoutGoalCount;
		data.flagCode2 = team2.flagCode;
		data.countryCode2 = team2.countryCode;
		data.countryName2 = team2.countryName;
		data.goalCount2 = team2.goalCount;
		data.overTimeGoalCount2 = team2.overTimeGoalCount;
		data.penaltyShootoutGoalCount2 = team2.penaltyShootoutGoalCount;
		return data;
	}, [event]);

	const isOT = typeof collectedData.overTimeGoalCount1 === "number" || typeof collectedData.overTimeGoalCount2 === "number";
	const isPS = typeof collectedData.penaltyShootoutGoalCount1 === "number" || typeof collectedData.penaltyShootoutGoalCount2 === "number";

	const isTeam1Win =
		collectedData.goalCount1 !== collectedData.goalCount2
			? collectedData.goalCount1 > collectedData.goalCount2
			: collectedData.overTimeGoalCount1 !== collectedData.overTimeGoalCount2
				? collectedData.overTimeGoalCount1 > collectedData.overTimeGoalCount2
				: collectedData.penaltyShootoutGoalCount1 !== collectedData.penaltyShootoutGoalCount2
					? collectedData.penaltyShootoutGoalCount1 > collectedData.penaltyShootoutGoalCount2
					: false; /* safe case when all is null */
	const isTeam2Win =
		collectedData.goalCount2 !== collectedData.goalCount1
			? collectedData.goalCount2 > collectedData.goalCount1
			: collectedData.overTimeGoalCount2 !== collectedData.overTimeGoalCount1
				? collectedData.overTimeGoalCount2 > collectedData.overTimeGoalCount1
				: collectedData.penaltyShootoutGoalCount2 !== collectedData.penaltyShootoutGoalCount1
					? collectedData.penaltyShootoutGoalCount2 > collectedData.penaltyShootoutGoalCount1
					: false; /* safe case when all is null */

	return (
		<>
			<div className="vs--cup-result-structure-block vs--flex vs--align-center vs--pl-16 vs--pr-16">
				<div className="vs--cup-result-structure-block-team vs--flex vs--flex-equal vs--align-center">
					{collectedData.flagCode1 ? (
						<div className="vs--mr-4">
							<Flag code={collectedData.flagCode1} gameType={event?.gameType} />
						</div>
					) : null}

					<span className="vs--title-white vs--font-normal">{getTeamName(collectedData.countryCode1)}</span>
				</div>
				{isOT && !isPS ? (
					<div className="vs--cup-result-structure-block-num vs--cup-result-structure-block-border vs--flex vs--align-center vs--pl-16 vs--pr-16">
						<span className={mergeClassNames("vs--title-white vs--font-big", isTeam1Win && "vs--text-green")}>{collectedData.overTimeGoalCount1}</span>
					</div>
				) : isPS ? (
					<div className="vs--cup-result-structure-block-num vs--cup-result-structure-block-border vs--flex vs--align-center vs--pl-16 vs--pr-16">
						<span className={mergeClassNames("vs--title-white vs--font-big", isTeam1Win && "vs--text-green")}>{collectedData.penaltyShootoutGoalCount1}</span>
					</div>
				) : null}
				<div className="vs--cup-result-structure-block-num vs--cup-result-structure-block-border vs--flex vs--align-center vs--pl-16">
					<span className={mergeClassNames("vs--title-white vs--font-big", isTeam1Win && "vs--text-green")}>{collectedData.goalCount1}</span>
				</div>
				{isOT && !isPS ? (
					<Tooltip placement="left" title={<span className="vs--title-white vs--font-normal vs--font-regular">{t("common.overtime")}</span>}>
						<div className="vs--cup-result-structure-overtime vs--flex vs--align-center vs--justify-center">
							<span className="vs--font-small vs--font-bold">{t("common.OvertimeShort")}</span>
						</div>
					</Tooltip>
				) : isPS ? (
					<Tooltip placement="left" title={<span className="vs--title-white vs--font-normal vs--font-regular">{t("common.penaltyShootout")}</span>}>
						<div className="vs--cup-result-structure-overtime vs--flex vs--align-center vs--justify-center">
							<span className="vs--font-small vs--font-bold">{t("common.penaltyShoutoutShort")}</span>
						</div>
					</Tooltip>
				) : null}
			</div>
			<div className="vs--cup-result-structure-block-second vs--flex vs--align-center vs--pl-16 vs--pr-16">
				<div className="vs--flex vs--flex-equal vs--align-center">
					{collectedData.flagCode2 ? (
						<div className="vs--mr-4">
							<Flag code={collectedData.flagCode2} gameType={event?.gameType} />
						</div>
					) : null}
					<span className="vs--title-white vs--font-normal">{getTeamName(collectedData.countryCode2)}</span>
				</div>
				{isOT && !isPS ? (
					<div className="vs--cup-result-structure-block-num vs--cup-result-structure-block-border vs--flex vs--align-center vs--pl-16 vs--pr-16">
						<span className={mergeClassNames("vs--title-white vs--font-big", isTeam2Win && "vs--text-green")}>{collectedData.overTimeGoalCount2}</span>
					</div>
				) : isPS ? (
					<div className="vs--cup-result-structure-block-num vs--cup-result-structure-block-border vs--flex vs--align-center vs--pl-16 vs--pr-16">
						<span className={mergeClassNames("vs--title-white vs--font-big", isTeam2Win && "vs--text-green")}>{collectedData.penaltyShootoutGoalCount2}</span>
					</div>
				) : null}
				<div className="vs--cup-result-structure-block-num vs--cup-result-structure-block-border vs--flex vs--align-center vs--pl-16">
					<span className={mergeClassNames("vs--title-white vs--font-big", isTeam2Win && "vs--text-green")}>{collectedData.goalCount2}</span>
				</div>
			</div>
		</>
	);
};

TreeEvent.propTypes = {
	event: eventType,
	defaultText: PropTypes.string
};

export default TreeEvent;
