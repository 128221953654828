import { buildPathToStaticFolderOfCDN } from "utils/common";

const ComboBoostCoin = () => {
	return (
		<div
			className="vs--combo-boost-coin"
		>
			<img
				className="vs--combo-boost-coin-logo"
				src={buildPathToStaticFolderOfCDN("images/misc/combo-boost-logo.gif")}
				alt="combo-boost"
			/>
		</div>
	)
}

export default ComboBoostCoin;