import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TreeEventSimpleCup from "./treeEventSimpleCup";
import eventType from "types/event.type";

const TreeQuarterFinals = ({ title, data }) => {
	const { t } = useTranslation();
	const getDefaultText = (orderNumber) => `${t("common.winnerOf")} ${t("common.round")} 16 ${t("common.day")} ${Math.ceil(orderNumber / 2)}`;
	return (
		<div className="vs--cup-result-structure-quarter vs--flex vs--flex-col vs--justify-around">
			<div className="vs--cup-result-structure-quarter-line">
				<div className="vs--cup-result-title">
					<span className="vs--font-big vs--font-bold">{title}</span>
				</div>
				<div className="vs--mt-16 vs--cup-result-structure-line">
					<TreeEventSimpleCup event={data?.[0] ?? {}} defaultText={getDefaultText(data?.[0]?.orderNumber ?? 1)} />
				</div>
			</div>
			{data.slice(1).map((event, i) => (
				<div key={event?.id ?? i} className="vs--cup-result-structure-quarter-line">
					<div className="vs--mt-16 vs--cup-result-structure-line">
						<TreeEventSimpleCup event={event} defaultText={getDefaultText(event?.orderNumber ?? 1)} />
					</div>
				</div>
			))}
		</div>
	);
};

TreeQuarterFinals.propTypes = {
	title: PropTypes.string,
	data: PropTypes.arrayOf(eventType)
};

export default TreeQuarterFinals;
