import { useCallback, useEffect } from "react";
import "prop-types";

import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";

import { getSeasonStatisticsThunk } from "store/slices/statistics/thunks";
import { setSeasonStatisticsIdAction, resetStatisticsStateAction } from "store/slices/statistics/actions";
import { selectCurrenSeasonEventId, selectCurrentGameType } from "store/slices/game/selectors";
import { selectSeasonStandings, selectIsSeasonLoading } from "store/slices/statistics/selectors";

const LeagueGamesLiveSeasonHoc = (ReactComponent) => {
	const WrappedReactComponentOfLeagueGamesLiveSeasonHoc = (props) => {
		const seasonId = useAppSelector(selectCurrenSeasonEventId);
		const currentGameType = useAppSelector(selectCurrentGameType);
		const standings = useAppSelector(selectSeasonStandings);
		const loading = useAppSelector(selectIsSeasonLoading);

		const dispatch = useAppDispatch();
		const setStatisticsId = useCallback((id) => dispatch(setSeasonStatisticsIdAction(id)), [dispatch])
		const getStandings = useCallback((gameType) => dispatch(getSeasonStatisticsThunk(gameType)), [dispatch])
		const resetStandings = useCallback(() => dispatch(resetStatisticsStateAction()), [dispatch])

		useEffect(() => {
			setStatisticsId(seasonId);
			return () => {
				setStatisticsId(null);
			};
		}, [seasonId, setStatisticsId]);

		useEffect(() => {
			getStandings(currentGameType);
			return () => {
				resetStandings();
			}
		}, [currentGameType, getStandings, resetStandings])

		return (
			<ReactComponent
				standings={standings}
				gameType={currentGameType}
				loading={loading}
				isFromResults={false}
				{...props}
			/>
		);
	}

	return WrappedReactComponentOfLeagueGamesLiveSeasonHoc;
};

export default LeagueGamesLiveSeasonHoc;
