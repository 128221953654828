import { useRef } from "react";
import PropTypes from "prop-types";

import Tooltip from "../tooltip";

import useOutsideAlerter from "hooks/useOutsideAlerter";
import useMergedState from "hooks/useMergedState";

const OUTSIDE_CLICK_CLASS = "vs--ui-dropdown-wrapper";

const Dropdown = ({
	outsideClickPreventClassName = "vs--ui-dropdown-wrapper",
	showDropdownContent: showDropdownContentProp,
	setShowDropdownContent: setShowDropdownContentProp,
	dropdownContent = null,
	renderSelectable = null,
	children = null,
	placement = 'bottom',
	openOnClick = true,
	...rest
}) => {
	const dropdownRef = useRef(null);

	const [showDropdownContent, setShowDropdownContent] = useMergedState(false, {
		value: showDropdownContentProp,
		onChange: setShowDropdownContentProp
	})

	useOutsideAlerter({ ref: dropdownRef, callback: () => setShowDropdownContent(false), preventClassName: OUTSIDE_CLICK_CLASS });

	return (
		<div {...rest} className={OUTSIDE_CLICK_CLASS} ref={dropdownRef}>
			<Tooltip openOnClick={openOnClick} overlayClassName="vs--ui-dropdown-tooltip" title={dropdownContent} isOpen={showDropdownContent} placement={placement}>
				<span onClick={() => setShowDropdownContent(!showDropdownContent)}>{renderSelectable ? renderSelectable(showDropdownContent) : children}</span>
			</Tooltip>
		</div>
	);
};

Dropdown.propTypes = {
	// Main content of dropdown
	dropdownContent: PropTypes.node.isRequired,
	// Renders selectable component of dropdown
	renderSelectable: PropTypes.func,
	//  Renders selectable component of dropdown if renderSelectable is null
	children: PropTypes.node
};

export default Dropdown;
