export const createNavbarDefaultState = () => {
	return {
		activeElement: null,
		smoothAnimation: true,
		showPrevArrow: false,
		showNextArrow: false,
		showMenu: false,
		allowIntoView: null,
		menuButton: null,
		arrowButtons: null,
		isScrollable: false
	};
};

export const NAVBAR_ACTION_TYPES = {
	UPDATE: "UPDATE",
	SET_ACTIVE_ELEMENT: "SET_ACTIVE_ELEMENT",
	SET_SHOW_PREV_ARROW: "SET_SHOW_PREV_ARROW",
	SET_SHOW_NEXT_ARROW: "SET_SHOW_NEXT_ARROW",
	SET_SHOW_MENU: "SET_SHOW_MENU",
	SET_IS_SCROLLABLE: "SET_IS_SCROLLABLE",
	ALLOW_INTO_VIEW: "ALLOW_INTO_VIEW",
	ALLOW_ARROW_BUTTON_FUNCTIONALITY: "ALLOW_ARROW_BUTTON_FUNCTIONALITY",
	ALLOW_MENU_BUTTON_FUNCTIONALITY: "ALLOW_MENU_BUTTON_FUNCTIONALITY",
	SET_SMOOTH_ANIMATION: "SET_SMOOTH_ANIMATION"
};

export const update = (payload = {}) => ({
	type: NAVBAR_ACTION_TYPES.UPDATE,
	payload: payload
});
export const setActiveElement = (activeElement) => ({
	type: NAVBAR_ACTION_TYPES.SET_ACTIVE_ELEMENT,
	payload: { activeElement }
});
export const setShowPrevArrow = (showPrevArrow) => ({
	type: NAVBAR_ACTION_TYPES.SET_SHOW_PREV_ARROW,
	payload: { showPrevArrow }
});
export const setShowNextArrow = (showNextArrow) => ({
	type: NAVBAR_ACTION_TYPES.SET_SHOW_NEXT_ARROW,
	payload: { showNextArrow }
});
export const setShowMenu = (showMenu) => ({
	type: NAVBAR_ACTION_TYPES.SET_SHOW_MENU,
	payload: { showMenu }
});
export const setAllowIntoView = (allowIntoView) => ({
	type: NAVBAR_ACTION_TYPES.ALLOW_INTO_VIEW,
	payload: { allowIntoView }
});
export const setIsScrollable = (isScrollable) => ({
	type: NAVBAR_ACTION_TYPES.SET_IS_SCROLLABLE,
	payload: { isScrollable }
});
export const setMenuButton = (menuButton) => ({
	type: NAVBAR_ACTION_TYPES.ALLOW_MENU_BUTTON_FUNCTIONALITY,
	payload: { menuButton }
});
export const setArrowButtons = (arrowButtons) => ({
	type: NAVBAR_ACTION_TYPES.ALLOW_ARROW_BUTTON_FUNCTIONALITY,
	payload: { arrowButtons }
});
export const setSmoothAnimation = (smoothAnimation) => ({
	type: NAVBAR_ACTION_TYPES.SET_SMOOTH_ANIMATION,
	payload: { smoothAnimation }
});

export const navbarReducer = (state, { type, payload }) => {
	switch (type) {
		case NAVBAR_ACTION_TYPES.UPDATE:
			const updatePayloadKeys = Object.keys(payload);
			const updateFlag = updatePayloadKeys.some((key) => {
				return Object.prototype.hasOwnProperty.call(state, key) || state[key] !== payload[key];
			});
			if (!updateFlag) {
				return state;
			}
			return { ...state, ...payload };
		case NAVBAR_ACTION_TYPES.SET_ACTIVE_ELEMENT:
			return { ...state, activeElement: payload.activeElement };
		case NAVBAR_ACTION_TYPES.SET_SHOW_PREV_ARROW:
			return { ...state, showPrevArrow: payload.showPrevArrow };
		case NAVBAR_ACTION_TYPES.SET_SHOW_NEXT_ARROW:
			return { ...state, showNextArrow: payload.showNextArrow };
		case NAVBAR_ACTION_TYPES.SET_SHOW_MENU:
			return { ...state, showMenu: payload.showMenu };
		case NAVBAR_ACTION_TYPES.SET_IS_SCROLLABLE:
			return { ...state, isScrollable: payload.isScrollable };
		case NAVBAR_ACTION_TYPES.ALLOW_INTO_VIEW:
			return { ...state, allowIntoView: payload.allowIntoView };
		case NAVBAR_ACTION_TYPES.ALLOW_MENU_BUTTON_FUNCTIONALITY:
			return { ...state, menuButton: payload.menuButton };
		case NAVBAR_ACTION_TYPES.ALLOW_ARROW_BUTTON_FUNCTIONALITY:
			return { ...state, arrowButtons: payload.arrowButtons };
		case NAVBAR_ACTION_TYPES.SET_SMOOTH_ANIMATION:
			return { ...state, smoothAnimation: payload.smoothAnimation };
		default:
			return state;
	}
};
