import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import useAppSelector from "hooks/store/useAppSelector";
import useAppDispatch from "hooks/store/useAppDispatch";

import InputRadioGroup from "components/ui/input/inputRadioGroup";
import Switch from "components/ui/switch";

import { saveSettingsThunk } from "store/slices/auth/thunks";
import { selectIsSoundOn, selectOddFormat } from "store/slices/auth/selectors";

import { ODD_FORMATS, ODD_FORMATS_TRANSLATION_KEYS } from "constants/market.constants";
import useGlobalVariables from "hooks/useGlobalVariables";
import { mergeClassNames } from "utils/common";

/* Settings Component */
const Settings = () => {
	const { t } = useTranslation();
	const { isMobile } = useGlobalVariables();
	const isSound = useAppSelector(selectIsSoundOn);
	const oddFormat = useAppSelector(selectOddFormat);

	const dispatch = useAppDispatch();

	const oddFormatsOptions = useMemo(() => {
		return Object.values(ODD_FORMATS).map((value) => {
			return {
				value: `${value}`,
				label: t(ODD_FORMATS_TRANSLATION_KEYS[value])
			};
		});
	}, []);

	/** Callback that will be called, when Settings item state is changed */
	const handleChange = (value, prop) => {
		dispatch(saveSettingsThunk({ [prop]: value }));
	};

	return (
		<div className="vs--settings-content vs--flex vs--flex-col vs--justify-between vs--pt-8 vs--pb-8">
			<label
				className={
					mergeClassNames(
						"vs--flex vs--justify-between vs--align-center vs--settings-content-item vs--pr-16 vs--pl-10",
						isMobile ? "vs--pb-8 vs--pt-8" : "vs--pt-8 vs--pb-16"
					)
				}
			>
				<div className="vs--flex vs--justify-between vs--align-center">
					<div className="vs--settings-content-icon vs--flex vs--justify-between vs--align-center vs--pt-2 vs--pr-2 vs--pb-2 vs--pl-2 vs--mr-10" data-type="sound">
						<i className="ic_speaker vs--font-bigest" />
					</div>
					<span className="vs--title-white vs--font-medium vs--font-normal">{t("common.sound")}</span>
				</div>
				<Switch onChange={(value) => handleChange(value, "isSound")} checked={isSound} />
			</label>
			<span className={mergeClassNames("vs--settings-content-odds-format-title", isMobile && "vs--mt-16", "vs--font-normal vs--mb-6 vs--ml-8")}>{t("common.oddsFormat")}</span>
			<InputRadioGroup className="vs--settings-content-odds-format" name="oddFormat" options={oddFormatsOptions} value={String(oddFormat)} onChange={(value) => handleChange(Number(value), "oddFormat")} />
		</div>
	);
};

export default Settings;
