import ApiUrls from "constants/api.constants";

import httpService from "../http";

const bonusService = {
	history: (queryData, signal) => {
		return httpService.get({
			path: ApiUrls.GET_BONUS_HISTORY,
			queryData,
			signal
		});
	}
};

export default bonusService;
