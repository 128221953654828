import { ON_BOARDING_COOKIE_NAMES, ON_BOARDING_COOKIE_VALUES, ON_BOARDING_EXPIRES_STRING, ON_BOARDING_TYPES } from "constants/onBoarding.constants";
import { getCookie, setCookie } from "./common";
import LocalStorageUtils from "utils/localStorage";

export const getValidOnboardingTypes = () => {
	const onBoardingTypes = Object.values(ON_BOARDING_TYPES);

	const validOnBoardingTypes = onBoardingTypes.filter((onBoardingType) => {
		return Boolean(ON_BOARDING_COOKIE_NAMES[onBoardingType]) && Boolean(ON_BOARDING_COOKIE_VALUES[onBoardingType]) && Boolean(ON_BOARDING_EXPIRES_STRING[onBoardingType]);
	});

	return validOnBoardingTypes;
};

export const setupOnBoardings = (onBoardingTypes) => {
	onBoardingTypes.forEach((onBoardingType) => {
		const cookieName = ON_BOARDING_COOKIE_NAMES[onBoardingType];
		// const condition = !getCookie(cookieName)
		const condition = !LocalStorageUtils.get(cookieName)
		if (condition) {
			LocalStorageUtils.set(cookieName, JSON.stringify(ON_BOARDING_COOKIE_VALUES[onBoardingType]))
			// setCookie(
			// 	cookieName,
			// 	JSON.stringify(ON_BOARDING_COOKIE_VALUES[onBoardingType]),
			// 	ON_BOARDING_EXPIRES_STRING[onBoardingType]
			// );
		}
	});
};

export const deleteOnBoardings = (onBoardingTypes) => {
	onBoardingTypes.forEach((onBoardingType) => {
		const cookieName = ON_BOARDING_COOKIE_NAMES[onBoardingType];
		const condition = LocalStorageUtils.get(cookieName)
		if (condition) {
			LocalStorageUtils.remove(cookieName)
		}
	});
};