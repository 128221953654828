import useAppSelector from "hooks/store/useAppSelector";
import useEvent from "hooks/useEvent";

import { selectSessionExitUrl } from "store/slices/auth/selectors";

import { sendPostMessageToParent } from "utils/iframe";

import { CLIENT_API } from "constants/integration.constants";

const useExitFromSession = () => {
	const exitUrl = useAppSelector(selectSessionExitUrl);

	const exitFromSession = useEvent(() => {
		if (exitUrl) {
			sendPostMessageToParent({ type: CLIENT_API.BACK_TO_HOME, value: exitUrl, mainDomain: exitUrl });
		}
		else {
			window.location.reload();
		}
	});

	return exitFromSession;
};

export default useExitFromSession;