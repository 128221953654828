import { PERIOD, ODD_FORMATS } from "constants/market.constants";

class BaseMarkets {
	/** Transform markets array to the useful array for markets UI , for all the tabs
	 * @function
	 * @param {array } markets - data to transform
	 * @param {object} gameData - Event data
	 * @param {Array} rtps - Array of rtps to apply on markets
	 * @param {number} gameType - Game Type
	 * @returns {object}
	 * @memberof BaseMarkets
	 */
	makeAllMarkets(markets, gameData, rtps, gameType) {
		const groups = this.getGroupsStructure();
		const result = {};
		Object.keys(groups).forEach((gr) => {
			result[gr] = this.makeMarketsArray(this.filterMarkets(gr, markets), gameData, rtps, gameType);
		});
		return result;
	}

	/** Get All possible markets for tab
	 * @function
	 * @param {string } market - tab name
	 * @param {array} markets - markets to filter
	 * @returns {array}
	 * @memberof BaseMarkets
	 */
	filterMarkets(tab, markets) {
		const allMarkets = this.getGroupsStructure()[tab].groups;
		const filtered = [];
		if (!Array.isArray(markets) || !markets.length) {
			return filtered;
		}
		allMarkets.forEach((market) => {
			markets.forEach((current) => {
				if (current.period === market.period && current.group === market.group && (!market.argument || market.argument === current.argument || (typeof market.argument === "function" && market.argument(current.argument)))) {
					filtered.push({ ...current, groupTitle: market.title, statistics: market.statistics, isNew: market.isNew || false });
				}
			});
		});
		return filtered;
	}

	/** Round factor
	 * @function
	 * @param {number} factor - factor
	 * @returns {number}
	 * @memberof BaseMarkets
	 */
	roundFactor(factor) {
		const roundedFactor = (
			factor < 1.01
				? factor
				: factor - 500 >= 0
					? Math.trunc(factor / 10) * 10
					: factor - 50 >= 0
						? Math.trunc(factor / 5) * 5
						: factor - 20 >= 0
							? Math.trunc(factor)
							: factor - 15 >= 0
								? (Math.trunc((factor * 10) / 5) * 5) / 10
								: factor - 7 >= 0
									? Math.round(factor * 10) / 10
									: factor - 3 >= 0
										? (Math.floor(((factor + 0.025) * 100) / 5) * 5) / 100
										: Math.round(factor * 100) / 100
		);
		return Math.min(roundedFactor, 1000);
	}

	/** Convert to user selected odd format
	 * @function
	 * @param {string} factor - factor
	 * @param {number} oddFormat - odd Format
	 * @returns {number}
	 * @memberof BaseMarkets
	 */
	convertToFormat(factor, oddFormat) {
		const getFractionParts = (num) => {
			const [wholeNumberPart, fractionalPart] = num.split(".");
			const denominator = Math.pow(10, fractionalPart.length);
			const numerator = (Number(wholeNumberPart) - 1) * denominator + Number(fractionalPart);

			return { numerator, denominator };
		};

		if (oddFormat === ODD_FORMATS.AMERICAN) {
			const str = factor.toString();

			if (str.indexOf(".") !== -1) {
				const { numerator, denominator } = getFractionParts(str);

				return factor >= 2 ? `+${Math.round(numerator * 100 / denominator)}` : Math.round(-100 * denominator / numerator);
			}

			return `+${(factor - 1) * 100}`;
		} else if (oddFormat === ODD_FORMATS.FRACTION) {
			const str = factor.toString();

			if (str.indexOf(".") !== -1) {
				const gcd = (num1, num2) => {
					if (num2 === 0) return num1;

					return gcd(num2, Math.floor(num1 % num2));
				};

				const { numerator, denominator } = getFractionParts(str);
				const divisor = gcd(numerator, denominator);

				return numerator / divisor + "/" + denominator / divisor;
			}

			return str + "/1";
		} else {
			return factor;
		}
	}

	/** Calculate factor using rtps
	 * @function
	 * @param {number} factor - factor
	 * @param {string} market - market name in system
	 * @param {string} period - market period
	 * @param {Array} rtps - rtps to apply
	 * @returns {number}
	 * @memberof BaseMarkets
	 */
	applyRtp(factor, market, period, argument, outcome, rtps) {
		let rtp = rtps.find((i) => i.name === market && i.period === period);

		if (market === "Handicap" && ((argument === -0.5 && outcome === "Home") || (argument === 0.5 && outcome === "Away"))) {
			rtp = rtps.find((i) => i.name === "Winner3Way" && i.period === period);
		} else if (market === "Handicap" && ((argument === 0.5 && outcome === "Home") || (argument === -0.5 && outcome === "Away"))) {
			rtp = rtps.find((i) => i.name === "DoubleChance" && i.period === period);
		} else if ((market === "WillHomeTeamScore" && outcome === "No") || (market === "Correct6HomeGoalsCount" && outcome === "Goal0")) {
			rtp = rtps.find((i) => i.name === "HomeTotal" && i.period === period);
		} else if ((market === "WillAwayTeamScore" && outcome === "No") || (market === "Correct6AwayGoalsCount" && outcome === "Goal0")) {
			rtp = rtps.find((i) => i.name === "AwayTotal" && i.period === period);
		} else if (
			(market === "Correct6GoalsCount" && outcome === "Goal0") ||
			(market === "CorrectScore6Goals" && outcome === "Home0Away0") ||
			(market === "WinnerAndTotal" && (argument === 1.5 || argument === 0.5) && outcome === "DrawAndUnder") ||
			(market === "Total3Way" && argument === 1 && outcome === "Under") ||
			(market === "DoubleChanceAndTotal" && argument === 0.5 && (outcome === "HomeOrDrawAndUnder" || outcome === "AwayOrDrawAndUnder")) ||
			(market === "NextPoint" && argument === 0 && outcome === "Neither")
		) {
			rtp = rtps.find((i) => i.name === "Total" && i.period === period);
		} else if ((market === "Correct6GoalsCount" && outcome === "Goal6" && period === PERIOD.MATCH) || (market === "Total3Way" && argument === 5 && outcome === "Over")) {
			rtp = rtps.find((i) => i.name === "Total" && i.period === period);
		} else if (market === "CorrectScore6Goals" && (outcome === "Home3Away0" || outcome === "Home0Away3") && (period === PERIOD.FIRSTHALF || period === PERIOD.SECONDHALF)) {
			rtp = rtps.find((i) => i.name === "Handicap" && i.period === period);
		} else if ((market === "Correct6GoalsCount" && outcome === "Goal3" && (period === PERIOD.FIRSTHALF || period === PERIOD.SECONDHALF)) || (market === "Total3Way" && argument === 2 && outcome === "Over" && (period === PERIOD.FIRSTHALF || period === PERIOD.SECONDHALF))) {
			rtp = rtps.find((i) => i.name === "Total" && i.period === period);
		} else if (market === "CorrectScore6Goals" && (outcome === "Home1Away0" || outcome === "Home0Away1")) {
			rtp = rtps.find((i) => i.name === "WinnerAndTotal" && i.period === period);
		} else if (market === "CorrectScore6Goals" && outcome === "Home3Away3" && period === PERIOD.MATCH) {
			rtp = rtps.find((i) => i.name === "WinnerAndTotal" && i.period === period);
		} else if (market === "CorrectScore6Goals" && outcome === "Home1Away1" && (period === PERIOD.FIRSTHALF || period === PERIOD.SECONDHALF)) {
			rtp = rtps.find((i) => i.name === "WinnerAndTotal" && i.period === period);
		}

		const margin = rtp ? rtp.margin : 7.5;
		let newFactor = factor;
		newFactor *= 1 - (margin / 100);

		return this.roundFactor(newFactor);
	}

	/** Abstract Methods */
	getGroups() {
		return [];
	}
	getGroupsStructure() {
		return {};
	}
	sortMarketsByOrderNumber() {
		return [];
	}
	makeMarketsArray() {
		return [];
	}
	makeName() {
		return "";
	}
	makeWinnerMarketName() {
		return "";
	}
	makeGroupTitle() {
		return "";
	}
	makeBetText() {
		return "";
	}
}

export default BaseMarkets;
