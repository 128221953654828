import { Fragment } from "react";
import PropTypes from "prop-types";
import AdditionalInfoForChampionsCup from "./additionalInfoForChampionsCup";
import { formatTime } from "utils/dateTime";
import { getRoundNameOfCupGame, mergeClassNames } from "utils/common";
import eventType from "types/event.type";
import useGlobalVariables from "hooks/useGlobalVariables";

const TabStructureTitle = ({ seasonStructure, round, activeTab }) => {
	const { isMobile } = useGlobalVariables();
	return (
		<Fragment>
			<span className={mergeClassNames("vs--season-structure-title vs--title-white vs--font-big vs--font-medium vs--text-center", isMobile ? "vs--pt-24 vs--pb-16" : "vs--pt-16 vs--pb-16")}>
				{(() => {
					switch (true) {
						case !seasonStructure.events:
						case !activeTab:
						case !round:
							return "";
						default:
							return `${getRoundNameOfCupGame(round.orderNumber, round.gameType)} (${formatTime(round.startTime)})`;
					}
				})()}
			</span>
			<AdditionalInfoForChampionsCup round={round} />
		</Fragment>
	);
};

TabStructureTitle.propTypes = {
	seasonStructure: eventType,
	round: eventType,
	activeTab: PropTypes.number
};

export default TabStructureTitle;
