import { ON_BOARDING_COOKIE_NAMES, ON_BOARDING_COOKIE_VALUES, ON_BOARDING_EXPIRES_STRING, ON_BOARDING_TYPES } from "constants/onBoarding.constants"
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { deleteOnBoardings, getValidOnboardingTypes, setupOnBoardings } from "utils/onBoarding"
import QuickBetting from "./quickBetting"

// const getIsDemoSession = state => state.auth.session.isDemo;

const OnBoarding = () => {
	// const isDemo = useSelector(getIsDemoSession);

	// const onBoardingGeneratorRef = useRef(null);
	const [currentOnBoardingType, setCurrentOnBoardingType] = useState(null);

	useLayoutEffect(() => {
		deleteOnBoardings(getValidOnboardingTypes())
	}, [])


	// useEffect(() => {
	// 	if (!isDemo) {
	// 		deleteOnBoardings(getValidOnboardingTypes())
	// 	}
	// }, [isDemo]);

	// useEffect(() => {
	// 	if (!isDemo) {
	// 		const onBoardingTypes = getValidOnboardingTypes();
	// 		onBoardingGeneratorRef.current = onBoardingTypes[Symbol.iterator]();
	// 		const { value, done } = onBoardingGeneratorRef.current.next()

	// 		if (done) {
	// 			return;
	// 		}
	// 		setCurrentOnBoardingType(value)
	// 	}
	// }, [isDemo, setCurrentOnBoardingType])

	let props = null
	let Component = null;

	// const onCancel = () => {
	// 	/*
	// 		Can't check with onBoardingGeneratorRef.current instanceof Iterator
	// 		It doesn't work on IOS
	// 	*/
	// 	const isValidReference = typeof onBoardingGeneratorRef.current?.next === "function";
	// 	if (isValidReference) {
	// 		const { value, done } = onBoardingGeneratorRef.current.next()
	// 		if (done) {
	// 			setCurrentOnBoardingType(null)
	// 		} else {
	// 			setCurrentOnBoardingType(value)
	// 		}

	// 	}
	// }

	switch (currentOnBoardingType) {
		// case ON_BOARDING_TYPES.QUICK_BETTING:
		// 	Component = QuickBetting
		// 	props = {
		// 		onCancel
		// 	}
		// 	break;
		default:
			Component = Fragment
			break;
	}

	return (
		<Component {...props} />
	)
}
export default OnBoarding