import { Fragment } from "react";
import PropTypes from "prop-types";
import { getRoundNameOfCupGame, mergeClassNames } from "utils/common";
import { GAME_STATUSES } from "constants/game.constants";
import eventType from "types/event.type";

const TabElement = ({ data: round, dataList }) => {
	return (
		<Fragment>
			{round.orderNumber === dataList.length ? <i className="ic_league vs--font-bigest vs--mr-4" /> : null}
			<span className="vs--title-white vs--font-regular vs--font-normal">{getRoundNameOfCupGame(round.orderNumber, round.gameType, true)}</span>
			{[GAME_STATUSES.STARTED, GAME_STATUSES.CLOSE_FOR_BETTING, GAME_STATUSES.PREAMBLE_STARTED].includes(round.status) ? <span className={mergeClassNames("vs--season-structure-tabs-item-marker", GAME_STATUSES.STARTED === round.status && "vs--season-structure-tabs-item-marker-green")} /> : null}
		</Fragment>
	);
};

TabElement.propTypes = {
	data: eventType,
	dataList: PropTypes.arrayOf(eventType)
};

export default TabElement;
