import PropTypes from "prop-types";
import { RESULTS } from "constants/game.constants";
import countryType from "./country.type";

export default PropTypes.arrayOf(
	PropTypes.shape({
		matchResult: PropTypes.oneOf(Object.values(RESULTS)),
		eventId: PropTypes.number,
		team1: countryType,
		team2: countryType
	})
);
