import TopBarGamesProvider from "providers/TopBarGamesProvider";
import { useCallback } from "react";
import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";

import { setCurrentGameTypeAction } from "store/slices/game/actions";

const TopBarMenuHoc = (Component) => {
	const selectCurrentGameType = (state) => state.game.currentGameType;
	const WrappedByTopBarMenuHocComponent = () => {
		const dispatch = useAppDispatch();
		const selectedGame = useAppSelector(selectCurrentGameType);
		const setSelectedGame = useCallback((gt) => dispatch(setCurrentGameTypeAction(gt)), [dispatch]);
		return (
			<TopBarGamesProvider showCountdown={true} selectedGame={selectedGame} setSelectedGame={setSelectedGame}>
				<Component />
			</TopBarGamesProvider>
		);
	};

	WrappedByTopBarMenuHocComponent.displayName = "WrappedByTopBarMenuHocComponent_" + Component.displayName;

	return WrappedByTopBarMenuHocComponent;
};

export default TopBarMenuHoc;
