import { useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";

import Modal from "components/ui/modal";
import Rules from "components/rules";

import { setShowRulesAction } from "store/slices/common/actions";
import { selectShowRules } from "store/slices/common/selectors";

/* rules Component in Header */
const RulesTab = () => {
	const { t } = useTranslation();
	const coordinatesRef = useRef();

	const showRules = useAppSelector(selectShowRules);

	const dispatch = useAppDispatch();

	return (
		<>
			<div
				className="vs--header-rules vs--flex vs--align-center vs--pl-16 vs--pr-16"
				onClick={(event) => {
					coordinatesRef.current = { clientX: event.clientX + "px", clientY: event.clientY + "px", topOffset: "60px" };
					dispatch(setShowRulesAction(true));
				}}
			>
				<span className="vs--header-rules-title vs--title-gray-50 vs--font-normal vs--font-regular ">{t("menu.rules")}</span>
			</div>
			<Modal coordinates={coordinatesRef.current} headerContent={t("menu.rules")} isOpen={showRules} onCancel={() => dispatch(setShowRulesAction(false))} classNames={{ content: "vs--modal-wrapper vs--modal-rules" }} onOutsideClick={Function.prototype}>
				<Rules />
			</Modal>
		</>
	);
};

export default RulesTab;
