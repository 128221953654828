import { useCallback, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { CollapseContext, PanelContext } from "contexts/collapse";

import { isNullish, mergeClassNames } from "utils/common";

const CollapseRenderer = ({
	index,
	children,
	isRowExpanded,
	expandIconPosition,
	expandIconRenderer
}) => {
	const { expandAll, expandedRowIds, handleCollapseChange } = useContext(CollapseContext);
	const { style, wrapperClassName, footerClassName, header = null, footer = null, rowId, showArrow = true, disableAction, onChange } = useContext(PanelContext);

	const isExpanded = expandAll || expandedRowIds.includes(rowId ?? index);

	const collapseContentRef = useRef(null);
	const isRowAlreadyExpandedRef = useRef(isExpanded);
	const isFirstRender = useRef(true);

	const setHeightToCollapseContent = useCallback((contentHeight) => {
		if (collapseContentRef.current) {
			collapseContentRef.current.style.height = contentHeight;
		}
	}, []);

	const resetCollapseContentStyles = () => {
		if (!isExpanded) {
			return;
		}

		setHeightToCollapseContent("");
		if (collapseContentRef.current) {
			collapseContentRef.current.style.overflow = "";
		}
	};

	const showHideCollapseContent = () => {
		if (disableAction) {
			return;
		}

		if (collapseContentRef.current) {
			setHeightToCollapseContent(`${collapseContentRef.current.scrollHeight}px`);
		}

		handleCollapseChange(rowId);
		if (onChange) {
			onChange(rowId);
		}
	};

	// Update height on collapse change
	useEffect(() => {
		const contentHeight = isExpanded && collapseContentRef.current ? collapseContentRef.current.scrollHeight : 0;

		if (!isExpanded && !isFirstRender.current && collapseContentRef.current) {
			setHeightToCollapseContent(`${collapseContentRef.current.scrollHeight}px`);
		}

		isFirstRender.current = false;

		const timeoutId = setTimeout(() => {
			setHeightToCollapseContent(`${contentHeight}px`);
			if (collapseContentRef.current) {
				collapseContentRef.current.style.overflow = "hidden";
			}
		}, 0);

		return () => {
			clearTimeout(timeoutId);
		};
	}, [isExpanded, setHeightToCollapseContent]);

	if (isExpanded) {
		isRowAlreadyExpandedRef.current = true;
	}

	return (
		<div className={mergeClassNames("vs--ui-collapse-wrapper", isExpanded && "vs--ui-collapse-wrapper-expanded", wrapperClassName)} style={style}>
			<div
				className={mergeClassNames("vs--ui-collapse-header", disableAction ? "vs--ui-collapse-header-disabled" : "vs--cursor-pointer")}
				onClick={showHideCollapseContent}
				aria-expanded={isExpanded}
			>
				{showArrow && <div className={`vs--ui-header-icon ${isExpanded ? "vs--ui-header-icon-active" : ""}`}>{typeof expandIconRenderer === "function" ? expandIconRenderer() : <i className="ic_down vs--font-bigest vs--title" />}</div>}
				{header}
			</div>
			<div className={`vs--ui-collapse-content ${isExpanded ? "vs--ui-collapse-content-expanded" : ""}`} ref={collapseContentRef} onTransitionEnd={resetCollapseContentStyles}>
				<div className={`vs--ui-collapse-content-box`}>{isRowAlreadyExpandedRef.current && children}</div>
			</div>
			{!isNullish(footer) && (
				<div className={mergeClassNames("vs--ui-collapse-footer", footerClassName)}>
					{footer}
				</div>
			)}
		</div>
	);
};

/** CollapseRenderer propTypes
 * PropTypes
 */
CollapseRenderer.propTypes = {
	/** Function which will fire on change of collapse panel */
	handleCollapseChange: PropTypes.func,
	/** Indicates that current row expanded */
	isRowExpanded: PropTypes.func,
	/** Expand icon renderer */
	expandIconRenderer: PropTypes.func,
	/** Elements to render  */
	content: PropTypes.node,
	/** Specified which mode is current */
	mode: PropTypes.string,
	/** Default expanded rows */
	defaultActiveIds: PropTypes.array,
	/** Expanded row ids controlled from outside  */
	activeRowIds: PropTypes.array,
	/** Specified expand icon position */
	expandIconPosition: PropTypes.string,
	/** Header content */
	header: PropTypes.node,
	/** Show/hide expand icon */
	showArrow: PropTypes.bool,
	/** Current Row id */
	rowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default CollapseRenderer;
