import { Fragment } from "react";
import PropTypes from "prop-types";
import TabStructurePenaltyShootoutInfo from "./tabStructureCommonInfo/tabStructurePenaltyShootoutInfo";
import TabStructureOverTimeInfo from "./tabStructureCommonInfo/tabStructureOverTimeInfo";
import TabStructureFullTimeInfo from "./tabStructureCommonInfo/tabStructureFullTimeInfo";
import TabStructureAggInfo from "./tabStructureCommonInfo/tabStructureAggInfo";

const TabStructureChampionsCupInfo = ({ isEventHasOTPS, teamInfo, opponentTeamInfo, isLeg2, isLeg2OrFinal = false, rowIndex = null }) => {
	const areCountryCodesExist = () => teamInfo?.countryCode && opponentTeamInfo?.countryCode;

	if (!areCountryCodesExist()) {
		return <Fragment></Fragment>;
	}

	return (
		<Fragment>
			<TabStructureFullTimeInfo teamInfo={teamInfo} opponentTeamInfo={opponentTeamInfo} isChampion={true} isLeg2={isLeg2} />
			{isLeg2OrFinal ? (
				<Fragment>
					<TabStructureOverTimeInfo isEventHasOTPS={isEventHasOTPS} teamInfo={teamInfo} opponentTeamInfo={opponentTeamInfo} showConditionalJsx={false} isLeg2={isLeg2} isLeg2OrFinal={isLeg2OrFinal} isChampion={true} rowIndex={rowIndex} />
					{isLeg2 ? <TabStructureAggInfo teamInfo={teamInfo} opponentTeamInfo={opponentTeamInfo} /> : null}
					<TabStructurePenaltyShootoutInfo isEventHasOTPS={isEventHasOTPS} teamInfo={teamInfo} opponentTeamInfo={opponentTeamInfo} showConditionalJsx={false} isLeg2OrFinal={isLeg2OrFinal} isChampion={true} rowIndex={rowIndex} />
				</Fragment>
			) : null}
		</Fragment>
	);
};

TabStructureChampionsCupInfo.propTypes = {
	teamInfo: PropTypes.object,
	opponentTeamInfo: PropTypes.object,
	isEventHasOTPS: PropTypes.bool,
	isLeg2: PropTypes.bool,
	isLeg2OrFinal: PropTypes.bool,
	rowIndex: PropTypes.number
};

export default TabStructureChampionsCupInfo;
