import { createSlice } from "@reduxjs/toolkit";

import {
	addLiveAndUpcomingsCases,
	addResultsCases,
	addLastResultsCases,
	addEventCasees,
	addGameCases,
	addActiveMarketsKeysCases,
	addSeasonResultsCases
} from "./extraReducers";
import { initialState, sliceName } from "./initialState";

const gameSlice = createSlice({
	name: sliceName,
	initialState: initialState,
	extraReducers: (builder) => {
		addLiveAndUpcomingsCases(builder);
		addEventCasees(builder);
		addGameCases(builder);
		addResultsCases(builder);
		addLastResultsCases(builder);
		addActiveMarketsKeysCases(builder);
		addSeasonResultsCases(builder);
	}
});

export const gameSliceName = sliceName;
export const gameSliceReducer = gameSlice.reducer;