import { COMBO_BOOST_STEP_FORM_NAMES } from "constants/betslip.constants"
import { mergeClassNames } from "utils/common";

const ComboBoostStepProgress = ({ boostFactors, checkBoostIsActive, checkBoostIsCompleted, stepsForDraw, children }) => {

	return (
		<div className="vs--combo-boost-step-progress">
			<div className="vs--combo-boost-step-progress-container">
				<div className="vs--combo-boost-step-container">
					{
						stepsForDraw.map((comboBoostStepsValue) => {
							const boost = boostFactors[COMBO_BOOST_STEP_FORM_NAMES[comboBoostStepsValue]]
							return (
								<div
									key={comboBoostStepsValue}
									className={
										mergeClassNames(
											"vs--combo-boost-step",
											checkBoostIsCompleted(comboBoostStepsValue) && "vs--combo-boost-step-completed",
											checkBoostIsActive(comboBoostStepsValue) && "vs--combo-boost-step-active"
										)
									}
									data-combo-boost-steps={comboBoostStepsValue}
								>
									{
										boost
											? (
												<div
													className="vs--combo-boost-step-value"
												>
													<span className="vs--combo-boost-step-value-number">+{boost}%</span>
													<div className="vs--combo-boost-step-value-arrow"></div>
												</div>
											)
											: null
									}
									<div className="vs--combo-boost-step-indicator"></div>
								</div>
							)
						})
					}
				</div>
			</div>
			{children}
		</div>
	)
}
export default ComboBoostStepProgress