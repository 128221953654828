import PropTypes from "prop-types";

import { SURVEY_TYPE } from "constants/survey.constants";

export default PropTypes.shape({
	id: PropTypes.string,
	name: PropTypes.string,
	type: PropTypes.oneOf(Object.values(SURVEY_TYPE)),
	suggestMessage: PropTypes.bool
});
