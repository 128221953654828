import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Switch from "components/ui/switch";

import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";

import { BETSLIP_STAKE_MODES } from "constants/betslip.constants";
import { BONUS_TYPE } from "constants/bonus.constants";

import { setStakeModeAction } from "store/slices/betslip/actions";
import { 
	selectSessionBonuses 
} from "store/slices/auth/selectors";
import { selectBetslipStakeMode } from "store/slices/betslip/selectors";
import { selectUseBonus } from "store/slices/bonus/selectors";

/** Stake per bet/split switcher component*/
const BetStakeMode = () => {
	const { t } = useTranslation();
	const stakeMode = useAppSelector(selectBetslipStakeMode);
	const bonuses = useAppSelector(selectSessionBonuses);
	const useBonus = useAppSelector(selectUseBonus);

	const dispatch = useAppDispatch();

	const currentBonus = bonuses?.[0] ?? {};
	const isFreeAmountBonus = useBonus && currentBonus.bonusType === BONUS_TYPE.FREEAMOUNT;

	// true if user can't change stake mode
	const readOnly = useBonus && !isFreeAmountBonus;

	/** Function which fires on switcher change
	 * @function
	 * @param {boolean} e - switcher value
	 * @memberOf BetStakeMode
	 */
	const onSwitchChange = (e) => {
		dispatch(setStakeModeAction(e ? BETSLIP_STAKE_MODES.SPLIT : BETSLIP_STAKE_MODES.PER_BET));
	};

	return (
		<div
			className={
				"vs--stake-mode vs--flex vs--align-center vs--justify-center" +
				(readOnly ? " vs--stake-mode-disabled" : "")
			}
		>
			<span className="vs--title-white vs--font-regular vs--font-normal vs--pr-8">
				{t("bet.splitStake")}
			</span>
			<Switch
				onChange={onSwitchChange}
				checked={stakeMode === BETSLIP_STAKE_MODES.SPLIT}
			/>
		</div>
	);
};

export default BetStakeMode;
