import { useRef, useEffect } from "react";
import PropTypes from "prop-types";

import InputPassword from "./inputPassword";
import InputTextArea from "./inputTextArea";
import useGlobalVariables from "hooks/useGlobalVariables";
import { isNullish, mergeClassNames } from "utils/common";

const INPUT_STATUS_STYLE_CLASSES = {
	normal: "vs--ui-input-normal",
	error: "vs--ui-input-error",
	warning: "vs--ui-input-warning"
};

const Input = ({
	className = "",
	onClick = Function.prototype,
	prefix,
	suffix,
	disabled = false,
	placeholder,
	status = "normal",
	type = "text",
	value,
	wrapperProps,
	...inputProps
}) => {
	const inputAreaRef = useRef(null);
	const { isMobile, isIOS } = useGlobalVariables();

	useEffect(() => {
		if (!inputAreaRef.current || !isMobile || !isIOS) {
			return;
		}
		if (inputAreaRef.current.value !== "" && !isNullish(inputAreaRef.current.value)) {
			inputAreaRef.current.classList.remove("vs--ui-input-box-area-active");
			return;
		}
		inputAreaRef.current.classList.add("vs--ui-input-box-area-active");
	}, [value, isMobile, isIOS]);

	const focusInput = () => {
		if (disabled) {
			return;
		}
		inputAreaRef.current.focus();
		if (onClick) {
			onClick();
		}
	};

	return (
		<div
			{...wrapperProps}
			data-input-type={type}
			className={mergeClassNames("vs--ui-input", INPUT_STATUS_STYLE_CLASSES[status], className)}
			onClick={focusInput} aria-disabled={disabled} 
		>
			{typeof prefix === "function" ? <div className="vs--ui-input-prefix">{prefix()}</div> : null}
			<span className="vs--ui-input-box" data-placeholder={placeholder}>
				<input value={value} type={type} className="vs--ui-input-box-area" ref={inputAreaRef} disabled={disabled} placeholder={isMobile && isIOS ? undefined : placeholder} {...inputProps} />
			</span>
			{Boolean(suffix) ? <div className="vs--ui-input-suffix">{suffix}</div> : null}
		</div>
	);
};

/** Input propTypes
 * PropTypes
 */
Input.propTypes = {
	/** Class to rewrite default styles of input */
	className: PropTypes.string,
	/** Render icon before input */
	prefix: PropTypes.func,
	/** Icon after input */
	suffix: PropTypes.node,
	/** Disabled input or not */
	disabled: PropTypes.bool,
	/** Placeholder of input */
	placeholder: PropTypes.string,
	/** Status of input */
	status: PropTypes.oneOf(["normal", "error", "warning"]),
	/** Input type */
	type: PropTypes.string,
	/** Input value */
	value: PropTypes.string
};

Input.Password = InputPassword;
Input.TextArea = InputTextArea;

export default Input;
