import { ELEMENT_VISIBILITY_CLASS_NAMES } from "constants/navbar.constants";

export const getElementVisibilityClassName = (visibilityValue) => {
	switch (visibilityValue) {
		case 1:
			return ELEMENT_VISIBILITY_CLASS_NAMES.VISIBLE;
		case 0:
			return ELEMENT_VISIBILITY_CLASS_NAMES.HIDDEN;
		default:
			return ELEMENT_VISIBILITY_CLASS_NAMES.SEMI_VISIBLE;
	}
};

export const calculateViewPort = ({ dir, parentBoundingClientRect, prevArrowBoundingClientRect, nextArrowBoundingClientRect, reverse = true }) => {
	const viewPortVariants = [
		prevArrowBoundingClientRect ? prevArrowBoundingClientRect.x + prevArrowBoundingClientRect.width : dir === "rtl" ? parentBoundingClientRect.x + parentBoundingClientRect.width : parentBoundingClientRect.x,
		nextArrowBoundingClientRect ? nextArrowBoundingClientRect.x : dir === "rtl" ? parentBoundingClientRect.x : parentBoundingClientRect.x + parentBoundingClientRect.width
	];

	dir === "rtl" && reverse && viewPortVariants.reverse();

	return viewPortVariants.reduce((acc, variant, i) => ((acc["x" + (i + 1)] = variant), acc), {});
};

export const getVisibilityPart = ({ dir, elementBoundingClientRect, parentBoundingClientRect, prevArrowBoundingClientRect, nextArrowBoundingClientRect }) => {
	const viewPort = calculateViewPort({ dir, parentBoundingClientRect, prevArrowBoundingClientRect, nextArrowBoundingClientRect });

	const childX1 = elementBoundingClientRect.x;
	const childX2 = elementBoundingClientRect.x + elementBoundingClientRect.width;

	const startDiff = childX1 - viewPort.x1;
	const endDiff = viewPort.x2 - childX2;

	const visibilityPart =
		startDiff >= 0 && endDiff >= 0 ? 1 : startDiff < 0 && endDiff < 0 ? 0 : childX2 > viewPort.x1 && childX1 <= viewPort.x1 ? (childX2 - viewPort.x1) / elementBoundingClientRect.width : childX1 < viewPort.x2 && childX2 >= viewPort.x2 ? (viewPort.x2 - childX1) / elementBoundingClientRect.width : 0;
	return visibilityPart;
};

export const getCoords = (rect) => {
	return {
		x1: rect.x,
		x2: rect.x + rect.width,
		y1: rect.y,
		y2: rect.y + rect.height
	};
};

export const checkPossibleElementsThatInterfereWithTheView = (element, container) => {
	const prevArrow = container.previousElementSibling;
	const nextArrow = container.nextElementSibling;

	const elementBoundingClientRect = element.getBoundingClientRect();
	const containerBoundingClientRect = container.getBoundingClientRect();
	const prevArrowBoundingClientRect = prevArrow ? prevArrow.getBoundingClientRect() : null;
	const nextArrowBoundingClientRect = nextArrow ? nextArrow.getBoundingClientRect() : null;

	const coords = {
		elementCoords: getCoords(elementBoundingClientRect),
		containerCoords: getCoords(containerBoundingClientRect),
		nextArrowCoords: nextArrowBoundingClientRect && getCoords(nextArrowBoundingClientRect),
		prevArrowCoords: prevArrowBoundingClientRect && getCoords(prevArrowBoundingClientRect)
	};

	const containerDoesNotInterfereWithTheView = coords.elementCoords.x1 >= coords.containerCoords.x1 && coords.elementCoords.x2 <= coords.containerCoords.x2;
	const prevArrowDoesNotInterfereWithTheView = coords.prevArrowCoords ? coords.elementCoords.x1 >= coords.prevArrowCoords.x2 : true;
	const nextArrowDoesNotInterfereWithTheView = coords.nextArrowCoords ? coords.elementCoords.x2 <= coords.nextArrowCoords.x1 : true;

	const isVisible = containerDoesNotInterfereWithTheView && prevArrowDoesNotInterfereWithTheView && nextArrowDoesNotInterfereWithTheView;

	return {
		isVisible,
		containerDoesNotInterfereWithTheView,
		prevArrowDoesNotInterfereWithTheView,
		nextArrowDoesNotInterfereWithTheView,
		coords
	};
};
