import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import useAppSelector from "hooks/store/useAppSelector";

import Loader from "components/ui/loader";
import BetSlipEmpty from "../betslip/empty";

import Collapse from "components/ui/collapse";
import PanelWrapper from "components/ui/collapse/panelWrapper";
import PendingBetHeader from "./pendingBet/header";
import PendingBetContent from "./pendingBet/content";
import PendingBetExpandContent from "./pendingBet/expandContent";

import useBetTicketBorderSvgProps from "hooks/betslip/useBetTicketBorderSvgProps";

import {
	selectPendingBets,
	selectIsPendingsLoading,
	selectIsBetslipCancelling
} from "store/slices/betslip/selectors";

import { BETSLIP_MODES } from "constants/betslip.constants";
import { COLLAPSE_MODE } from "constants/ui.constants";

/* Pending Bets Tab Component */
const PendingBets = () => {
	const { t } = useTranslation();
	const pendingBets = useAppSelector(selectPendingBets);
	const loading = useAppSelector(selectIsPendingsLoading);
	const isCanceling = useAppSelector(selectIsBetslipCancelling);

	const borderSvgStyle = useBetTicketBorderSvgProps("--vs--bg-color-base");

	return loading ? (
		<Loader />
	) : (
		<Fragment>
			{pendingBets.length > 0 ? (
				<div className="vs--rightBar-content">
					<div className="vs--pending vs--pl-8 vs--pr-8 vs--pt-16 vs--pb-8">
						<Collapse
							className="vs--flex vs--flex-wrap vs--pending-item"
							mode={COLLAPSE_MODE.ACCORDION}
						>
							{pendingBets.map((bet, index) => {
								const rowId = `${bet.betSlipId}_${bet.betId}`;

								return (
									<PanelWrapper
										index={index}
										wrapperClassName="vs--pending-item-wrapper vs--flex vs--flex-col"
										footerClassName="vs--pending-item-footer vs--flex-grow"
										key={rowId}
										rowId={rowId}
										showArrow={false}
										disableAction={bet.type === BETSLIP_MODES.SINGLE}
										header={<PendingBetHeader data={bet} />}
										footer={<PendingBetContent borderSvgStyle={borderSvgStyle} data={bet} />}
									>
										<PendingBetExpandContent data={bet} />
									</PanelWrapper>
								)
							})}
						</Collapse>
					</div>
				</div>
			) : (
				<div className="vs--rightBar-content">
					<BetSlipEmpty text={t("bet.pendingEmptyText")} subtext={""} />
				</div>
			)}

			{isCanceling && <Loader className="vs--pending-loader" fixed={true} />}
		</Fragment>
	);
};

export default PendingBets;
