import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Flag from "components/ui/flag";
import { GAME_TYPE, GAME_STATUSES } from "constants/game.constants";
import { buildPathToStaticFolderOfCDN } from 'utils/common';

const yellowCardIcon = buildPathToStaticFolderOfCDN("images/misc/yellow-card.svg");
const redCardIcon = buildPathToStaticFolderOfCDN("images/misc/red-card.svg");
const penaltyIcon = buildPathToStaticFolderOfCDN("images/misc/penalty.svg");
const goalIcon = buildPathToStaticFolderOfCDN("images/misc/ball-colored.svg");
const changingProps = ["yellowCardCount", "redCardCount", "penaltyCount", "cornerCount", "goalCount"];

const TabStructureTeamInfo = ({ teamInfo, gameType, status }) => {
	const yellowCardCountAnimationTimer = useRef(null);
	const redCardCountAnimationTimer = useRef(null);
	const penaltyCountAnimationTimer = useRef(null);
	const cornerCountAnimationTimer = useRef(null);
	const goalCountAnimationTimer = useRef(null);

	if (!teamInfo) {
		return null;
	}

	const [oldTeamInfo, setOldTeamInfo] = useState(null);

	const [showYellowCardCount, setShowYellowCardCount] = useState(false);
	const [showRedCardCount, setShowRedCardCount] = useState(false);
	const [showPenaltyCount, setShowPenaltyCount] = useState(false);
	const [showGoalCount, setShowGoalCount] = useState(false);

	useEffect(() => {
		if (status === GAME_STATUSES.STARTED) {
			if (oldTeamInfo !== null) {
				changingProps.forEach((prop) => {
					if (oldTeamInfo[prop] !== teamInfo[prop]) {
						switch (prop) {
							case "yellowCardCount":
								setShowYellowCardCount(true);
								break;
							case "redCardCount":
								setShowRedCardCount(true);
								break;
							case "penaltyCount":
								setShowPenaltyCount(true);
								break;
							case "goalCount":
								setShowGoalCount(true);
								break;
						}
					}
				});
			}
			setOldTeamInfo(teamInfo);
		} else {
			setOldTeamInfo(null);
		}
	}, [teamInfo, status]);

	/** Yellow Card */
	useEffect(() => {
		if (showYellowCardCount) {
			clearTimeout(yellowCardCountAnimationTimer.current);

			yellowCardCountAnimationTimer.current = setTimeout(() => {
				setShowYellowCardCount(false);
			}, 5000);
		}
	}, [showYellowCardCount]);

	/** Red Card */
	useEffect(() => {
		if (showRedCardCount) {
			clearTimeout(redCardCountAnimationTimer.current);

			redCardCountAnimationTimer.current = setTimeout(() => {
				setShowRedCardCount(false);
			}, 5000);
		}
	}, [showRedCardCount]);

	/** Penalty */
	useEffect(() => {
		if (showPenaltyCount) {
			clearTimeout(penaltyCountAnimationTimer.current);

			penaltyCountAnimationTimer.current = setTimeout(() => {
				setShowPenaltyCount(false);
			}, 5000);
		}
	}, [showPenaltyCount]);

	/** Goal */
	useEffect(() => {
		if (showGoalCount) {
			clearTimeout(goalCountAnimationTimer.current);

			goalCountAnimationTimer.current = setTimeout(() => {
				setShowGoalCount(false);
			}, 5000);
		}
	}, [showGoalCount]);

	/** Clear timers */
	useEffect(() => {
		return () => {
			clearTimeout(yellowCardCountAnimationTimer.current);
			clearTimeout(redCardCountAnimationTimer.current);
			clearTimeout(penaltyCountAnimationTimer.current);
			clearTimeout(cornerCountAnimationTimer.current);
			clearTimeout(goalCountAnimationTimer.current);
		};
	}, []);

	return (
		<div className={"vs--flex vs--flex-equal vs--align-center vs--pt-8 vs--pb-8 vs--pl-16 vs--pr-16 " + (teamInfo.countryCode ? "vs--justify-start" : "vs--justify-center")}>
			{teamInfo.countryCode ? (
				<div className="vs--mr-8">
					<Flag code={teamInfo.countryCode} gameType={gameType} />
				</div>
			) : null}
			<span className="vs--title-white vs--font-regular vs--font-normal">{teamInfo.name}</span>
			<div className="vs--season-structure-list-item-info">
				{showYellowCardCount ? <img src={yellowCardIcon} alt="yellowCard" /> : null}
				{showRedCardCount ? <img src={redCardIcon} alt="redCard" /> : null}
				{showPenaltyCount ? <img src={penaltyIcon} alt="penalty" /> : null}
				{showGoalCount ? <img src={goalIcon} alt="goal" /> : null}
			</div>
		</div>
	);
};

TabStructureTeamInfo.propTypes = {
	teamInfo: PropTypes.object,
	gameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	status: PropTypes.oneOf(Object.values(GAME_STATUSES))
};

export default TabStructureTeamInfo;
