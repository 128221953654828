import ApiUrls from "constants/api.constants";

import httpService from "../http";

const kenoService = {
	currentBets: (signal) => {
		return httpService.get({
			path: ApiUrls.GET_KENO_CURRENT_BETS,
			signal
		});
	},

	lastBets: (signal) => {
		return httpService.get({
			path: ApiUrls.GET_KENO_LAST_BETS,
			signal
		});
	}
};

export default kenoService;
