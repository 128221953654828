const PATHS = {
	HOME: "/",
	RESULTS: "/results",
	RESULTS_SEASON: "/results/season/:id",
	RESULTS_SEASON_STRUCTURE: "/results/season/structure/:id",
	STATISTICS: "/statistics",
	RULES: "/rules",
	SETTINGS: "/settings",
	HISTORY: "/history",
	HISTORY_SINGLE: "/history/:id",
	EVENT: "/event/:id",
	SEASON_EVENT: "/seasonEvent/:id",
	BONUS: "/bonus",
	STREAM: "/stream"
};

export default PATHS;
