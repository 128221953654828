import { sliceName } from "./initialState";

export const selectIsKenoCurrentBetsloading = (state) => state[sliceName].isCurrentBetsLoading;
export const selectKenoCurrentBets = (state) => state[sliceName].currentBets;
export const selectIsKenoLastBetsLoading = (state) => state[sliceName].isLastBetsLoading;
export const selectKenoLastBets = (state) => state[sliceName].lastBets;
export const selectKenoActiveBalls = (state) => state[sliceName].activeBalls;
export const selectKenoSelectedBalls = (state) => state[sliceName].selectedBalls;
export const selectKenoSelectedBallsLength = (state) => state[sliceName].selectedBalls.length;
export const selectKenoHoveredBalls = (state) => state[sliceName].hoveredBalls;
export const selectKenoLastAddedBalls = (state) => state[sliceName].lastAddedBalls;