import { lazy, Fragment, Suspense, useLayoutEffect } from "react";
import useAppSelector from "hooks/store/useAppSelector";

import { selectSessionLanguageId, selectIsRtl } from "store/slices/auth/selectors";

const RTL = lazy(() => import("./rtl"));
const LTR = lazy(() => import("./ltr"));

/** Component to include styles */
const ConditionalStyles = () => {
	const languageId = useAppSelector(selectSessionLanguageId);
	const isRtl = useAppSelector(selectIsRtl); 

	useLayoutEffect(() => {
		if (!languageId) {
			return;
		}

		if (isRtl) {
			document.body.classList.remove("vs--ltr");
			document.body.classList.add("vs--rtl");
		} else {
			document.body.classList.remove("vs--rtl");
			document.body.classList.add("vs--ltr");
		}

		document.documentElement.setAttribute("lang", languageId);
	}, [languageId]);

	return <Suspense fallback={<Fragment />}>{languageId && isRtl ? <RTL /> : <LTR />}</Suspense>;
};

export default ConditionalStyles;
