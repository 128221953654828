import { NAVBAR_ICONS, NAVBAR_INTERACTIVE_ELEMENT_KEYS } from "constants/navbar.constants";
import { mergeClassNames } from "utils/common";

const { MENU: MENU_KEY } = NAVBAR_INTERACTIVE_ELEMENT_KEYS;

const DefaultMenu = ({ dir, itemsFilteringFn, menuItemInnerComponent: MenuItemInnerComponent, navbarMenuDropdown: NavbarMenuDropdown, navbarMenuItem: NavbarMenuItem, menuClassName, menuDropdownClassName, menuItemClassName, onClick, onBlur, localDataList, visibilityState }) => {
	return (
		<div className="vs--navbar-menu" dir={dir} onBlur={onBlur}>
			<button className={mergeClassNames("vs--navbar-menu-btn", menuClassName)} onClick={onClick} dir={dir}>
				{NAVBAR_ICONS[MENU_KEY]}
			</button>
			{visibilityState ? (
				<NavbarMenuDropdown dir={dir} menuDropdownClassName={menuDropdownClassName}>
					{localDataList.reduce((acc, { state, data }) => {
						const isValid = itemsFilteringFn({ data, state });
						if (isValid) {
							acc.push(
								<NavbarMenuItem key={data.id} menuItemClassName={menuItemClassName}>
									<MenuItemInnerComponent data={data} state={state} />
								</NavbarMenuItem>
							);
						}
						return acc;
					}, [])}
				</NavbarMenuDropdown>
			) : null}
		</div>
	);
};

export default DefaultMenu;
