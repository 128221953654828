import PropTypes from "prop-types";
import rtpType from "types/rtp.type";
import { GAME_TYPE, GAME_ACTIVITY_STATE } from "constants/game.constants";

export default PropTypes.shape({
	id: PropTypes.string,
	rtPs: PropTypes.arrayOf(rtpType),
	name: PropTypes.string,
	type: PropTypes.oneOf(Object.values(GAME_TYPE)),
	state: PropTypes.oneOf(Object.values(GAME_ACTIVITY_STATE)),
	nextEventStartSeconds: PropTypes.number
});
