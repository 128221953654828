import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";

import LocalStorageUtils from "utils/localStorage";

import { toggleFeedbackVisibilityAction } from "store/slices/common/actions";
import { selectIsFeedbackVisible } from "store/slices/common/selectors";
import { selectUserId, selectSessionSurveys } from "store/slices/auth/selectors";

import feedbackService from "services/api/feedback";
import CommonView from "components/feedbackPopup/commonView";
import MobileView from "components/feedbackPopup/mobileView";
import useGlobalVariables from "hooks/useGlobalVariables";

const UserFeedbackPopup = () => {
	const { t } = useTranslation();
	const { isMobile } = useGlobalVariables();

	const dispatch = useAppDispatch();

	const userId = useAppSelector(selectUserId);
	const [survey] = useAppSelector(selectSessionSurveys);
	const isVisible = useAppSelector(selectIsFeedbackVisible);

	const [selectedEmojiValue, setSelectedEmojiValue] = useState(null);
	const [isThankYouMsgVisible, setIsThankYouMsgVisible] = useState(false);

	const storageKey = `vs__feedback_is_shown_${userId}`;

	const sendFeedback = ({ grade = selectedEmojiValue, message = "" }) => {
		setIsThankYouMsgVisible(true);
		feedbackService.leaveFeedback({ grade, surveyId: survey.id, message: message ? message : null });
	};

	const closeFeedback = () => {
		if (selectedEmojiValue === null) {
			sendFeedback({ grade: 0 });
		}

		LocalStorageUtils.set(storageKey, true);
		dispatch(toggleFeedbackVisibilityAction(storageKey));
		setSelectedEmojiValue(null);
		setIsThankYouMsgVisible(false);
	};

	const commonProps = {
		closeFeedback,
		title: t("common.shareYourFeedback"),
		closeElement: t("common.skip"),
		sendFeedback,
		setSelectedEmojiValue,
		selectedEmojiValue,
		survey,
		isThankYouMsgVisible,
		isVisible
	};

	/** Removes key from LocalStorage, when backend cash has already been cleared from GetDetails */
	useEffect(() => {
		if (survey === undefined && LocalStorageUtils.get(storageKey)) {
			LocalStorageUtils.remove(storageKey);
		}
	}, [survey]);

	/** Closing feedback popup during 3 seconds after submitting */
	useEffect(() => {
		let timeoutId;

		if (isThankYouMsgVisible) {
			timeoutId = setTimeout(closeFeedback, 3000);
		}

		return () => clearTimeout(timeoutId);
	}, [isThankYouMsgVisible]);

	if (!isVisible || survey === undefined) {
		return null;
	}

	return isMobile ? <MobileView {...commonProps} /> : <CommonView {...commonProps} />;
};

export default UserFeedbackPopup;
