import PropTypes from "prop-types";

import { GAME_TYPE } from "constants/game.constants";

export default PropTypes.shape({
	id: PropTypes.string,
	path: PropTypes.string,
	gameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	title: PropTypes.string,
	languageCode: PropTypes.string,
	userName: PropTypes.string,
	version: PropTypes.string,
	date: PropTypes.string,
	isPublished: PropTypes.bool,
	content: PropTypes.string
});
