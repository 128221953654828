import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Tooltip from "components/ui/tooltip";

import useAppSelector from "hooks/store/useAppSelector";

import { selectIsRtl } from "store/slices/auth/selectors";

const TabStructurePenaltyShootoutInfo = ({ isEventHasOTPS, teamInfo, opponentTeamInfo, showConditionalJsx, isChampion = false, isSimple = false, isLeg2OrFinal = false, rowIndex = null }) => {
	const isRtl = useAppSelector(selectIsRtl);
	const { t } = useTranslation();

	if (isSimple) {
		if (!isEventHasOTPS) {
			return null;
		}
		if (teamInfo.penaltyShootoutGoalCount === undefined) {
			return null;
		}
	}

	if (isChampion && !isLeg2OrFinal) {
		return null;
	}

	return (
		<div className="vs--flex vs--align-center vs--justify-center vs--season-structure-list-item-result">
			<span className={"vs--title-white vs--font-medium vs--font-big" + (teamInfo.penaltyShootoutGoalCount > opponentTeamInfo.penaltyShootoutGoalCount ? " vs--text-green" : "")}>{typeof teamInfo.penaltyShootoutGoalCount === "number" ? teamInfo.penaltyShootoutGoalCount : " - "}</span>
			{showConditionalJsx ? (
				<Tooltip placement={isRtl ? "right" : "left"} title={<span className="vs--title vs--font-normal vs--font-regular">{t("common.penaltyShootout")}</span>} overlayClassName="vs--season-structure-list-item-result-tooltip">
					<div className="vs--season-structure-list-item-result-ot">
						<span className="vs--title vs--font-medium vs--font-small">{t("common.penaltyShoutoutShort")}</span>
					</div>
				</Tooltip>
			) : null}
		</div>
	);
};

TabStructurePenaltyShootoutInfo.propTypes = {
	teamInfo: PropTypes.object,
	opponentTeamInfo: PropTypes.object,
	showConditionalJsx: PropTypes.bool,
	isEventHasOTPS: PropTypes.bool,
	isChampion: PropTypes.bool,
	isSimple: PropTypes.bool,
	isLeg2OrFinal: PropTypes.bool,
	rowIndex: PropTypes.number
};

export default TabStructurePenaltyShootoutInfo;
