import { BETSLIP_MODES, COMBO_BOOST_STEP_FORM_NAMES, COMBO_BOOST_STEPS } from "constants/betslip.constants";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useAppSelector from "./store/useAppSelector";

import { selectComboBoostSettings, selectIsDemoSession, selectSessionCurrency } from "store/slices/auth/selectors";
import { selectBets, selectBetslipMode, selectBetslipStake } from "store/slices/betslip/selectors";
import { selectUseBonus } from "store/slices/bonus/selectors";

const comboBoostStepsValues = Object.values(COMBO_BOOST_STEPS).sort((next, prev) => next < prev ? -1 : prev < next ? 1 : 0)
const boostFactorsDefault = {}

const useComboBoost = () => {
	const { t } = useTranslation()
	const comboBoostSettings = useAppSelector(selectComboBoostSettings);
	const isDemo = useAppSelector(selectIsDemoSession);
	const mode = useAppSelector(selectBetslipMode);
	const bets = useAppSelector(selectBets);
	const stake = useAppSelector(selectBetslipStake);
	const useBonus = useAppSelector(selectUseBonus);
	const currency = useAppSelector(selectSessionCurrency);

	const isComboBoostActive = !isDemo && !useBonus && mode === BETSLIP_MODES.MULTI && comboBoostSettings.isComboBoostEnabled;
	const boostFactors = isComboBoostActive ? comboBoostSettings.boostFactors : boostFactorsDefault;

	const activeBets = useMemo(() => {
		if (!isComboBoostActive) {
			return [];
		}
		return bets.filter((bet) => !bet.isExpired)
	}, [isComboBoostActive, bets])

	const comboBoostBets = useMemo(() => {
		if (!isComboBoostActive) {
			return [];
		}
		return activeBets.filter(bet => bet.factor >= comboBoostSettings.minOddFactor)
	}, [isComboBoostActive, activeBets, comboBoostSettings])

	const betsCount = comboBoostBets.length;

	const stepsForDraw = useMemo(() => {

		if (!isComboBoostActive) {
			return [];
		}

		let maxStep = 0;
		let maxValue = 0
		comboBoostStepsValues.forEach(comboBoostStepsValue => {
			if (maxValue < boostFactors[COMBO_BOOST_STEP_FORM_NAMES[comboBoostStepsValue]]) {
				maxValue === boostFactors[COMBO_BOOST_STEP_FORM_NAMES[comboBoostStepsValue]];
				maxStep = comboBoostStepsValue;
			}
		})

		const stepsWithValues = comboBoostStepsValues.filter(comboBoostStepsValue => comboBoostStepsValue <= maxStep)

		const stepsForDraw = Array.from({ length: 3 }, (_, i) => i + 1).concat(stepsWithValues)

		return stepsForDraw;
	}, [isComboBoostActive, boostFactors])

	const { availableBoosts, boostCounts, minCountForBoost, maxCountForBoost } = useMemo(() => {
		let availableBoosts = {}
		let boostCounts = [];

		if (isComboBoostActive) {
			comboBoostStepsValues.forEach(comboBoostStepsValue => {
				const boost = boostFactors[COMBO_BOOST_STEP_FORM_NAMES[comboBoostStepsValue]];
				if (typeof boost !== "number" || !boost) {
					return
				}
				availableBoosts[comboBoostStepsValue] = boost
			})
	
			boostCounts = Object.keys(availableBoosts).map(Number).sort((next, prev) => next < prev ? -1 : prev < next ? 1 : 0)
		}

		return {
			availableBoosts,
			boostCounts,
			minCountForBoost: boostCounts.at(0) ?? 0,
			maxCountForBoost: boostCounts.at(-1 ?? 0)
		}
	}, [isComboBoostActive, boostFactors])

	const calculatePossibleWin = () => {
		if (mode !== BETSLIP_MODES.MULTI || !isComboBoostActive) {
			return 0
		}

		const totalStake = stake - (useBonus ? stake : 0);
		const sumOfFactors = bets.reduce((total, bet) => total + bet.factor, 0);

		const amounts = comboBoostBets.map((bet) => {
			return (totalStake * bet.factor) / sumOfFactors
		})
		
		const sumOfAmounts = amounts.reduce((total, amount) => total + amount, 0);

		const possibleWin = comboBoostBets.reduce((total, bet) => total * bet.factor, 1) * sumOfAmounts;

		return possibleWin
	};

	const getCurrentBoostFactor = () => {
		if (!isComboBoostActive) {
			return 0
		}

		if (minCountForBoost > betsCount) {
			return 0
		}

		if (maxCountForBoost < betsCount) {
			return availableBoosts[boostCounts.at(-1)]
		}
		
		const currentBoost = boostCounts.findLast(boost => boost <= betsCount);

		return availableBoosts[currentBoost] ?? 0;
	}

	const checkBoostIsCompleted = (comboBoostStepsValue) => {
		if (!isComboBoostActive) {
			return false
		}
		return betsCount >= comboBoostStepsValue
	}

	const checkBoostIsActive = (comboBoostStepsValue) => {
		if (!isComboBoostActive) {
			return false
		}
		if (minCountForBoost > betsCount) {
			return false;
		}

		if (maxCountForBoost < betsCount) {
			return comboBoostStepsValue === maxCountForBoost
		}

		return (betsCount >= comboBoostStepsValue && getCurrentBoostFactor() === availableBoosts[comboBoostStepsValue])
	}

	const getBoostDescriptionText = (configObjs) => {

		const { hideAlways, showMaxCaseText } = Object.assign({}, { hideAlways: false, showMaxCaseText: true }, configObjs)

		if (!isComboBoostActive || hideAlways) {
			return ""
		}

		if (betsCount >= maxCountForBoost) {
			return showMaxCaseText ? t("common.youGetMaxBoost") : null
		}

		if (betsCount < minCountForBoost) {
			return t("common.someBetsToBoost").replace("{count}", minCountForBoost - betsCount).replace("{boostPercent}", availableBoosts[minCountForBoost])
		}
		const boostCount = boostCounts.find(boostCount => boostCount > betsCount);
		return t("common.someBetsToBoost").replace("{count}", boostCount - betsCount).replace("{boostPercent}", availableBoosts[boostCount])

	}

	return {
		betsCount,
		isComboBoostActive,
		comboBoostBets,
		comboBoostSettings,
		stepsForDraw,
		availableBoosts,
		boostCounts,
		minCountForBoost,
		maxCountForBoost,
		getCurrentBoostFactor,
		checkBoostIsCompleted,
		checkBoostIsActive,
		getBoostDescriptionText,
		calculatePossibleWin,
		currency
	}
}

export default useComboBoost