import { useTranslation } from "react-i18next";

/* Success view of Betslip */
const BetSlipSuccess = () => {
	const { t } = useTranslation();

	return (
		<div className="vs--rightBar-section vs--rightBar-success vs--fade-in vs--flex vs--justify-center vs--align-center vs--flex-col">
			<span className="vs--title-green vs--font-normal vs--font-medium vs--rightBar-success-title">{t("bet.betslipSuccessText")}</span>
			<span className="vs--title-gray-80 vs--font-normal vs--font-regular vs--rightBar-success-subTitle vs--pl-16 vs--pr-16 vs--text-center">{t("bet.betslipSuccessSubtext")}</span>
		</div>
	);
};

/** BetSlipSuccess propTypes
 * PropTypes
 */
export default BetSlipSuccess;
