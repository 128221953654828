import './polyfill'
import ReactDOM from 'react-dom/client';

import 'utils/consoleExtenssion';
// import './assets/css/scss/common/typeography.css';
import './assets/css/scss/style.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import './assets/styles/style.scss';

import App from "./App";

if (import.meta.env.MODE === "production" && location.protocol !== "https:") {
	location.replace(`https:${location.href.substring(location.protocol.length)}`);
} else {
	const root = ReactDOM.createRoot(document.getElementById("root"));
	root.render(<App />);
}
