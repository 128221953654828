import * as signalR from "@microsoft/signalr";
import { isNullish, debounce } from "utils/common";

class WebSocketService {
	constructor(connectionURL, onSetupCallback) {
		this.connection = null;
		this.promise = null;
		this.connectionURL = connectionURL;
		this.onSetupCallback = onSetupCallback;
		this.buildConnection();

		this.debouncedHandleConnectionError = debounce(this.handleConnectionError, 5000);
	}

	/** Function to call when connection is ready
	 * @function
	 * @memberof WebSocketService
	 */
	setup = () => {
		if (typeof this.onSetupCallback === "function") {
			this.onSetupCallback(this);
		}
	};

	/** Start signalR connection if it is not started yet
	 * @function
	 * @returns {Promise}
	 * @memberof WebSocketService
	 */
	startConnection = async () => {
		if (this.isConnected || this.isConnecting || this.isReconnecting) {
			return this.promise;
		}
		try {
			this.promise = this.connection.start();
			await this.promise;
			this.setup();
		} catch (error) {
			console.log("SignalR start fails: " + this.connectionURL);
			console.log(error);
			this.debouncedHandleConnectionError();
		}

		return this.promise;
	};

	/** Function to call when connection failed
	 * @function
	 * @memberof WebSocketService
	 */
	handleConnectionError = () => {
		this.startConnection();
	};

	buildConnection = () => {
		const connection = new signalR.HubConnectionBuilder();

		connection.withUrl(this.connectionURL, {
			skipNegotiation: true,
			transport: signalR.HttpTransportType.WebSockets
		});
		connection.withAutomaticReconnect();
		connection.configureLogging(
			import.meta.env.SYSTEM_APP_MODE !== "production" ? signalR.LogLevel.Error : signalR.LogLevel.None
		);

		this.connection = connection.build();

		this.connection.onreconnected(() => {
			console.log("SignalR reconnected: " + this.connectionURL);
			this.setup();
		});

		this.connection.onclose((err) => {
			console.log("SignalR closed: " + this.connectionURL);
			if (!isNullish(err)) {
				this.debouncedHandleConnectionError();
			}
		});

		this.startConnection();
	};

	/** Function to send args to server
	 * @function
	 * @param {string} methodName
	 * @param {array} args
	 * @memberof WebSocketService
	 */
	invoke = (methodName, ...args) => {
		return this.connection.invoke(methodName, ...args);
	};

	/** Getter to check connection state is Connected
	 * @type {boolean}
	 * @memberof WebSocketService
	 */
	get isConnected() {
		return this.connection.state === signalR.HubConnectionState.Connected;
	}

	/** Getter to check connection state is Connecting
	 * @type {boolean}
	 * @memberof WebSocketService
	 */
	get isConnecting() {
		return this.connection.state === signalR.HubConnectionState.Connecting;
	}

	/** Getter to check connection state is Disconnected
	 * @type {boolean}
	 * @memberof WebSocketService
	 */
	get isDisconnected() {
		return this.connection.state === signalR.HubConnectionState.Disconnected;
	}

	/** Getter to check connection state is Disconnecting
	 * @type {boolean}
	 * @memberof WebSocketService
	 */
	get isDisconnecting() {
		return this.connection.state === signalR.HubConnectionState.Disconnecting;
	}

	/** Getter to check connection state is Reconnecting
	 * @type {boolean}
	 * @memberof WebSocketService
	 */
	get isReconnecting() {
		return this.connection.state === signalR.HubConnectionState.Reconnecting;
	}

	/** Function to subscribe to event
	 * @function
	 * @param {string} event
	 * @param {Callback} callback
	 * @memberof WebSocketService
	 */
	on = (methodName, callback) => {
		return this.connection.on(methodName, callback);
	};

	/** Function to unsubscribe from event
	 * @function
	 * @param {string} event
	 * @param {Callback} callback
	 * @memberof WebSocketService
	 */
	off = (methodName, callback) => {
		return this.connection.off(methodName, callback);
	};

	/** Stop signalR connection if it necessary
	 * @function
	 * @returns {void}
	 * @memberof WebSocketService
	 */
	stopConnection = () => {
		try {
			return this.connection.stop();
		} catch (error) {
			console.log(error);
		}
	};
}

export default WebSocketService;
