import { Fragment } from "react";
import PropTypes from "prop-types";
import TabStructurePenaltyShootoutInfo from "./tabStructureCommonInfo/tabStructurePenaltyShootoutInfo";
import TabStructureOverTimeInfo from "./tabStructureCommonInfo/tabStructureOverTimeInfo";
import TabStructureFullTimeInfo from "./tabStructureCommonInfo/tabStructureFullTimeInfo";

const TabStructureSimpleCupInfo = ({ isEventHasOTPS, teamInfo, opponentTeamInfo, showConditionalJsx, rowIndex }) => {
	return (
		<Fragment>
			<TabStructurePenaltyShootoutInfo isEventHasOTPS={isEventHasOTPS} teamInfo={teamInfo} opponentTeamInfo={opponentTeamInfo} showConditionalJsx={showConditionalJsx} isSimple={true} rowIndex={rowIndex} />
			<TabStructureOverTimeInfo isEventHasOTPS={isEventHasOTPS} teamInfo={teamInfo} opponentTeamInfo={opponentTeamInfo} showConditionalJsx={showConditionalJsx} isSimple={true} rowIndex={rowIndex} />
			<TabStructureFullTimeInfo teamInfo={teamInfo} opponentTeamInfo={opponentTeamInfo} isSimple={true} rowIndex={rowIndex} />
		</Fragment>
	);
};

TabStructureSimpleCupInfo.propTypes = {
	teamInfo: PropTypes.object,
	opponentTeamInfo: PropTypes.object,
	showConditionalJsx: PropTypes.bool,
	isEventHasOTPS: PropTypes.bool,
	rowIndex: PropTypes.number
};

export default TabStructureSimpleCupInfo;
