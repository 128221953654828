import PropTypes from "prop-types";

import { buildPathToStaticFolderOfCDN, mergeClassNames } from "utils/common"
import { flagCodeMapper } from 'utils/flags';

import { GAME_TYPE } from "constants/game.constants";

/** Flag Component */
const Flag = ({
	code,
	gameType = GAME_TYPE.FOOTBALL_SINGLE_MATCH,
	className = "",
	renderWhenFlagExistsOnly = false
}) => {
	const mappedFlagCode = flagCodeMapper(gameType, code);
	const img = buildPathToStaticFolderOfCDN(`images/flags/${mappedFlagCode}`);
	const style = (
		mappedFlagCode
			? ({ backgroundImage: `url("${img}")` })
			: null
	);
	return (
		<div className={mergeClassNames("vs--flag", className)}>
			<div className="vs--flag-icon" style={(!renderWhenFlagExistsOnly || img.includes(".svg")) ? style : null} />
		</div>
	);
};

/** Flag propTypes
 * PropTypes
 */
Flag.propTypes = {
	className: PropTypes.string,
	/** Country/Team code of flag */
	code: PropTypes.string,
	/** Game type wich flag need to show */
	gameType: PropTypes.oneOf(Object.values(GAME_TYPE))
};

export default Flag;
