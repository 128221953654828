import ApiUrls from "constants/api.constants";

import httpService from "../http";

const gameService = {
	event: (id, signal) => {
		return httpService.get({
			path: ApiUrls.GET_FOOTBALL_EVENT,
			queryData: { id },
			signal
		});
	},

	liveAndUpcomings: (gameType, signal) => {
		return httpService.get({
			path: ApiUrls.GET_LIVE_AND_UPCOMING,
			queryData: { type: gameType },
			signal
		});
	},

	results: ({ gameType, filters }, signal) => {
		return httpService.get({
			path: ApiUrls.GET_LAST_RESULTS,
			queryData: { type: gameType, ...filters },
			signal
		});
	},

	seasonResults: (id, signal) => {
		return httpService.get({
			path: ApiUrls.GET_LAST_SEASON_RESULTS,
			queryData: { id },
			signal
		});
	},

	lastResults: (gameType, signal) => {
		return httpService.get({
			path: ApiUrls.GET_LAST_FINISHED_RESULTS,
			queryData: { type: gameType },
			signal
		});
	}
};

export default gameService;
