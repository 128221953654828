import { COLLAPSE_MODE } from "constants/ui.constants";

export const updateExpandedRowIds = ({ mode, expandedRowIds, currentRowId }) => {
	if (mode === COLLAPSE_MODE.ACCORDION) {
		return expandedRowIds[0] !== currentRowId ? [currentRowId] : [];
	}

	// the case of Collapse mode
	if (expandedRowIds.includes(currentRowId)) {
		return expandedRowIds.filter((id) => id !== currentRowId);
	}

	return [...expandedRowIds, currentRowId];
};

export const constructArrayForGivenRange = ({ from, to }) => {
	if (typeof from !== "number" || typeof to !== "number") {
		console.error(new Error("array can be constructed only with numbers"));

		return [];
	}

	const constructedArray = [];

	for (let i = from; i <= to; i++) {
		constructedArray.push(i);
	}

	return constructedArray;
};

export const getDisplayedItemsRange = ({ currentPageSize, currentPage, total }) => {
	const currentPageLastItemIndex = currentPage * currentPageSize;
	const displayedItemsFrom = currentPageLastItemIndex - currentPageSize + 1;
	const displayedItemsTo = Math.min(currentPageLastItemIndex, total);

	return [displayedItemsFrom, displayedItemsTo];
};

export const isDropdownFitsOnScreen = ({ sizeChangerElement, dropDownElement }) => {
	const sizeChangerPosition = sizeChangerElement.getBoundingClientRect();

	const isDropdownFitsOnScreen = window.innerHeight - sizeChangerPosition.bottom > dropDownElement.clientHeight + 10;

	return isDropdownFitsOnScreen;
};
