const sliceName = "lucky-six";

const initialState = {
	rtPsLoading: false,
	marketsLoading: false,
	resultRtPs: {},
	resultMarkets: {}
};

export { sliceName, initialState };
