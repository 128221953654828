import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Input from "components/ui/input";
import Emoji from 'components/ui/emoji';
import useGlobalVariables from 'hooks/useGlobalVariables';
import { buildPathToStaticFolderOfCDN } from 'utils/common';
import SurveyType from 'types/survey.type';

const rates = [
	{
		name: "terrible",
		className: "vs--mr-16",
		imgSrc: buildPathToStaticFolderOfCDN("images/misc/emoji-angry.png"),
		value: 1
	},
	{
		name: "bad",
		className: "vs--mr-16",
		imgSrc: buildPathToStaticFolderOfCDN("images/misc/emoji-sad.png"),
		value: 2
	},
	{
		name: "okay",
		className: "vs--mr-16",
		imgSrc: buildPathToStaticFolderOfCDN("images/misc/emoji-neutral.png"),
		value: 3
	},
	{
		name: "good",
		className: "vs--mr-16",
		imgSrc: buildPathToStaticFolderOfCDN("images/misc/emoji-satisfied.png"),
		value: 4
	},
	{
		name: "great",
		imgSrc: buildPathToStaticFolderOfCDN("images/misc/emoji-happy.png"),
		value: 5
	}
];

const CommonView = ({ closeFeedback, title, sendFeedback, setSelectedEmojiValue, selectedEmojiValue, survey, isThankYouMsgVisible }) => {
	const { t } = useTranslation();
	const { isMobile } = useGlobalVariables();
	const [inputValue, setInputValue] = useState("");

	const handleEmojiSelect = (grade) => {
		if (grade > 2) {
			sendFeedback({ grade });
		} else if (grade <= 2 && !survey.suggestMessage) {
			sendFeedback({ grade });
		}

		setSelectedEmojiValue(grade);
	};

	return (
		<div className="vs--modal-feedback">
			{!isMobile && (
				<div className="vs--modal-feedback-tittle vs--flex vs--justify-between vs--align-center">
					<span className="vs--title-white -white vs--font-big vs--font-medium">{title}</span>
					<i className="ic_close vs--font-bigest" onClick={closeFeedback}></i>
				</div>
			)}
			{selectedEmojiValue === null && (
				<div className="vs--modal-feedback-content vs--pt-16 vs--pr-16 vs--pb-16 vs--pl-16">
					<span className="vs--title-white vs--font-normal">{t("common.surveyQuestion")}</span>
					<div className="vs--flex vs--mt-16">
						{rates.map((rate, index) => (
							<Emoji key={`${rate.name}_${index}`} emoji={rate} onClick={() => handleEmojiSelect(rate.value)} />
						))}
					</div>
				</div>
			)}
			{!isThankYouMsgVisible && selectedEmojiValue !== null && selectedEmojiValue < 3 && survey.suggestMessage && (
				<div className="vs--modal-feedback-content vs--pt-16 vs--pr-16 vs--pb-16 vs--pl-16">
					<div className="vs--modal-feedback-comment">
						<span className="vs--title-white vs--font-normal vs--pr-16">{t("common.poorRatedSurveyQuestion")}</span>
						<div className="vs--mt-16 vs--mb-16">
							<Input.TextArea value={inputValue} onChange={(event) => setInputValue(event.target.value)} placeholder={t("common.surveyTextAreaPlaceholder")} maxLength={1000} />
						</div>
						<div className="vs--flex vs--justify-end" onClick={() => sendFeedback({ message: inputValue })}>
							<button className="vs--button vs--pt-8 vs--pb-8 vs--pl-16 vs--pr-16 vs--font-normal vs--font-medium">{t("common.send")}</button>
						</div>
					</div>
				</div>
			)}
			{isThankYouMsgVisible && (
				<div className="vs--modal-feedback-content vs--pt-16 vs--pr-16 vs--pb-16 vs--pl-16">
					<div className="vs--modal-feedback-done vs--text-center vs--pt-8">
						<i className="ic_done"></i>
						<div className="vs--mt-8 vs--mb-4">
							<b className="vs--title-white vs--font-big">{t("common.thankYou")}</b>
						</div>
						<span className="vs--title-white vs--font-normal vs--pl-8 vs--pr-8">{t("common.ratedSurveyMessage")}</span>
					</div>
				</div>
			)}
		</div>
	);
};

CommonView.propTypes = {
	closeFeedback: PropTypes.func,
	title: PropTypes.string,
	sendFeedback: PropTypes.func,
	setSelectedEmojiValue: PropTypes.func,
	selectedEmojiValue: PropTypes.number,
	survey: SurveyType,
	isThankYouMsgVisible: PropTypes.bool
};

export default CommonView;
