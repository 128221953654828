export const PROJECT_TYPE = {
	BACK_OFFICE: 0,
	ONLINE: 1,
	RETAIL: 2
};

export const VIDEO_LAYOUT = {
	NORMAL: 1,
	REVERSED: 2
};

export const ANALYTICAL_TOOL_TYPE = {
	HOTJAR: 1,
	GOOGLE_ANALYTICS: 2,
	VWO: 4,
	YANDEX_METRICA: 8
};

export const LOGIN_CONFIGURATION_TYPE = {
	URL: 1,
	POPUP: 2
};

export const CONNECTION_STATE = {
	NORMAL: 1,
	SLOW: 2,
	OFFLINE: 3
};

export const SEASON_GAME_LIVE_RESULTS_TABS_INFO = {
	LIVE_SCORES: 1,
	GAME_LIVE_RESULT: 2
};

export const MEDIA_FOR_CHECK_POINTER = "(pointer: coarse)";
export const PX_FOR_IMMEDIATELY_CHANGE_TO_MOBILE_VIEW = 1200;
export const MEDIA_FOR_IMMEDIATELY_CHANGE_TO_MOBILE_VIEW = `screen and (max-width: ${PX_FOR_IMMEDIATELY_CHANGE_TO_MOBILE_VIEW}px)`;

export const TASK_SCHEDULER_TIME_PERIODS = {
	LIVE_AND_UPCOMMINGS: 3 * 60 * 1000,
	LAST_RESULTS: 3 * 60 * 1000
};
