import {
	setKenoStatisticsAction,
	setQuickStatisticsStateAction,
	setSeasonStatisticsIdAction,
	setCurrentParticipantAction,
	setRacingCurrentEventAction,
	setSeasonLiveModalMobileAction,
	updateSeasonStructureAction,
	updateSeasonStatisticsAction,
	resetStatisticsStateAction,
	setStatisticsTeamAction,
	resetQuickStatisticsStatisticsAction
} from "./actions";

import {
	getCountriesThunk,
	getFootballTeamStatisticsThunk,
	getPenaltyTeamStatisticsThunk,
	getFootballTeamToTeamStatisticsThunk,
	getPenaltyTeamToTeamStatisticsThunk,
	getHeadToHeadAllTimeStatisticsThunk,
	getRaceParticipantsThunk,
	getRaceParticipantStatisticsThunk,
	getSeasonStatisticsThunk,
	getKenoStatisticsThunk,
	getSeasonStructureThunk,
	getFootballQuickStatisticsThunk,
	getPenaltyQuickStatisticsThunk,
	getLeagueQuickStatisticsThunk,
	getLuckySixStatisticsThunk
} from "./thunks";

import { initialState } from "./initialState";

import { updateSeasonStatisticsStructure } from "utils/statistics";

import { GAMES_REDUCER_KEYS } from "constants/statistics.constants";

export const addCommonCases = (builder) => {
	builder.addCase(resetStatisticsStateAction, (state, { payload }) => {
		state = initialState;
	});
};

export const addCountriesCases = (builder) => {
	builder.addCase(getCountriesThunk.fulfilled, (state, { payload: countries, meta: { arg: gameType } }) => {
		state[GAMES_REDUCER_KEYS[gameType]].countries = countries;
	});
};

export const addTeamStatisticsCases = (builder) => {
	builder.addCase(getFootballTeamStatisticsThunk.pending, (state) => {
		state.loading = true;
	});

	builder.addCase(getFootballTeamStatisticsThunk.fulfilled, (state, { payload, meta: { arg } }) => {
		const { lastMatches } = payload.data;

		state[GAMES_REDUCER_KEYS[arg.gameType]][payload.teamStatistics] = {
			...state[GAMES_REDUCER_KEYS[arg.gameType]][payload.teamStatistics],
			last10Matches: lastMatches
		};
	});

	builder.addCase(getFootballTeamStatisticsThunk.rejected, (state) => {
		state.loading = false;
	});

	builder.addCase(getPenaltyTeamStatisticsThunk.pending, (state) => {
		state.loading = true;
	});

	builder.addCase(getPenaltyTeamStatisticsThunk.fulfilled, (state, { payload, meta: { arg } }) => {
		state[GAMES_REDUCER_KEYS[arg.gameType]][payload.teamStatistics] = {
			...payload.data,
			loaded: payload.data.lastMatches.length < 10
		};
	});

	builder.addCase(getPenaltyTeamStatisticsThunk.rejected, (state) => {
		state.loading = false;
	});

	builder.addCase(setStatisticsTeamAction, (state, { payload }) => {
		state[GAMES_REDUCER_KEYS[payload.gameType]][`team${payload.index}`] = payload.team;
	});
};

export const addTeamToTeamStatisticsCases = (builder) => {
	builder.addCase(getFootballTeamToTeamStatisticsThunk.pending, (state) => {
		state.loading = true;
	});

	builder.addCase(getFootballTeamToTeamStatisticsThunk.fulfilled, (state, { payload: data, meta: { arg } }) => {
		state[GAMES_REDUCER_KEYS[arg.gameType]].h2h = data;
	});

	builder.addCase(getFootballTeamToTeamStatisticsThunk.rejected, (state) => {
		state.loading = false;
	});

	builder.addCase(getPenaltyTeamToTeamStatisticsThunk.pending, (state) => {
		state.loading = true;
	});

	builder.addCase(getPenaltyTeamToTeamStatisticsThunk.fulfilled, (state, { payload: data, meta: { arg } }) => {
		state[GAMES_REDUCER_KEYS[arg.gameType]].h2h = data;
	});

	builder.addCase(getPenaltyTeamToTeamStatisticsThunk.rejected, (state) => {
		state.loading = false;
	});

	builder.addCase(getHeadToHeadAllTimeStatisticsThunk.pending, (state) => {
		state.loading = true;
	});

	builder.addCase(getHeadToHeadAllTimeStatisticsThunk.fulfilled, (state, { payload: data, meta: { arg } }) => {
		const { team1, team2, ...rest } = data;

		state[GAMES_REDUCER_KEYS[arg.gameType]] = {
			...state[GAMES_REDUCER_KEYS[arg.gameType]],
			team1Statistics: { ...state[GAMES_REDUCER_KEYS[arg.gameType]].team1Statistics, ...team1 },
			team2Statistics: { ...state[GAMES_REDUCER_KEYS[arg.gameType]].team2Statistics, ...team2 },
			...rest
		};
	});

	builder.addCase(getHeadToHeadAllTimeStatisticsThunk.rejected, (state) => {
		state.loading = false;
	});
};

export const addRacingStatisticsCases = (builder) => {
	builder.addCase(getRaceParticipantsThunk.fulfilled, (state, { payload: participants }) => {
		state.racing.participants = participants;
	});

	builder.addCase(getRaceParticipantStatisticsThunk.fulfilled, (state, { payload: data }) => {
		state.racing.statistics = data;
	});

	builder.addCase(setCurrentParticipantAction, (state, { payload }) => {
		state.racing.currentParticipant = payload.participant;
	});

	builder.addCase(setRacingCurrentEventAction, (state, { payload }) => {
		state.racing.currentEvent = payload.eventId;
	});
};

export const addSeasonStatisticsCases = (builder) => {
	builder.addCase(getSeasonStatisticsThunk.pending, (state) => {
		state.season.loading = true;
	});

	builder.addCase(getSeasonStatisticsThunk.fulfilled, (state, { payload: data }) => {
		state.season.standings = data;
		state.season.loading = false;
	});

	builder.addCase(getSeasonStatisticsThunk.rejected, (state) => {
		state.season.loading = false;
	});

	builder.addCase(getSeasonStructureThunk.pending, (state) => {
		state.season.loading = true;
	});

	builder.addCase(getSeasonStructureThunk.fulfilled, (state, { payload: data }) => {
		state.season.structure = data;
		state.season.loading = false;
	});

	builder.addCase(getSeasonStructureThunk.rejected, (state) => {
		state.season.loading = false;
	});

	builder.addCase(setSeasonStatisticsIdAction, (state, { payload }) => {
		state.season.id = payload.id;
	});

	builder.addCase(setSeasonLiveModalMobileAction, (state, { payload }) => {
		state.season.showSeasonLiveModal = payload.show;
	});

	builder.addCase(updateSeasonStructureAction, (state, { payload }) => {
		const newStatisticsStructure = updateSeasonStatisticsStructure(state.season.structure, payload.data);

		state.season.structure = newStatisticsStructure;
	});

	builder.addCase(updateSeasonStatisticsAction, (state, { payload }) => {
		if (!payload.data.statistics) {
			return;
		}

		state.season.standings = payload.data.statistics;
	});
};

export const addKenoStatisticsCases = (builder) => {
	builder.addCase(getKenoStatisticsThunk.pending, (state) => {
		state.loading = true;
	});

	builder.addCase(getKenoStatisticsThunk.fulfilled, (state, { payload: data }) => {
		state.loading = false;
		state.kenoStatistics = data;
	});

	builder.addCase(getKenoStatisticsThunk.rejected, (state) => {
		state.loading = false;
	});

	builder.addCase(setKenoStatisticsAction, (state, { payload }) => {
		state.kenoStatistics = payload.data;
	});
};

export const addQuickStatisticsCases = (builder) => {
	builder.addCase(getFootballQuickStatisticsThunk.pending, (state) => {
		state.quickStatistics.loading = true;
		state.quickStatistics.teamsData = null;
	});

	builder.addCase(getFootballQuickStatisticsThunk.fulfilled, (state, { payload: data }) => {
		state.quickStatistics.loading = false;
		state.quickStatistics.statistics = data.slice(0, 3);
	});

	builder.addCase(getFootballQuickStatisticsThunk.rejected, (state) => {
		state.quickStatistics.loading = false;
	});

	builder.addCase(getPenaltyQuickStatisticsThunk.pending, (state) => {
		state.quickStatistics.loading = true;
		state.quickStatistics.teamsData = null;
	});

	builder.addCase(getPenaltyQuickStatisticsThunk.fulfilled, (state, { payload: data }) => {
		state.quickStatistics.loading = false;
		state.quickStatistics.statistics = data.lastMatches.slice(0, 3);
	});

	builder.addCase(getPenaltyQuickStatisticsThunk.rejected, (state) => {
		state.quickStatistics.loading = false;
	});

	builder.addCase(getLeagueQuickStatisticsThunk.pending, (state) => {
		state.quickStatistics.loading = true;
		state.quickStatistics.teamsData = null;
	});

	builder.addCase(getLeagueQuickStatisticsThunk.fulfilled, (state, { payload: data }) => {
		const { lastMatches, ...teamsData } = data;

		state.quickStatistics.loading = false;
		state.quickStatistics.teamsData = teamsData;
		state.quickStatistics.statistics = lastMatches.slice(0, 3);
	});

	builder.addCase(getLeagueQuickStatisticsThunk.rejected, (state) => {
		state.quickStatistics.loading = false;
	});

	builder.addCase(setQuickStatisticsStateAction, (state, { payload }) => {
		state.quickStatistics.state = payload.state;
	});

	builder.addCase(resetQuickStatisticsStatisticsAction, (state) => {
		state.quickStatistics.statistics = [];
	});
};

export const addLuckySixStatisticsCases = (builder) => {
	builder.addCase(getLuckySixStatisticsThunk.pending, (state) => {
		state.loading = true;
	});

	builder.addCase(getLuckySixStatisticsThunk.fulfilled, (state, { payload: data }) => {
		state.loading = false;
		state.luckySixStatistics = data;
	});

	builder.addCase(getLuckySixStatisticsThunk.rejected, (state) => {
		state.loading = false;
	});
};
