export const getURLMergedWithQueryData = (url, queryData) => {
	const urlInstance = new URL(url);

	// By adding a timestamp to the URL, we disable caching.
	urlInstance.searchParams.append("timestamp", Date.now());

	const paramsNotProvided = queryData === undefined || Object.keys(queryData).length === 0;

	if (paramsNotProvided) {
		return urlInstance.href;
	}

	const paramEntries = Object.entries(queryData);

	paramEntries.forEach(([key, value]) => {
		if ([null, undefined, ""].includes(value)) {
			return;
		}

		if (Array.isArray(value)) {
			return value.forEach((item) => {
				urlInstance.searchParams.append(key, item);
			});
		}

		urlInstance.searchParams.append(key, value);
	});

	return urlInstance.href;
};

export const getURL = (path) => {
	const baseURL = import.meta.env.SYSTEM_API_URL;

	return `${baseURL}${path}`;
};
