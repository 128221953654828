import PropTypes from "prop-types";
import TabStructureTeamInfo from "./tabStructureTeamInfo";
import TabStructureSimpleCupInfo from "./tabStructureSimpleCupInfo";
import TabStructureChampionsCupInfo from "./tabStructureChampionsCupInfo";
import { isSimpleCupGame, isChampionsCupGame, isLeg2, isLeg2OrFinal } from "utils/common";
import eventType from "types/event.type";

const TabStructureLine = ({ teamInfo, opponentTeamInfo, event, showConditionalJsx, isEventHasOTPS, orderNumber, rowIndex }) => {
	const gameType = event?.gameType;
	const status = event?.status;

	return (
		<div className="vs--flex vs--align-center vs--justify-between">
			<TabStructureTeamInfo teamInfo={teamInfo} gameType={gameType} status={status} />
			<div className="vs--flex vs--align-center vs--justify-end">
				{((props) => {
					switch (true) {
						case isSimpleCupGame(gameType):
							return <TabStructureSimpleCupInfo {...props} />;
						case isChampionsCupGame(gameType):
							return <TabStructureChampionsCupInfo isLeg2={isLeg2(orderNumber)} isLeg2OrFinal={isLeg2OrFinal(orderNumber, gameType)} {...props} />;
						default:
							return null;
					}
				})({
					teamInfo,
					showConditionalJsx,
					isEventHasOTPS,
					opponentTeamInfo,
					rowIndex
				})}
			</div>
		</div>
	);
};

TabStructureLine.propTypes = {
	teamInfo: PropTypes.object,
	opponentTeamInfo: PropTypes.object,
	event: eventType,
	showConditionalJsx: PropTypes.bool,
	isEventHasOTPS: PropTypes.bool,
	orderNumber: PropTypes.number,
	rowIndex: PropTypes.number
};

export default TabStructureLine;
