export default class StorageWrapper {
	static Types = {
		Session: 1,
		Local: 2
	};
	static AvailableKeys = {
		[StorageWrapper.Types.Session]: "sessionstorage-test",
		[StorageWrapper.Types.Local]: "localstorage-test"
	};
	static ValuesToStore = {
		[StorageWrapper.Types.Session]: "sessionstorage-test",
		[StorageWrapper.Types.Local]: "localstorage-test"
	};

	static _validateAndAssignType = (type) => {
		if (!Object.values(StorageWrapper.Types).includes(type)) {
			throw new Error("Invalid type parameter.\n" + "Type is required and must be on of STORAGE_TYPES:\n" + "session storage 1, local storage 2");
		}
		return type;
	};

	static _validateAndAssignAvailableKey = (key, type) => {
		if (key === null || key === undefined) {
			return key || StorageWrapper.AvailableKeys[type];
		}
		if (typeof key === "string" && key !== "") {
			return key;
		}
		throw new Error("Invalid type parameter.\n" + "Available Key is optional or can be string");
	};

	static _validateAndAssignValueToStore = (valueToStore, type) => {
		if (valueToStore === null || valueToStore === undefined) {
			return valueToStore || StorageWrapper.ValuesToStore[type];
		}
		if (typeof valueToStore === "string" && valueToStore !== "") {
			return valueToStore;
		}
		throw new Error("Invalid type parameter.\n" + "Available Key is optional or can be string");
	};

	constructor(type, availableKey, availableValueToStore) {
		this.type = StorageWrapper._validateAndAssignType(type);
		this.key = StorageWrapper._validateAndAssignAvailableKey(availableKey, type);
		this.valueToStore = StorageWrapper._validateAndAssignValueToStore(availableValueToStore, type);
	}

	get storage() {
		switch (this.type) {
			case StorageWrapper.Types.Session:
				return window.sessionStorage;
			case StorageWrapper.Types.Local:
				return window.localStorage;
			default:
				throw new Error("Storage type: " + this.type + " doesn't implemented.");
		}
	}

	/** Check if storage is avalable
	 * @function
	 * @returns {boolean}
	 */
	get isAvailable() {
		try {
			this.storage.setItem(this.key, this.valueToStore);
			const recoveredValue = this.storage.getItem(this.key);
			this.storage.removeItem(this.key);
			return recoveredValue === this.valueToStore;
		} catch (e) {
			return false;
		}
	}

	/** Get from storage
	 * @memberof StorageWrapper
	 * @function
	 * @param {string} key - key
	 * @returns {Object}
	 */
	get = (key) => {
		if (!this.isAvailable) {
			return;
		}
		const data = this.storage.getItem(key);
		return JSON.parse(data);
	};

	/** Set in storage
	 * @memberof StorageWrapper
	 * @function
	 * @param {string} key - key
	 * @param {Object} value - value
	 */
	set = (key, value) => {
		if (!this.isAvailable) {
			return;
		}
		this.storage.setItem(key, JSON.stringify(value));
	};

	/** Remove from storage
	 * @memberof StorageWrapper
	 * @function
	 * @param {string} key - key
	 */
	remove = (key) => {
		if (!this.isAvailable) {
			return;
		}
		this.storage.removeItem(key);
	};

	/** Clear storage
	 * @memberof StorageWrapper
	 * @function
	 */
	clear = () => {
		if (!this.isAvailable) {
			return;
		}
		this.storage.clear();
	};
}
