import { Fragment, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";

import PostMessagesIntegration from "components/postMessagesIntegration";
import Main from "components/main";
import Header from "components/header";
import TopBar from "components/topBar";
import RightBar from "components/rightBar";
import MobileHeader from "components/mobileHeader";
import MobileMenu from "components/ui/mobileMenu";
import QuickBetslip from "components/ui/quickBetslip";
import SeasonLiveModal from "components/ui/season/modal/seasonLive";
import SeasonResultModal from "components/ui/season/modal/seasonResult";
import BetslipWonPopup from "components/betslipWonPopup";
import BonusActivationPopup from "components/bonusActivationPopup";
import UserFeedbackPopup from "components/feedbackPopup";
import BlockedMessage from "components/ui/blockedMessage";

import { placeBetSuccessAction } from "store/slices/betslip/actions";
import { selectSessionGames } from "store/slices/auth/selectors";
import { selectLiveAndUpcomingsData, selectCurrentGameType, selectIsLiveAndUpcomingsLoading } from "store/slices/game/selectors";
import { selectSeasonShowLiveModal } from "store/slices/statistics/selectors";
import { selectSeasonShowResultModalBySeasonId } from "store/slices/season/selectors";
import { selectPlaceBetSuccess } from "store/slices/betslip/selectors";

import useGlobalVariables from "hooks/useGlobalVariables";
import { GAME_ACTIVITY_STATE, GAME_NAVIGATION_BY_SCROLL_CLASSES, GAME_TYPE } from "constants/game.constants";
import { buildPathToStaticFolderOfCDN } from "utils/common";
import vsToast from "utils/toast";
import OnBoarding from "components/onBoarding";

/** Main Layout Component */
const Layout = () => {
	const games = useAppSelector(selectSessionGames);
	const currentGameType = useAppSelector(selectCurrentGameType);
	const liveAndUpcomings = useAppSelector(selectLiveAndUpcomingsData);
	const isliveAndUpcomingsLoading = useAppSelector(selectIsLiveAndUpcomingsLoading);
	const showSeasonLiveModal = useAppSelector(selectSeasonShowLiveModal);
	const showSeasonResultModalBySeasonId = useAppSelector(selectSeasonShowResultModalBySeasonId);
	const { t } = useTranslation();
	const location = useLocation();
	const isCurrentGameDeactivated =
	((games.find((game) => game.type === currentGameType))?.state ?? GAME_ACTIVITY_STATE.ACTIVE) === GAME_ACTIVITY_STATE.INACTIVE;
	const { isMobile, isIOS, isBuilderIntegration } = useGlobalVariables();
	const success = useAppSelector(selectPlaceBetSuccess);

	const dispatch = useAppDispatch();

	const hideSuccessMessage = () => dispatch(placeBetSuccessAction(false));

	useEffect(() => {
		if (!isIOS) {
			return;
		}
		document.getElementsByClassName("vs--container")[0].classList.add("vs--container-ios");
	}, [isIOS]);

	/** Detect case for landscape mode in mobile */
	useEffect(() => {
		if (!isMobile) {
			return;
		}

		/* function listener for window orientation change */
		const handleOrientationChange = () => {
			if (window.orientation > 0) {
				document.body.classList.add("vs--orientation-landscape");
				return;
			}
			document.body.classList.remove("vs--orientation-landscape");
		};

		handleOrientationChange();
		window.addEventListener("orientationchange", handleOrientationChange);

		return () => {
			window.removeEventListener("orientationchange", handleOrientationChange);
		};
	}, [isMobile]);

	useEffect(() => {
		if (isMobile && success) {
			vsToast.success(
				<div>
					<div>{t("bet.betslipSuccessText")}</div>
					<div>{t("bet.betslipSuccessSubtext")}</div>
				</div>
				,
				{ onClose: hideSuccessMessage }
			)
		}
	}, [isMobile, success])

	return (
		<Fragment>
			<Main />
			<PostMessagesIntegration />
			<div className="vs--container" data-pathname={location.pathname}>
				<div className="vs--wrapper vs--flex vs--flex-col">
					{isMobile && !isBuilderIntegration ? <MobileHeader /> : null}
					<TopBar isCurrentGameDeactivated={isCurrentGameDeactivated} />
					{isMobile ? null : <Header />}
					<div className={GAME_NAVIGATION_BY_SCROLL_CLASSES.VS_WRAPPER_SECTION}>
						{
							(
								isCurrentGameDeactivated &&
								!isliveAndUpcomingsLoading &&
								liveAndUpcomings.length === 0
							)
								? (
									<BlockedMessage
										title='common.gameIsDeactivated'
										message='common.gameIsDeactivatedMessage'
										imageSrc={buildPathToStaticFolderOfCDN("images/misc/plug.svg")}
									/>
								)
								: (
									<Fragment>
										{
											isMobile
												? <Outlet />
												: (
													<div className="vs--flex-equal vs--flex">
														<div className={`${GAME_NAVIGATION_BY_SCROLL_CLASSES.VS_WRAPPER_SECTION_INNER} vs--flex-equal`}>
															<Outlet />
														</div>
														<RightBar />
													</div>
												)
										}
									</Fragment>
								)
						}
					</div>
					{isMobile ? (
						<Fragment>
							<MobileMenu />
							{currentGameType !== GAME_TYPE.KENO ? <QuickBetslip /> : null}
						</Fragment>
					) : null}
				</div>
				<BetslipWonPopup />
				{isMobile ? (
					<Fragment>
						{showSeasonLiveModal ? <SeasonLiveModal /> : null}
						{showSeasonResultModalBySeasonId ? <SeasonResultModal /> : null}
					</Fragment>
				) : null}
				<BonusActivationPopup />
				<UserFeedbackPopup />
				{/* //TODO Need dynamic implementation with BO fore more flexibility */}
				<OnBoarding />
			</div>
		</Fragment>
	);
};

export default Layout;
