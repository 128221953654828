import { forwardRef, useContext } from "react";
import { StoreContext } from "contexts/navbar";
import { mergeClassNames } from "utils/common";

const DefaultElements = forwardRef(({ children, dir, elementsClassName }, ref) => {
	const [{ smoothAnimation }] = useContext(StoreContext);
	return (
		<ul ref={ref} className={mergeClassNames("vs--navbar-elements", "vs--invisible-scroll", smoothAnimation && "scroll-behavior-smooth", elementsClassName)} dir={dir}>
			{children}
		</ul>
	);
});

export default DefaultElements;
