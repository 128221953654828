import { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import { LiveScoresChangeDataContext } from "contexts/liveScoresContext";
import { buildPathToStaticFolderOfCDN } from "utils/common";

const yellowCardIcon = buildPathToStaticFolderOfCDN("images/misc/yellow-card.svg");
const redCardIcon = buildPathToStaticFolderOfCDN("images/misc/red-card.svg");
const penaltyIcon = buildPathToStaticFolderOfCDN("images/misc/penalty.svg");
const goalIcon = buildPathToStaticFolderOfCDN("images/misc/ball-colored.svg");

const LiveScoreAction = ({ rowIndex }) => {
	const changedData = useContext(LiveScoresChangeDataContext);
	return (
		<Fragment>
			{changedData[rowIndex] && changedData[rowIndex].key === "yellowCardCount" ? <img className="vs--table-live-score-action-icon" src={yellowCardIcon} alt="yellowCard" /> : null}
			{changedData[rowIndex] && changedData[rowIndex].key === "redCardCount" ? <img className="vs--table-live-score-action-icon" src={redCardIcon} alt="redCard" /> : null}
			{changedData[rowIndex] && changedData[rowIndex].key === "penaltyCount" ? <img className="vs--table-live-score-action-icon" src={penaltyIcon} alt="penalty" /> : null}
			{changedData[rowIndex] && changedData[rowIndex].key === "goalCount" ? <img className="vs--table-live-score-action-icon" src={goalIcon} alt="goal" /> : null}
		</Fragment>
	);
};

LiveScoreAction.propTypes = {
	rowIndex: PropTypes.number
};

export default LiveScoreAction;
