import Dropdown from "components/ui/dropdown";
import SettingsComponent from "components/pages/settings/settings";

/* Settings Component at right of Header */
const Settings = () => {
	return (
		<div className="vs--settings vs--flex vs--align-center vs--pl-16">
			<Dropdown
				dropdownContent={<SettingsComponent />}
				renderSelectable={(selected) => (
					<i
						className={`ic_gear vs--font-bigest ${selected ? "ic_gear-active" : ""}`}
					/>
				)}
			/>
		</div>
	);
};

export default Settings;
