import { sliceName } from "./initialState";

const emptyArray = [];
const emptyObj = {};

export const selectSeasonCurrentEventId = (state) => state[sliceName].currentEventId;
export const selectShowNextLiveAndUpcomings = (state) => state[sliceName].showNextLiveAndUpcomings;
export const selectSeasonSelectedMarket = (state) => state[sliceName].selectedMarket;
export const selectSeasonSpecialMarket = (state) => state[sliceName].special;
export const selectSeasonShowResultModalBySeasonId = (state) => state[sliceName].showSeasonResultModalBySeasonId;
export const selectSeasonStandings = (state) => state[sliceName].standings ?? emptyArray;
export const selectIsSeasonLoading = (state) => state[sliceName].isLoading ?? false;
export const selectSeasonStructure = (state) => state[sliceName].structure ?? emptyObj;

export const selectSpecialMarketsSeasonId = (state) => selectSeasonSpecialMarket(state)?.seasonId;
export const selectShowSpecialMarkets = (state) => selectSeasonSpecialMarket(state)?.show;
export const selectSpecialMarkets = (state) => selectSeasonSpecialMarket(state)?.markets;
export const createGivenSpecialMarketSelector = (part) => (state) => selectSeasonSpecialMarket(state)?.markets?.[part] ?? emptyArray;

export const createSelectedSpecialMarketsSelector = (selected) => (state) => selectSpecialMarkets(state)[selected];