import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import NoData from "components/ui/noData";
import Loader from "components/ui/loader";
import TabStructure from "./tabStructure";
import TreeStructure from "./treeStructure";
import StructureContainer from "./structureContainer";
import { isChampionsCupGame, isLeg1 } from "utils/common";
import eventType from "types/event.type";
import useGlobalVariables from "hooks/useGlobalVariables";

/** Cup Structure Component */
const CupStructure = ({ loading, structure, isFromResults = false }) => {
	const [seasonStructure, setStructure] = useState(structure);
	const { isMobile } = useGlobalVariables();
	useEffect(() => {
		if (!Object.keys(structure).length) {
			return;
		}
		const newStructure = structuredClone(structure);
		if (isChampionsCupGame(newStructure.gameType) && Array.isArray(newStructure.events)) {
			// Loop for calculating AGG for Leg 2
			newStructure.events = newStructure.events.map((round, index, arr) => {
				// Check only Leg 2
				if (isLeg1(round.orderNumber)) {
					return round;
				}

				// Loop on events of Leg 2 round
				round.events = round.events.map((simpleEvent) => {
					if (!simpleEvent.orderNumber) {
						return simpleEvent;
					}

					// Find event with same teams in previous round
					const prevousRaund = arr[index - 1];
					if (!prevousRaund) {
						return simpleEvent;
					}
					const prevousRaundEvents = prevousRaund.events || [];
					if (!prevousRaundEvents.length) {
						return simpleEvent;
					}

					const previousRaundEvent = prevousRaundEvents.find((prevRaundEvent) => {
						if (!prevRaundEvent.gameData) {
							return false;
						}
						if (!prevRaundEvent.orderNumber) {
							return false;
						}
						return prevRaundEvent.orderNumber === simpleEvent.orderNumber;
					});

					//  Interrupt if there isn't event yet
					if (!previousRaundEvent) {
						return simpleEvent;
					}

					if (!simpleEvent.gameData) {
						simpleEvent.gameData = {};
					}

					// Define defaults if necessary
					if (!simpleEvent.gameData.team1) {
						simpleEvent.gameData.team1 = {};
					}
					if (!simpleEvent.gameData.team2) {
						simpleEvent.gameData.team2 = {};
					}

					const team1 = simpleEvent?.gameData.team1 ?? {};
					const team2 = simpleEvent?.gameData.team2 ?? {};
					const prevTeam1 = previousRaundEvent?.gameData.team1 ?? {};
					const prevTeam2 = previousRaundEvent?.gameData.team2 ?? {};

					// Calculating AGG
					simpleEvent.gameData.team1.aggGoalCount = (team1.goalCount ?? 0) + (team1.overTimeGoalCount ?? 0) + (prevTeam2.goalCount ?? 0);
					simpleEvent.gameData.team2.aggGoalCount = (team2.goalCount ?? 0) + (team2.overTimeGoalCount ?? 0) + (prevTeam1.goalCount ?? 0);

					return { ...simpleEvent };
				});

				return { ...round };
			});
		}
		setStructure(newStructure);
	}, [loading, structure]);

	if (loading) {
		return (
			<StructureContainer>
				<div className="structure-loader">
					<Loader />
				</div>
			</StructureContainer>
		);
	}

	if ((seasonStructure?.events ?? []).length === 0) {
		return (
			<StructureContainer>
				<NoData />
			</StructureContainer>
		);
	}

	return <StructureContainer>{isFromResults && !isMobile ? <TreeStructure seasonStructure={seasonStructure} /> : <TabStructure seasonStructure={seasonStructure} />}</StructureContainer>;
};

/** CupStructure propTypes
 * PropTypes
 */
CupStructure.propTypes = {
	/** Redux state property, the season data */
	structure: eventType,
	/** Redux state property, is true when loading structure */
	loading: PropTypes.bool,
	/** React property, will component mount from Result page */
	isFromResults: PropTypes.bool
};

export default CupStructure;
