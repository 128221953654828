import Clock from "./clock";
import Settings from "./settings";
import Menu from "./menu";
import RulesTab from "./rules";
import Bonus from "./bonus";
import useGlobalVariables from "hooks/useGlobalVariables";
import useAppSelector from "hooks/store/useAppSelector";

import { selectSessionBalance, selectIsRtl, selectIsDemoSession } from "store/slices/auth/selectors";

import { makeCurrencyText } from "utils/common";

/* Header Component */
const Header = () => {
	const balance = useAppSelector(selectSessionBalance);
	const isRtl = useAppSelector(selectIsRtl);
	const isDemo = useAppSelector(selectIsDemoSession);
	const { isMobile } = useGlobalVariables();

	return (
		<div className="vs--header vs-flex vs--flex-row vs--justify-between vs--mb-8">
			<Menu dir={isRtl ? "rtl" : "ltr"} />
			<div className="vs--header-right-part vs--flex vs--pr-16">
				{isDemo && !isMobile && (
					<div className="vs--header-balance vs--flex vs--align-center vs--pl-16 vs--pr-16">
						<span className="vs--header-balance-title vs--title-gray-80 vs--font-normal vs--font-regular">{makeCurrencyText(balance, { code: "FUN", decimalCount: 2 })}</span>
					</div>
				)}
				<Bonus />
				<RulesTab />
				<Clock />
				<Settings />
			</div>
		</div>
	);
};

export default Header;
