import { mergeClassNames } from "utils/common";

const DefaultMenuDropdown = ({ dir, menuDropdownClassName, children }) => {
	return (
		<div className={mergeClassNames("vs--navbar-menu-dropdown", menuDropdownClassName)} dir={dir}>
			<ul className="vs--navbar-menu-dropdown-wrapper">{children}</ul>
		</div>
	);
};

export default DefaultMenuDropdown;
