import PropTypes from "prop-types";
import TabStructureLine from "./tabStructureLine";
import { SCENES_LENGTH } from "constants/game.constants";
import { isSimpleCupGame } from "utils/common";
import eventType from "types/event.type";

const TabStructureRow = ({ round, event, teamData, index }) => {
	const isEventHasOTPS = isSimpleCupGame(round.gameType) ? teamData && teamData.complitedScenes && teamData.complitedScenes.length === SCENES_LENGTH[round.gameType].ALL : false;
	return (
		<div className={"vs--season-structure-list-item vs--ml-8 vs--mr-8" + (index !== round.events.length - 1 ? " vs--mb-8" : "")}>
			<TabStructureLine orderNumber={round.orderNumber} teamInfo={teamData.team1} opponentTeamInfo={teamData.team2} event={event} showConditionalJsx={isSimpleCupGame(round.gameType) ? true : false} isEventHasOTPS={isEventHasOTPS} rowIndex={index} />
			<TabStructureLine orderNumber={round.orderNumber} teamInfo={teamData.team2} opponentTeamInfo={teamData.team1} event={event} showConditionalJsx={false} isEventHasOTPS={isEventHasOTPS} rowIndex={index} />
		</div>
	);
};

TabStructureRow.propTypes = {
	round: eventType,
	event: eventType,
	teamData: PropTypes.object,
	index: PropTypes.number
};

export default TabStructureRow;
