import { useEffect, useRef, useState } from "react";

/**
 * Sets state safely, considering component destruction.
 * @callback SetSafeState
 * @param {any} updater Function to update the state.
 * @param {boolean} [ignoreDestroy=false] Whether to ignore component destruction or not.
 */

/**
 * Same as React.useState but `setState` accept `ignoreDestroy` param to not to setState after destroyed.
 * We do not make this auto is to avoid real memory leak.
 * Developer should confirm it's safe to ignore themselves.
 * @param {any} defaultValue
 * @returns {[any, SetSafeState]}
 */
const useSafeState = (defaultValue) => {
	const destroyRef = useRef(false);
	const [value, setValue] = useState(defaultValue);

	useEffect(() => {
		destroyRef.current = false;

		return () => {
			destroyRef.current = true;
		};
	}, []);

	/**
	 * @type {SetSafeState}
	 */
	const setSafeState = (updater, ignoreDestroy = false) => {
		if (ignoreDestroy && destroyRef.current) {
			return;
		}

		setValue(updater);
	};

	return [value, setSafeState];
};

export default useSafeState;
