import { createAction } from "@reduxjs/toolkit";

import { sliceName } from "./initialState";

export const updateKenoCurrentBetsAction = createAction(`${sliceName}/updateKenoCurrentBets`, (canceledBet) => {
	return {
		payload: { canceledBet }
	};
});

export const setSelectedBallsAction = createAction(`${sliceName}/setSelectedBalls`, (balls) => {
	return {
		payload: { balls }
	};
});

export const addSelectedBallsAction = createAction(`${sliceName}/addSelectedBalls`, (balls) => {
	return {
		payload: { balls }
	};
});

export const removeSelectedBallsAction = createAction(`${sliceName}/removeSelectedBalls`, (balls) => {
	return {
		payload: { balls }
	};
});

export const setActiveBallsAction = createAction(`${sliceName}/setActiveBalls`, (balls, add) => {
	return {
		payload: { balls, add }
	};
});

export const setLastAddedBallsAction = createAction(`${sliceName}/setLastAddedBalls`, (balls) => {
	return {
		payload: { balls }
	};
});

export const setHoveredBallsAction = createAction(`${sliceName}/setHoveredBalls`, (balls) => {
	return {
		payload: { balls }
	};
});