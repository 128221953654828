import PropTypes from "prop-types";
import { matchRoutes, useLocation } from "react-router-dom";

import Paths from "constants/path.constants";

const routes = Object.values(Paths).map((path) => ({ path }));

const useCurrentPath = () => {
	const location = useLocation();

	const [{ route }] = matchRoutes(routes, location);

	return route?.path ?? "";
};

useCurrentPath.PropTypes = {
	routes: PropTypes.arrayOf(PropTypes.shape({ path: PropTypes.string }))
};

export default useCurrentPath;
