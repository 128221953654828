import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TreeEventChampionsCup from "./treeEventChampionsCup";
import TreeStructureHeader from "./treeStructureHeader";
import eventType from "types/event.type";

const TreeRound16 = ({ title, data, roundName, getEventsInfoFromData, prepareForLoop }) => {
	const { t } = useTranslation();
	const defaultText = t("common.roundStartsSoon");
	const isHeaderVisible = data && data[0].headerVisibility && data[1].headerVisibility;
	return (
		<div className={"vs--cup-result-structure-round vs--flex vs--flex-col vs--justify-around" + (isHeaderVisible ? " vs--cup-result-structure-part" : "")}>
			<div className="vs--mt-16 vs--cup-result-structure-line">
				<div className="vs--cup-result-title">
					<span className="vs--font-big vs--font-bold">{title}</span>
				</div>
				{isHeaderVisible ? <TreeStructureHeader /> : null}
				<TreeEventChampionsCup eventInfo={getEventsInfoFromData(data, 0)} defaultText={defaultText} />
			</div>
			{prepareForLoop(data, roundName, (i) => {
				return (
					<div key={i} className="vs--mt-16 vs--cup-result-structure-line">
						<TreeEventChampionsCup eventInfo={getEventsInfoFromData(data, i)} defaultText={defaultText} />
					</div>
				);
			})}
		</div>
	);
};

TreeRound16.propTypes = {
	title: PropTypes.string,
	data: PropTypes.arrayOf(eventType),
	roundName: PropTypes.string,
	getEventsInfoFromData: PropTypes.func,
	prepareForLoop: PropTypes.func
};

export default TreeRound16;
