import useAppSelector from "hooks/store/useAppSelector";
import useGlobalVariables from "hooks/useGlobalVariables";
import useEvent from "hooks/useEvent";

import { selectLoginConfiguration } from "store/slices/auth/selectors";

import { sendPostMessageToParent } from "utils/iframe";
import { CLIENT_API } from "constants/integration.constants";
import { getUrlVars } from "utils/common";
import { LOGIN_CONFIGURATION_TYPE } from "constants/common.constants";

const useLoginScript = () => {
	const loginConfiguration = useAppSelector(selectLoginConfiguration);
	const { isMobile } = useGlobalVariables();

	const runLoginScript = useEvent(() => {
		const urlVars = getUrlVars();

		if (!isMobile) {
			if (loginConfiguration.type === LOGIN_CONFIGURATION_TYPE.URL && loginConfiguration.data) {
				window.open(loginConfiguration.data, "_top");
			} else if (loginConfiguration.type === LOGIN_CONFIGURATION_TYPE.POPUP && loginConfiguration.data) {
				try {
					window.eval(loginConfiguration.data);
				} catch (ex) {
					console.log(ex);
				}
			}
		} else if (urlVars["builder"]) {
			try {
				sendPostMessageToParent({ eventName: "Login", gameName: "football-single-match-virtualsport" });
			} catch (ex) {
				console.log(ex);
			}
		} else {
			if (loginConfiguration.mobileLoginURL) {
				try {
					sendPostMessageToParent({ type: CLIENT_API.LOGIN, value: loginConfiguration.mobileLoginURL, url: loginConfiguration.mobileLoginURL });
				} catch (ex) {
					console.log(ex);
				}
			}
		}
	});

	return runLoginScript;
};

export default useLoginScript;