import { Fragment, useContext, useEffect, useRef } from "react";
import { EventTargetContext, StoreContext } from "contexts/navbar";
import { setShowPrevArrow, setShowNextArrow } from "contextStores/navbar.duck";
import { mergeClassNames, isNullish } from "utils/common";
import { VSEvent } from "helpers/customEvents";
import { ARROWS_LISTENIN_EVENT_TYPES, NAVBAR_EVENT_TYPES, NAVBAR_INTERACTIVE_ELEMENT_KEYS } from "constants/navbar.constants";

const setShowArrow = {
	[NAVBAR_INTERACTIVE_ELEMENT_KEYS.PREV]: setShowPrevArrow,
	[NAVBAR_INTERACTIVE_ELEMENT_KEYS.NEXT]: setShowNextArrow
};

const stateName = {
	[NAVBAR_INTERACTIVE_ELEMENT_KEYS.PREV]: "showPrevArrow",
	[NAVBAR_INTERACTIVE_ELEMENT_KEYS.NEXT]: "showNextArrow"
};

const DefaultArrow = ({ type, dir, arrowClassName, navbarArrowInner: NavbarArrowInner }) => {
	const lowerCaseType = type.toLowerCase();
	const [contextState, dispatch] = useContext(StoreContext);
	const eventTarget = useContext(EventTargetContext);
	const { arrowButtons } = contextState;
	const ref = useRef();
	const isStateValueNullish = isNullish(arrowButtons);
	const isVisible = isStateValueNullish ? contextState[stateName[type]] : arrowButtons;

	useEffect(() => {
		const eventTypeToListen = ARROWS_LISTENIN_EVENT_TYPES[type];
		const action = setShowArrow[type];

		const scrollLIstener = (event) => {
			dispatch(action(!event.data));
		};
		eventTarget.addEventListener(eventTypeToListen, scrollLIstener);
		return () => {
			eventTarget.removeEventListener(eventTypeToListen, scrollLIstener);
		};
	}, [type, dir, eventTarget, dispatch]);

	if (!isVisible) {
		return <Fragment />;
	}

	return (
		<div ref={ref} className={mergeClassNames("vs--navbar-arrow", "vs--navbar-arrow-" + lowerCaseType, !isStateValueNullish && "vs--navbar-arrow-static", !contextState[stateName[type]] && "vs--navbar-arrow-disable", arrowClassName)} dir={dir}>
			<NavbarArrowInner
				dir={dir}
				type={type}
				className={mergeClassNames("vs--navbar-arrow-btn", "vs--navbar-arrow-btn-" + lowerCaseType)}
				onClick={() => {
					eventTarget.dispatchEvent(new VSEvent(NAVBAR_EVENT_TYPES.ARROW_NAVIGATION, type));
				}}
			/>
		</div>
	);
};

export default DefaultArrow;
