import sessionType from "types/session.type";
import localStorage from "utils/localStorage";
import { ODD_FORMATS } from "constants/market.constants";

/** Format get animation status
 * @function
 * @param {sessionType } session
 * @returns {boolean} - isAnimation on status
 */
export const getCurrentSettings = (session) => {
	const getInitialSettings = () => {
		const quickBetAmount = session?.currency?.singleMin ?? 1

		const initialSettings = {
			isAnimation: false,
			isSound: false,
			hideRebetPopup: false,
			isQuickBet: false,
			quickBetAmount,
			oddFormat: ODD_FORMATS.DECIMAL
		};

		return Object.assign(initialSettings, localStorage.get(`vs__settings__${session.projectId}`), { quickBetAmount });
	}

	const settings =
		session.isAuthorized && !session.isDemo
			? session.settings
			: getInitialSettings();

	return settings;
};
