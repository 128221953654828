import { useTranslation } from "react-i18next"
import ComboBoostCoin from "./comboBoostCoin"
import ComboBoostMinOddFactor from "./comboBoostMinOddFactor"
import Tooltip from "components/ui/tooltip"

const ComboBoostTitle = ({ children, minOddFactor }) => {
	const { t } = useTranslation()
	return (
		<div className="vs--bet-slip-combo-boost-title">
			<ComboBoostCoin />
			<span className="vs--font-normal vs--font-bold vs--title-brand vs--font-uppercase vs--mr-4">
				{t("common.comboboost")}
			</span>
			<Tooltip
				title={
					<div className="vs--bet-slip-combo-boost-title-info-tooltip-content">
						{t("common.comboboostInfo").replace("%X%", minOddFactor)}
					</div>
				}
				overlayClassName="vs--bet-slip-combo-boost-title-info-tooltip"
			>
				<i className="ic_info vs--cursor-pointer" />
			</Tooltip>
			{children}
		</div>
	)
}
export default ComboBoostTitle