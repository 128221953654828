import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";

import { setSeasonLiveModalMobileAction } from "store/slices/statistics/actions";
import { selectSeasonShowLiveModal } from "store/slices/statistics/selectors";
import { selectCurrentGameType } from "store/slices/game/selectors";

import Standings from "components/ui/season/standings";
import CupStructure from "components/ui/season/cupStructure";
import LeagueGamesLiveSeasonHoc from "hocs/seasonGamesInfo/leagueGamesLiveSeasonHoc";
import CupGamesLiveSeasonHoc from "hocs/seasonGamesInfo/cupGamesLiveSeasonHoc";
import useSwipeableModal from "hooks/useSwipeableModal";
import { isCupGame, isLeagueGame } from "utils/common";

const WrappedReactComponentOfLeagueGamesLiveSeasonHoc = LeagueGamesLiveSeasonHoc(Standings);
const WrappedReactComponentOfCupGamesLiveSeasonHoc = CupGamesLiveSeasonHoc(CupStructure);

/** Season Live Modal Component */
const SeasonLiveModal = () => {
	const showSeasonLiveModal = useAppSelector(selectSeasonShowLiveModal);
	const currentGameType = useAppSelector(selectCurrentGameType);
	const { t } = useTranslation();

	const dispatch = useAppDispatch();

	const data = useSwipeableModal({
		title: isLeagueGame(currentGameType) ? t("common.teamStandings") : isCupGame(currentGameType) ? t("common.cupStructure") : "",
		onClose: () => dispatch(setSeasonLiveModalMobileAction(null)),
		visibilityState: showSeasonLiveModal,
		children: isLeagueGame(currentGameType) ? <WrappedReactComponentOfLeagueGamesLiveSeasonHoc id={showSeasonLiveModal} /> : isCupGame(currentGameType) ? <WrappedReactComponentOfCupGamesLiveSeasonHoc id={showSeasonLiveModal} /> : null
	});

	return data?.component;
};

export default SeasonLiveModal;
