import { createSlice } from "@reduxjs/toolkit";

import { 
	addHistoryCases
} from "./extraReducers";
import { initialState, sliceName } from "./initialState";

const betHistorySlice = createSlice({
	name: sliceName,
	initialState: initialState,
	extraReducers: (builder) => {
		addHistoryCases(builder);
	}
});

export const betHistorySliceName = sliceName;
export const betHistorySliceReducer = betHistorySlice.reducer;
