import PropTypes from "prop-types";
import CommonView from "components/feedbackPopup/commonView";
import useSwipeableModal from "hooks/useSwipeableModal";

const MobileView = ({ title, closeFeedback, closeElement, isVisible, ...otherProps }) => {
	const { component } = useSwipeableModal({
		title,
		closeElement,
		onClose: closeFeedback,
		visibilityState: isVisible,
		children: <CommonView {...otherProps} />
	});

	return component;
};

MobileView.propTypes = {
	title: PropTypes.string,
	closeFeedback: PropTypes.func,
	closeElement: PropTypes.string,
	isVisible: PropTypes.bool
};

export default MobileView;
