import axiosInstance from "libs/axios";
import { getURL, getURLMergedWithQueryData } from "services/http/helpers";

class HttpService {
	httpProvider = null;
	// TODO: this we should use for Token Provider
	defaultRequest = Function.prototype;

	constructor(httpProvider, defaultRequestFn) {
		this.httpProvider = httpProvider;
		/**
		 * There should be a function that does not override the default httpProvider request behavior.
		 */
		this.defaultRequest = defaultRequestFn;
	}

	#request({ url, method, queryData, requestBody, signal, configs = {} }) {
		const request = this.httpProvider({
			url,
			method,
			params: queryData,
			data: requestBody,
			signal,
			...configs
		});

		return request;
	}

	get({ path, queryData, configs, signal }) {
		const url = getURL(path);
		const mergedURL = getURLMergedWithQueryData(url, queryData);

		return this.#request({
			method: "get",
			url: mergedURL,
			configs,
			signal
		});
	}

	post({ path, queryData, requestBody, configs, signal }) {
		const url = getURL(path);
		const mergedURL = getURLMergedWithQueryData(url, queryData);

		return this.#request({
			method: "post",
			url: mergedURL,
			requestBody,
			configs,
			signal
		});
	}

	delete({ path, queryData, requestBody, configs, signal }) {
		const url = getURL(path);
		const mergedURL = getURLMergedWithQueryData(url, queryData);

		return this.#request({
			method: "delete",
			url: mergedURL,
			requestBody,
			configs,
			signal
		});
	}
}

const httpService = new HttpService(axiosInstance, axiosInstance.request);

export default httpService;
