import { useCallback, useEffect } from "react";
import "prop-types";

import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";

import { getSeasonStructureThunk } from "store/slices/statistics/thunks";
import { setSeasonStatisticsIdAction, resetStatisticsStateAction } from "store/slices/statistics/actions";
import { selectCurrenSeasonEventId, selectCurrentGameType } from "store/slices/game/selectors";
import { selectSessionGames } from "store/slices/auth/selectors";
import { selectSeasonStructure } from "store/slices/statistics/selectors";
import { selectIsSeasonLoading, selectIsSeasonUpdateRequestSended } from "store/slices/statistics/selectors";

import useUpdateEffect from "hooks/useUpdateEffect";
import { GAME_STATUSES } from "constants/game.constants";

const CupGamesLiveSeasonHoc = (ReactComponent) => {
	const WrappedReactComponentOfCupGamesLiveSeasonHoc = (props) => {
		const seasonId = useAppSelector(selectCurrenSeasonEventId);
		const currentGameType = useAppSelector(selectCurrentGameType);
		const games = useAppSelector(selectSessionGames);
		const isSeasonLoading = useAppSelector(selectIsSeasonLoading);
		const isUpdateRequestSended = useAppSelector(selectIsSeasonUpdateRequestSended);

		const game = games.find((g) => g.type === currentGameType);
		const gameId = game?.id;
		const structure = useAppSelector(selectSeasonStructure);
		const loading = isSeasonLoading || isUpdateRequestSended;

		const dispatch = useAppDispatch();
		const setStatisticsId = useCallback((id) => dispatch(setSeasonStatisticsIdAction(id)), [dispatch]);
		const getStructure = useCallback((gameId) => dispatch(getSeasonStructureThunk(gameId)), [dispatch]);
		const resetStandings = useCallback(() => dispatch(resetStatisticsStateAction()), [dispatch]);
		const sendUpdateRequest = useCallback((gameId) => dispatch(getSeasonStructureThunk(gameId)), [dispatch]);

		useEffect(() => {
			setStatisticsId(seasonId);
		}, [seasonId, setStatisticsId]);

		useEffect(() => {
			getStructure(gameId);
			return () => {
				resetStandings();
			};
		}, [gameId, getStructure, resetStandings]);

		useUpdateEffect(() => {
			let thunkApi;

			if (gameId && structure.status === GAME_STATUSES.PREAMBLE_STARTED) {
				thunkApi = sendUpdateRequest(gameId);
			}

			return () => {
				// during current game type change aborting old data fetching
				thunkApi?.abort();
			}
		}, [gameId, structure.status, sendUpdateRequest]);

		return <ReactComponent structure={structure} loading={loading} isFromResults={false} currentGameType={currentGameType} {...props} />;
	};

	return WrappedReactComponentOfCupGamesLiveSeasonHoc;
};

export default CupGamesLiveSeasonHoc;
