import PropTypes from "prop-types";

const TabStructureFullTimeInfo = ({ teamInfo, opponentTeamInfo, isChampion = false, isLeg2 = false }) => {
	if (!isChampion) {
		if (!teamInfo.countryCode) {
			return null;
		}
	}
	return (
		<div className="vs--flex vs--align-center vs--justify-center vs--season-structure-list-item-result">
			<span className={"vs--title-white vs--font-medium vs--font-big" + (!isLeg2 && teamInfo.goalCount > opponentTeamInfo.goalCount ? " vs--text-green" : "")}>{typeof teamInfo.goalCount === "number" ? teamInfo.goalCount : " - "}</span>
		</div>
	);
};

TabStructureFullTimeInfo.propTypes = {
	teamInfo: PropTypes.object,
	opponentTeamInfo: PropTypes.object,
	isChampion: PropTypes.bool,
	isLeg2: PropTypes.bool
};

export default TabStructureFullTimeInfo;
