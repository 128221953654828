export const ERROR_STATUSES = {
	NOT_ALLOWED: "NotAllowed",
	INVALID_PARAMETERS: "InvalidParameters"
};

export const ERROR_MESSAGE = {
	BLOCKED: "Blocked",
	GUID_NOT_FOUND: "Guid Not Found!",
	GAME_NOT_FOUND: "Game Not Found!",
	INVALID_PLAYED_DATA: "InvalidPlayedData",
	NOT_SUPPORTED_CURRENCY: "NotSupportedCurrency",
	INVALID_PLAYER_CURRENCY: "InvalidPlayerCurrency",
	IMPERMISSIBLE_EVENT_STATE: "Impermissible Event state.",
	PLAYER_SETTINGS_INVALID_PARAMETER: "Player_Settings InvalidParameters"
};

export const ERROR_RESOURCE = {
	NONE: "None",
	EVENT: "Event",
	CUSTOM: "Custom",
	OPERATOR: "Operator",
	PLAYER_GEO_LOCATION: "Player_GeoLocation",
	SESSION: "Session",
	PLAYER: "Player",
	PLAYER_DEVICE: "Player_Device",
	PLAYER_BROWSER: "Player_Browser",
	PLAYER_BONUS: "Player_Bonus",
	PLAYER_SETTINGS: "Player_Settings",
	BET_SLIP_BET: "BetSlip_Bet"
};

