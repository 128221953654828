import { Fragment, useContext, useLayoutEffect, useMemo, useState } from "react";
import { NAVBAR_EVENT_TYPES } from "constants/navbar.constants";
import { EventTargetContext, StoreContext } from "contexts/navbar";
import { isNullish } from "utils/common";
import { setShowMenu } from "contextStores/navbar.duck";
import useEvent from "hooks/useEvent";

const DefaultMenu = ({ dataList, navbarMenu: NavbarMenu, menuItemInnerComponent, dir, itemsFilteringFn, navbarMenuDropdown, navbarMenuItem, menuClassName, menuDropdownClassName, menuItemClassName, elementId }) => {
	const [{ showMenu, menuButton }, dispatch] = useContext(StoreContext);
	const eventTarget = useContext(EventTargetContext);
	const [isListVisible, setIsListVisible] = useState(false);
	const [dataListWithState, setDataListWithState] = useState(() => {
		if (dataList.length === 0) {
			return [];
		}
		return dataList.map((data) => ({ state: 0, data }));
	});

	const isVisible = isNullish(menuButton) ? showMenu : menuButton;

	const memoedItemsFilteringFn = useEvent(itemsFilteringFn);
	const filteredDataList = useMemo(() => dataListWithState.filter(memoedItemsFilteringFn), [dataListWithState, memoedItemsFilteringFn]);

	useLayoutEffect(() => {
		if (!isNullish(menuButton)) {
			return;
		}

		const handler = (event) => {
			dispatch(setShowMenu(!event.data));
		};
		eventTarget.addEventListener(NAVBAR_EVENT_TYPES.THERE_IS_NOT_ELEMENTS_OR_ALL_VISIBLE, handler);
		return () => {
			eventTarget.removeEventListener(NAVBAR_EVENT_TYPES.THERE_IS_NOT_ELEMENTS_OR_ALL_VISIBLE, handler);
		};
	}, [dir, eventTarget, dispatch, menuButton]);

	useLayoutEffect(() => {
		const handler = (event) => {
			const { elementId: eventElementId, state } = event.data;
			setDataListWithState((prevDataListWithState) => {
				return prevDataListWithState.map((localData, index) => {
					const iterationElementId = typeof elementId === "function" ? elementId(localData.data, index, prevDataListWithState, true) : typeof elementId === "string" ? localData.data[elementId] : index;
					if (iterationElementId === eventElementId) {
						return { ...localData, state: state };
					}
					return localData;
				});
			});
		};
		eventTarget.addEventListener(NAVBAR_EVENT_TYPES.ELEMENTS_VISIBILITY, handler);
		return () => {
			eventTarget.removeEventListener(NAVBAR_EVENT_TYPES.ELEMENTS_VISIBILITY, handler);
		};
	}, [dir, eventTarget, setDataListWithState, elementId]);

	if (!isVisible) {
		return <Fragment />;
	}

	return (
		<NavbarMenu
			dir={dir}
			menuItemInnerComponent={menuItemInnerComponent}
			itemsFilteringFn={itemsFilteringFn}
			navbarMenuDropdown={navbarMenuDropdown}
			navbarMenuItem={navbarMenuItem}
			menuClassName={menuClassName}
			menuDropdownClassName={menuDropdownClassName}
			menuItemClassName={menuItemClassName}
			onClick={() => {
				setIsListVisible((prev) => !prev);
			}}
			onBlur={() => {
				setIsListVisible(false);
			}}
			localDataList={filteredDataList}
			visibilityState={isListVisible}
		/>
	);
};

export default DefaultMenu;
