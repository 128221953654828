import { useState, useMemo, useEffect, useRef, useLayoutEffect, forwardRef, Fragment, useImperativeHandle } from "react";
import ExpandableRow from "./expandableRow";
import PrefixColumn from "./prefixColumn";
import { mergeClassNames, isNullish } from "utils/common";
import { BLUNK_COLUM, DEFAULT_CLASSES, SEPARATE_CLASSES, LAYOUT_CLASSES, TEXT_ALIGN_CLASSES, VERTICAL_ALIGN } from "constants/table.constants";
import useEvent from "hooks/useEvent";
import { TableColumnNode } from "helpers/table.halper";
import ScrollBar from "components/ui/scrollBar";
import useGlobalVariables from "hooks/useGlobalVariables";
import useForceUpdate from "hooks/useForceUpdate";

const Table = ({
	columns: outsideColumn = [],
	data = [],
	layout = "auto",
	textAlign = "center",
	verticalAlign = "middle",
	separate = false,
	allowScrolling = false,
	allowPaddingWhenScrolling = true,
	drowHead = true,
	selectable = false,
	expandable = false,
	expandedKeys = null,
	setExpandedKeys = null,
	lineKey = null,
	//#region Class Names
	wrapperClassName = null,
	scrollbarContainerClassName = null,
	nonScrollableClassName = null,
	className = null,
	headClassName = null,
	bodyClassName = null,
	headRowClassName = null,
	bodyRowClassName = null,
	columnClassName = null,
	textClassName = null,
	//#endregion
	colgroupWrapperComponent: ColgroupWrapperComponent = Table.defaultColgroupWrapperComponent,
	colgroupColRenderFn = Table.defaultColgroupColRenderFn,
	scrollbarContainer: ScrollbarContainer = Table.scrollbarContainer,
	nonScrollableTableTitle: NonScrollableTableTitle = Table.nonScrollableTableTitle,
	headComponent: HeadComponent = Table.defaultHeadComponent,
	headCellRenderFn = Table.defaultHeadCellRenderFn,
	bodyComponent: BodyComponent = Table.defaultBodyComponent,
	bodyRowComponent: BodyRowComponent = expandable ? Table.defaultBodyExpandableRowComponent : Table.defaultBodySimpleRowComponent,
	bodyCellComponent = Table.defaultBodyCellComponent,
	prefixRenderFn = Table.defaultPrefixRenderFn,
	prefixRenderOptions = null,
	expandContentComponent = Fragment,
	simpleRowComponent: SimpleRowComponent = expandable ? Table.defaultBodySimpleRowComponent : Function.prototype,
	onRowSelect: outerOnRowSelect = null,
	alwaysExpanded = false,
	isRowExpandable = alwaysExpanded ? true : null,
	allowLastBlunkCellInRow = alwaysExpanded || expandable,
	tableRef: outsideTableRef,
	...otherProps
}) => {
	const { isMobile } = useGlobalVariables();
	const [expanded, setExpanded] = useState([]);
	const [selectedRowData, setSelectedRowData] = useState(null);
	const wrapperRef = useRef();
	const titleContainerRef = useRef();
	const scrollBarRef = useRef();
	const scrollBarContainerRef = useRef();
	const tableRef = useRef();
	const containerRef = useRef();
	const headerForceUpdateRef = useRef(null);

	useImperativeHandle(outsideTableRef, () => tableRef.current);

	const addToExpand = useEvent((rowKey) => {
		const isStateOutside = typeof setExpandedKeys === "function";
		const setStateFn = isStateOutside ? setExpandedKeys : setExpanded;
		const stateForUpdate = isStateOutside ? expandedKeys : expanded;
		setStateFn(stateForUpdate.concat(rowKey));
	});
	const removeFromExpand = useEvent((rowKey) => {
		const isStateOutside = typeof setExpandedKeys === "function";
		const setStateFn = isStateOutside ? setExpandedKeys : setExpanded;
		const stateForUpdate = isStateOutside ? expandedKeys : expanded;
		setStateFn(stateForUpdate.filter((k) => k !== rowKey));
	});
	const onRowSelect = useEvent((rowData) => {
		setSelectedRowData(rowData);
	});
	const rowSelectFn = useEvent((rowData) => outerOnRowSelect(rowData));

	useEffect(() => {
		if (Array.isArray(expandedKeys)) {
			setExpanded(expandedKeys);
		}
	}, [expandedKeys, setExpanded]);

	useEffect(() => {
		if (!selectable) {
			return;
		}
		if (isNullish(selectedRowData)) {
			return;
		}
		rowSelectFn(selectedRowData);
	}, [selectable, selectedRowData, rowSelectFn]);

	const columns = useMemo(() => {
		if (!expandable || alwaysExpanded) {
			return [...outsideColumn];
		}

		const retVal = prefixRenderFn === null ? [...outsideColumn] : [new TableColumnNode(null, null, prefixRenderFn, prefixRenderOptions), ...outsideColumn];

		if (allowLastBlunkCellInRow) {
			retVal.push(Table.BlunkCell);
		}

		return retVal;
	}, [outsideColumn, alwaysExpanded, expandable, prefixRenderFn, prefixRenderOptions, allowLastBlunkCellInRow]);

	const tableWrapperClassName = mergeClassNames(DEFAULT_CLASSES.TABLE_WRAPPER, allowScrolling && DEFAULT_CLASSES.TABLE_WRAPPER_SCROLLABLE, wrapperClassName);
	const tableScrollbarContainerClassName = mergeClassNames(DEFAULT_CLASSES.TABLE_SCROLLBAR_CONTAINER, allowScrolling && DEFAULT_CLASSES.TABLE_SCROLLBAR_CONTAINER_SCROLLABLE, allowScrolling && isMobile && DEFAULT_CLASSES.TABLE_SCROLLBAR_CONTAINER_SCROLLABLE_MOBILE, scrollbarContainerClassName);
	const standartTableClassName = mergeClassNames(
		DEFAULT_CLASSES.MAIN_CLASS,
		expandable && DEFAULT_CLASSES.EXPANDABLE_CLASS,
		alwaysExpanded && DEFAULT_CLASSES.ALWAYS_EXPANDED,
		SEPARATE_CLASSES[separate],
		TEXT_ALIGN_CLASSES[textAlign],
		LAYOUT_CLASSES[layout],
		VERTICAL_ALIGN[verticalAlign],
		className
	);

	const tableClassName = mergeClassNames(standartTableClassName, allowScrolling && DEFAULT_CLASSES.TABLE_SCROLLABLE);

	const tableNonScrollableClassName = mergeClassNames(standartTableClassName, nonScrollableClassName);

	const tableHeadClassName = mergeClassNames(DEFAULT_CLASSES.HEAD_CLASS, headClassName);
	const tableBodyClassName = mergeClassNames(DEFAULT_CLASSES.BODY_CLASS, bodyClassName);
	const tableHeadRowClassName = mergeClassNames(DEFAULT_CLASSES.HEAD_ROW_CLASS, headRowClassName);
	const tableBodyRowClassName = mergeClassNames(DEFAULT_CLASSES.BODY_ROW_CLASS, bodyRowClassName);
	const tableColumnClassName = mergeClassNames(DEFAULT_CLASSES.COLUMN_CLASS, columnClassName);
	const tableTextClassName = mergeClassNames(DEFAULT_CLASSES.TEXT_CLASS, textClassName);

	useEffect(() => {
		if (!allowScrolling || !drowHead || !scrollBarContainerRef.current || !wrapperRef.current || !titleContainerRef.current || !scrollBarRef.current) {
			return;
		}
		// TODO: Maybe need to use by some condition
		/*
			scrollBarContainerRef.current.style.height = (
				wrapperRef.current.offsetHeight - titleContainerRef.current.offsetHeight
			) + "px"
		*/
		scrollBarRef.current.update();
	}, [allowScrolling, drowHead]);

	useEffect(() => {
		if (drowHead && allowScrolling) {
			const resizeObserver = new ResizeObserver(() => {
				headerForceUpdateRef?.current?.();
			});

			resizeObserver.observe(wrapperRef.current);

			return () => resizeObserver.disconnect();
		}
	}, [allowScrolling, drowHead]);

	return (
		<div ref={wrapperRef} className={tableWrapperClassName}>
			{drowHead && allowScrolling ? (
				<NonScrollableTableTitle
					forceUpdateRef={headerForceUpdateRef}
					ref={titleContainerRef}
					tableRef={tableRef}
					wrapperRef={wrapperRef}
					tableNonScrollableClassName={tableNonScrollableClassName}
					theadProps={{ drowHead, columns, headCellRenderFn, tableHeadClassName, tableHeadRowClassName, tableColumnClassName, tableTextClassName }}
					colgroupWrapperComponent={ColgroupWrapperComponent}
					headComponent={HeadComponent}
					columns={columns}
					colgroupColRenderFn={colgroupColRenderFn}
					drowHead={drowHead}
					headCellRenderFn={headCellRenderFn}
					tableHeadClassName={tableHeadClassName}
					tableHeadRowClassName={tableHeadRowClassName}
					tableColumnClassName={tableColumnClassName}
					tableTextClassName={tableTextClassName}
					isMobile={isMobile}
					allowPaddingWhenScrolling={allowPaddingWhenScrolling}
				/>
			) : null}
			<ScrollbarContainer key={ScrollbarContainer.name} ref={scrollBarContainerRef} className={tableScrollbarContainerClassName} scrollBarRef={scrollBarRef} containerRef={containerRef} allowScrolling={allowScrolling} allowPaddingWhenScrolling={allowPaddingWhenScrolling} isMobile={isMobile}>
				<table ref={tableRef} className={tableClassName} {...otherProps}>
					<ColgroupWrapperComponent columns={columns} colgroupColRenderFn={colgroupColRenderFn} />
					<HeadComponent
						drowHead={allowScrolling ? false : drowHead}
						columns={columns}
						headCellRenderFn={headCellRenderFn}
						tableHeadClassName={tableHeadClassName}
						tableHeadRowClassName={tableHeadRowClassName}
						tableColumnClassName={tableColumnClassName}
						tableTextClassName={tableTextClassName}
					/>
					<BodyComponent
						data={data}
						columns={columns}
						expandable={expandable}
						expanded={expanded}
						alwaysExpanded={alwaysExpanded}
						isRowExpandable={isRowExpandable}
						addToExpand={addToExpand}
						removeFromExpand={removeFromExpand}
						selectable={selectable}
						selectedRowData={selectedRowData}
						onRowSelect={onRowSelect}
						bodyRowComponent={BodyRowComponent}
						bodyCellComponent={bodyCellComponent}
						simpleRowComponent={SimpleRowComponent}
						expandContentComponent={expandContentComponent}
						tableBodyClassName={tableBodyClassName}
						tableBodyRowClassName={tableBodyRowClassName}
						lineKey={lineKey}
						tableColumnClassName={tableColumnClassName}
						tableTextClassName={tableTextClassName}
						headerForceUpdateRef={headerForceUpdateRef}
					/>
				</table>
			</ScrollbarContainer>
		</div>
	);
};

Table.BlunkCell = BLUNK_COLUM;

Table.defaultColgroupWrapperComponent = ({ columns, colgroupColRenderFn }) => {
	if (columns.length === 0) {
		return null;
	}
	return <colgroup>{columns.map((callData, key) => colgroupColRenderFn({ callData, key }))}</colgroup>;
};
Table.defaultColgroupWrapperComponent.displayName = "TableDefaultColgroupWrapperComponent";

Table.defaultColgroupColRenderFn = ({ callData, key }) => {
	if (callData === Table.BlunkCell) {
		return <col key={key} />;
	}
	if (isNullish(callData.additionalInfo) || typeof callData.additionalInfo !== "object") {
		return <col key={key} />;
	}
	const props = Object.assign({}, callData.additionalInfo.colProps);
	const style = Object.assign({}, props.style, { width: callData.additionalInfo.width });
	return <col key={key} {...props} style={style} />;
};

Table.defaultHeadComponent = ({ drowHead, columns, headCellRenderFn, tableHeadClassName, tableHeadRowClassName, tableColumnClassName, tableTextClassName }) => {
	if (!drowHead || columns.length === 0) {
		return null;
	}
	return (
		<thead className={tableHeadClassName}>
			<tr className={tableHeadRowClassName}>{columns.map((callData, key) => headCellRenderFn({ callData, key, tableColumnClassName, tableTextClassName }))}</tr>
		</thead>
	);
};
Table.defaultHeadComponent.displayName = "TableDefaultHeadComponent";

Table.defaultHeadCellRenderFn = ({ callData, key, tableColumnClassName, tableTextClassName, ...props }) => {
	const mergedClasses = mergeClassNames(tableColumnClassName, callData.additionalInfo && "alignment" in callData.additionalInfo && TEXT_ALIGN_CLASSES[callData.additionalInfo["alignment"]], "className" in props && props.className);
	const style = callData?.additionalInfo?.colProps?.style ?? null;
	return (
		<th style={style} {...props} className={mergedClasses} key={isNullish(callData.name) ? `tableIndex${key}` : callData.name}>
			{callData === Table.BlunkCell || isNullish(callData.title) ? null : <span className={tableTextClassName}>{callData.title}</span>}
		</th>
	);
};
Table.defaultBodyComponent = ({
	data,
	columns,
	expanded,
	addToExpand,
	expandable,
	alwaysExpanded,
	isRowExpandable,
	removeFromExpand,
	selectable,
	selectedRowData,
	onRowSelect,
	bodyRowComponent: BodyRowComponent,
	bodyCellComponent,
	simpleRowComponent: SimpleRowComponent,
	expandContentComponent,
	tableBodyClassName,
	tableBodyRowClassName,
	lineKey,
	tableColumnClassName,
	tableTextClassName,
	headerForceUpdateRef
}) => {
	// Need to work after every render of this component
	useLayoutEffect(() => {
		if (headerForceUpdateRef && headerForceUpdateRef.current && typeof headerForceUpdateRef.current === "function") {
			headerForceUpdateRef.current();
		}
	}, undefined);

	if (data.length === 0) {
		return null;
	}

	return (
		<tbody className={tableBodyClassName}>
			{data.map((record, rowIndex) => {
				const key = Table.generateLineKey(lineKey, record, rowIndex);
				return (
					<BodyRowComponent
						key={key}
						record={record}
						rowIndex={rowIndex}
						columns={columns}
						expanded={expanded}
						expandable={expandable}
						alwaysExpanded={alwaysExpanded}
						isRowExpandable={isRowExpandable}
						addToExpand={addToExpand}
						removeFromExpand={removeFromExpand}
						selectable={selectable}
						selectedRowData={selectedRowData}
						onRowSelect={onRowSelect}
						lineKey={lineKey}
						bodyCellComponent={bodyCellComponent}
						simpleRowComponent={SimpleRowComponent}
						expandContentComponent={expandContentComponent}
						data={data}
						tableBodyRowClassName={tableBodyRowClassName}
						tableColumnClassName={tableColumnClassName}
						tableTextClassName={tableTextClassName}
					/>
				);
			})}
		</tbody>
	);
};
Table.defaultBodyComponent.displayName = "TableDefaultBodyComponent";

Table.defaultBodySimpleRowComponent = (props) => {
	const { record, rowIndex, columns, lineKey, bodyCellComponent: BodyCellComponent, data, expandable, isRowExpandable, expanded, alwaysExpanded, tableBodyRowClassName, tableColumnClassName, tableTextClassName, onExpand, selectable, selectedRowData, onRowSelect, onClick, ...otherProps } = props;
	const key = Table.generateLineKey(lineKey, record, rowIndex);
	delete otherProps["simpleRowComponent"];
	delete otherProps["expandContentComponent"];
	delete otherProps["addToExpand"];
	delete otherProps["removeFromExpand"];
	delete otherProps["alwaysExpanded"];
	const _onClick = (event) => {
		if (expandable && (isRowExpandable === null || (typeof isRowExpandable === "function" && isRowExpandable({ record, rowIndex, columns, lineKey, data, expanded })))) {
			onExpand(record, rowIndex, key, event);
		}
		if (selectable) {
			onRowSelect({ record, rowIndex, key, event });
		}
		if (typeof onClick === "function") {
			onClick(record, rowIndex, event);
		}
	};
	return (
		<tr className={mergeClassNames(tableBodyRowClassName, selectable && !isNullish(selectedRowData) && selectedRowData.key === key && DEFAULT_CLASSES.BODY_ROW_SELECTED_CLASS, (alwaysExpanded || expanded.includes(key)) && DEFAULT_CLASSES.ROW_EXPANDED)} onClick={_onClick} {...otherProps}>
			{columns.map((callData, cellIndex) => (
				<BodyCellComponent
					key={isNullish(callData.name) ? `tableIndex${cellIndex}` : callData.name}
					callData={callData}
					record={record}
					columns={columns}
					data={data}
					expanded={expanded}
					lineKey={lineKey}
					cellIndex={cellIndex}
					rowIndex={rowIndex}
					tableColumnClassName={tableColumnClassName}
					tableTextClassName={tableTextClassName}
				/>
			))}
		</tr>
	);
};
Table.defaultBodySimpleRowComponent.displayName = "TableDefaultBodySimpleRowComponent";

Table.defaultBodyExpandableRowComponent = (props) => {
	const { record, rowIndex, lineKey, alwaysExpanded, expanded, columns, addToExpand, removeFromExpand } = props;
	const key = Table.generateLineKey(lineKey, record, rowIndex);
	const isExpanded = alwaysExpanded || expanded.includes(key);

	return <ExpandableRow {...props} colSpan={columns.length} alwaysExpanded={alwaysExpanded} isExpanded={isExpanded} onExpand={alwaysExpanded ? Function.prototype : () => (isExpanded ? removeFromExpand(key) : addToExpand(key))} />;
};
Table.defaultBodyExpandableRowComponent.displayName = "TableDefaultBodyExpandableRowComponent";

Table.defaultBodyCellComponent = ({ callData, record, columns, expanded, data, lineKey, cellIndex, rowIndex, tableColumnClassName, tableTextClassName, ...props }) => {
	const isDefaultRender = typeof callData.renderFn !== "function";
	const mergedClasses = mergeClassNames(tableColumnClassName, callData.additionalInfo && "alignment" in callData.additionalInfo && TEXT_ALIGN_CLASSES[callData.additionalInfo["alignment"]]);
	const style = callData?.additionalInfo?.colProps?.style ?? null;
	return (
		<td style={style} {...props} className={mergedClasses} key={isNullish(callData.name) ? `tableIndex${cellIndex}` : callData.name}>
			{isDefaultRender ? <span className={tableTextClassName}>{record[callData.name]}</span> : callData.renderFn(isNullish(callData.name) ? callData.name : record[callData.name], record, callData, { lineKey, cellIndex, rowIndex, columns, data, expanded, tableTextClassName })}
		</td>
	);
};
Table.defaultBodyCellComponent.displayName = "TableDefaultBodyCellComponent";

Table.defaultPrefixRenderFn = (...args) => {
	const record = args[1];
	const { rowIndex, lineKey, expanded } = args[args.length - 1];
	const key = Table.generateLineKey(lineKey, record, rowIndex);
	const isExpanded = expanded.includes(key);
	return <PrefixColumn isExpanded={isExpanded} />;
};

Table.generateLineKey = (lineKey, record, lineIndex) => {
	if (typeof lineKey === "function") {
		return lineKey(record, lineIndex);
	}
	if (typeof lineKey === "string") {
		return record[lineKey];
	}
	return lineIndex;
};

Table.nonScrollableTableTitle = forwardRef(
	(
		{
			tableNonScrollableClassName,
			wrapperRef,
			tableRef,
			colgroupWrapperComponent: ColgroupWrapperComponent,
			headComponent: HeadComponent,
			columns,
			colgroupColRenderFn,
			drowHead,
			headCellRenderFn,
			tableHeadClassName,
			tableHeadRowClassName,
			tableColumnClassName,
			tableTextClassName,
			isMobile,
			allowPaddingWhenScrolling,
			forceUpdateRef
		},
		ref
	) => {
		const [forceUpdateFunc, forceUpdateValue] = useForceUpdate();
		forceUpdateRef.current = forceUpdateFunc;

		const colGroupInnerProps = useMemo(() => {
			if (!wrapperRef || !wrapperRef.current) {
				return { colgroupColRenderFn, columns };
			}
			const colgroup = tableRef.current.querySelector("colgroup");
			if (!colgroup) {
				return { colgroupColRenderFn, columns };
			}
			const newColumns = Array.prototype.map.call(colgroup.children, (col, i) => {
				if (!columns[i]) {
					return columns[i];
				}
				return new TableColumnNode(columns[i].name, columns[i].title, columns[i].renderFn, Object.assign({}, columns[i].additionalInfo, { width: col.offsetWidth }));
			});
			return { colgroupColRenderFn, columns: newColumns };
		}, [forceUpdateValue]);

		return (
			<div ref={ref} className={mergeClassNames(!isMobile && allowPaddingWhenScrolling && "vs--pr-16")}>
				<table className={tableNonScrollableClassName}>
					{colGroupInnerProps && <ColgroupWrapperComponent {...colGroupInnerProps} />}
					<HeadComponent drowHead={drowHead} columns={columns} headCellRenderFn={headCellRenderFn} tableHeadClassName={tableHeadClassName} tableHeadRowClassName={tableHeadRowClassName} tableColumnClassName={tableColumnClassName} tableTextClassName={tableTextClassName} />
				</table>
			</div>
		);
	}
);

Table.nonScrollableTableTitle.displayName = "TableNonScrollableTableTitle";

Table.scrollbarContainer = forwardRef(({ isMobile, className, scrollBarRef, containerRef, allowScrolling, allowPaddingWhenScrolling, children }, ref) => {
	return allowScrolling ? (
		<div ref={ref} className={className}>
			<ScrollBar vertical={true} ref={scrollBarRef} containerRef={containerRef} className={mergeClassNames(!isMobile && allowPaddingWhenScrolling && "vs--pr-16")}>
				{children}
			</ScrollBar>
		</div>
	) : (
		<div ref={ref} className={className}>
			<div ref={scrollBarRef}>{children}</div>
		</div>
	);
});

Table.scrollbarContainer.displayName = "TableScrollbarContainer";

export default Table;
