import { useEffect, useId, useRef } from "react";
import PropTypes from "prop-types";
import PortalWrapper from "components/portalWrapper";
import OutsideAlerter from "components/ui/outsideAlerter";

import modalServiceInstance from "./service";

import useGlobalVariables from "hooks/useGlobalVariables";

const Modal = ({
	zIndex = 1500,
	coordinates: outerCoordinates,
	classNames: { outside = "", content = "", header = "", body = "", footer = "" },
	isOpen = false,
	headerContent = null,
	footerContent = null,
	closeIcon = <i className="ic_close" />,
	maskClosable = true,
	escapeClosable = true,
	onCancel = Function.prototype,
	onOutsideClick = onCancel,
	onEscapePress = onCancel,
	outsideRef,
	contentRef,
	children
}) => {
	const { isMobile } = useGlobalVariables();

	const modalId = useId();

	const modalServiceInstanceRef = useRef(modalServiceInstance);

	useEffect(() => {
		if (!isOpen) {
			/** Removing from opened Modals when isOpen false */
			modalServiceInstanceRef.current?.remove(modalId);
		} else {
			modalServiceInstanceRef.current?.add({
				id: modalId,
				zIndex: zIndex,
				onEscapePress: escapeClosable ? onEscapePress : null
			});
		}
	}, [isOpen]);

	/** Removing from opened Modals on ounmount */
	useEffect(() => {
		return () => {
			modalServiceInstanceRef.current?.remove(modalId);
		}
	}, [])

	if (!isOpen) {
		return null;
	}

	const coordinates = outerCoordinates !== undefined ? outerCoordinates : { clientX: "50vw", clientY: "50vh", topOffset: isMobile ? "60px" : "150px" };

	return (
		<PortalWrapper wrapperId="modal">
			<OutsideAlerter
				className={`vs--ui-modal-overlay ${outside}`}
				preventId={modalId}
				callback={maskClosable ? onOutsideClick : Function.prototype}
				outsideRef={outsideRef}
			>
				<div
					className={`vs--ui-modal-content ${content}`}
					style={{
						"--x": coordinates.clientX,
						"--y": coordinates.clientY,
						"--top-offset": coordinates.topOffset
					}}
					ref={contentRef}
				>
					{closeIcon !== null ? (
						<div className="vs--ui-modal-close-button" onClick={onCancel}>
							{closeIcon}
						</div>
					) : null}
					{headerContent !== null ? <div className={`vs--ui-modal-header ${header}`}>{headerContent !== null && <div className="vs--ui-modal-header-title">{headerContent}</div>}</div> : null}
					<div className={`vs--ui-modal-body ${body}`}>{children}</div>
					{footerContent !== null ? <div className={`vs--ui-modal-footer ${footer}`}>{footerContent}</div> : null}
				</div>
			</OutsideAlerter>
		</PortalWrapper>
	);
};

Modal.propTypes = {
	/** React property, css variables` viewport x, y coordinates and position top */
	coordinates: PropTypes.shape({
		clientX: PropTypes.string,
		clientY: PropTypes.string,
		topOffset: PropTypes.string
	}),
	/** React property, additional classNames for modal */
	classNames: PropTypes.shape({
		outside: PropTypes.string,
		content: PropTypes.string,
		header: PropTypes.string,
		body: PropTypes.string,
		footer: PropTypes.string
	}),
	/** React property, represents whether modal is open */
	isOpen: PropTypes.bool,
	/** React property, represents whether modal is outside click closable or not */
	maskClosable: PropTypes.bool,
	/** React property, some jsx in header part */
	headerContent: PropTypes.node,
	/** React property, some jsx for footer */
	footerContent: PropTypes.node,
	/** React property, custom close icon */
	closeIcon: PropTypes.element,
	/** React property, callback function that will be called on outside click */
	onOutsideClick: PropTypes.func,
	/** React property, callback function that will be called on Esc keyboard press */
	onEscapePress: PropTypes.bool,
	/** React property, function that will be called on modal close */
	onCancel: PropTypes.func,
	/** React property, modal content */
	children: PropTypes.node,
	/** React ref object */
	outsideRef: PropTypes.object,
	/** React ref object */
	contentRef: PropTypes.object
};

export default Modal;
