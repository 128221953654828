import BaseMarkets from "./baseMarkets";
import FootballMarkets from "./footballMarkets";
import RacingMarkets from "./racingMarkets";
import PenaltyShotoutsMarkets from "./penaltyShootoutMarkets";

import { GAME_TYPE } from "constants/game.constants";

import { isRacingGame, isSeasonGame } from "utils/common";

/** Method to run class method
 * @function
 * @param {string} fn - method name
 * @param {array} args - array of arguments to pass to the method
 * @param {gameType} number - the game type
 * @returns {any}
 */
const runMarketUtilsFunction = (fn, args, gameType) => {
	const getInstance = () => {
		switch (true) {
			case gameType === GAME_TYPE.FOOTBALL_SINGLE_MATCH || isSeasonGame(gameType):
				return new FootballMarkets();
			case isRacingGame(gameType):
				return new RacingMarkets();
			case gameType === GAME_TYPE.PENALTY_SHOOTOUT:
				return new PenaltyShotoutsMarkets();
			default:
				return new BaseMarkets();
		}
	};

	const instance = getInstance();

	return instance[fn].apply(instance, args);
};

export default runMarketUtilsFunction;
