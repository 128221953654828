import { 
	getCurrentBetsThunk,
	getLastBetsThunk
} from "./thunks";

import {
	updateKenoCurrentBetsAction,
	setSelectedBallsAction,
	addSelectedBallsAction,
	removeSelectedBallsAction,
	setActiveBallsAction,
	setLastAddedBallsAction
} from "./actions";

import { KENO_WINNER_BALLS_COUNT } from "constants/game.constants";

export const addCurrentBetsCasees = (builder) => {
	builder.addCase(getCurrentBetsThunk.pending, (state) => {
		state.isCurrentBetsLoading = true;
	});

	builder.addCase(getCurrentBetsThunk.fulfilled, (state, { payload: bets }) => {
		state.isCurrentBetsLoading = false;
		state.currentBets = bets;
	});

	builder.addCase(getCurrentBetsThunk.rejected, (state) => {
		state.isCurrentBetsLoading = false;
	});

	builder.addCase(updateKenoCurrentBetsAction, (state, { payload }) => {
		state.currentBets = state.currentBets.filter((currentBet) => payload.canceledBet.bets.findIndex((bet) => bet.id === currentBet.id) === -1);
	});
}

export const addLastBetsCases = (builder) => {
	builder.addCase(getLastBetsThunk.pending, (state) => {
		state.isLastBetsLoading = true;
	});

	builder.addCase(getLastBetsThunk.fulfilled, (state, { payload: bets }) => {
		state.isLastBetsLoading = false;
		state.lastBets = bets;
	});

	builder.addCase(getLastBetsThunk.rejected, (state) => {
		state.isLastBetsLoading = false;
	});
}

export const addBallsCases = (builder) => {
	builder.addCase(setSelectedBallsAction, (state, { payload }) => {
		state.selectedBalls = payload.balls;
	});

	builder.addCase(addSelectedBallsAction, (state, { payload }) => {
		state.selectedBalls = state.selectedBalls.concat(Array.isArray(payload.balls) ? payload.balls : [payload.balls])
	});

	builder.addCase(removeSelectedBallsAction, (state, { payload }) => {
		state.selectedBalls = state.selectedBalls.filter((ball) => (Array.isArray(payload.balls) ? !payload.balls.includes(ball) : payload.balls !== ball))
	});

	builder.addCase(setActiveBallsAction, (state, { payload }) => {
		state.activeBalls = !payload.add ? payload.balls : state.activeBalls.length < KENO_WINNER_BALLS_COUNT ? state.activeBalls.concat([{ number: payload.balls[state.activeBalls.length], animating: true }]) : state.activeBalls
	});

	builder.addCase(setLastAddedBallsAction, (state, { payload }) => {
		state.lastAddedBalls = payload.balls;
	});
}