import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Modal from "components/ui/modal";
import useGlobalVariables from "hooks/useGlobalVariables";

/** Confirmation modal component */
const AreYouSure = ({ onOk, onCancel, text, enableDontShowAgain }) => {
	const { t } = useTranslation();
	const { isMobile } = useGlobalVariables();
	const [checked, setChecked] = useState(false);

	return (
		<Modal coordinates={{ clientX: "50vw", clientY: "50vh", topOffset: isMobile ? "60px" : "150px" }} isOpen={true} onCancel={onCancel} classNames={{ content: "vs--modal-wrapper vs--areyousure" }} closeIcon={null}>
			<div className="vs--areyousure-inner vs--flex vs--flex-col vs--flex vs--justify-center vs--align-center vs--flex-col vs--text-center vs--pb-10 vs--pt-8">
				<div className="vs--areyousure-content vs--flex vs--align-center vs--justify-center vs--flex-equal vs--flex-col">
					<i className="ic_info"></i>
					<span className="vs--title-white vs--font-normal vs--font-condensed vs--mt-16">{text ? text : t("common.areYouSure")}</span>
				</div>
				<div className="vs--areyousure-buttons vs--flex vs--align-center vs--justify-center vs--mt-24">
					<button type="button" className="vs--button vs--pt-4 vs--pb-4 vs--pl-8 vs--pr-8 vs--font-medium vs--font-small vs--mr-8" onClick={onCancel}>
						{t("common.no")}
					</button>
					<button type="button" className="vs--button vs--pt-4 vs--pb-4 vs--pl-8 vs--pr-8 vs--font-medium vs--font-small vs--ml-8" onClick={(e) => onOk(checked, e)}>
						{t("common.yes")}
					</button>
				</div>
				{enableDontShowAgain && (
					<div className="vs--mt-10 vs--areyousure-inner-checkbox vs--flex vs--justify-center vs--align-center">
						<div className="vs--flex vs--justify-center vs--align-center">
							<input type="checkbox" value={checked} onChange={(e) => setChecked(e.target.value)} />
							<i className="ic_check"></i>
						</div>
						<span className="vs--ml-8 vs--font-medium vs--font-small">{t("common.dontShowItAgain")}</span>
					</div>
				)}
			</div>
		</Modal>
	);
};

/** AreYouSure propTypes
 * PropTypes
 */
AreYouSure.propTypes = {
	/** Function which will fire on modal "ok" button click */
	onOk: PropTypes.func,
	/** Function which will fire on modal cancel */
	onCancel: PropTypes.func,
	/** Are you sure text */
	text: PropTypes.string,
	/** Show dont' show again checkbox */
	enableDontShowAgain: PropTypes.bool
};

export default AreYouSure;
