import { combineReducers } from "@reduxjs/toolkit";

import { authSliceName, authSliceReducer } from "./slices/auth";
import { betHistorySliceName, betHistorySliceReducer } from "./slices/betHistory";
import { betslipSliceName, betslipSliceReducer } from "./slices/betslip";
import { bonusSliceName, bonusSliceReducer } from "./slices/bonus";
import { commonSliceName, commonSliceReducer } from "./slices/common";
import { eventSliceName, eventSliceReducer } from "./slices/event";
import { gameSliceName, gameSliceReducer } from "./slices/game";
import { kenoSliceName, kenoSliceReducer } from "./slices/keno";
import { seasonSliceName, seasonSliceReducer } from "./slices/season";
import { statisticsSliceName, statisticsSliceReducer } from "./slices/statistics";
import { luckySixReducer, luckySixSliceName } from "store/slices/lucky-six/index.js";

const rootReducer = combineReducers({
	[authSliceName]: authSliceReducer,
	[betHistorySliceName]: betHistorySliceReducer,
	[betslipSliceName]: betslipSliceReducer,
	[bonusSliceName]: bonusSliceReducer,
	[commonSliceName]: commonSliceReducer,
	[eventSliceName]: eventSliceReducer,
	[gameSliceName]: gameSliceReducer,
	[kenoSliceName]: kenoSliceReducer,
	[seasonSliceName]: seasonSliceReducer,
	[statisticsSliceName]: statisticsSliceReducer,
	[luckySixSliceName]: luckySixReducer
});

export default rootReducer;
