import { useContext, useEffect, useRef, useState } from "react";
import { EventTargetContext } from "contexts/navbar";
import { VSEvent } from "helpers/customEvents";
import { NAVBAR_EVENT_TYPES, NAVBAR_SUBEVENT_TYPES } from "constants/navbar.constants";
import { getElementVisibilityClassName, getVisibilityPart } from "utils/navbar";
import { mergeClassNames } from "utils/common";

const DefaultElement = ({ elementId = null, dir, children, flexElements, elementClassName, activeElementClassName }) => {
	const ref = useRef();
	const eventTarget = useContext(EventTargetContext);
	const [visibilityPart, setVisibilityPart] = useState(0);
	const [isSelect, setIsSelected] = useState(false);

	const onClickCapture = () => {
		eventTarget.dispatchEvent(new VSEvent(NAVBAR_EVENT_TYPES.ACTIVE_ELEMENT, elementId));
		if (visibilityPart === 1) {
			return;
		}
		eventTarget.dispatchEvent(
			new VSEvent(NAVBAR_EVENT_TYPES.INTO_VIEW, {
				element: ref.current,
				state: visibilityPart
			})
		);
	};

	useEffect(() => {
		const classNameChangeFunction = (event) => {
			if (!ref.current) {
				return;
			}
			const elementBoundingClientRect = ref.current.getBoundingClientRect();
			const state = getVisibilityPart(Object.assign(new Object(), event.data, { dir, elementBoundingClientRect }));
			setVisibilityPart(state);
		};
		const moveToView = () => {
			if (!ref.current) {
				return;
			}
			const parent = ref.current.parentElement;
			parent.scrollLeft = ref.current.offsetLeft + ref.current.offsetWidth / 2 - parent.offsetWidth / 2;
		};
		const updateElement = (select) => {
			setIsSelected(select);
		};

		const eventType = NAVBAR_EVENT_TYPES.MESSAGE_TO_ELEMENT + elementId;
		const subEvents = NAVBAR_SUBEVENT_TYPES[NAVBAR_EVENT_TYPES.MESSAGE_TO_ELEMENT];

		const onMessageToElement = ({ data: actions = [] }) => {
			actions.forEach(({ type, payload }) => {
				switch (type) {
					case subEvents.MOVE_TO_VIEW:
						return moveToView();
					case subEvents.UPDATE_ELEMENT:
						return updateElement(payload.select);
				}
			});
		};

		eventTarget.addEventListener(NAVBAR_EVENT_TYPES.ELEMENTS_ON_SCROLL, classNameChangeFunction);
		eventTarget.addEventListener(eventType, onMessageToElement);
		return () => {
			eventTarget.removeEventListener(NAVBAR_EVENT_TYPES.ELEMENTS_ON_SCROLL, classNameChangeFunction);
			eventTarget.removeEventListener(eventType, onMessageToElement);
		};
	}, [dir, eventTarget, elementId, setVisibilityPart, setIsSelected]);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			eventTarget.dispatchEvent(new VSEvent(NAVBAR_EVENT_TYPES.ELEMENTS_VISIBILITY, { elementId, state: visibilityPart }));
		}, 10);
		return () => clearTimeout(timeoutId);
	}, [elementId, visibilityPart, eventTarget]);

	return (
		<li
			ref={ref}
			className={mergeClassNames("vs--navbar-element", flexElements && "vs--navbar-element-flex", isSelect && "vs--navbar-element-selected", getElementVisibilityClassName(visibilityPart), elementClassName, isSelect && activeElementClassName)}
			data-element-id={elementId}
			onClickCapture={onClickCapture}
		>
			{children}
		</li>
	);
};

export default DefaultElement;
