import { createAction } from "@reduxjs/toolkit";

import { sliceName } from "./initialState";

export const updateHistoryBetSlipBetAction = createAction(`${sliceName}/updateHistoryBetSlipBet`, (bet) => {
	return {
		payload: { bet }
	};
});

export const updateHistoryBetSlipAction = createAction(`${sliceName}/updateHistoryBetSlip`, (bet) => {
	return {
		payload: { bet }
	};
});

export const setHistoryOpenedBetSlipIdAction = createAction(`${sliceName}/setHistoryOpenedBetSlipId`, (id) => {
	return {
		payload: { id }
	};
});

export const cancelBetslipHistoryAction = createAction(`${sliceName}/cancelBetslipHistory`, (bet) => {
	return {
		payload: { bet }
	};
});

export const setHistoryAction = createAction(`${sliceName}/setHistory`, (data) => {
	return {
		payload: { data }
	};
});

export const setHistoryFiltersAction = createAction(`${sliceName}/setHistoryFilters`, (filters) => {
	return {
		payload: { filters }
	};
});

export const resetHistoryAction = createAction(`${sliceName}/resetHistory`);