import { BETSLIP_MODES } from "constants/betslip.constants";
import { useEffect, useMemo, useRef } from "react";
import { getEventsRepeatedBets } from "utils/bets";
import useGlobalVariables from "./useGlobalVariables";
import useAppDispatch from "./store/useAppDispatch";
import useAppSelector from "./store/useAppSelector";
import usePrevious from "./usePrevious";

import { setBetslipModeThunk } from "store/slices/betslip/thunks";
import { selectIsQuickBetEnabled } from "store/slices/auth/selectors";
import { selectBets } from "store/slices/betslip/selectors";

const useBetSlipModeAutoChanger = () => {
	const { isMobile } = useGlobalVariables();

	const prevNonExpiredBetsRef = useRef();
	const bets = useAppSelector(selectBets);
	const isQuickBetEnabled = useAppSelector(selectIsQuickBetEnabled);

	const dispatch = useAppDispatch();

	const { nonExpiredBets, nonHidden } = useMemo(() => {
		return {
			nonExpiredBets: bets.filter((b) => !b.isExpired),
			nonHidden: bets.filter(bet => !bet.hidden)
		};
	}, [bets]);

	prevNonExpiredBetsRef.current = usePrevious(nonExpiredBets);

	useEffect(() => {
		if (isQuickBetEnabled) {
			return;
		}
		if (nonExpiredBets.length < 2) {
			dispatch(setBetslipModeThunk(BETSLIP_MODES.SINGLE));
		} else if ((prevNonExpiredBetsRef.current?.length === 1 && nonExpiredBets.length === 2) || (
			isMobile && nonExpiredBets.length >= 2
		)) {
			const groupedRepeatedBets = getEventsRepeatedBets(bets, BETSLIP_MODES.MULTI);
			const hasDuplicate = nonExpiredBets.some(element => Boolean(groupedRepeatedBets[element.eventId]));

			if (!hasDuplicate) {
				dispatch(setBetslipModeThunk(BETSLIP_MODES.MULTI));
			}
		}
	}, [nonExpiredBets.length, bets.length, isMobile]);

	return {
		nonHidden
	}

}
export default useBetSlipModeAutoChanger;