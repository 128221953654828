import useAppSelector from "hooks/store/useAppSelector";
import useEvent from "hooks/useEvent";
import useAppDispatch from "hooks/store/useAppDispatch";

import { selectBetslipLastResults, selectBets } from "store/slices/betslip/selectors";
import { selectLiveAndUpcomingsData, selectMatchesData } from "store/slices/game/selectors";
import { doBetThunk } from "store/slices/betslip/thunks";

import runMarketUtilsFunction from "utils/markets/run";

import { GAME_STATUSES } from "constants/game.constants";

const useRebet = () => {
	const betslipLastResults = useAppSelector(selectBetslipLastResults);
	const bets = useAppSelector(selectBets);
	const liveAndUpcomings = useAppSelector(selectLiveAndUpcomingsData);
	const matches = useAppSelector(selectMatchesData);

	const dispatch = useAppDispatch();

	const reBet = useEvent((successFn) => {
		const reBetDataTemp = betslipLastResults.map((b) => ({
			market: b.market,
			oddId: b.oddId,
			gameType: b.gameType,
			period: b.period,
			stake: b.amount + ""
		}));
	
		const reBetData = [];
	
		reBetDataTemp.forEach((d) => {
			if (!reBetData.some((r) => r.oddId === d.oddId)) {
				reBetData.push(d);
			}
		});
	
		const nextUpcoming = liveAndUpcomings.find((ev) => ev.status === GAME_STATUSES.PREAMBLE_STARTED || ev.status === GAME_STATUSES.NEW);
	
		const nextUpcomingId = nextUpcoming ? nextUpcoming.id : null;
	
		const nextUpcomingEventData = matches?.[nextUpcomingId];
	
		const upcomingEvent = nextUpcomingEventData?.event ?? {};
	
		const markets = nextUpcomingEventData?.markets ?? {};
	
		reBetData.forEach((bet, index) => {
			let found = null;
			let id = bet.oddId;
			Object.keys(markets).forEach((m) => {
				if (!found) {
					markets[m].forEach((item) => {
						if (!found) {
							if (item.bets.find((b) => b.id === id && !b.disabled)) {
								found = item.bets.find((b) => b.id === id);
							}
						}
					});
				}
			});
			if (found) {
				reBetData[index].data = found;
			}
		});
	
		reBetData.forEach((bet) => {
			if (bet.data) {
				let isBetSelected = bets.some((b) => b.eventId === upcomingEvent.id && b.betId === bet.oddId);
				let group = runMarketUtilsFunction(
					"makeGroupTitle",
					[
						{
							group: bet.market,
							period: bet.period,
							argument: bet.argument,
							team1: upcomingEvent?.gameData?.team1?.countryCode,
							team2: upcomingEvent?.gameData?.team2?.countryCode,
							gameType: bet.gameType
						}
					],
					bet.gameType
				);
				if (!isBetSelected) {
					dispatch(doBetThunk({
						market: { ...bet.data, period: bet.period },
						group,
						eventInfo: upcomingEvent,
						stake: bet.stake
					}));
				}
			}
		});
	
		if (reBetData.some((b) => b.data)) {
			successFn && successFn(reBetData.filter((b) => b.data).length);
		}
	});

	return reBet;
};

export default useRebet;