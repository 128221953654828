import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";

import { setErrorMessageAction } from "store/slices/common/actions";
import { selectErrorMessage } from "store/slices/common/selectors";

/** Error Message Dialog Component */
const ErrorMessage = () => {
	const { t } = useTranslation();

	const message = useAppSelector(selectErrorMessage);

	const dispatch = useAppDispatch();

	const closeMessage = () => dispatch(setErrorMessageAction(""));

	return (
		<div className="vs--error">
			<div className="vs--error-inner vs--pl-30 vs--pr-30 vs--pt-30 vs--pb-30">
				<i className="ic_info vs--mb-12" />
				<span className="vs--title vs--font-normal vs--font-regular">{message}</span>
				<button type="button" className="vs--button vs--mt-16 vs--pt-6 vs--pb-6 vs--pl-16 vs--pr-16 vs--font-normal vs--font-condensed vs--font-bold" onClick={closeMessage}>
					{t("common.ok")}
				</button>
			</div>
		</div>
	);
};

export default ErrorMessage;
