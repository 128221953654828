import moment from "moment";

import { getDefaultLiveInfoPositions } from "utils/common";

const sliceName = "common";
const initialState = {
	errorMessage: "",
	blockedMessage: "",
	videoMinimized: false,
	liveInfoEvent: null,
	liveInfoPositions: getDefaultLiveInfoPositions(),
	showLiveInfoEvent: false,
	currentTime: moment().unix(),
	isFeedbackVisible: false,
	rules: {
		isLoading: false,
		showRules: false,
		current: {},
		prev: {}
	}
};

export { sliceName, initialState };
