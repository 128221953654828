import { ToastContainer, toast, Zoom } from "react-toastify";
import { useSelector } from "react-redux";
import { isRTL } from "utils/common";

const getLanguage = (state) => state.auth.session.languageId;

const vsToast = toast;

const MessageContainer = () => {
	const languageId = useSelector(getLanguage);
	const rtl = isRTL(languageId);
	return (
		<ToastContainer
			position={rtl ? "top-left" : "top-right"}
			autoClose={5000}
			newestOnTop={true}
			closeOnClick={true}
			rtl={rtl}
			pauseOnFocusLoss={true}
			theme="colored"
			pauseOnHover={false}
			hideProgressBar={true}
			transition={Zoom}
			limit={3}
		/>
	)
}

export {
	MessageContainer,
	vsToast as default
};