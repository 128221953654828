export class VSEventTarget extends EventTarget {
	listeners = {};

	addEventListener(eventType, eventCallback, options) {
		try {
			super.addEventListener(eventType, eventCallback, options);
		} catch (error) {
			console.error(error);
			return;
		}

		if (!Array.isArray(this.listeners[eventType])) {
			this.listeners[eventType] = [];
		}

		const isHaveSame = !!this.listeners[eventType].find((listener) => {
			return listener.eventType === eventType && listener.eventCallback === eventCallback;
		});

		if (isHaveSame) {
			return;
		}

		this.listeners[eventType].push({ eventType, eventCallback, options });
	}

	removeEventListener(eventType, eventCallback, options) {
		try {
			super.removeEventListener(eventType, eventCallback, options);
		} catch (error) {
			console.error(error);
			return;
		}

		if (!Array.isArray(this.listeners[eventType])) {
			return;
		}

		this.listeners = this.listeners[eventType].filter((listener) => {
			return listener.eventType !== eventType && listener.eventCallback !== eventCallback;
		});
	}
}

export class VSEvent extends Event {
	data = null;

	constructor(type, data = null, eventInit) {
		super(type, eventInit);
		this.data = data;
	}
}


export const onBoardingEventTarget = new VSEventTarget()