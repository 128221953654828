import { createContext } from "react";
import { VSEventTarget } from "helpers/customEvents";

const StoreContext = createContext({});
StoreContext.displayName = "StoreContext";

const EventTargetContext = createContext(new VSEventTarget());
EventTargetContext.displayName = "EventTargetContext";

export { StoreContext, EventTargetContext };
