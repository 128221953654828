import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Dropdown from "components/ui/dropdown";
import Switch from "components/ui/switch";

import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";

import { useQuickBetThunk } from "store/slices/betslip/thunks";
import { saveSettingsThunk } from "store/slices/auth/thunks";
import { setRigthbarTabAction, placeBetSuccessAction } from "store/slices/betslip/actions";
import { pendingBetsThunk } from "store/slices/betslip/thunks";
import { selectIsDemoSession, selectIsQuickBetEnabled } from "store/slices/auth/selectors";
import { selectPendingBets, selectRightbarTab } from "store/slices/betslip/selectors";

import { getCookie, mergeClassNames } from "utils/common";
import { getCurrentSettings } from "utils/settings";
import { SWITCH_SIZES } from "constants/ui.constants";
import { BETSLIP_TABS, QUICK_BET_ANIMATABLE_EL_DATA_ATTRS } from "constants/betslip.constants";
import QuickBettingTooltip from "components/onBoarding/quickBettingTooltip";
import { ON_BOARDING_COOKIE_NAMES, ON_BOARDING_TOOLTIP_VISIBILITY_EVENT, ON_BOARDING_TYPES } from "constants/onBoarding.constants";
import SessionStorageUtils from "utils/sessionStorage";
import LocalStorageUtils from "utils/localStorage";
// import { onBoardingEventTarget } from "helpers/customEvents";

const RightBarTabs = () => {
	const { t } = useTranslation();

	const isDemo = useAppSelector(selectIsDemoSession);
	const isQuickBetEnabled = useAppSelector(selectIsQuickBetEnabled);
	const pendings = useAppSelector(selectPendingBets);
	const rightbarTab = useAppSelector(selectRightbarTab);

	const dispatch = useAppDispatch();

	const [quickBet, setQuickBet] = useState(isQuickBetEnabled);
	const [fadeClass, setFadeClass] = useState("");
	const [showDropdownContent, setShowDropdownContent] = useState(false);
	const [text, setText] = useState(t(`bet.${rightbarTab === BETSLIP_TABS.BETSLIP ? "betslip" : "pendingBets"}`));
	const [textClass, setTextClass] = useState("fade-in");

	const hideSuccessMessage = () => dispatch(placeBetSuccessAction(false));

	{/* //TODO Need dynamic implementation with BO fore more flexibility */}
	// const [isQuickBetOnBoardingTooltipVisible, setIsQuickBetOnBoardingTooltipVisible] = useState(false);

	{/* //TODO Need dynamic implementation with BO fore more flexibility */}
	// const handleQuickBetOnBoardingTooltipVisibility = (visible) => {
	// 	setIsQuickBetOnBoardingTooltipVisible(visible);

	// 	const quickBettingOnBoardSession = SessionStorageUtils.get(ON_BOARDING_COOKIE_NAMES[ON_BOARDING_TYPES.QUICK_BETTING]);

	// 	if(quickBettingOnBoardSession?.showTooltip !== visible) {
	// 		SessionStorageUtils.set(
	// 			ON_BOARDING_COOKIE_NAMES[ON_BOARDING_TYPES.QUICK_BETTING],
	// 			{ ...(quickBettingOnBoardSession ?? {}), showTooltip: visible }
	// 		);
	// 	}
	// };

	const toggleQuickBet = (value) => {
		hideSuccessMessage();
		{/* //TODO Need dynamic implementation with BO fore more flexibility */}
		// if(value && isQuickBetOnBoardingTooltipVisible) {
		// 	handleQuickBetOnBoardingTooltipVisibility(false);
		// }
		dispatch(saveSettingsThunk({ isQuickBet: value }));
	};

	{/* //TODO Need dynamic implementation with BO fore more flexibility */}
	// useEffect(() => {
	// 	const setVisibilityState = (e) => {
	// 		handleQuickBetOnBoardingTooltipVisibility(e.data.state);
	// 	}

	// 	onBoardingEventTarget.addEventListener(ON_BOARDING_TOOLTIP_VISIBILITY_EVENT, setVisibilityState)

	// 	return () => {
	// 		onBoardingEventTarget.removeEventListener(ON_BOARDING_TOOLTIP_VISIBILITY_EVENT, setVisibilityState)
	// 	}

	// }, [setIsQuickBetOnBoardingTooltipVisible])

	useEffect(() => {
		const newText = t(`bet.${rightbarTab === BETSLIP_TABS.BETSLIP ? "betslip" : "pendingBets"}`);
		if (text !== newText) {
			setTextClass("fade-out-betslip-text");
			const timer = setTimeout(() => {
				setText(newText);
				setTextClass("fade-in-betslip-text");
			}, 200);

			return () => clearTimeout(timer);
		}
	}, [rightbarTab, t, text]);

	useEffect(() => {
		if (quickBet !== isQuickBetEnabled) {
			setFadeClass("fade-out");
			const timer = setTimeout(() => {
				setQuickBet(isQuickBetEnabled);
				setFadeClass("fade-in");
			}, 100);
			return () => clearTimeout(timer);
		}
	}, [isQuickBetEnabled, quickBet]);

	useEffect(() => {
		dispatch(useQuickBetThunk(isQuickBetEnabled));
	}, [isQuickBetEnabled]);

	const renderDropdownContent = () => {
		const onItemClick = (tab) => {
			dispatch(setRigthbarTabAction(tab));
			setShowDropdownContent(false);
		};

		return (
			<div className="vs--betslip-dropdown-content vs--flex vs--flex-col">
				<div className={mergeClassNames("vs--flex vs--align-center vs--cursor-pointer vs--pl-8 vs--pr-8", "vs--betslip-dropdown-content-item", rightbarTab === BETSLIP_TABS.BETSLIP && "vs--title-brand")} onClick={() => onItemClick(BETSLIP_TABS.BETSLIP)}>
					{t("bet.betslip")}
				</div>
				<div className={mergeClassNames("vs--flex vs--align-center vs--cursor-pointer vs--pl-8 vs--pr-8", "vs--betslip-dropdown-content-item", rightbarTab === BETSLIP_TABS.PENDING_BETS && "vs--title-brand")} onClick={() => onItemClick(BETSLIP_TABS.PENDING_BETS)}>
					{t("bet.pendingBets")}
					{pendings.length > 0 && <b className="vs--badge vs--title-black vs--font-smallest vs--font-bold vs--ml-6">{pendings.length}</b>}
				</div>
			</div>
		);
	};

	useEffect(() => {
		dispatch(pendingBetsThunk());
	}, [isDemo]);

	{/* //TODO Need dynamic implementation with BO fore more flexibility */}
	// useEffect(() => {

	// 	if(isQuickBetEnabled || isDemo) {
	// 		return setIsQuickBetOnBoardingTooltipVisible(false);
	// 	}

		
	// 	// const isQuickBetOnboardingShown = !!getCookie(ON_BOARDING_COOKIE_NAMES[ON_BOARDING_TYPES.QUICK_BETTING]);
	// 	const isQuickBetOnboardingShown = !!LocalStorageUtils.get(ON_BOARDING_COOKIE_NAMES[ON_BOARDING_TYPES.QUICK_BETTING]);
	// 	const quickBettingOnBoardSession = SessionStorageUtils.get(ON_BOARDING_COOKIE_NAMES[ON_BOARDING_TYPES.QUICK_BETTING]);

	// 	setIsQuickBetOnBoardingTooltipVisible(isQuickBetOnboardingShown && (quickBettingOnBoardSession?.showTooltip ?? true));

	// }, [isQuickBetEnabled, isDemo])

	return (
		<div className="vs--flex vs--mb-2">
			<div className={mergeClassNames("vs--betslip-header vs--text-center vs--flex vs--align-center vs--justify-between vs--pt-8 vs--pb-8 vs--pl-12 vs--pr-12", rightbarTab === BETSLIP_TABS.BETSLIP && "vs--betslip-section-active")} data-unique={rightbarTab === BETSLIP_TABS.BETSLIP ? "betslip" : "pendingBets"}>
				<Dropdown data-element-id={QUICK_BET_ANIMATABLE_EL_DATA_ATTRS.BETSLIP_HEADER} showDropdownContent={showDropdownContent} setShowDropdownContent={setShowDropdownContent} dropdownContent={renderDropdownContent()} placement="bottom-start">
					<div className="vs--flex vs--align-center vs--cursor-pointer">
						<i className={mergeClassNames("vs--title-brand vs--font-bigest", fadeClass, quickBet ? "ic_thunder" : "ic_quick-betslip")} />
						<span className={mergeClassNames("vs--title-white vs--font-normal vs--font-regular vs--ml-4", textClass)}>{text}</span>
						<i className="ic_right vs--cursor-pointer vs--font-bigest" />
					</div>
				</Dropdown>
				<div className="vs--betslip-header-quick-bet vs--flex vs--align-center">
					<span className="vs--betslip-header-quick-bet-title vs--title-gray-80">{t("bet.quickBet")}</span>
					<Switch
						className={mergeClassNames("vs--betslip-header-quick-bet-switch vs--ml-8" /*, isQuickBetOnBoardingTooltipVisible && "vs--quick-betting-onboarding-tooltip-highlight-target" */)}
						size={SWITCH_SIZES.LARGE}
						indicatorContent={
							<Fragment>
								<i className="ic_thunder vs--betslip-header-quick-bet-switch-thunder-active vs--title-brand" />
								<i className="ic_thunder ic_thunder-active vs--betslip-header-quick-bet-switch-thunder" />
							</Fragment>
						}
						checked={isQuickBetEnabled}
						onChange={toggleQuickBet}
					/>
					{/* //TODO Need dynamic implementation with BO fore more flexibility */}
					{
						// isQuickBetOnBoardingTooltipVisible
						// 	? <QuickBettingTooltip onClick={() => handleQuickBetOnBoardingTooltipVisibility(false)} />
						// 	: null
					}
				</div>
			</div>
		</div>
	);
};

export default RightBarTabs;
