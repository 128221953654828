import { createAction } from "@reduxjs/toolkit";

import { sliceName } from "./initialState";

export const setSeasonCurrentEventIdAction = createAction(`${sliceName}/setSeasonCurrentEventId`, (id) => {
	return {
		payload: { id }
	};
});

export const updateSeasonMarketsAction = createAction(`${sliceName}/updateSeasonMarkets`, ({ season, gameType, rtps, index }) => {
	return {
		payload: { season, gameType, rtps, index }
	};
});

export const clearSeasonMarketsAction = createAction(`${sliceName}/clearSeasonMarkets`);

export const showSeasonMarketsAction = createAction(`${sliceName}/showSeasonMarkets`, (show) => {
	return {
		payload: { show }
	};
});

export const setSeasonSkeletonHideAction = createAction(`${sliceName}/setSeasonSkeletonHide`, (hide) => {
	return {
		payload: { hide }
	};
});

export const setSeasonSelectedMarketAction = createAction(`${sliceName}/setSeasonSelectedMarket`, (market) => {
	return {
		payload: { market }
	};
});

export const setShowNextLiveAndUpcomingsAction = createAction(`${sliceName}/setShowNextLiveAndUpcomings`, (show) => {
	return {
		payload: { show }
	};
});

export const setSeasonResultModalMobileAction = createAction(`${sliceName}/setSeasonResultModalMobile`, (id) => {
	return {
		payload: { id }
	};
});

export const clearSeasonStructureAndStandingsAction = createAction(`${sliceName}/clearSeasonStructureAndStandings`);