import PropTypes from "prop-types";
import footballDataType from "./footballData.type";
import racingDataType from "./racingData.type";

export default PropTypes.shape({
	key: PropTypes.string,  // Unique key generated by Frontend during addBet
	gameData: PropTypes.oneOfType([footballDataType, racingDataType]),
	name: PropTypes.string,
	eventId: PropTypes.number,
	group: PropTypes.string,
	factor: PropTypes.number,
	stake: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isExpired: PropTypes.bool
});
