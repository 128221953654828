import { createPortal } from "react-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";

const PopupContent = ({ id, placement, overlayClassName, title, positionStrategy, popupContainer, ...rest }) => {
	const content = (
		<ReactTooltip id={id} place={placement} className={overlayClassName} positionStrategy={positionStrategy} {...rest}>
			{title}
		</ReactTooltip>
	);

	return popupContainer ? createPortal(content, popupContainer) : content;
};

export default PopupContent;
