import { createSlice } from "@reduxjs/toolkit";

import {
	addEventHistoryCase
} from "./extraReducers";
import { initialState, sliceName } from "./initialState";

const eventSlice = createSlice({
	name: sliceName,
	initialState: initialState,
	extraReducers: (builder) => {
		addEventHistoryCase(builder);
	}
});

export const eventSliceName = sliceName;
export const eventSliceReducer = eventSlice.reducer;