import PropTypes from "prop-types";

const TabStructureAggInfo = ({ teamInfo, opponentTeamInfo }) => {
	if (!teamInfo) {
		return null;
	}
	if (!opponentTeamInfo) {
		return null;
	}
	return (
		<div className="vs--flex vs--align-center vs--justify-center vs--season-structure-list-item-result vs--title-white">
			(&nbsp;
			<span className={"vs--title-white vs--font-medium vs--font-big" + (teamInfo.aggGoalCount > opponentTeamInfo.aggGoalCount ? " vs--text-green" : "")}>{teamInfo.aggGoalCount === undefined ? " - " : `${teamInfo.aggGoalCount}`}</span>
			&nbsp;)
		</div>
	);
};

TabStructureAggInfo.propTypes = {
	teamInfo: PropTypes.object,
	opponentTeamInfo: PropTypes.object
};

export default TabStructureAggInfo;
