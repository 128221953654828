import {
	bonusHistoryThunk,
	addBonusHistoryThunk
} from "./thunks";

import {
	setUseBonusAction,
	setBonusHistoryFiltersAction
} from "./actions";

export const addBonusHistoryCases = (builder) => {
	builder.addCase(bonusHistoryThunk.pending, (state) => {
		state.loading = true;
	});

	builder.addCase(bonusHistoryThunk.fulfilled, (state, { payload: data }) => {
		state.total = data.item1;

		state.loading = false;
		state.history = data.item2;
	});

	builder.addCase(bonusHistoryThunk.rejected, (state) => {
		state.loading = false;
	});

	builder.addCase(addBonusHistoryThunk.fulfilled, (state, { payload: data }) => {
		state.total = data.item1;

		state.history = [...state.history, ...data.item2];
	});
};

export const addUseBonusCases = (builder) => {
	builder.addCase(setUseBonusAction, (state, { payload }) => {
		state.useBonus = payload.useBonus;
	});

	builder.addCase(setBonusHistoryFiltersAction, (state, { payload }) => {
		state.filters = {
			...state.filters,
			...payload.filters
		};
	})
}