import { mergeClassNames } from "utils/common";

const DottedLoader = () => {
	return (
		<div className="vs--dotted-loader">
			{Array.from({ length: 16 }, (_, index) => (
				<div key={index} className={mergeClassNames("vs--dotted-loader-inner", `vs--dotted-loader-inner-${index}`)}></div>
			))}
		</div>
	)
};

export default DottedLoader;