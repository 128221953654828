import { EventTargetProvider, StoreProvider } from "providers/navbar";
import NavbarCoreComponent from "components/ui/navbar/navbarCoreComponent";

const Navbar = ({ menuButton, arrowButtons, allowIntoView, eventTargetRef, ...otherProps }) => {
	return (
		<EventTargetProvider eventTargetRef={eventTargetRef}>
			<StoreProvider menuButton={menuButton} arrowButtons={arrowButtons} allowIntoView={allowIntoView}>
				<NavbarCoreComponent {...otherProps} />
			</StoreProvider>
		</EventTargetProvider>
	);
};

export default Navbar;
