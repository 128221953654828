import { BONUS_STATES } from "constants/bonus.constants";

const sliceName = "bonus";
const initialState = {
	useBonus: false,
	history: [],
	loading: false,
	filters: {
		status: BONUS_STATES.ALL
	}
}

export { sliceName, initialState };