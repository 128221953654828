import { Fragment, useContext } from "react";

import Flag from "components/ui/flag";
import DottedLoader from "components/ui/loader/dotted";
import AnimatedDiv from "components/ui/animatedDiv";

import useGlobalVariables from "hooks/useGlobalVariables";
import useBetHandlers from "hooks/useBetHandlers";
import useAppSelector from "hooks/store/useAppSelector";

import { selectOddFormat, selectIsQuickBetEnabled, selectIsRtl } from "store/slices/auth/selectors";

import { numberWithSpaces, mergeClassNames } from "utils/common";
import runMarketUtilsFunction from "utils/markets/run";
import { ODD_STATE, QUICK_BET_ANIMATABLE_EL_DATA_ATTRS } from "constants/betslip.constants";
import { GAME_STATUSES } from "constants/game.constants";
import PenaltyMarketOddsContext from "contexts/penaltyMarketOddsContext";

const Odd = ({ odd, showName = true }) => {
	const { isMobile } = useGlobalVariables();
	const { market, group, eventInfo, tab } = useContext(PenaltyMarketOddsContext);

	const isQuickBetEnabled = useAppSelector(selectIsQuickBetEnabled);
	const oddFormat = useAppSelector(selectOddFormat);
	const isRtlLanguage = useAppSelector(selectIsRtl);

	const {
		isPlaceBetLoading,
		isBetSelected,
		isBetDisabled,
		isBonusBetDisabled,
		animationParams,
		setAnimationParams,
		handleClick
	} = useBetHandlers({
		market,
		group,
		eventInfo,
		animatedDivTarget: isQuickBetEnabled 
  ? (isMobile 
      ? `div[data-element-id="${QUICK_BET_ANIMATABLE_EL_DATA_ATTRS.BET_HISTORY}"]`
      : `b[data-element-id="${QUICK_BET_ANIMATABLE_EL_DATA_ATTRS.BADGE}"]`
    )
  : `div[data-element-id="${QUICK_BET_ANIMATABLE_EL_DATA_ATTRS[isMobile ? "BETSLIP" : "RIGHTBAR_CONTENT"]}"]`
	});

	const isDisabled = isBetDisabled(odd);
	const isSelected = !isDisabled && isBetSelected(odd?.id);

	return (
		<AnimatedDiv
			className={isQuickBetEnabled ? "vs--quick-bet-odd" : "vs--bet-odd vs--flex vs--align-center"}
			startTransition={!isPlaceBetLoading}
			initialRect={animationParams?.initialRect}
			targetSelector={animationParams?.targetSelector}
			containerClass=".vs--wrapper-section"
			position={isMobile ? 'center' : isQuickBetEnabled ? 'center' : 'left'}
			stickyHeight={100}
			onAnimationEnd={() => {
				setAnimationParams(null)
			}}
		>
			<div
				className={
					mergeClassNames(
						"vs--table-odds-penalty-item",
						isBonusBetDisabled && "vs--table-odds-penalty-item-blocked",
						isSelected && "vs--table-odds-penalty-item-selected",
						isDisabled && "vs--table-odds-penalty-item-disabled",
						eventInfo?.status === GAME_STATUSES.FINISHED && "vs--table-odds-penalty-item-state",
						(
							odd?.oddState !== 0 &&
							(
								"vs--table-odds-penalty-item-state-" + odd?.oddState
							)
						),
						odd?.withFlag && " vs--table-odds-penalty-item-with-flag",
						"vs--outcome-" + odd?.outcome
					)
				}
				onClick={(e) => handleClick(e, odd)}
				data-unique={odd?.id}
			>
				{isPlaceBetLoading ? <DottedLoader /> : odd?.oddState !== ODD_STATE.PENDING && isMobile ? (
					isDisabled ? (
						<i className="ic_lock vs--font-bigest"></i>
					) : (
						<Fragment>
							<span className="vs--title-white vs--font-regular vs--font-normal" dangerouslySetInnerHTML={{ __html: showName ? odd?.showName : "" }} />
							<span className="vs--title-brand vs--font-medium vs--font-normal vs--odd-factor">{numberWithSpaces(runMarketUtilsFunction("convertToFormat", [odd?.factor, oddFormat]))}</span>
						</Fragment>
					)
				) : (
					<Fragment>
						{isDisabled ? null : (
							<Fragment>
								{odd?.withFlag ? (
									<div className="vs--flex vs--align-center vs--flex-start vs--pr-8">
										<Flag code={odd?.outcome} gameType={eventInfo?.gameType} />
										<span className="vs--title-gray-80 vs--font-regular vs--font-normal vs--ml-8" title={showName ? odd?.showName : ""} dangerouslySetInnerHTML={{ __html: showName ? odd?.showName : "" }} />
									</div>
								) : (
									<span className="vs--title-gray-80 vs--font-regular vs--font-normal" title={showName ? odd?.showName : ""} dangerouslySetInnerHTML={{ __html: showName ? odd?.showName : "" }} />
								)}
							</Fragment>
						)}
						{isDisabled ? (
							<i className="ic_lock vs--font-bigest" />
						) : (
							<span className={mergeClassNames("vs--title-brand vs--font-medium vs--font-normal", !isMobile || tab !== "fulltime" ? "vs--text-center" : isRtlLanguage ? "vs--ml-8 vs--pr-8 vs--text-left" : "vs--text-right vs--mr-8", "vs--odd-factor")}>
								{numberWithSpaces(runMarketUtilsFunction("convertToFormat", [odd?.factor, oddFormat]))}
							</span>
						)}
					</Fragment>
				)}
			</div>
		</AnimatedDiv>
	);
};

export default Odd;
