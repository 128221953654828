import { sliceName } from "./initialState";

export const selectBetslipLastResults = (state) => state[sliceName].betslipLastResults;
export const selectPendingBets = (state) => state[sliceName].pendings;
export const selectIsPendingsLoading = (state) => state[sliceName].pendingLoading;
export const selectBets = (state) => state[sliceName].bets;
export const selectQuickBets = (state) => state[sliceName].quickBets;
export const selectPlaceBetSuccess = (state) => state[sliceName].success;
export const selectBetslipShowKeyboard = (state) => state[sliceName].showKeyboard;
export const selectBetslipMode = (state) => state[sliceName].mode;
export const selectBetslipStake = (state) => state[sliceName].stake;
export const selectBetslipStakeMode = (state) => state[sliceName].stakeMode;
export const selectShowMobileBetslipsModal = (state) => state[sliceName].showMobileBetslipsModal;
export const selectShowMobileQuickBetslip = (state) => state[sliceName].showMobileQuickBetslip;
export const selectIsBetslipCancelling = (state) => state[sliceName].isCanceling;
export const selectRightbarTab = (state) => state[sliceName].rightbarTab;
export const selectIsPlaceBetLoading = (state) => state[sliceName].loading;
export const createSelectBetsOrQuickBets = (quickEnabled) => (state) => (quickEnabled ? selectQuickBets(state) : selectBets(state));
