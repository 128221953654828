import { sliceName } from "./initialState";

export const selectVideoMinimized = (state) => state[sliceName].videoMinimized;
export const selectLiveInfoEvent = (state) => state[sliceName].liveInfoEvent;
export const selectLiveInfoPositions = (state) => state[sliceName].liveInfoPositions;
export const createGivenLiveInfoPositionSelector = (gameType) => (state) => selectLiveInfoPositions(state)?.[gameType];
export const selectShowLiveInfoEvent = (state) => state[sliceName].showLiveInfoEvent;
export const selectCurrentTime = (state) => state[sliceName].currentTime;
export const selectIsFeedbackVisible = (state) => state[sliceName].isFeedbackVisible;
export const selectErrorMessage = (state) => state[sliceName].errorMessage;
export const selectBlockedMessage = (state) => state[sliceName].blockedMessage;
export const selectRules = (state) => state[sliceName].rules;
export const selectIsRulesLoading = (state) => selectRules(state).isLoading;
export const selectShowRules = (state) => selectRules(state).showRules;
export const selectPrevRules = (state) => selectRules(state).prev;
export const selectCurrentRules = (state) => selectRules(state).current;

