import { Fragment } from "react";
import PropTypes from "prop-types";

import useAppSelector from "hooks/store/useAppSelector";

import BetSlip from "../../rightBar/partials/betslip";
import RightBarTabs from "../../rightBar/partials/tabs";
import PendingBets from "../../rightBar/partials/pendingBets";
import MobileHeader from "components/mobileHeader";

import { selectRightbarTab } from "store/slices/betslip/selectors";

import { mergeClassNames } from "utils/common";
import useGlobalVariables from "hooks/useGlobalVariables";

/** Mobile Bet Slip Component */
const MobileBetSlip = () => {
	const rightbarTab = useAppSelector(selectRightbarTab);
	const { isMobile, isBuilderIntegration } = useGlobalVariables();

	return (
		<div className={mergeClassNames("vs--betslip-mobile vs--flex vs--flex-col", isMobile && "vs--overflow-x-hidden")}>
			{!isBuilderIntegration && <MobileHeader />}
			<RightBarTabs />
			{rightbarTab === 1 ? <BetSlip /> : <PendingBets />}
		</div>
	);
};

export default MobileBetSlip;
