import { useMemo } from "react";

const useBetTicketBorderSvgProps = (color) => {
	const getComputedColor = () => {
		if (typeof color === "string" && !color.startsWith("--")) {
			return color;
		}

		const computedColor = getComputedStyle(document.documentElement)
			.getPropertyValue(color || '--vs--bg-color-base')
			.trim();
		return computedColor;
	};

	const svgProps = useMemo(() => {
		const svgWidth = 6;
		const svgHeight = 6;

		// Create SVG string directly instead of using DOM manipulation
		const svgString = `
		<svg xmlns="http://www.w3.org/2000/svg" width="${svgWidth}px" height="${svgHeight}px" viewBox="0 0 ${svgWidth} ${svgHeight}">
			<circle cx="3" cy="3" r="3" fill="${getComputedColor()}"/>
		</svg>
		`.trim();

		const backgroundImage = `url('data:image/svg+xml,${encodeURIComponent(svgString)}')`;

		return {
			style: {
				backgroundImage,
				backgroundRepeat: 'repeat-x'
			}
		};
	}, [color]);

	return svgProps;
};

export default useBetTicketBorderSvgProps;