import { createSlice } from "@reduxjs/toolkit";

import { 
	addPlayerCases,
	addBonusCases,
	addAuthenticationCases, 
	addStreamCases, 
	addSessionCreationCases,
	addSessionDetailsCases,
	addSessionLogoCases,
	addSaveSettingsCases
} from "./extraReducers";
import { initialState, sliceName } from "./initialState";

const authSlice = createSlice({
	name: sliceName,
	initialState: initialState,
	extraReducers: (builder) => {
		addPlayerCases(builder);
		addBonusCases(builder);
		addSessionCreationCases(builder);
		addSessionDetailsCases(builder);
		addSessionLogoCases(builder);
		addAuthenticationCases(builder);
		addStreamCases(builder);
		addSaveSettingsCases(builder);
	}
});

export const authSliceName = sliceName;
export const authSliceReducer = authSlice.reducer;
