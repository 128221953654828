export const canUseDom = () => {
	return !!(typeof window !== "undefined" && window.document && window.document.createElement);
}

export const listenOnce = (element, eventName, callBack) => {
	const fn = (e) => {
		callBack(e)
		element.removeEventListener(eventName, fn)
	}
	element.addEventListener(eventName, fn)
}