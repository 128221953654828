import PropTypes from "prop-types";
import Games from "components/topBar/games";

import useAppSelector from "hooks/store/useAppSelector";

import { selectLiveAndUpcomingsData } from "store/slices/game/selectors";

import { mergeClassNames } from "utils/common";
import useGlobalVariables from "hooks/useGlobalVariables";
import TopBarMenuHoc from "hocs/topBar/topBarMenuHoc";

const WrappedGames = TopBarMenuHoc(Games);

/* topBar Component */
const topBar = ({ isCurrentGameDeactivated }) => {
	const liveAndUpcomings = useAppSelector(selectLiveAndUpcomingsData);
	const { isMobile } = useGlobalVariables();

	return (
		<div className={mergeClassNames("vs--topBar", isMobile ? "vs--mt-4 vs--mb-4 vs--ns" : "vs--mb-8", !isMobile && liveAndUpcomings.length === 0 && isCurrentGameDeactivated && "vs--topBar-games-deactivated")}>
			<WrappedGames />
		</div>
	);
};

/** topBar propTypes
 * PropTypes
 */
topBar.propTypes = {
	/** Current game active flag */
	isCurrentGameDeactivated: PropTypes.bool
};


export default topBar;
