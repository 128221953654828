import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useGlobalVariables from "hooks/useGlobalVariables";
import useAppDispatch from "hooks/store/useAppDispatch";

import AreYouSure from "components/ui/areYouSure";

import { cancelBetSlipThunk, cancelSingleBetThunk } from "store/slices/betslip/thunks";

import {
	isChampionsCupGame,
	isLeagueGame,
	isNullish,
	isRacingGame,
	mergeClassNames
} from "utils/common";
import { isBetslipCancelable, getBetEventName } from "utils/bets";

import { BETSLIP_MODES, BET_STATE } from "constants/betslip.constants";
import { GAME_TYPE, GAME_TYPE_ICON_MAPPER, GAME_TYPE_MAPPER, KENO_BALL_SIZE } from "constants/game.constants"

const PendingBetHeader = ({ data }) => {
	const { isMobile } = useGlobalVariables();
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const getBetTypeInfo = () => {
		if (data.isDemo) {
			return { text: t('common.demo'), classNamePrefix: "demo" };
		}
		if (data.bonusId) {
			return { text: t("bonus.bonus"), classNamePrefix: "bonus" };
		}

		return null;
	};

	const isSingleBet = data.type === BETSLIP_MODES.SINGLE;
	const isCancelAvailable = isSingleBet ? (data.allowCancel && data.status === BET_STATE.PENDING) : isBetslipCancelable(data);
	const betTypeInfo = getBetTypeInfo();

	const onModalCancel = (e) => {
		e.stopPropagation();
		setShowDeleteModal(false);
	};

	const cancel = (_, e) => {
		e.stopPropagation();
		setShowDeleteModal(false);

		if (isSingleBet) {
			dispatch(cancelSingleBetThunk({ id: data.betSlipId, betId: showDeleteModal }));
			return;
		}

		dispatch(cancelBetSlipThunk(data.betSlipId));
	}

	return (
		<div className="vs--pending-item-header vs--pt-6 vs--pb-6 vs--pl-8 vs--pr-8 vs--font-small">
			<div className="vs--flex vs--justify-between vs--align-center vs--title-white">
				<div className="vs--flex vs--align-center">
					<div className="vs--flex vs--align-center vs--pl-4">
						{isSingleBet ? (
							<Fragment>
								<i className={mergeClassNames(GAME_TYPE_ICON_MAPPER[data.gameType], "vs--mr-4 vs--font-20")} />
								{(data.gameType === GAME_TYPE.KENO || isRacingGame(data.gameType)) ? (
									<span>
										{t(`common.${GAME_TYPE_MAPPER[data.gameType]}`)}
									</span>
								) : (
									<span>
										{getBetEventName(data)}
									</span>
								)}
							</Fragment>
						) : (
							<Fragment>
								<i className={mergeClassNames("ic_bet-ticket", "vs--mr-4 vs--font-20")} />
								<span>{t("bet.multi")}</span>
								&nbsp;
								<span>{`(${data.bets.length})`}</span>
								<i className="ic_down vs--font-20"></i>
							</Fragment>
						)}
					</div>
					{!isNullish(betTypeInfo) && (
						<div
							className={mergeClassNames(
								"vs--title-dark-black vs--font-small vs--font-bold vs--ml-4 vs--pl-4 vs--pr-4 vs--pending-item-header-bet",
								`vs--pending-item-header-bet-${betTypeInfo.classNamePrefix}`
							)}
						>
							{betTypeInfo.text}
						</div>
					)}
				</div>
				{isCancelAvailable && (
					<div
						className="vs--title-brand vs--font-normal vs--font-medium vs--cursor-pointer"
						onClick={(e) => {
							e.stopPropagation();

							setShowDeleteModal(data[isSingleBet ? "betId" : "betSlipId"])
						}}
					>
						{t("common.cancel")}
					</div>
				)
				}
			</div>
			{
				showDeleteModal && isCancelAvailable
					? (
						<AreYouSure
							onCancel={onModalCancel}
							onOk={cancel}
						/>
					)
					: null
			}
		</div>
	)
};

export default PendingBetHeader;