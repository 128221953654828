import { createSlice } from "@reduxjs/toolkit";

import {
	addBetslipCases,
	addStakeCases,
	addModeCases,
	addRightbarTabCases,
	addKeyboardCases,
	addBetslipResultsCases,
	addBetCases,
	addRemoveBetsCases,
	addPendingBetsCases,
	addPlaceBetCases,
	cancelBetCases
} from "./extraReducers";
import { initialState, sliceName } from "./initialState";

const betslipSlice = createSlice({
	name: sliceName,
	initialState: initialState,
	extraReducers: (builder) => {
		addBetslipCases(builder);
		addStakeCases(builder);
		addModeCases(builder);
		addRightbarTabCases(builder);
		addKeyboardCases(builder);
		addBetslipResultsCases(builder);
		addBetCases(builder);
		addRemoveBetsCases(builder);
		addPendingBetsCases(builder);
		addPlaceBetCases(builder);
		cancelBetCases(builder);
	}
});

export const betslipSliceName = sliceName;
export const betslipSliceReducer = betslipSlice.reducer;
