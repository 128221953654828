import PropTypes from "prop-types";
import footballDataType from "./footballData.type";
import racingDataType from "./racingData.type";

import { GAME_TYPE, GAME_EVENT_TYPE } from "constants/game.constants";

const eventType = {
	status: PropTypes.number,
	startTime: PropTypes.string,
	preambleStartTime: PropTypes.string,
	noMoreBetsTime: PropTypes.string,
	gameData: PropTypes.oneOfType([footballDataType, racingDataType]),
	id: PropTypes.number,
	duration: PropTypes.number,
	passedDistance: PropTypes.number,
	gameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	type: PropTypes.oneOf(Object.values(GAME_EVENT_TYPE))
}

export default PropTypes.shape({
	...eventType,
	events: PropTypes.arrayOf(PropTypes.shape(eventType))
})
