import { useRef, useLayoutEffect, useEffect } from "react";
import PropTypes from "prop-types";
import useGlobalVariables from "hooks/useGlobalVariables";
import Navbar from "components/ui/navbar";
import TabElement from "./tabElement";

import useAppSelector from "hooks/store/useAppSelector";

import { selectCurrentGameType } from "store/slices/game/selectors";
import { selectIsRtl } from "store/slices/auth/selectors";

import { isNullish } from "utils/common";
import { VSEvent } from "helpers/customEvents";
import { NAVBAR_EVENT_TYPES, NAVBAR_SUBEVENT_TYPES } from "constants/navbar.constants";
import eventType from "types/event.type";

const TabsComponent = ({ seasonStructure, activeTab, setActiveTab }) => {
	const currentGameType = useAppSelector(selectCurrentGameType);
	const isRTLLanguage = useAppSelector(selectIsRtl);

	const { isMobile } = useGlobalVariables();
	const isMountedRef = useRef(false);
	const eventTargetRef = useRef();


	useLayoutEffect(() => {
		isMountedRef.current = false;
	}, [currentGameType]);

	useEffect(() => {
		if (isMountedRef.current) {
			return;
		}

		const eventType = NAVBAR_EVENT_TYPES.MESSAGE_TO_ELEMENT + activeTab;
		const { MOVE_TO_VIEW } = NAVBAR_SUBEVENT_TYPES[NAVBAR_EVENT_TYPES.MESSAGE_TO_ELEMENT];
		eventTargetRef.current.dispatchEvent(new VSEvent(NAVBAR_EVENT_TYPES.ACTIVE_ELEMENT, activeTab));
		eventTargetRef.current.dispatchEvent(new VSEvent(eventType, [{ type: MOVE_TO_VIEW }]));
		isMountedRef.current = true;
	}, [activeTab, currentGameType]);

	useEffect(() => {
		const eventTarget = eventTargetRef.current;
		const setActiveListener = ({ data: elementId }) => {
			if (isNullish(elementId)) {
				return;
			}
			setActiveTab(elementId);
		};
		eventTarget.addEventListener(NAVBAR_EVENT_TYPES.ACTIVE_ELEMENT, setActiveListener);
		return () => {
			eventTarget.removeEventListener(NAVBAR_EVENT_TYPES.ACTIVE_ELEMENT, setActiveListener);
		};
	}, [currentGameType, setActiveTab]);

	return (
		<Navbar
			dir={isRTLLanguage ? "rtl" : "ltr"}
			eventTargetRef={eventTargetRef}
			menuButton={false}
			arrowButtons={isMobile ? false : null}
			flexElements={true}
			elementClassName="vs--game-live-result-sctructure-inner-tabs-item vs--flex vs--justify-center vs--align-center"
			activeElementClassName="vs--game-live-result-sctructure-inner-tabs-item-active"
			dataList={seasonStructure.events}
			elementId="orderNumber"
			elementInnerComponent={TabElement}
		/>
	);
};

TabsComponent.propTypes = {
	seasonStructure: eventType,
	activeTab: PropTypes.number,
	setActiveTab: PropTypes.func
};

export default TabsComponent;
