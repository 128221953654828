import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";
import useEvent from "hooks/useEvent";

import { setLiveInfoEventAction } from "store/slices/common/actions";

import { selectLiveInfoEvent } from "store/slices/common/selectors";
import { selectIsAnimation } from "store/slices/auth/selectors";
import { selectCurrentGameType } from "store/slices/game/selectors";
import { getEventThunk, getEventInBackgroundThunk } from "store/slices/game/thunks";

import { GAME_STATUSES } from "constants/game.constants";

const useGetEvent = () => {
	const liveInfoEvent = useAppSelector(selectLiveInfoEvent);
	const isAnimation = useAppSelector(selectIsAnimation);
	const currentGameType = useAppSelector(selectCurrentGameType);

	const dispatch = useAppDispatch();

	const requestEvent = useEvent(async (id, isBackground, updateLiveInfo, options = { isMobile: false }) => {
		try {
			if (!requestEvent.requestPendingHistory) {
				requestEvent.requestPendingHistory = {};
			}
			if (requestEvent.requestPendingHistory[id]) {
				return;
			} else {
				requestEvent.requestPendingHistory[id] = true;
			}
			const params = { gameType: currentGameType, id };
			const thunkApi = isBackground ? dispatch(getEventInBackgroundThunk(params)) : dispatch(getEventThunk(params));

			const data = await thunkApi.unwrap();

			if (isBackground && updateLiveInfo) {
				if ((liveInfoEvent && liveInfoEvent.status !== GAME_STATUSES.FINISHED) || (!liveInfoEvent && !isAnimation && !options.isMobile)) {
					dispatch(setLiveInfoEventAction(data.event));
				}
			}
		} catch (error) {
			console.log(error);
		}

		if (requestEvent.requestPendingHistory[id]) {
			delete requestEvent.requestPendingHistory[id];
		}
	});

	const getEvent = useEvent((id, options = { isMobile: false }) => {
		return requestEvent(id, false, null, options);
	});

	const getEventInBackground = useEvent((id, updateLiveInfo, options = { isMobile: false }) => {
		return requestEvent(id, true, updateLiveInfo, options);
	});

	return { getEvent, getEventInBackground };
};

export default useGetEvent;
