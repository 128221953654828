export const COLLAPSE_MODE = {
	ACCORDION: "accordion",
	COLLAPSE: "collapse"
};

export const EXPAND_ICON_POSITION = {
	START: "start",
	END: "end"
};

export const SWITCH_SIZES = {
	SMALL: "small",
	DEFAULT: "default",
	LARGE: "large"
}

export const DOTS = "...";

export const LEFT = "left";
export const RIGHT = "right";

export const DEFAULT_PAGE_SIZE_OPTIONS = [20, 50, 100, 200, 300];
export const DEFAULT_PAGE_SIZE = 20;

export const DEFAULT_PAGE = 1;

export const RATE_VARIANTS = {
	STANDARD: "standard",
	SHORT: "short",
	FULL: "full"
}
