import ApiUrls from "constants/api.constants";

import httpService from "../http";

const betService = {
	pendings: (signal) => {
		return httpService.get({
			path: ApiUrls.GET_PENDING_BETS,
			signal
		});
	},

	placeBet: (data, signal) => {
		return httpService.post({
			path: ApiUrls.PLACE_BET,
			requestBody: data,
			signal
		});
	},

	cancelBetslip: (id, signal) => {
		return httpService.post({
			path: ApiUrls.CANCEL_BETSLIP,
			requestBody: { id },
			signal
		});
	},

	cancelSingleBet: (data, signal) => {
		return httpService.post({
			path: ApiUrls.CANCEL_BET,
			requestBody: data,
			signal
		});
	},

	eventHistory: (id, signal) => {
		return httpService.get({
			path: ApiUrls.GET_EVENT_HISTORY,
			queryData: { id },
			signal
		});
	}
};

export default betService;
