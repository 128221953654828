import { useTranslation } from "react-i18next";

const TreeStructureHeader = () => {
	const { t } = useTranslation();
	return (
		<div className="vs--cup-result-structure-block-header vs--flex vs--align-center vs--mb-12">
			<span className="vs--title-white vs--flex-inline vs--flex-equal vs--justify-start vs--align-center vs--font-small vs--font-medium vs--pl-16">{t("statistics.participants")}</span>
			<span className="vs--title-white vs--flex-inline vs--justify-center vs--align-center vs--font-small vs--font-medium vs--pr-12">{`${t("common.leg")} 1`}</span>
			<span className="vs--title-white vs--flex-inline vs--justify-center vs--align-center vs--font-small vs--font-medium vs--pr-12">{`${t("common.leg")} 2`}</span>
			<span className="vs--title-white vs--flex-inline vs--justify-center vs--align-center vs--font-small vs--font-medium vs--pr-16">{t("common.aggShort")}</span>
		</div>
	);
};
export default TreeStructureHeader;
