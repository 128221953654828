import { createAction } from "@reduxjs/toolkit";

import { sliceName } from "./initialState";

export const setCurrentTimeAction = createAction(`${sliceName}/setCurrentTime`);

export const setShowLiveInfoEventAction = createAction(`${sliceName}/setShowLiveInfoEvent`, (show) => {
	return {
		payload: { show }
	};
});

export const setLiveInfoEventAction = createAction(`${sliceName}/setLiveInfoEvent`, (event) => {
	return {
		payload: { event }
	};
});

export const updateLiveInfoEventAction = createAction(`${sliceName}/updateLiveInfoEvent`, (event) => {
	return {
		payload: { event }
	};
});

export const setEventPositionsAction = createAction(`${sliceName}/setEventPositions`, (positions) => {
	return {
		payload: { positions }
	};
});

export const setShowRulesAction = createAction(`${sliceName}/setShowRules`, (showRules) => {
	return {
		payload: { showRules }
	};
});

export const toggleShowRulesAction = createAction(`${sliceName}/toggleShowRules`);

export const setVideoMinimizedAction = createAction(`${sliceName}/setVideoMinimized`, (minimized) => {
	return {
		payload: { minimized }
	};
});

export const toggleFeedbackVisibilityAction = createAction(`${sliceName}/toggleFeedbackVisibility`, (storageKey) => {
	return {
		payload: { storageKey }
	};
});

export const setErrorMessageAction = createAction(`${sliceName}/setErrorMessage`, (message) => {
	return {
		payload: { message }
	};
});

export const setBlockedMessageAction = createAction(`${sliceName}/setBlockedMessage`, (message) => {
	return {
		payload: { message }
	};
});