import { useState, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import GlobalVariablesContext from "contexts/globalVariablesContext";
import { detectAllWithMedias } from "utils/deviceDetect";
import { MEDIA_FOR_CHECK_POINTER, MEDIA_FOR_IMMEDIATELY_CHANGE_TO_MOBILE_VIEW } from "constants/common.constants";

const GlobalVariablesProvider = ({ children }) => {
	const [globalVariables, setGlobalVariables] = useState(detectAllWithMedias);

	useLayoutEffect(() => {
		const pointerMediaMediaQueryList = window.matchMedia(MEDIA_FOR_CHECK_POINTER);
		const widthMediaQueryList = window.matchMedia(MEDIA_FOR_IMMEDIATELY_CHANGE_TO_MOBILE_VIEW);

		const pointerMediaMediaQueryListEventHandler = () => setGlobalVariables(detectAllWithMedias());
		const widthMediaMediaQueryListEventHandler = (mediaEvent) => setGlobalVariables(detectAllWithMedias(mediaEvent));

		pointerMediaMediaQueryList.addEventListener("change", pointerMediaMediaQueryListEventHandler);
		widthMediaQueryList.addEventListener("change", widthMediaMediaQueryListEventHandler);
		return () => {
			pointerMediaMediaQueryList.removeEventListener("change", pointerMediaMediaQueryListEventHandler);
			widthMediaQueryList.removeEventListener("change", widthMediaMediaQueryListEventHandler);
		};
	}, [setGlobalVariables]);

	return <GlobalVariablesContext.Provider value={globalVariables}>{children}</GlobalVariablesContext.Provider>;
};

GlobalVariablesProvider.propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.element])
};

export default GlobalVariablesProvider;
