import { createAction } from "@reduxjs/toolkit";

import { sliceName } from "./initialState";

export const setUseBonusAction = createAction(`${sliceName}/setUseBonus`, (useBonus) => {
	return {
		payload: { useBonus }
	};
});

export const setBonusHistoryFiltersAction = createAction(`${sliceName}/setBonusHistoryFilters`, (filters) => {
	return {
		payload: { filters }
	};
});