import { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Tooltip from "components/ui/tooltip";
import Flag from "components/ui/flag";
import eventType from "types/event.type";

const TreeEventChampionsLeague = ({ eventInfo, defaultText = "" }) => {
	const { t } = useTranslation();

	const { team1, team2, gameType } = useMemo(() => {
		const { leg1, leg2 } = eventInfo;
		const data = { team1: {}, team2: {} };

		data.gameType = leg1?.gameType ?? null;

		const getTeam = (leg, teamIndex) => leg?.gameData?.[`team${teamIndex}`] ?? {};

		data.team1.flagCode = getTeam(leg1, 1).flagCode ?? "";
		data.team2.flagCode = getTeam(leg1, 2).flagCode ?? "";

		data.team1.countryCode = getTeam(leg1, 1).countryCode;
		data.team2.countryCode = getTeam(leg1, 2).countryCode;

		data.team1.fullTimeGoalCount1 = getTeam(leg1, 1).goalCount ?? "-";  
		data.team2.fullTimeGoalCount1 = getTeam(leg1, 2).goalCount ?? "-";  

		data.team1.fullTimeGoalCount2 = getTeam(leg2, 2).goalCount ?? "-";  
		data.team2.fullTimeGoalCount2 = getTeam(leg2, 1).goalCount ?? "-";  

		data.team1.overTimeGoalCount = getTeam(leg2, 2).overTimeGoalCount ?? "-";  
		data.team2.overTimeGoalCount = getTeam(leg2, 1).overTimeGoalCount ?? "-";

		data.team1.penaltyShootoutGoalCount = getTeam(leg2, 2).penaltyShootoutGoalCount ?? "-"; 
		data.team2.penaltyShootoutGoalCount = getTeam(leg2, 1).penaltyShootoutGoalCount ?? "-";

		[1, 2].forEach((number) => {
			const teamKey = `team${number}`;
			data[teamKey].aggGoalCount = 0;
			if (typeof data[teamKey].fullTimeGoalCount1 !== "number" || typeof data[teamKey].fullTimeGoalCount2 !== "number") {
				data[teamKey].aggGoalCount = "-";
				return;
			}
			data[teamKey].aggGoalCount += data[teamKey].fullTimeGoalCount1 + data[teamKey].fullTimeGoalCount2;
			if (typeof data[teamKey].overTimeGoalCount === "number") {
				data[teamKey].aggGoalCount += data[teamKey].overTimeGoalCount;
				return;
			}
			return;
		});

		return data;
	}, [eventInfo]);

	const isOT = typeof team1.overTimeGoalCount === "number" || typeof team2.overTimeGoalCount === "number";
	const isPS = typeof team1.penaltyShootoutGoalCount === "number" || typeof team2.penaltyShootoutGoalCount === "number";

	const isTeam1Win = team1.aggGoalCount > team2.aggGoalCount || team1.penaltyShootoutGoalCount > team2.penaltyShootoutGoalCount;

	const isTeam2Win = team2.aggGoalCount > team1.aggGoalCount || team2.penaltyShootoutGoalCount > team1.penaltyShootoutGoalCount;

	const isFirstMatchFinished = eventInfo.leg1.gameData !== null;

	const team1Name = team1.countryCode ? team1.countryCode : defaultText;
	const team2Name = team2.countryCode ? team2.countryCode : defaultText;

	return (
		<div className="vs--cup-result-structure-semi-line-inner">
			<div className="vs--cup-result-structure-block vs--flex vs--align-center vs--pl-16">
				<div className="vs--cup-result-structure-block-team vs--flex vs--flex-equal vs--align-center">
					<Flag code={team1.flagCode} gameType={gameType} />
					<span className="vs--title-white vs--font-normal vs--ml-4">{team1Name}</span>
				</div>

				{isFirstMatchFinished && (
					<div className="vs--cup-result-structure-block-num vs--cup-result-structure-block-border vs--flex vs--align-center vs--pl-16 vs--pr-16">
						<span className="vs--title-white vs--font-big">{team1.fullTimeGoalCount1}</span>
					</div>
				)}

				{isFirstMatchFinished && (
					<div className="vs--cup-result-structure-block-num vs--cup-result-structure-block-border vs--flex vs--align-center vs--pl-16 vs--pr-16">
						<span className="vs--title-white vs--font-big">{team1.fullTimeGoalCount2}</span>
					</div>
				)}

				<div className="vs--cup-result-structure-block-num vs--cup-result-structure-block-border vs--flex vs--align-center vs--pl-12 vs--pr-12">{isFirstMatchFinished && <span className={"vs--title-white vs--font-big" + (isTeam1Win ? " vs--text-green" : "")}>( {team1.aggGoalCount} )</span>}</div>
			</div>

			{isOT && !isPS ? (
				<Tooltip placement="left" title={<span className="vs--title-white vs--font-normal vs--font-regular">{t("common.overtime")}</span>}>
					<div className="vs--cup-result-structure-overtime vs--flex vs--align-center vs--justify-center">
						<span className="vs--font-small vs--font-bold">{t("common.OvertimeShort")}</span>
					</div>
				</Tooltip>
			) : isPS ? (
				<Tooltip placement="left" title={<span className="vs--title-white vs--font-normal vs--font-regular">{t("common.penaltyShootout")}</span>}>
					<div className="vs--cup-result-structure-overtime vs--flex vs--align-center vs--justify-center">
						<span className="vs--font-small vs--font-bold">{t("common.penaltyShoutoutShort")}</span>
					</div>
				</Tooltip>
			) : null}

			<div className="vs--cup-result-structure-block-second vs--flex vs--align-center vs--pl-16">
				<div className="vs--flex vs--flex-equal vs--align-center">
					<Flag code={team2.flagCode} gameType={gameType} />
					<span className="vs--title-white vs--font-normal vs--ml-4">{team2Name}</span>
				</div>

				{isFirstMatchFinished && (
					<div className="vs--cup-result-structure-block-num vs--cup-result-structure-block-border vs--flex vs--align-center vs--pl-16 vs--pr-16">
						<span className="vs--title-white vs--font-big">{team2.fullTimeGoalCount1}</span>
					</div>
				)}

				{isFirstMatchFinished && (
					<div className="vs--cup-result-structure-block-num vs--cup-result-structure-block-border vs--flex vs--align-center vs--pl-16 vs--pr-16">
						<span className="vs--title-white vs--font-big">{team2.fullTimeGoalCount2}</span>
					</div>
				)}

				<div className="vs--cup-result-structure-block-num vs--cup-result-structure-block-border vs--flex vs--align-center vs--pl-12 vs--pr-12">{isFirstMatchFinished && <span className={"vs--title-white vs--font-big" + (isTeam2Win ? " vs--text-green" : "")}>( {team2.aggGoalCount} )</span>}</div>
			</div>
		</div>
	);
};

TreeEventChampionsLeague.propTypes = {
	eventInfo: PropTypes.shape({ leg1: eventType, leg2: eventType }),
	defaultText: PropTypes.string
};

export default TreeEventChampionsLeague;
