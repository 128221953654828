import { useState, useEffect } from "react";
import PropTypes from "prop-types";

const BetSlipEmpty = ({ text, subtext, isQuickBetEnabled }) => {
	const [quickBet, setQuickBet] = useState(isQuickBetEnabled);
	const [fadeClass, setFadeClass] = useState("");

	useEffect(() => {
		if (quickBet !== isQuickBetEnabled) {
			setFadeClass("fade-out");
			const timer = setTimeout(() => {
				setQuickBet(isQuickBetEnabled);
				setFadeClass("fade-in");
			}, 100);
			return () => clearTimeout(timer);
		}
	}, [isQuickBetEnabled, quickBet]);

	return (
		<div className="vs--betslip-empty vs--fade-in vs--flex vs--flex-col vs--justify-center vs--align-center vs--pl-6 vs--pr-6">
			<div className={`vs--betslip-empty-icon vs--pl-6 vs--pr-8 vs--pt-12 vs--pb-4 ${fadeClass}`}>
				{quickBet ? (
					<>
						<i className="ic_quick-betslip vs--title-brand" />
						<div className="vs--betslip-empty-icon-thunder vs--flex vs--align-center vs--justify-center">
							<i className="ic_thunder vs--font-18" />
						</div>
					</>
				) : (
					<i className="ic_receipt" />
				)}
			</div>
			<span className={`vs--title-white vs--font-normal vs--font-medium vs--betslip-empty-text vs--mt-20 ${fadeClass}`}>{text}</span>
			<span className={`vs--title-gray-80 vs--font-normal vs--font-regular vs--betslip-empty-subtext vs--text-center vs--mb-4 ${fadeClass}`}>{subtext}</span>
		</div>
	);
};

BetSlipEmpty.propTypes = {
	text: PropTypes.string,
	subtext: PropTypes.string,
	isQuickBetEnabled: PropTypes.bool
};

export default BetSlipEmpty;
