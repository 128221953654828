const sliceName = "statistics";
const initialState = {
	loading: false,
	football: {
		team1: null,
		team2: null,
		countries: null,
		team1Statistics: null,
		team2Statistics: null,
		h2h: []
	},
	penalty: {
		team1: null,
		team2: null,
		countries: null,
		team1Statistics: null,
		team2Statistics: null,
		h2h: {}
	},
	racing: {
		participants: [],
		currentParticipant: null,
		statistics: null,
		currentEvent: null
	},
	season: {
		id: null,
		standings: [],
		structure: {},
		loading: false,
		isUpdateRequestSended: false,
		showSeasonLiveModal: null
	},
	quickStatistics: {
		state: {
			visible: false
		},
		loading: false,
		statistics: [],
		teamsData: null
	},
	kenoStatistics: [],
	luckySixStatistics: []
};

export { sliceName, initialState };
