import { Fragment, useState, useEffect } from "react";
import SwipeableModal from "components/ui/swipeableModal";

const useSwipeableModal = ({ children = null, title = null, closeElement = null, onClose = Function.prototype, actionComponents = null, visibilityState = false, ...modalProps } = {}) => {
	const [isVisible, setIsVisible] = useState(visibilityState);
	useEffect(() => setIsVisible(visibilityState), [visibilityState]);
	const component = !isVisible ? (
		<Fragment />
	) : (
		<SwipeableModal
			{...modalProps}
			title={title}
			onClose={(e) => {
				setIsVisible(false);
				onClose(e);
			}}
			closeElement={closeElement}
			actionComponents={actionComponents}
		>
			{children}
		</SwipeableModal>
	);
	return {
		isVisible,
		setIsVisible,
		component
	};
};

export default useSwipeableModal;
