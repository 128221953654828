import { useTranslation } from "react-i18next"

const ComboBoostMinOddFactor = ({ minOddFactor }) => {
	const { t } = useTranslation()
	return (
		<span className="vs--bet-slip-combo-boost-min-odd-factor vs--title-gray-80 vs--font-regular vs--font-small">
			{t("common.min")}&nbsp;{t("bet.odds")}:&nbsp;{minOddFactor}
		</span>
	)
}
export default ComboBoostMinOddFactor