import { getUrlVars } from "utils/common";
import { GLOBAL_VARIABLES } from "constants/contexts.constants";
import { IS_ANDROID, IS_BLACKBERRY, IS_EDGE, IS_IOS, IS_OPERA_MINI, IS_WINDOWS_MOBILE_1, IS_WINDOWS_MOBILE_2 } from "constants/regExp.constants";
import { MEDIA_FOR_IMMEDIATELY_CHANGE_TO_MOBILE_VIEW } from "constants/common.constants";

/** Check if device is ios
 * @function
 * @returns {boolean}
 */
export const isIOS = () => navigator.userAgent.match(IS_IOS) !== null;

/** Check if device is Mac
 * @function
 * @returns {boolean}
 */
export const isMac = () => navigator.userAgent.includes("Mac") && "ontouchend" in document;

/** Check if device is android
 * @function
 * @returns {boolean}
 */
export const isAndroid = () => navigator.userAgent.match(IS_ANDROID) !== null;

/** Check if device is android
 * @function
 * @returns {boolean}
 */
export const isBlackBerry = () => navigator.userAgent.match(IS_BLACKBERRY) !== null;

/** Check if device is opera mini
 * @function
 * @returns {boolean}
 */
export const isOperaMini = () => navigator.userAgent.match(IS_OPERA_MINI) !== null;

/** Check if device is windows mobile
 * @function
 * @returns {boolean}
 */
export const isWindowsMobile = () => navigator.userAgent.match(IS_WINDOWS_MOBILE_1) !== null || navigator.userAgent.match(IS_WINDOWS_MOBILE_2) !== null;

/** Check if browser is ie or edge
 * @function
 * @returns {boolean}
 */
export const isIEOrEdge = () => window.navigator.userAgent.indexOf("Trident/7") > -1 || IS_EDGE.test(navigator.userAgent);

/** Check if browser is IE
 * @function
 * @returns {boolean}
 */
export const isIE = () => window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

/** Check if browser is firefox
 * @function
 * @returns {boolean}
 */
export const isFirefox = () => window.navigator.userAgent.toLowerCase().indexOf("firefox") > -1;

/** Check if device is mobile
 * @function
 * @returns {boolean}
 */
export const isMobile = () => {
	return isAndroid() || isBlackBerry() || isIOS() || isOperaMini() || isWindowsMobile() || getUrlVars()["isMobile"] === "true";
};

/** Detect by all device checking functions
 * @function
 * @returns {boolean}
 */
export const detect = () => {
	return {
		isIOS: isIOS(),
		isMac: isMac(),
		isAndroid: isAndroid(),
		isBlackBerry: isBlackBerry(),
		isOperaMini: isOperaMini(),
		isWindowsMobile: isWindowsMobile(),
		isIEOrEdge: isIEOrEdge(),
		isIE: isIE(),
		isFirefox: isFirefox(),
		isMobile: isMobile()
	};
};

export const detectAll = () => ({
	...GLOBAL_VARIABLES,
	...detect(),
	isPreview: Boolean(getUrlVars()["isPreview"]),
	isDemo: Boolean(getUrlVars()["isDemo"]),
	isBuilderIntegration: Boolean(getUrlVars()["builder"])
});

export const detectAllWithMedias = (mediaEvent) => {
	const detectedValues = detectAll();
	const matchesMedia = mediaEvent ?? window.matchMedia(MEDIA_FOR_IMMEDIATELY_CHANGE_TO_MOBILE_VIEW);
	if (!detectedValues.isMobile && matchesMedia.matches) {
		detectedValues.isMobile = matchesMedia.matches;
	}
	return detectedValues;
};
