export const BONUS_STATES = {
	ALL: "All",
	ACTIVE: 1,
	FINISHED: 2
};

export const BONUS_TYPE = {
	FREEBET: 1,
	FREEAMOUNT: 2
};

export const BONUS_WIN_TYPE = {
	TOTAL: 1,
	PURE: 2
};
