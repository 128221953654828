import { createAsyncThunk } from "@reduxjs/toolkit";

import rulesService from "services/api/rules";

import { sliceName } from "./initialState";

export const gameRulesThunk = createAsyncThunk(`${sliceName}/gameRules`, async (gameType, { signal }) => {
	const response = await rulesService.rules(gameType, signal);

	return response.data.value;
});