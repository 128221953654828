import { Fragment, cloneElement, useRef, useEffect, useState, useId } from "react";
import PropTypes from "prop-types";

import PopupContent from "components/ui/tooltip/popupContent";
import useForkRef from "hooks/useForkRef";

import { isReactComponent, isReactFragment } from "utils/common";

const Tooltip = ({ title, children, overlayClassName, placement = "top", positionStrategy = "fixed", getPopupContainer = null, ...otherProps }) => {
	const [popupContainer, setPopupContainer] = useState(null);

	const id = useId();
	const triggerNodeRef = useRef(null);

	const childRefCallback = useForkRef(children?.ref, triggerNodeRef);

	useEffect(() => {
		if (typeof getPopupContainer === "function") {
			setPopupContainer(getPopupContainer(triggerNodeRef.current));
		}
	}, [getPopupContainer]);

	if (!isReactComponent(children) || isReactFragment(children)) {
		return children;
	}

	return (
		<Fragment>
			{cloneElement(
				children,
				Object.assign({}, children.props, {
					"data-tooltip-id": id,
					ref: childRefCallback
				})
			)}
			<PopupContent id={id} title={title} placement={placement} positionStrategy={positionStrategy} overlayClassName={overlayClassName} popupContainer={popupContainer} {...otherProps} />
		</Fragment>
	);
};

Tooltip.propTypes = {
	/** React Property, tooltip content */
	title: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
	/** React Property, tooltip trigger node */
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
	/** React Property, tooltip content placement */
	placement: PropTypes.oneOf(["top", "top-start", "top-end", "right", "right-start", "right-end", "bottom", "bottom-start", "bottom-end", "left", "left-start", "left-end"]),
	/** React Property, tooltip position strategy */
	positionStrategy: PropTypes.oneOf(["fixed", "absolute"]),
	/** React Property, tooltip content className */
	overlayClassName: PropTypes.string,
	/** React Property, callback where to set tooltip in html */
	getPopupContainer: PropTypes.func
};

export default Tooltip;
