import PropTypes from "prop-types";
import BetSlip from "./partials/betslip";
import PendingBets from "./partials/pendingBets";
import RightBarTabs from "./partials/tabs";

import AnimationMarker from "./partials/animationMarker";

import useAppSelector from "hooks/store/useAppSelector";

import { selectRightbarTab, selectPlaceBetSuccess } from "store/slices/betslip/selectors";
import { selectIsQuickBetEnabled } from "store/slices/auth/selectors";

import SessionStorageUtils from "utils/sessionStorage";

/* RightBar Component */
const RightBar = () => {
	const rightbarTab = useAppSelector(selectRightbarTab);
	const isQuickBetEnabled = useAppSelector(selectIsQuickBetEnabled);
	const success = useAppSelector(selectPlaceBetSuccess);

	return (
		<div className="vs--rightBar vs--ml-8">
			<RightBarTabs />
			{rightbarTab === 1 ? <BetSlip /> : <PendingBets />}
			{success && !isQuickBetEnabled && !SessionStorageUtils.get("repeatBet") && (
				<AnimationMarker />
			)}
		</div>
	);
};

export default RightBar;
