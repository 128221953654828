export const GLOBAL_VARIABLES = {
	isIOS: false,
	isMac: false,
	isAndroid: false,
	isBlackBerry: false,
	isOperaMini: false,
	isWindowsMobile: false,
	isIEOrEdge: false,
	isIE: false,
	isFirefox: false,
	isMobile: false,

	isPreview: false,
	isDemo: false,

	isBuilderIntegration: false
};
