import { createAsyncThunk } from "@reduxjs/toolkit";

import betService from "services/api/bet";

import { sliceName } from "./initialState";

export const betEventHistoryThunk = createAsyncThunk(`${sliceName}/betEventHistory`, async (id, { signal }) => {
	const response = await betService.eventHistory(id, signal);

	return response.data.value;
});