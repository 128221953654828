import {
	getSeasonMarketsThunk,
	getSeasonStandingsThunk,
	getSeasonStructureThunk
} from "./thunks";

import {
	setSeasonCurrentEventIdAction,
	showSeasonMarketsAction,
	updateSeasonMarketsAction,
	clearSeasonMarketsAction,
	setSeasonSkeletonHideAction,
	setSeasonSelectedMarketAction,
	setShowNextLiveAndUpcomingsAction,
	setSeasonResultModalMobileAction,
	clearSeasonStructureAndStandingsAction
} from "./actions";

import runMarketUtilsFunction from "utils/markets/run";

import { SELECTED_SEASON_GAMES, PREDICATE_MULTIPLIER_OF_SEASON_GAMES } from "constants/game.constants";

export const addCommonCases = (builder) => {
	builder.addCase(setSeasonSkeletonHideAction, (state, { payload }) => {
		state.hideSkeleton = payload.hide;
	});

	builder.addCase(setSeasonSelectedMarketAction, (state, { payload }) => {
		state.selectedMarket = payload.market;
	});

	builder.addCase(setSeasonResultModalMobileAction, (state, { payload }) => {
		state.showSeasonResultModalBySeasonId = payload.id;
	});

	builder.addCase(setShowNextLiveAndUpcomingsAction, (state, { payload }) => {
		state.showNextLiveAndUpcomings = payload.show;
	});

	builder.addCase(clearSeasonStructureAndStandingsAction, (state) => {
		state.structure = {};
		state.standings = [];
	});
}

export const addEventCases = (builder) => {
	builder.addCase(setSeasonCurrentEventIdAction, (state, { payload }) => {
		state.currentEventId = payload.id;
	});
}

export const addMarketsCasees = (builder) => {
	builder.addCase(getSeasonMarketsThunk.fulfilled, (state, { payload: dataForUpdate }) => {
		state.special = {
			...state.special,
			...dataForUpdate
		};
	});

	builder.addCase(updateSeasonMarketsAction, (state, { payload }) => {
		let markets = runMarketUtilsFunction("makeAllMarkets", [payload.season.markets, null, payload.rtps, payload.gameType], payload.gameType);
		markets = markets.fulltime;
		markets = markets.map((m) => ({
			...m,
			bets: m.bets.filter((b) => !b.disabled).sort((b1, b2) => (b1.factor < b2.factor ? -1 : 1))
		}));

		let seasonSelector = SELECTED_SEASON_GAMES.NONE;
		if (payload.index !== -1) {
			seasonSelector = payload.index < PREDICATE_MULTIPLIER_OF_SEASON_GAMES[payload.season.gameType] ? SELECTED_SEASON_GAMES.CURRENT : SELECTED_SEASON_GAMES.NEXT;
		} else {
			markets = [];
		}

		const dataForUpdate = {
			markets: {
				...state?.special?.markets ?? {},
				[seasonSelector]: markets
			}
		};

		if (seasonSelector === SELECTED_SEASON_GAMES.CURRENT) {
			dataForUpdate.seasonId = payload.season.id;
			dataForUpdate.status = payload.season.status;
		}

		if ((state?.special?.show ?? SELECTED_SEASON_GAMES.NONE) === SELECTED_SEASON_GAMES.CURRENT && markets.length === 0) {
			dataForUpdate.show = SELECTED_SEASON_GAMES.NONE;
		}

		state.special = {
			...state.special,
			...dataForUpdate
		};
	});

	builder.addCase(clearSeasonMarketsAction, (state) => {
		const data = {
			markets: {
				[SELECTED_SEASON_GAMES.NONE]: [],
				[SELECTED_SEASON_GAMES.CURRENT]: [],
				[SELECTED_SEASON_GAMES.NEXT]: []
			},
			show: SELECTED_SEASON_GAMES.NONE
		};

		state.special = {
			...state.special,
			...data
		}
	});

	builder.addCase(showSeasonMarketsAction, (state, { payload }) => {
		state.currentEventId = null
		state.special = {
			...state.special,
			show: payload.show
		}
	});
}

export const addStandingsCases = (builder) => {
	builder.addCase(getSeasonStandingsThunk.pending, (state) => {
		state.isLoading = true;
	});

	builder.addCase(getSeasonStandingsThunk.fulfilled, (state, { payload: data }) => {
		state.isLoading = false;
		state.standings = data;
	});

	builder.addCase(getSeasonStandingsThunk.rejected, (state) => {
		state.isLoading = false;
	});
}

export const addStructureCases = (builder) => {
	builder.addCase(getSeasonStructureThunk.pending, (state) => {
		state.isLoading = true;
	});

	builder.addCase(getSeasonStructureThunk.fulfilled, (state, { payload: data }) => {
		state.isLoading = false;
		state.structure = data;
	});

	builder.addCase(getSeasonStructureThunk.rejected, (state) => {
		state.isLoading = false;
	});
}