// Importing necessary functions and hooks
import useEvent from "hooks/useEvent";
import useLayoutUpdateEffect from "hooks/useLayoutUpdateEffect";
import useSafeState from "hooks/useSafeState";

// Defining the Updater type
const hasValue = (value) => {
	return value !== undefined;
};

/**
 * @typedef {Object} Option
 * @property {any} defaultValue
 * @property {any} value
 * @property {function} onChange
 * @property {function} postState
 */

/**
 * @callback useMergedState
 * @description Similar to `useSafeState` but will use props value if provided. Note that internal use rc-util `useSafeState` hook.
 * @param {any} defaultStateValue
 * @param {Option} option
 * @returns {[any, function]}
 */
const useMergedState = (defaultStateValue, option) => {
	const { defaultValue, value, onChange, postState } = option || {};

	// Initializing state
	const [innerValue, setInnerValue] = useSafeState(() => {
		if (hasValue(value)) {
			return value;
		} else if (hasValue(defaultValue)) {
			return typeof defaultValue === "function" ? defaultValue() : defaultValue;
		} else {
			return typeof defaultStateValue === "function" ? defaultStateValue() : defaultStateValue;
		}
	});

	// Merged value
	const mergedValue = value !== undefined ? value : innerValue;
	const postMergedValue = postState ? postState(mergedValue) : mergedValue;

	// Handling change
	const onChangeFn = useEvent(onChange);
	const [prevValue, setPrevValue] = useSafeState([mergedValue]);

	useLayoutUpdateEffect(() => {
		const prev = prevValue[0];
		if (innerValue !== prev) {
			onChangeFn(innerValue, prev);
		}
	}, [prevValue]);

	// Sync value back to `undefined` when it goes from controlled to uncontrolled
	useLayoutUpdateEffect(() => {
		if (!hasValue(value)) {
			setInnerValue(value);
		}
	}, [value]);

	// Update function
	const triggerChange = useEvent((updater, ignoreDestroy) => {
		setInnerValue(updater, ignoreDestroy);
		setPrevValue([mergedValue], ignoreDestroy);
	});

	return [postMergedValue, triggerChange];
};

export default useMergedState;
