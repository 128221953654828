import TopBarGamesProvider from "providers/TopBarGamesProvider";
import { useCallback } from "react";
import useAppDispatch from "hooks/store/useAppDispatch";
import { isNullish } from "utils/common";

import { setCurrentGameTypeAction } from "store/slices/game/actions";

const TopBarRulesHoc = (Component) => {
	const WrappedByTopBarRulesHocComponent = ({ selectedGame, setSelectedGame }) => {
		const dispatch = useAppDispatch();
		const setSelectedGameFromRedux = useCallback((gt) => dispatch(setCurrentGameTypeAction(gt)), [dispatch]);
		return (
			<TopBarGamesProvider showCountdown={false} selectedGame={selectedGame} setSelectedGame={isNullish(setSelectedGame) ? setSelectedGameFromRedux : setSelectedGame}>
				<Component />
			</TopBarGamesProvider>
		);
	};

	WrappedByTopBarRulesHocComponent.displayName = "WrappedByTopBarRulesHocComponent_" + Component.displayName;

	return WrappedByTopBarRulesHocComponent;
};

export default TopBarRulesHoc;
