import { useEffect } from "react";
import useEvent from "./useEvent";
import { isNullish } from "utils/common";

/**
 * Invoke a 'callback' every specified period of time by 'timePeriod'
 * @param {function} task function too run on each interval period
 * @param {number} timePeriod value of time period in milliseconds
 */

const useTaskScheduler = (task, timePeriod) => {
	// Wrapping a 'task' argument with a 'useEvent' to make the reference immutable
	const immutableTaskReference = useEvent(task);

	// Process assignment of 'task'
	useEffect(() => {
		// Assign a 'task' in an event loop with a 'timePeriod' to run at each interval of the period
		const intervalId = setInterval(immutableTaskReference, timePeriod);
		return () => {
			// Remove the 'task' from event loop
			clearInterval(intervalId);
		};
	}, [immutableTaskReference, timePeriod]);
};
export default useTaskScheduler;
