import { useCallback, useEffect } from "react";
import PropTypes from "prop-types";

import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";

import { clearSeasonStructureAndStandingsAction } from "store/slices/season/actions";
import { getSeasonStandingsThunk } from "store/slices/season/thunks";
import { selectSeasonStandings, selectIsSeasonLoading } from "store/slices/season/selectors";

import useGlobalVariables from "hooks/useGlobalVariables";

const LeagueGamesResultsHoc = (ReactComponent) => {
	const WrappedReactComponentOfLeagueGamesResultsHoc = ({ id, ...props }) => {
		const { isMobile } = useGlobalVariables();

		const standings = useAppSelector(selectSeasonStandings);
		const loading = useAppSelector(selectIsSeasonLoading);

		const dispatch = useAppDispatch();
		const getStandings = useCallback((id) => dispatch(getSeasonStandingsThunk(id)), [dispatch]);
		const clearStructureAndStandings = useCallback(() => dispatch(clearSeasonStructureAndStandingsAction()), [dispatch]);

		useEffect(() => {
			getStandings(id);
			return () => {
				clearStructureAndStandings();
			};
		}, [id, getStandings, clearStructureAndStandings]);

		return <ReactComponent standings={standings} loading={loading} isFromResults={!isMobile} id={id} {...props} />;
	};

	WrappedReactComponentOfLeagueGamesResultsHoc.propTypes = {
		id: PropTypes.number
	};

	return WrappedReactComponentOfLeagueGamesResultsHoc;
};

export default LeagueGamesResultsHoc;
