import PropTypes from "prop-types";
import useSwipeableModal from "hooks/useSwipeableModal";
import GameSelectionComponent from "./gameSelectionComponent";

const GamesModal = ({ isVisible, onClickCallback, title }) => {
	const { component } = useSwipeableModal({
		title,
		onClose: onClickCallback,
		visibilityState: isVisible,
		children: <GameSelectionComponent onClickCallback={onClickCallback} />
	});

	return component;
};

GamesModal.propTypes = {
	title: PropTypes.string,
	onClose: PropTypes.func,
	isVisible: PropTypes.bool
};

export default GamesModal;
