import { useEffect } from "react";
import useGlobalVariables from "./useGlobalVariables";
import useEvent from "./useEvent";

/** Function - hook
 * @function
 * @param { object } ref - reference to element
 * @param { func } callback - function to call when clicked outside
 * @memberOf useOutsideAlerter
 */
const useOutsideAlerter = ({ ref, callback, preventClassName, currentOutsideId }) => {
	const { isMobile } = useGlobalVariables();

	/** Function to detect if click is outside component
	 * @function
	 * @param { object } event - click event object
	 * @memberOf useOutsideAlerter
	 */
	const handleClickOutside = useEvent((event) => {
		if (typeof callback !== "function") {
			return;
		}
		if (!ref.current || ref.current.contains(event.target)) {
			return;
		}
		if(currentOutsideId && event.target.closest('[data-outside-id]')?.dataset.outsideId !== currentOutsideId) {
			return;
		}
		if (!preventClassName || !event.target.closest("." + preventClassName)) {
			callback();
		}
	});

	/** Add event listener */
	useEffect(() => {
		document.addEventListener(isMobile ? "touchend" : "mousedown", handleClickOutside);
		return () => {
			document.removeEventListener(isMobile ? "touchend" : "mousedown", handleClickOutside);
		};
	}, [handleClickOutside, isMobile, ref]);
};

export default useOutsideAlerter;
