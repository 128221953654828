import PropTypes from "prop-types";

export default PropTypes.shape({
	code: PropTypes.string,
	name: PropTypes.string,
	symbol: PropTypes.string,
	favoriteBets: PropTypes.arrayOf(PropTypes.string),
	decimalCount: PropTypes.number,
	singleMin: PropTypes.number,
	singleMax: PropTypes.number,
	multiMin: PropTypes.number,
	multiMax: PropTypes.number,
	multiPossibleWinMax: PropTypes.number,
	singlePossibleWinMax: PropTypes.number
});
