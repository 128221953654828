import { useState } from "react";
import PropTypes from "prop-types";
import { mergeClassNames } from "utils/common";
import { SWITCH_SIZES } from "constants/ui.constants";

const KEY_CODE = {
	LEFT: 37,
	RIGHT: 39,
};

const SWITCH_SIZE_CLASSES = {
	[SWITCH_SIZES.DEFAULT]: "vs--ui-switch-default-size",
	[SWITCH_SIZES.LARGE]: "vs--ui-switch-large-size",
	[SWITCH_SIZES.SMALL]: "vs--ui-switch-small-size",
};

/* eslint-disable react/prop-types */
const Switch = ({
	checkedBoxContent = null,
	unCheckedBoxContent = null,
	indicatorContent = null,
	defaultChecked = false,
	onChange,
	disabled = false,
	collapsed = false,
	className = null,
	checked,
	size = SWITCH_SIZES.DEFAULT,
	dir = "ltr",
	...restProps
}) => {
	const isControlledFromOutside = typeof checked === "boolean";

	const [internalCheckedState, setInternalCheckedState] = useState(() => {
		if (isControlledFromOutside) {
			return;
		}

		return defaultChecked;
	});

	const isChecked = isControlledFromOutside ? checked : internalCheckedState;

	const handleInternalChange = (newChecked, event) => {
		if (typeof onChange === "function") {
			onChange(newChecked, event);
		}

		if (isControlledFromOutside) {
			return;
		}

		setInternalCheckedState(newChecked);
	};

	const handleInternalClick = (e) => {
		handleInternalChange(!isChecked, e);
	};

	const handleInternalKeyDown = (e) => {
		if (e.which === KEY_CODE.LEFT) {
			handleInternalChange(false, e);
		} else if (e.which === KEY_CODE.RIGHT) {
			handleInternalChange(true, e);
		}
	};

	return (
		<button
			dir={dir}
			className={mergeClassNames(
				"vs--ui-switch",
				SWITCH_SIZE_CLASSES[size],
				isChecked && "vs--ui-switch-active",
				disabled && "vs--ui-switch-disabled",
				collapsed && "vs--ui-switch-collapsed",
				className
			)}
			onClick={handleInternalClick}
			type="button"
			role="switch"
			aria-checked={isChecked}
			disabled={disabled || collapsed}
			onKeyDown={handleInternalKeyDown}
			{...restProps}
		>
			<div className={mergeClassNames("vs--ui-switch-indicator", isChecked && "vs--ui-switch-indicator-active")}>
				{indicatorContent}
			</div>
			<div
				className={mergeClassNames(
					"vs--ui-switch-box vs--flex vs--align-center vs--justify-center vs--font-small vs--font-bold",
					isChecked && "vs--ui-switch-box-active"
				)}
			>
				{isChecked ? checkedBoxContent : unCheckedBoxContent}
			</div>
		</button>
	);
};

Switch.propTypes = {
	/** React property, the content next to the checked indicator */
	checkedBoxContent: PropTypes.node,
	/** React property, the content next to the unchecked indicator */
	unCheckedBoxContent: PropTypes.node,
	/** React property, content inside indicator */
	indicatorContent: PropTypes.node,
	/** React property, default checked state */
	defaultChecked: PropTypes.bool,
	/** React Property, checked state, will be controlled from Parent component */
	checked: PropTypes.bool,
	/** React Property, is switch disabled or not */
	disabled: PropTypes.bool,
	/** React Property, is switch collapsed/expanded */
	collapsed: PropTypes.bool,
	/** React Property, Switch className */
	className: PropTypes.string,
	/** React Property, switch size */
	size: PropTypes.string,
	/** React Property, direction rtl or ltr */
	dir: PropTypes.string,
	/** React property, Parent Component onChange callback */
	onChange: PropTypes.func
};

export default Switch;
