import { useMemo } from "react";
import PropTypes from "prop-types";
import TopBarGamesContext from "contexts/topBarGamesContext";

const TopBarGamesProvider = ({ showCountdown, selectedGame, setSelectedGame, children }) => {
	const contextValue = useMemo(() => ({ showCountdown, selectedGame, setSelectedGame }), [showCountdown, selectedGame, setSelectedGame]);

	return <TopBarGamesContext.Provider value={contextValue}>{children}</TopBarGamesContext.Provider>;
};

TopBarGamesProvider.propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.element])
};

export default TopBarGamesProvider;
