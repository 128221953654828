import { createAsyncThunk } from "@reduxjs/toolkit";

import betHistoryService from "services/api/betHistory";

import { sliceName } from "./initialState";

export const getHistoryThunk = createAsyncThunk(`${sliceName}/getHistory`, async (betslipId, { signal, getState }) => {
	const filters = { ...getState()[sliceName].filters };
	if (betslipId) {
		filters.betSlipId = betslipId;
	}

	const response = await betHistoryService.history(filters, signal);

	return response.data.value;
});

export const addHistoryThunk = createAsyncThunk(`${sliceName}/addHistory`, async (betslipId, { signal, getState }) => {
	const filters = { ...getState()[sliceName].filters };
	if (betslipId) {
		filters.betSlipId = betslipId;
	}

	const response = await betHistoryService.history(filters, signal);

	return response.data.value;
});