import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";
import useGlobalVariables from "hooks/useGlobalVariables";
import useEvent from "hooks/useEvent";

import useLoginScript from "hooks/auth/useLoginScript";

import { switchToRealModeThunk, updateSessionDetailsThunk } from "store/slices/auth/thunks";
import { selectIsAuthorized, selectIsAnonymous } from "store/slices/auth/selectors";
import { placeBetSuccessAction } from "store/slices/betslip/actions";

const usePlayForReal = () => {
	const dispatch = useAppDispatch();
	const isAuthorized = useAppSelector(selectIsAuthorized);
	const isAnonymous = useAppSelector(selectIsAnonymous);
	const globalVariables = useGlobalVariables();

	const runLoginScript = useLoginScript();

	const playForReal = useEvent(async () => {
		// TODO: should refactor AnimationMaker usage part to remove placeBetSuccessAction dispatch 
		dispatch(placeBetSuccessAction(false));

		try {
			if (!globalVariables.isPreview) {
				if (isAuthorized && !isAnonymous) {
					await dispatch(switchToRealModeThunk()).unwrap();
					await dispatch(updateSessionDetailsThunk(globalVariables)).unwrap();
				} else {
					runLoginScript();
				}
			}
		} catch(error) {
			console.log(error);
		}
	});

	return playForReal;
};

export default usePlayForReal;