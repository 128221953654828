import { createAsyncThunk } from "@reduxjs/toolkit";

import kenoService from "services/api/keno";

import { sliceName } from "./initialState";

export const getCurrentBetsThunk = createAsyncThunk(`${sliceName}/getCurrentBets`, async (_, { signal }) => {
	const response = await kenoService.currentBets(signal);

	return response.data.value;
});

export const getLastBetsThunk = createAsyncThunk(`${sliceName}/getLastBets`, async (_, { signal }) => {
	const response = await kenoService.lastBets(signal);

	return response.data.value;
});