import PropTypes from "prop-types";
import lastMatches from "./lastMatches.type";

export default PropTypes.shape({
	played: PropTypes.number,
	win: PropTypes.number,
	winPercent: PropTypes.number,
	highestWin: PropTypes.string,
	totalGoals: PropTypes.number,
	avgGoals: PropTypes.number,
	lastMatches,
	loaded: PropTypes.bool,
	lose: PropTypes.number,
	losePercent: PropTypes.number,
	draw: PropTypes.number,
	drawPercent: PropTypes.number
});
