import { useState, useMemo } from "react";

import useAppDispatch from "./store/useAppDispatch";
import useAppSelector from "./store/useAppSelector";
import useEvent from "./useEvent";

import { selectSessionGames, selectSessionCurrency, selectSessionCurrentBonus, selectIsSoundOn, selectIsQuickBetEnabled } from "store/slices/auth/selectors";
import { createSelectBetsOrQuickBets, selectBets, selectQuickBets, selectShowMobileQuickBetslip } from "store/slices/betslip/selectors";
import { selectIsBonusBetDisabled } from "store/slices/game/selectors";
import { selectUseBonus } from "store/slices/bonus/selectors";
import { clearAllTypeBetsAction, removeBetAction } from "store/slices/betslip/actions";
import { doBetThunk, placeQuickBetThunk } from "store/slices/betslip/thunks";

import { GAME_STATUSES, GAME_ACTIVITY_STATE } from "constants/game.constants";
import { useTranslation } from "react-i18next";
import useGlobalVariables from "hooks/useGlobalVariables";

import { buildPathToStaticFolderOfCDN, playAudio, makeCurrencyText } from "utils/common";
import vsToast from "utils/toast";
import { BONUS_TYPE } from "constants/bonus.constants";

const useBetHandlers = ({ market, group, eventInfo, animatedDivTarget = null }) => {
	const { t } = useTranslation();

	const globalVariables = useGlobalVariables();

	const { isMobile } = globalVariables;

	const games = useAppSelector(selectSessionGames);
	const currentBonus = useAppSelector(selectSessionCurrentBonus);
	const isSound = useAppSelector(selectIsSoundOn);
	const currency = useAppSelector(selectSessionCurrency);
	const isQuickBetEnabled = useAppSelector(selectIsQuickBetEnabled);
	const bets = useAppSelector(createSelectBetsOrQuickBets(isQuickBetEnabled));
	const isBonusBetDisabled = useAppSelector(selectIsBonusBetDisabled);
	const useBonus = useAppSelector(selectUseBonus);
	const isQuickBetslipVisible = useAppSelector(selectShowMobileQuickBetslip);

	const dispatch = useAppDispatch();

	const gameType = eventInfo?.gameType;

	const isBettingDisabled = useMemo(() => {
		const game = games.find((g) => g.type === gameType);
		return game?.state === GAME_ACTIVITY_STATE.CLOSE_FOR_BETTING;
	}, [games, gameType]);

	const [isPlaceBetLoading, setIsPlaceBetLoading] = useState(false);
	const [animationParams, setAnimationParams] = useState(null);

	const isFreeAmountBonus = useBonus && currentBonus?.bonusType === BONUS_TYPE.FREEAMOUNT;

	const getMinLimit = () => currency.singleMin ?? 0;
	const getMaxLimit = () => currency.singleMax ?? Infinity;
	const getMaxPossibleWin = () => currency.singlePossibleWinMax ?? Infinity;

	/** Function to check if bet is already in betslip bets
	 * @function
	 * @param {string} id
	 * @returns {boolean}
	 * @memberOf useBetHandlers
	 */
	const isBetSelected = useEvent((id, eventInfoArg = eventInfo) => {
		return bets.some((b) => b.betId === id && b.eventId === eventInfoArg.id);
	});

	/** Function to check if bet is disabled
	 * @function
	 * @param {object} bet
	 * @returns {boolean}
	 * @memberOf useBetHandlers
	 */
	const isBetDisabled = useEvent((bet, eventInfoArg = eventInfo) => {
		if (!bet) {
			return false;
		}

		return bet.disabled || isBettingDisabled || [GAME_STATUSES.CLOSE_FOR_BETTING, GAME_STATUSES.STARTED].includes(eventInfoArg?.status);
	});

	const checkIsThereFreeAmountBonusError = (stakeAmount) => {
		const totalStack = isQuickBetEnabled
			? stakeAmount
			: bets.reduce((acc, b) => {
					const stake = Number(b.stake);
					if (Number.isNaN(stake)) {
						return acc;
					}
					return acc + stake;
				}, 0);
		if (currentBonus.amount < totalStack) {
			return true;
		}
		const difference = currentBonus.amount - totalStack;
		return difference !== 0 && difference < (currency.singleMin ?? 0);
	};

	const hasBetStakeError = (bet) => {
		const isValueInRange = (Number(bet.stake) < (currency.singleMin ?? 0) || Number(bet.stake) > (currency.singleMax ?? Infinity)) && Number(bet.stake) !== 0;

		if (isFreeAmountBonus) {
			const retVal = checkIsThereFreeAmountBonusError(bet.stake);
			if (retVal) {
				return retVal;
			}
		}

		return isValueInRange;
	};

	const isBetExpired = (bet) => {
		return bet && bet.isExpired;
	};

	/** Function which checks if "place bet" button should be disabled
	 * @function
	 * @returns {boolean}
	 * @memberOf QuickBetslip
	 */
	const isPlaceBetDisabled = (bet) => {
		const numAmount = Number(bet.stake);
		return numAmount === 0 || Number.isNaN(numAmount) || hasBetStakeError(bet) || isBetExpired(bet);
	};

	const initBetAnimation = (initialRect) => {
		setAnimationParams({ initialRect, targetSelector: animatedDivTarget });
	};

	/** Function which fires on "place bet" button click
	 * @function
	 * @memberOf QuickBetslip
	 */
	const handlePlaceQuickBet = (bet, animationCb) => {
		setIsPlaceBetLoading(true);

		const onSuccess = () => {
			setIsPlaceBetLoading(false);
			animationCb();

			if (isMobile && isSound) {
				playAudio(buildPathToStaticFolderOfCDN("media/success.mp3"));
			}
		};

		const onError = (bet) => {
			setIsPlaceBetLoading(false);
			dispatch(removeBetAction({ key: bet.key, removeStake: false }));
		};

		if (isPlaceBetDisabled(bet)) {
			if (isFreeAmountBonus && checkIsThereFreeAmountBonusError(bet.stake)) {
				vsToast.error(t("bet.unallowedStack"));
			} else if (bet.stake < getMinLimit()) {
				vsToast.error(
					`${t("bet.minBetAmountIs")} ${makeCurrencyText(getMinLimit(), {
						currencyId: currency.currencyId ?? 0,
						decimalCount: currency.decimalCount ?? 0
					})}`
				);
			} else if (bet.stake > getMaxLimit()) {
				vsToast.error(
					`${t("bet.maxBetAmountIs")} ${makeCurrencyText(getMaxLimit(), {
						currencyId: currency.currencyId ?? 0,
						decimalCount: currency.decimalCount ?? 0
					})}`
				);
			} else {
				vsToast.error(`${t("bet.maxPosWinAmountIs")} ${makeCurrencyText(getMaxPossibleWin(), currency)}`);
			}

			onError(bet);
			return;
		}
		dispatch(
			placeQuickBetThunk({
				bet,
				onSuccess,
				onError
			})
		);
	};

	/** Function which fires on bet click
	 * @function
	 * @param {object} marketArg - market to bet
	 * @memberOf useBetHandlers
	 */
	const handleClick = useEvent((htmlEvent, marketArg, eventInfoArg = eventInfo) => {
		const isSelected = isBetSelected(marketArg.id);
		const isDisabled = isBetDisabled(marketArg);

		if (isBonusBetDisabled) {
			vsToast.error(t("bonus.betUnallowed"));
			return;
		}
		if (eventInfoArg.status !== GAME_STATUSES.NEW && eventInfoArg.status !== GAME_STATUSES.PREAMBLE_STARTED) return;

		const initialRect = htmlEvent.currentTarget.getBoundingClientRect();
		const animationCb = () => {
			if (animatedDivTarget === null) {
				return;
			}

			initBetAnimation(initialRect);
		};

		if (!isDisabled || isSelected) {
			if (isQuickBetslipVisible && isSelected) {
				dispatch(clearAllTypeBetsAction());
				return;
			}

			if (isQuickBetslipVisible && isQuickBetEnabled) {
				return;
			}

			if (isQuickBetEnabled && (animationParams !== null || isSelected || isPlaceBetLoading)) {
				return;
			}

			dispatch(
				doBetThunk({
					isQuickBet: isQuickBetEnabled,
					market: { ...marketArg, period: market?.period },
					group,
					eventInfo: eventInfoArg,
					stake: "",
					onSuccess: (bet) => {
						if (isQuickBetEnabled) {
							handlePlaceQuickBet(bet, animationCb);
						} else {
							animationCb();
						}
					},
					globalVariables
				})
			);
		}
	});

	return {
		isBetSelected,
		isBetDisabled,
		isPlaceBetLoading,
		isBettingDisabled,
		isBonusBetDisabled,
		animationParams,
		setAnimationParams,
		handleClick,
		handlePlaceQuickBet
	};
};

export default useBetHandlers;
