import { useRef, forwardRef, useImperativeHandle, useEffect } from "react";

import PropTypes from "prop-types";

import PerfectScrollbar from "react-perfect-scrollbar";

import { mergeClassNames } from "utils/common";
import useGlobalVariables from "hooks/useGlobalVariables";

/** ScrollBar component */
const ScrollBar = forwardRef(({ children, className = null, horizontal, vertical, containerRef = null, alwaysVisible = true, ...rest }, ref) => {
	const _scrollBarRef = useRef(null);
	const _scrollBarContainerRef = useRef(null);
	const { isMobile } = useGlobalVariables();

	useImperativeHandle(ref, () => ({
		update() {
			_scrollBarRef.current && _scrollBarRef.current.updateScroll();
		},

		scrollToTop() {
			if (_scrollBarContainerRef.current) {
				_scrollBarContainerRef.current.scrollTop = 0;
			}
		}
	}));

	useImperativeHandle(containerRef, () => _scrollBarContainerRef.current);

	useEffect(() => {
		setTimeout(() => _scrollBarRef.current && _scrollBarRef.current.updateScroll(), 200);
	}, []);

	if (isMobile) {
		return (
			<div className={className} style={{ overflow: "auto" }}>
				{children}
			</div>
		);
	}

	return (
		<PerfectScrollbar
			className={mergeClassNames(className, alwaysVisible && "vs--scrollbar-always-visible")}
			options={{
				suppressScrollX: !horizontal,
				suppressScrollY: !vertical,
				wheelPropagation: false
			}}
			containerRef={(ref) => {
				if (ref && !horizontal) {
					ref._getBoundingClientRect = ref.getBoundingClientRect;

					ref.getBoundingClientRect = () => {
						const original = ref._getBoundingClientRect();
						return { ...original, height: Math.round(original.height) };
					};
					_scrollBarContainerRef.current = ref;
				}
			}}
			ref={_scrollBarRef}
			{...rest}
		>
			{children}
		</PerfectScrollbar>
	);
});

/** MobileFilters propTypes
 * PropTypes
 */
ScrollBar.propTypes = {
	/** Content of scrollable block */
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	/** Box class name */
	className: PropTypes.string,
	/** Enable horizontal scrollbar */
	horizontal: PropTypes.bool,
	/** Enable vertical scrollbar */
	vertical: PropTypes.bool
};

export default ScrollBar;
