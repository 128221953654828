import { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TreeRound16 from "./treeRound16";
import TreeQuarterFinals from "./treeQuarterFinals";
import TreeSemiFinals from "./treeSemiFinals";
import TreeFinal from "./treeFinal";
import { getEventsQtyBySeasonalPeriods } from "utils/common";
import { SEASONAL_PERIODS, GAME_TYPE } from "constants/game.constants";

const TreeSimpleCup = ({ treeData, gameType }) => {
	const { t } = useTranslation();

	const getEventsInfoFromData = (data, index) => {
		const leg1 = data?.[0]?.data[index] ?? {};
		const leg2 = data?.[1]?.data[index] ?? {};
		return { leg1, leg2 };
	};

	const prepareForLoop = (data, roundName, callback = Function.prototype) => {
		const [leg1Info, leg2Info] = data;
		if (!leg1Info || !leg2Info) {
			return [];
		}
		if (!leg1Info.data || !leg2Info.data) {
			return [];
		}
		const len = getEventsQtyBySeasonalPeriods(gameType, roundName);
		const arr = [];
		for (let i = 1; i < len; i++) {
			arr.push(callback(i));
		}
		return arr;
	};

	return (
		<Fragment>
			<TreeRound16 title={t(`common.${SEASONAL_PERIODS.ROUND_16}`) + " 16"} data={treeData[SEASONAL_PERIODS.ROUND_16]} roundName={SEASONAL_PERIODS.ROUND_16} getEventsInfoFromData={getEventsInfoFromData} prepareForLoop={prepareForLoop} />
			<TreeQuarterFinals title={t(`common.${SEASONAL_PERIODS.QUARTER_FINALS}`)} data={treeData[SEASONAL_PERIODS.QUARTER_FINALS]} roundName={SEASONAL_PERIODS.QUARTER_FINALS} getEventsInfoFromData={getEventsInfoFromData} prepareForLoop={prepareForLoop} />
			<TreeSemiFinals title={t(`common.${SEASONAL_PERIODS.SEMI_FINALS}`)} data={treeData[SEASONAL_PERIODS.SEMI_FINALS]} roundName={SEASONAL_PERIODS.SEMI_FINALS} getEventsInfoFromData={getEventsInfoFromData} prepareForLoop={prepareForLoop} />
			<TreeFinal title={t(`common.${SEASONAL_PERIODS.FINAL}`)} data={treeData[SEASONAL_PERIODS.FINAL]} getEventsInfoFromData={getEventsInfoFromData} />
		</Fragment>
	);
};

TreeSimpleCup.propTypes = {
	treeData: PropTypes.object,
	roundName: PropTypes.string,
	gameType: PropTypes.oneOf(Object.values(GAME_TYPE))
};

export default TreeSimpleCup;
