import { createSlice } from "@reduxjs/toolkit";

import {
	addCurrentBetsCasees,
	addLastBetsCases,
	addBallsCases
} from "./extraReducers";
import { initialState, sliceName } from "./initialState";

const kenoSlice = createSlice({
	name: sliceName,
	initialState: initialState,
	extraReducers: (builder) => {
		addCurrentBetsCasees(builder);
		addLastBetsCases(builder);
		addBallsCases(builder);
	}
});

export const kenoSliceName = sliceName;
export const kenoSliceReducer = kenoSlice.reducer;