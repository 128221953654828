import { useRef } from 'react';
import { mergeClassNames } from 'utils/common';

const BetTicketBorder = ({ className, svgProps }) => {
	const svgContainerRef = useRef(null);

	return (
		<div
			className={mergeClassNames("vs--ui-bet-ticket-border", className)}
			ref={svgContainerRef}
			style={{...svgProps.style}}
		/>
	);
};

export default BetTicketBorder;