import { COMBO_BOOST_STEP_FORM_NAMES, COMBO_BOOST_STEPS } from "constants/betslip.constants";

const sliceName = "auth";
const initialState = {
	sessionLoaded: false,
	sessionFailed: false,
	isSessionUpdating: false,
	session: {
		games: [],
		settings: {},
		bonuses: [],
		surveys: [],
		comboBoostSettings: {
			isComboBoostEnabled: false,
			minOddFactor: 0,
			boostFactors: {
				[COMBO_BOOST_STEP_FORM_NAMES[COMBO_BOOST_STEPS.FOUR_BETS]]: 0,
				[COMBO_BOOST_STEP_FORM_NAMES[COMBO_BOOST_STEPS.FIVE_BETS]]: 0,
				[COMBO_BOOST_STEP_FORM_NAMES[COMBO_BOOST_STEPS.SIX_BETS]]: 0,
				[COMBO_BOOST_STEP_FORM_NAMES[COMBO_BOOST_STEPS.SEVEN_BETS]]: 0,
				[COMBO_BOOST_STEP_FORM_NAMES[COMBO_BOOST_STEPS.EIGHT_BETS]]: 0,
				[COMBO_BOOST_STEP_FORM_NAMES[COMBO_BOOST_STEPS.NINE_BETS]]: 0,
				[COMBO_BOOST_STEP_FORM_NAMES[COMBO_BOOST_STEPS.TEN_BETS]]: 0
			}
		}
	},
	player: {},
	sessionId: null,
	switchingToRealMode: false,
	translationsLoaded: false
}

export { sliceName, initialState };