import { useMemo } from "react";
import { CollapseContext, PanelContext } from "contexts/collapse";

const CollapseProvider = ({
	children,
	expandAll,
	expandedRowIds,
	handleCollapseChange
}) => {
	const value = useMemo(() => {
		return { expandAll, expandedRowIds, handleCollapseChange };
	}, [expandAll, expandedRowIds, handleCollapseChange]);

	return <CollapseContext.Provider value={value}>{children}</CollapseContext.Provider>;
}

const PanelProvider = ({
	style,
	wrapperClassName, 
	footerClassName,
	children, 
	key,
	rowId,
	showArrow,
	disableAction,
	onChange,
	header,
	footer
}) => {
	const value = useMemo(() => {
		return { style, wrapperClassName, footerClassName, key, rowId, showArrow, disableAction, onChange, header, footer };
	}, [style, wrapperClassName, footerClassName, key, rowId, showArrow, disableAction, onChange, header, footer]);

	return <PanelContext.Provider value={value}>{children}</PanelContext.Provider>;
};

export { CollapseProvider, PanelProvider };