import Paths from "constants/path.constants";

export const MENU_ITEMS = [
	{
		id: "home",
		path: Paths.HOME,
		title: "menu.home"
	},
	{
		id: "results",
		path: Paths.RESULTS,
		title: "menu.results",
		pathsToSelect: [Paths.EVENT]
	},
	{
		id: "statistics",
		path: Paths.STATISTICS,
		title: "menu.statistics"
	},
	{
		id: "history",
		path: Paths.HISTORY,
		title: "menu.betsHistory"
	},
	{
		id: "bonus",
		path: Paths.BONUS,
		title: "menu.bonusHistory"
	}
];
