import { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { mergeClassNames } from "utils/common";

import { VIRTUAL_KEYBOARD_KEYS } from "constants/keyboard.constants";

const KEYBOARD_KEYS = Object.values(VIRTUAL_KEYBOARD_KEYS);

/** Keyboard Component */
const Keyboard = forwardRef(({
	className,
	focusedInputId = null,
	isInteger,
	value,
	onChange = Function.prototype,
	visible = false,
	toggleVisibility = Function.prototype,
	typedKeysMaxLength = 12,
	...rest
}, ref) => {
	const { t } = useTranslation();

	const [isFocusedOnSameInput, setIsFocusedOnSameInput] = useState(false);
	const [isVisible, setIsVisible] = useState(visible);

	const toggleKeyboardVisibilty = (visible) => {
		setIsVisible(visible);
		toggleVisibility(visible);
	}

	/** Function which fires on keyboard buttons click
	 * @function
	 * @param {string} key - button key
	 * @memberOf Keyboard
	 */
	const handleKeyboardClick = (key) => {
		const amount = value.toString();
		switch (key) {
			case VIRTUAL_KEYBOARD_KEYS.OK:
				onChange(amount, key);
				toggleKeyboardVisibilty(false);
				break;
			case VIRTUAL_KEYBOARD_KEYS.BACK:
				if (amount.length > 1) {
					let newAmount = amount.substring(0, amount.length - 1);
					if (newAmount.endsWith(VIRTUAL_KEYBOARD_KEYS.DOT)) newAmount = newAmount.substring(0, newAmount.length - 1);
					onChange(newAmount, key);
				} else {
					onChange("", key);
				}
				break;
			default:
				if (isFocusedOnSameInput) {
					if (
						(amount === "" && key === VIRTUAL_KEYBOARD_KEYS.TWO_ZERO) ||
						(amount === VIRTUAL_KEYBOARD_KEYS.ZERO && key !== VIRTUAL_KEYBOARD_KEYS.DOT)
					) {
						break;
					}
					if (amount !== "") {
						if (!amount.includes(VIRTUAL_KEYBOARD_KEYS.DOT) || key !== VIRTUAL_KEYBOARD_KEYS.DOT) {
							if ((!isInteger || key !== VIRTUAL_KEYBOARD_KEYS.DOT) && amount.length < typedKeysMaxLength) {
								onChange(amount + key);
							}
						}
					} else if (key !== VIRTUAL_KEYBOARD_KEYS.DOT && (key !== VIRTUAL_KEYBOARD_KEYS.ZERO || key !== VIRTUAL_KEYBOARD_KEYS.TWO_ZERO)) {
						onChange(key);
					} else if (key === VIRTUAL_KEYBOARD_KEYS.DOT) {
						onChange(`${VIRTUAL_KEYBOARD_KEYS.ZERO}${VIRTUAL_KEYBOARD_KEYS.DOT}`);
					}
				} else {
					if ([VIRTUAL_KEYBOARD_KEYS.ZERO, VIRTUAL_KEYBOARD_KEYS.TWO_ZERO].includes(key)) {
						onChange("");
					} else if (key === VIRTUAL_KEYBOARD_KEYS.DOT) {
						onChange(`${VIRTUAL_KEYBOARD_KEYS.ZERO}${VIRTUAL_KEYBOARD_KEYS.DOT}`);
					} else {
						onChange(key);
					}

					setIsFocusedOnSameInput(true);
				}
				break;
		}
	};

	/** Update isVisible local state on visible prop change */
	useEffect(() => {
		setIsVisible(visible);
	}, [visible]);

	/** Update isFocusedOnSameInput local state on focusedInputId change */
	useEffect(() => {
		setIsFocusedOnSameInput(false);
	}, [focusedInputId]);

	return (
		<div
			ref={ref}
			className={mergeClassNames(
				"vs--keyboard",
				!isVisible && "vs--keyboard-hidden",
				className
			)}
			{...rest}
		>
			{
				KEYBOARD_KEYS.map(key => (
					<div
						className={mergeClassNames(
							"vs--flex vs--align-center vs--justify-center vs--cursor-pointer vs--text-center",
							"vs--keyboard-key",
							`vs--keyboard-key-${key}`,
							isInteger && key === VIRTUAL_KEYBOARD_KEYS.DOT && "vs--keyboard-key-disabled"
						)}
						key={key}
						onClick={() => handleKeyboardClick(key)}
					>
						<span className="vs--title-white vs--font-medium vs--font-normal">{
							key !== VIRTUAL_KEYBOARD_KEYS.BACK ? key : <i className="ic_backspace vs--font-bigest" />
						}</span>
					</div>
				))
			}
		</div>
	);
});

/** Keyboard propTypes
 * PropTypes
 */
Keyboard.propTypes = {
	/** React property, main className  */
	className: PropTypes.string,
	/** React prop, unique id based focused input */
	focusedInputId: PropTypes.string,
	/** Funtion to call on change */
	onChange: PropTypes.func,
	/** Funtion to call to show/hide keyboard */
	toggleVisibility: PropTypes.func,
	/** Visible state of keyboard */
	visible: PropTypes.bool,
	/** If true will allow only whole numbers */
	isInteger: PropTypes.bool,
	/** Current value of input */
	value: PropTypes.string,
	/** Max valid typed keys length */
	typedKeysMaxLength: PropTypes.number
}

export default Keyboard;
