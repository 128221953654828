import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TreeEventSimpleCup from "../treeSimpleCup/treeEventSimpleCup";
import eventType from "types/event.type";

const TreeFinal = ({ title, data, getEventsInfoFromData }) => {
	const { t } = useTranslation();
	const defaultText = `${t("common.winnerOf")} ${t("common.semiFinals")}`;

	return (
		<div className="vs--cup-result-structure-final vs--flex vs--flex-col vs--justify-around">
			<div className="vs--cup-result-structure-final-line">
				<div className="vs--cup-result-title">
					<span className="vs--font-big vs--font-bold">{title}</span>
				</div>
				<div className="vs--mt-16 vs--cup-result-structure-line">
					<TreeEventSimpleCup event={getEventsInfoFromData(data, 0).leg1} defaultText={defaultText} />
				</div>
			</div>
		</div>
	);
};

TreeFinal.propTypes = {
	title: PropTypes.string,
	data: PropTypes.arrayOf(eventType),
	getEventsInfoFromData: PropTypes.func
};

export default TreeFinal;
