import { createAction } from "@reduxjs/toolkit";

import { sliceName } from "./initialState";

import { generateBetKey } from "utils/bets";

export const setBetslipAction = createAction(`${sliceName}/setBetslip`, (betslip) => {
	return {
		payload: { betslip }
	};
});

export const setStakeAction = createAction(`${sliceName}/setStake`, (stake) => {
	return {
		payload: { stake }
	};
});

export const updateBetStakeAction = createAction(`${sliceName}/updateBetStake`, (stake, key) => {
	return {
		payload: { stake, key }
	};
});

export const setModeAction = createAction(`${sliceName}/setMode`, (mode) => {
	return {
		payload: { mode }
	};
});

export const setStakeModeAction = createAction(`${sliceName}/setStakeMode`, (mode) => {
	return {
		payload: { mode }
	};
});

export const setRigthbarTabAction = createAction(`${sliceName}/setRigthbarTab`, (tab) => {
	return {
		payload: { tab }
	};
});

export const setShowKeyboardAction = createAction(`${sliceName}/setShowKeyboard`, (show) => {
	return {
		payload: { show }
	};
});

export const setShowMobileBetSlipsModalAction = createAction(`${sliceName}/setShowMobileBetSlipsModal`, (show) => {
	return {
		payload: { show }
	};
});

export const setShowMobileQuickBetSlipAction = createAction(`${sliceName}/setShowMobileQuickBetSlips`, (show) => {
	return {
		payload: { show }
	};
});

export const updateMatchBetsAction = createAction(`${sliceName}/updateMatchBets`, (event) => {
	return {
		payload: { event }
	};
});

export const addBetAction = createAction(`${sliceName}/addBet`, (bet, isQuickBet = false) => {
	return {
		payload: {
			isQuickBet,
			bet: {
				...bet,
				key: bet.key ?? generateBetKey(bet)
			}
		}
	};
});

export const addBetslipResultAction = createAction(`${sliceName}/addBetslipResult`, (data) => {
	return {
		payload: { data }
	};
});

export const clearBetslipResultsAction = createAction(`${sliceName}/clearBetslipResults`);

export const removeBetAction = createAction(`${sliceName}/removeBet`, ({
	isQuickBet = false,
	key = null,
	betId,
	eventId,
	removeStake = true
}) => {
	return {
		payload: { key, betId, eventId, removeStake, isQuickBet }
	};
});

export const removePendingAction = createAction(`${sliceName}/removePending`, (pending) => {
	return {
		payload: { pending }
	};
});

export const removePendingBetAction = createAction(`${sliceName}/removePendingBet`, (bet) => {
	return {
		payload: { bet }
	};
});

export const updatePendingAction = createAction(`${sliceName}/updatePending`, (pending) => {
	return {
		payload: { pending }
	};
});

export const updatePendingBetAction = createAction(`${sliceName}/updatePendingBet`, (bet) => {
	return {
		payload: { bet }
	};
});

export const clearBetsAction = createAction(`${sliceName}/clearBets`, (isQuickBet = false) => {
	return {
		payload: { isQuickBet }
	};
});

export const clearAllTypeBetsAction = createAction(`${sliceName}/clearAllTypeBets`);

export const clearHiddenBetsAction = createAction(`${sliceName}/clearHiddenBets`);

export const placeBetSuccessAction = createAction(`${sliceName}/placeBetSuccess`, (success) => {
	return {
		payload: { success }
	};
});