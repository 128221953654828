export const CLIENT_API = {
	LOGIN: "rgs-login",
	BACK_TO_HOME: "rgs-backToHome",
	GAME: "rgs-game",
	BALANCE: "rgs-balance",
	SETTINGS: "rgs-settings",
	EVENT: "rgs-round",
	BET: "rgs-bet",
	WIN: "rgs-win",
	RULES: "rgs-rules",
	HEIGHT_CHANGE: "rgs-height-change",
	FIT_FULL_HEIGHT: "rgs-fit-full-height",
}
