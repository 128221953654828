import { SELECTED_SEASON_GAMES } from "constants/game.constants";

const sliceName = "season";
const initialState = {
	current: null,
	selectedMarket: "Winner3Way",
	special: {
		markets: {
			[SELECTED_SEASON_GAMES.NONE]: [],
			[SELECTED_SEASON_GAMES.CURRENT]: [],
			[SELECTED_SEASON_GAMES.NEXT]: []
		},
		show: SELECTED_SEASON_GAMES.NONE
	},
	structure: {},
	standings: [],
	showSeasonResultModalBySeasonId: null,
	isLoading: false,
	hideSkeleton: false,
	showNextLiveAndUpcomings: false
}

export { sliceName, initialState };