import { PanelProvider } from "providers/collapse";
import CollapseRenderer from "components/ui/collapse/collapseRenderer";

const PanelWrapper = ({
	index,
	style,
	wrapperClassName,
	footerClassName,
	rowId,
	showArrow,
	disableAction,
	header,
	footer,
	children,
	expandIconPosition,
	onChange,
	handleCollapseChange,
	expandIconRenderer
}) => {

	return (
		<PanelProvider
			style={style}
			wrapperClassName={wrapperClassName}
			footerClassName={footerClassName}
			rowId={rowId}
			showArrow={showArrow}
			disableAction={disableAction}
			onChange={onChange}
			header={header}
			footer={footer}
		>
			<CollapseRenderer
				index={index}
				expandIconPosition={expandIconPosition}
				expandIconRenderer={expandIconRenderer}
			>
				{children}
			</CollapseRenderer>
		</PanelProvider>
	)
};

export default PanelWrapper;