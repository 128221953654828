import { useState, useEffect, useRef, Fragment } from "react";
import { useTranslation } from "react-i18next";

import MobileSingleBet from 'components/rightBar/partials/betslip/mobileSingleBet';
import BetButtons from "components/rightBar/partials/betslip/stake/betButtons";
import FavoriteBets from "components/rightBar/partials/betslip/stake/favoriteBets";
import Keyboard from "components/ui/keyboard";
import Switch from "../switch";

import useAppSelector from "hooks/store/useAppSelector";
import useAppDispatch from "hooks/store/useAppDispatch";
import useDocumentListener from 'hooks/useDocumentListener';
import useBetTicketBorderSvgProps from "hooks/betslip/useBetTicketBorderSvgProps";
import useDelayedRender from "hooks/useDelayedRender";

import { saveSettingsThunk } from "store/slices/auth/thunks";
import {
	selectIsDemoSession,
	selectIsQuickBetEnabled,
	selectIsSoundOn,
	selectQuickBetAmount,
	selectSessionCurrency,
	selectSessionCurrentBonus,
	selectSessionProjectId
} from "store/slices/auth/selectors";
import { selectUseBonus } from "store/slices/bonus/selectors";
import { selectCurrentGameType } from "store/slices/game/selectors";
import { 
	selectBets, 
	selectBetslipShowKeyboard, 
	selectBetslipStake, 
	selectIsPlaceBetLoading, 
	selectQuickBets,
	selectShowMobileQuickBetslip 
} from "store/slices/betslip/selectors";
import { placeBetThunk, placeQuickBetThunk } from "store/slices/betslip/thunks";
import {
	clearBetsAction,
	clearAllTypeBetsAction,
	updateBetStakeAction,
	setShowKeyboardAction,
	setShowMobileQuickBetSlipAction,
	addBetAction,
} from "store/slices/betslip/actions";

import { buildPathToStaticFolderOfCDN, isNullish, mergeClassNames, numberWithSpaces, playAudio } from "utils/common";
import LocalStorageUtils from "utils/localStorage";
import { getBetEventName } from "utils/bets";
import useGlobalVariables from "hooks/useGlobalVariables";
import useUpdateEffect from "hooks/useUpdateEffect";

import { BONUS_TYPE } from "constants/bonus.constants";
import { GAME_TYPE, GAME_TYPE_ICON_MAPPER } from "constants/game.constants";
import { BETSLIP_KEEP_FOCUSED_STAKE_DATA_ATTRS } from "constants/betslip.constants";
import { SWITCH_SIZES } from "constants/ui.constants";
import { VIRTUAL_KEYBOARD_KEYS } from "constants/keyboard.constants";

const UNMOUNT_DELAY = 300;

/** Mobile Quick Betslip Component */
const QuickBetslip = () => {
	const { t } = useTranslation();
	const projectId = useAppSelector(selectSessionProjectId);
	const isDemo = useAppSelector(selectIsDemoSession);
	const useBonus = useAppSelector(selectUseBonus);
	const currentBonus = useAppSelector(selectSessionCurrentBonus);
	const isSound = useAppSelector(selectIsSoundOn);
	const currentGameType = useAppSelector(selectCurrentGameType);
	const stake = useAppSelector(selectBetslipStake);
	const currency = useAppSelector(selectSessionCurrency);
	const showKeyboard = useAppSelector(selectBetslipShowKeyboard);
	const quickBets = useAppSelector(selectQuickBets);
	const isPlaceBetLoading = useAppSelector(selectIsPlaceBetLoading);
	const isQuickBetEnabled = useAppSelector(selectIsQuickBetEnabled);
	const quickBetAmount = useAppSelector(selectQuickBetAmount);
	const bets = useAppSelector(selectBets);
	const isQuickBetslipVisible = useAppSelector(selectShowMobileQuickBetslip);
	const { isMobile } = useGlobalVariables();

	const borderSvgStyle = useBetTicketBorderSvgProps("--vs--bg-color-base");

	const appContainerRef = useRef(null);
	const betsCountRef = useRef(0);
	const isQuickBetEnabledRef = useRef(isQuickBetEnabled);
	isQuickBetEnabledRef.current = isQuickBetEnabled;
	const isQuickBetToggleOnRef = useRef(false);

	const { mounted, rendered } = useDelayedRender(
		isQuickBetslipVisible,
		{
			exitDelay: UNMOUNT_DELAY
		}
	);

	const dispatch = useAppDispatch();

	const isFreeAmountBonus = useBonus && currentBonus.bonusType === BONUS_TYPE.FREEAMOUNT;
	const isFreeBetBonus = useBonus && currentBonus.bonusType === BONUS_TYPE.FREEBET;
	const readOnly = useBonus && !isFreeAmountBonus;
	const isBetExpired = bets[0] && bets[0].isExpired;

	const setShowKeyboard = (show) => dispatch(setShowKeyboardAction(show));

	const getInitialAmount = () => {
		if (isFreeBetBonus) {
			return currentBonus.amount ?? 0;
		}
		const initial = LocalStorageUtils.get("vs__quick_betslip_amount_" + projectId);
		if (initial) return initial.toString();
		let favBets = currency.favoriteBets ?? [];
		favBets = favBets.filter((fav) => fav !== "" && fav !== null && !fav.startsWith("x") && !isNaN(fav)).map((fav) => Number(fav));
		return favBets.length > 0 ? Math.min.apply(null, favBets).toString() : "";
	};

	const [amount, setAmount] = useState(getInitialAmount());

	const checkIsThereFreeAmountBonusError = (value) => {
		const val = isQuickBetEnabledRef.current && !isNullish(value) ? Number(value) : null;

		const totalStack = val ?? bets.reduce((acc, b) => { const stake = Number(b.stake); if (Number.isNaN(stake)) { return acc } return acc + stake }, 0)
		if (currentBonus.amount < totalStack) { return true }
		const difference = currentBonus.amount - totalStack
		return difference !== 0 && difference < (currency.singleMin ?? 0)
	}

	const toggleQuickBet = (value) => {
		isQuickBetToggleOnRef.current = value;
		dispatch(saveSettingsThunk({ isQuickBet: value }));

		if(isQuickBetToggleOnRef.current) {
			const quickBet = quickBets.find((b) => b.key === bets[0].key);

			if(quickBet) {
				return;
			}

			dispatch(addBetAction(bets[0], isQuickBetToggleOnRef.current));
		} else {
			dispatch(clearBetsAction(true));
		}
	};

	const hasError = (value) => {
		const val = isNullish(value) ? amount : value;

		const isValueOutsideRange = (Number(val) < currency.singleMin ||
			Number(val) > (currency.singleMax ?? Infinity));

		if (isFreeAmountBonus) {
			const retVal = checkIsThereFreeAmountBonusError(val);
			if (retVal) {
				return retVal;
			}
		}

		return isValueOutsideRange;
	};

	const onKeyboardKeyClick = (value, pressedKey) => {
		if (pressedKey === VIRTUAL_KEYBOARD_KEYS.OK) {
			if (!isQuickBetEnabled || isDemo) {
				return;
			}

			if (!hasError(value)) {
				dispatch(saveSettingsThunk({ quickBetAmount: value }));
			}
		}

		setAmount(value);
	};

	const isPlaceBetDisabled = () => {
		const numAmount = Number(amount);
		return (numAmount === 0 || Number.isNaN(numAmount) || hasError() || isBetExpired || isPlaceBetLoading)
	};

	const handlePlaceBet = () => {
		if (isPlaceBetDisabled()) {
			return;
		}

		if (isQuickBetToggleOnRef.current) {
			dispatch(placeQuickBetThunk({
				isQuickBetslipOn: true,
				bet: bets[0],
				onSuccess: () => {
					if (isMobile && isSound) {
						playAudio(buildPathToStaticFolderOfCDN("media/success.mp3"));
					}
				}
			}));

			return;
		}

		dispatch(placeBetThunk(() => {
			if (isMobile && isSound) {
				playAudio(buildPathToStaticFolderOfCDN("media/success.mp3"));
			}
		}));
	};

	useEffect(() => {
		if(isQuickBetEnabled) {
			setAmount(quickBetAmount);
		}
	}, [isQuickBetEnabled]);

	/** Keep previous amount in mamory, to set as defualt next time when quick betslip opens */
	useEffect(() => {
		LocalStorageUtils.set("vs__quick_betslip_amount_" + projectId, isFreeBetBonus ? (currentBonus.amount ?? 0) : amount);
	}, [amount, isFreeBetBonus]);

	/** Open quick bet when single bet added */
	useEffect(() => {
		const isVisible = (!isQuickBetToggleOnRef.current || !isQuickBetEnabledRef.current) && betsCountRef.current === 0 && bets.length === 1;
		dispatch(setShowMobileQuickBetSlipAction(isVisible));
		betsCountRef.current = bets.length;
	}, [bets.length]);

	/** Reset initial value when opening quick bet*/
	useEffect(() => {
		if (!isQuickBetslipVisible) {
			setAmount(getInitialAmount());
		}

		if (readOnly) {
			setShowKeyboard(false);
			setAmount(isFreeBetBonus ? getInitialAmount() : numberWithSpaces(stake));
		} else {
			setAmount(getInitialAmount());
		}
	}, [isQuickBetslipVisible, readOnly, isFreeBetBonus]);

	/** Update bet stake in redux when amount change */
	useEffect(() => {
		if (!isFreeBetBonus && isQuickBetslipVisible) {
			dispatch(updateBetStakeAction(amount.toString(), bets[0]?.key));
		}
	}, [amount, isQuickBetslipVisible, isFreeBetBonus]);

	/** Update bet stake in redux when amount change */
	useEffect(() => {
		if (!isQuickBetslipVisible) {
			if (!isFreeBetBonus && !readOnly) {
				dispatch(updateBetStakeAction("", bets[bets.length - 1]?.key));
			}
			setShowKeyboard(false);
		}
	}, [isQuickBetslipVisible, isFreeBetBonus]);

	// Unchangeable referance of click handler, keep/close Keyboard on outside click
	useDocumentListener('click', event => {
		if (event?.target) {
			const hasDataAttribute = (element, attr) => {
				return element.closest(`[${attr}]`) !== null;
			};

			const hasRelevantAttribute = Object.values(BETSLIP_KEEP_FOCUSED_STAKE_DATA_ATTRS).some(attr => hasDataAttribute(event.target, attr));

			if (!hasRelevantAttribute) {
				setShowKeyboard(false);
			}
		}
	});

	useEffect(() => {
		let timer = null;
		if (isBetExpired) {
			timer = setTimeout(() => dispatch(clearBetsAction(true)), 3000);
		}
		return () => clearTimeout(timer);
	}, [isBetExpired]);

	useEffect(() => {
		if (!appContainerRef.current) { appContainerRef.current = document.getElementsByClassName("App")?.[0] }
		if (isQuickBetslipVisible && appContainerRef.current && !appContainerRef.current.classList.contains("vs--lift-up")) { appContainerRef.current.classList.add("vs--lift-up") }
		if (!isQuickBetslipVisible && appContainerRef.current && appContainerRef.current.classList.contains("vs--lift-up")) { appContainerRef.current.classList.remove("vs--lift-up") }
	}, [isQuickBetslipVisible])

	/** Cleaning showKeyboard Redux state on Component unmount */
	useEffect(() => {
		return () => {
			setShowKeyboard(false);
		}
	}, []);

	useUpdateEffect(() => {
		if(!mounted && isQuickBetEnabledRef.current && betsCountRef.current === 1) {
			dispatch(clearAllTypeBetsAction())
		}
	}, [mounted]);

	if (!mounted || !bets.length || currentGameType === GAME_TYPE.KENO) {
		return null;
	}

	return (
		<div
			className={mergeClassNames(
				"vs--quick-betslip",
				showKeyboard && "vs--quick-betslip-with-keyboard",
				rendered && "vs--quick-betslip-visible"
			)}
		>
			<div className="vs--quick-betslip-header vs--flex vs--justify-between vs--align-center vs--pl-16 vs--pr-8">
				<div className="vs--flex vs--align-center">
					<i className={mergeClassNames(GAME_TYPE_ICON_MAPPER[bets[0].gameType], "vs--mr-4 vs--font-20")} />
					<span className="vs--title-white vs--font-big vs--font-medium">
						{getBetEventName(bets[0])}
					</span>
				</div>
				<div className="vs--flex vs--flex-end">
					<i
						className="ic_down vs--font-bigest vs--ml-8"
						onClick={() => dispatch(setShowMobileQuickBetSlipAction(false))}
					/>
				</div>
			</div>
			<div className="vs--quick-betslip-content vs--flex vs--flex-col vs--pl-12 vs--pr-12 vs--pt-10 vs--pb-20">
				<MobileSingleBet
					amount={amount}
					isQuickBetslipOn={true}
					borderSvgStyle={borderSvgStyle}
					bet={bets[0]}
					key={bets[0].betId + "_" + bets[0].eventId}
					index={0}
					focusedStakeId={bets[0].key}
				/>
				{showKeyboard && (
					<FavoriteBets
						className="vs--mt-8 vs--mb-8"
						value={amount}
						favoriteBetCalcCb={setAmount}
					/>
				)}
				<Keyboard
					{...{ [BETSLIP_KEEP_FOCUSED_STAKE_DATA_ATTRS.KEYBOARD]: true }}
					value={`${amount}`}
					onChange={onKeyboardKeyClick}
					toggleVisibility={(visible) => setShowKeyboard(visible)}
					visible={showKeyboard}
					isInteger={currency.decimalCount === 0}
				/>
				<BetButtons
					className="vs--mt-10"
					placeBetHandler={handlePlaceBet}
					isQuickBetslipOn={true}
					checkPlaceBetAvailability={isPlaceBetDisabled}
					hasError={hasError}
				/>
				{!showKeyboard && (
					<div className={mergeClassNames(
						"vs--flex vs--justify-between vs--title-white vs--mt-10 vs--pt-10 vs--pb-10 vs--pl-8 vs--pr-8",
						"vs--quick-betslip-content-quick-bet-widget",
						!isQuickBetEnabled && "vs--quick-betslip-content-quick-bet-widget-off"
					)}>
						<div>{t("bet.placeBetWithOneClick")}</div>
						<Switch
							className="vs--quick-betslip-header-quick-bet-switch vs--ml-8"
							size={SWITCH_SIZES.LARGE}
							indicatorContent={
								<Fragment>
									<i className="ic_thunder vs--quick-betslip-header-quick-bet-switch-thunder-active vs--title-brand" />
									<i className="ic_thunder ic_thunder-active vs--quick-betslip-header-quick-bet-switch-thunder" />
								</Fragment>
							}
							checked={isQuickBetEnabled}
							onChange={toggleQuickBet}
						/>
					</div>
				)}
			</div>
		</div>
	)
};

export default QuickBetslip;
