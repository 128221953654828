import { createSlice } from "@reduxjs/toolkit";

import {
	addCommonCases,
	addEventCases,
	addMarketsCasees,
	addStandingsCases,
	addStructureCases
} from "./extraReducers";
import { initialState, sliceName } from "./initialState";

const seasonSlice = createSlice({
	name: sliceName,
	initialState: initialState,
	extraReducers: (builder) => {
		addCommonCases(builder);
		addEventCases(builder);
		addMarketsCasees(builder);
		addStandingsCases(builder);
		addStructureCases(builder);
	}
});

export const seasonSliceName = sliceName;
export const seasonSliceReducer = seasonSlice.reducer;