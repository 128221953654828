import { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";

import { saveSettingsThunk } from "store/slices/auth/thunks";
import { setShowRulesAction, toggleShowRulesAction } from "store/slices/common/actions";
import { selectSessionBalance, selectIsAnimation, selectIsSoundOn } from "store/slices/auth/selectors";
import { selectLiveAndUpcomingsData, selectCurrentGameType } from "store/slices/game/selectors";

import { sendEventStatus } from "utils/postMessagesIntegration";
import { sendPostMessageToParent } from "utils/iframe";
import { CLIENT_API } from "constants/integration.constants";
import { GAME_STATUSES } from "constants/game.constants";

const PostMessagesIntegration = () => {
	const currentGameType = useAppSelector(selectCurrentGameType);
	const liveAndUpcomings = useAppSelector(selectLiveAndUpcomingsData);
	const isAnimationOn = useAppSelector(selectIsAnimation);
	const isSoundOn = useAppSelector(selectIsSoundOn);
	const balance = useAppSelector(selectSessionBalance);

	const dispatch = useAppDispatch();

	const isMessageSentRef = useRef(false);
	const balanceRef = useRef();
	balanceRef.current = balance;

	/** Listening to the post messages sent by RGS */
	useEffect(() => {
		const onMessage = (event) => {
			switch (event?.data?.type) {
				case CLIENT_API.BALANCE:
					sendPostMessageToParent({ type: CLIENT_API.BALANCE, balance: balanceRef.current });
					break;
				case CLIENT_API.RULES:
					const dataState = event?.data?.state;
					if (dataState === "toggle") {
						dispatch(toggleShowRulesAction());
						break;
					}
					if (dataState === "show") {
						dispatch(setShowRulesAction(true));
						break;
					}
					if (dataState === "hide") {
						dispatch(setShowRulesAction(false));
						break;
					}
					break;
				case CLIENT_API.SETTINGS:
					const settings = {};

					if (event?.data?.isSoundOn) {
						settings.isSound = event.data.isSoundOn;
					}
					if (event?.data?.isAnimationOn) {
						settings.isAnimation = event.data.isAnimationOn;
					}

					dispatch(saveSettingsThunk(settings));
					break;
				default:
					break;
			}
		};
		window.addEventListener("message", onMessage);
		return () => {
			window.removeEventListener("message", onMessage);
		};
	}, []);

	/** Sending message to the RGS on current game change */
	useEffect(() => {
		if (currentGameType) {
			sendPostMessageToParent({ type: CLIENT_API.GAME, value: currentGameType });
		}

		return () => {
			isMessageSentRef.current = false;
		};
	}, [currentGameType]);

	/** Sending message to the RGS first time, when getting game started status from request */
	useEffect(() => {
		if (!isMessageSentRef.current && liveAndUpcomings.length > 0) {
			isMessageSentRef.current = true;
			const liveMatch = liveAndUpcomings.find((event) => event?.status === GAME_STATUSES.STARTED);

			if (liveMatch) {
				sendEventStatus(liveMatch);
			}
		}
	}, [liveAndUpcomings]);

	/** Sending message to the RGS on balance change */
	useEffect(() => {
		sendPostMessageToParent({ type: CLIENT_API.BALANCE, balance });
	}, [balance]);

	/** Sending message to the RGS on user settings change */
	useEffect(() => {
		sendPostMessageToParent({ type: CLIENT_API.SETTINGS, isSoundOn, isAnimationOn });
	}, [isAnimationOn, isSoundOn]);

	return <></>;
};

export default PostMessagesIntegration;
