import { NAVBAR_ICONS } from "constants/navbar.constants";

const DefaultArrowInner = ({ className, onClick, type, dir }) => {
	return (
		<button className={className} onClick={onClick}>
			{NAVBAR_ICONS[dir || "ltr"][type]}
		</button>
	);
};

export default DefaultArrowInner;
