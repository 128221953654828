import {
	getHistoryThunk,
	addHistoryThunk
} from "./thunks";

import {
	updateHistoryBetSlipBetAction,
	updateHistoryBetSlipAction,
	setHistoryOpenedBetSlipIdAction,
	cancelBetslipHistoryAction,
	setHistoryAction,
	setHistoryFiltersAction,
	resetHistoryAction
} from "./actions";

import { getDefaultDate } from "utils/dateTime";
import { mapperFnUpdateBets } from "utils/bets";

import { BETSLIP_MODES, BET_STATE } from "constants/betslip.constants";

export const addHistoryCases = (builder) => {
	builder.addCase(getHistoryThunk.pending, (state) => {
		state.loading = true;
		state.isAdding = false;
	});

	builder.addCase(getHistoryThunk.fulfilled, (state, { payload: data }) => {
		state.loading = false;
		state.isAdding = false;
		state.total = data.item1;
		state.data = data.item2;
		state.loaded = true;
	});

	builder.addCase(getHistoryThunk.rejected, (state) => {
		state.loading = false;
		state.isAdding = false;
	});

	builder.addCase(addHistoryThunk.pending, (state) => {
		state.isAdding = true;
	});

	builder.addCase(addHistoryThunk.fulfilled, (state, { payload: data }) => {
		state.isAdding = false;
		state.total = data.item1;
		state.data = state.data.concat(data.item2);
		state.loaded = true;
	});

	builder.addCase(addHistoryThunk.rejected, (state) => {
		state.isAdding = false;
	});

	builder.addCase(updateHistoryBetSlipBetAction, (state, { payload }) => {
		if (payload.bet.type === BETSLIP_MODES.SINGLE) {
			const mappedData = mapperFnUpdateBets({ bets: [payload.bet] });

			state.data = state.data.map((d) => d.betId !== payload.bet.id ? d : (
				{ ...d, ...mappedData[payload.bet.id], betSlipId: d.betSlipId }
			));
			return;
		}

		const mappedData = mapperFnUpdateBets(payload.bet);

		state.data = state.data.map((d) => {
			if (d.type === BETSLIP_MODES.MULTI) {
				return ({
					...d,
					bets: d.bets.map((bet) => bet.betId === payload.bet.id ? { ...mappedData, betSlipId: d.betSlipId } : bet)
				})
			}

			return d;
		});
	});

	builder.addCase(updateHistoryBetSlipAction, (state, { payload }) => {
		if (payload.bet.type === BETSLIP_MODES.SINGLE) {
			return;
		}

		const { id: betSlipId, ...rest } = payload.bet;

		const mappedData = mapperFnUpdateBets({ betSlipId, ...rest });

		state.data = state.data.map((d) => d.betSlipId !== payload.bet.id ? d : { ...d, ...mappedData, bets: d.bets });
	});

	builder.addCase(cancelBetslipHistoryAction, (state, { payload }) => {
		const mappedData = mapperFnUpdateBets(payload.bet.type === BETSLIP_MODES.SINGLE ? { bets: [payload.bet] } : payload.bet);

		state.data = state.data.map((d) => d.betSlipId !== payload.bet.id ? d : { ...d, status: BET_STATE.CANCELLED, ...mappedData });
	});

	builder.addCase(setHistoryAction, (state, { payload }) => {
		state.total = payload.data.item1;
		state.data = payload.data.item2;
		state.loaded = true;
	});

	builder.addCase(setHistoryFiltersAction, (state, { payload }) => {
		state.filters = { ...state.filters, ...payload.filters };
		state.filtersReset = false;
	});

	builder.addCase(resetHistoryAction, (state) => {
		state.data = [];
		state.total = 0;
		state.loaded = false;

		state.filters = {
			page: 1,
			limit: 20,
			...getDefaultDate()
		},
			state.filtersReset = true;
	});

	builder.addCase(setHistoryOpenedBetSlipIdAction, (state, { payload }) => {
		state.openedBetSlipId = payload.id;
	});
};