import { useRef, useEffect } from "react";

/* Return old value and remember new as old for next livecycle */
function usePrevious(value) {
	const ref = useRef();

	useEffect(() => {
		ref.current = value;
	}, [value]);

	return ref.current;
}

export default usePrevious;
