import { useState, useMemo } from "react";
import PropTypes from "prop-types";

const InputRadio = ({ label, side = "left", className, name = "", disabled = false, isChecked, value, onChange }) => {
	const [checked, setChecked] = useState(Boolean(isChecked));

	/** Memoized variable that takes isChecked prop or checked local state */
	const _checked = useMemo(() => (typeof isChecked === "boolean" ? isChecked : checked), [isChecked, checked]);

	/** Local onChange Callback that will be called, when Radio button state is changed */
	const _onChange = (e) => {
		setChecked((prev) => !prev);
		onChange(e.target.value, e);
	};

	const renderLabel = () => {
		return (
			<div>
				<span className={`vs--title-white vs--ui-input-radio-label vs--ui-input-radio-label-${side}`}>{label}</span>
			</div>
		);
	};

	return (
		<div className={"vs--ui-input-radio-content vs--flex vs--justify-between vs--align-center" + (className ? ` ${className}` : "")}>
			<label>
				{label !== undefined && side === "left" && renderLabel()}
				<div className="vs--ui-input-radio-content-inline vs--flex vs--align-center ">
					<input className="vs--visually-hidden vs--ui-input-radio-button" type="radio" name={name} value={value} checked={_checked} onChange={_onChange} disabled={disabled} />
					<span className="vs--ui-input-radio-overlay-button"></span>
				</div>
				{label !== undefined && side === "right" && renderLabel()}
			</label>
		</div>
	);
};

InputRadio.propTypes = {
	className: PropTypes.string,
	/** Radio button name */
	name: PropTypes.string,
	/** Label near Radio button */
	label: PropTypes.node,
	/** Label side */
	side: PropTypes.oneOf(["left", "right"]),
	/** Radio button disabled state */
	disabled: PropTypes.bool,
	/** Radio button checked state */
	isChecked: PropTypes.bool,
	/** Radio button value */
	value: PropTypes.string,
	/** Callback that will be called on Radio button checked state change */
	onChange: PropTypes.func
};

export default InputRadio;
