import { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import PenaltyMarketsEventContext from "contexts/penaltyMarketsEventContext";
import runMarketUtilsFunction from "utils/markets/run";
import { isCupGame, getRoundNameOfCupGame, mergeClassNames } from "utils/common";
import { GAME_EVENT_TYPE, GAME_STATUSES } from "constants/game.constants";
import useGlobalVariables from "hooks/useGlobalVariables";
import { DEFAULT_CLASSES } from "constants/table.constants";

const PenaltyColumn = ({ market }) => {
	const { isMobile } = useGlobalVariables();
	const { currentEvent, currentMatch, isModalView } = useContext(PenaltyMarketsEventContext);
	const { t } = useTranslation();
	const ref = useRef();

	useEffect(() => {
		if (market?.bets?.length > 0 || market?.markets?.length > 0) {
			return;
		}
		if (!ref.current) {
			return;
		}
		const row = ref.current.closest(".vs--table-row");
		if (!row) {
			return;
		}
		// row.style.display = "none";
		if (!row.classList.contains(DEFAULT_CLASSES.ROW_EXPANDED)) {
			return;
		}
		const nextRow = row.nextElementSibling;
		if (!nextRow.classList.contains(DEFAULT_CLASSES.ROW_COLLAPSE)) {
			return;
		}
		// nextRow.style.display = "none";
	}, [market]);

	/** Function to get market text
	 * @function
	 * @param {object} bet
	 * @returns {string}
	 * @memberOf FootballMarketsList
	 */
	const makeTitle = (bet) => {
		if (!bet) {
			return null;
		}
		const groupTitle = runMarketUtilsFunction(
			"makeGroupTitle",
			[
				{
					period: bet.period,
					group: bet.group,
					team1: currentEvent?.gameData?.team1?.countryCode ?? "",
					team2: currentEvent?.gameData?.team2?.countryCode ?? "",
					argument: bet.argument,
					gameType: currentEvent?.gameType ?? 0
				}
			],
			currentEvent?.gameType ?? 0
		);

		return groupTitle;
	};

	const showInCenter = isModalView || (isMobile && currentEvent?.status === GAME_STATUSES.FINISHED);

	return (
		<span ref={ref} className={mergeClassNames("vs--table-markets-title vs--title-white vs--font-normal vs--font-regular vs--flex", showInCenter && "vs--justify-center vs--align-center")} data-new-market={market.isNew ? t("common.new") : ""}>
			{makeTitle(market)}
		</span>
	);
};

export default PenaltyColumn;
