import { useState, useRef, useEffect, useMemo, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";

import { gameRulesThunk } from "store/slices/common/thunks";
import { selectCurrentGameType } from "store/slices/game/selectors";
import { selectCurrentRules, selectPrevRules, selectIsRulesLoading } from "store/slices/common/selectors";

import ScrollBar from "components/ui/scrollBar";
import Games from "components/topBar/games";
import Loader from "components/ui/loader";
import Tooltip from "components/ui/tooltip";
import useGlobalVariables from "hooks/useGlobalVariables";
import { formatDateTime } from "utils/dateTime";
import { GAME_TYPE, GAME_TYPE_MAPPER } from "constants/game.constants";
import { mergeClassNames } from "utils/common";
import TopBarRulesHoc from "hocs/topBar/topBarRulesHoc";

const WrappedGames = TopBarRulesHoc(Games);

/** Rules Component */
const Rules = () => {
	const currentGameType = useAppSelector(selectCurrentGameType);
	const isLoading = useAppSelector(selectIsRulesLoading);
	const rules = useAppSelector(selectCurrentRules);
	const prevRules = useAppSelector(selectPrevRules);

	const { t } = useTranslation();
	const { isMobile } = useGlobalVariables();
	const _scrollBarRef = useRef(null);
	const contentContainerRef = useRef();
	const iframeRef = useRef();

	const dispatch = useAppDispatch();

	const [isPreviousRule, setIsPreviousRule] = useState(false);
	const [selectedGame, setSelectedGame] = useState(currentGameType);

	const activeGame = useMemo(() => (isMobile ? currentGameType : selectedGame), [selectedGame, currentGameType]);

	const footerContent = (isPreviousRule ? rules : prevRules)?.[activeGame]?.date;
	const content = (isPreviousRule ? prevRules : rules)?.[activeGame]?.content ?? "";

	const getHeaderTitle = () => {
		const rule = (isPreviousRule ? prevRules : rules)?.[activeGame] ?? {};
		let title = rule.title ?? "";
		const date = rule.date ?? "";

		if (date) {
			title += `: ${formatDateTime(date)}`;
		}

		return title;
	};

	const handleGameChange = (gameType) => {
		setSelectedGame(gameType);
		setIsPreviousRule(false);
	};

	/** Get rules from backend handler
	 * @function
	 * @memberOf Rules
	 */
	const handleDownload = () => {
		const link = document.createElement("a");
		const date = new Date();

		const filePath = rules?.[activeGame]?.path ?? "";
		link.href = `${import.meta.env.SYSTEM_CDN_URL}/${filePath}?t=${date.getTime()}`;
		if (!isMobile) {
			link.target = "_blank";
		}

		const gameName = GAME_TYPE_MAPPER[activeGame] ? t(`common.${GAME_TYPE_MAPPER[activeGame]}`) : "";
		const fileName = `${gameName} ${t("menu.rules")} ${formatDateTime(date)}`;

		link.download = fileName + ".pdf";
		link.click();
	};

	useEffect(() => {
		if (!content || !iframeRef.current) {
			return;
		}

		iframeRef.current.contentWindow.document.body.innerHTML = '';
		iframeRef.current.contentWindow.document.body.insertAdjacentHTML("afterbegin", content);
		iframeRef.current.contentWindow.document.body.insertAdjacentHTML(
			"afterbegin",
			`
				<style>
					body {
						font-family: Roboto, sans-serif;
						margin: 0px;
						background-color: ${window.getComputedStyle(document.documentElement).getPropertyValue("--vs--bg-color-base")};
						color: ${window.getComputedStyle(document.documentElement).getPropertyValue("--vs--text-color-1")};
					}
					::-webkit-scrollbar {
						display: none;
					}
				</style>
			`
		);
	}, [content, rules, prevRules]);

	/** Get Rule by Game change */
	useEffect(() => {
		if (activeGame && !rules?.[activeGame]) {
			dispatch(gameRulesThunk(activeGame));
		}
	}, [activeGame]);

	useEffect(() => {
		if (_scrollBarRef && _scrollBarRef.current) {
			_scrollBarRef.current.scrollToTop();
			_scrollBarRef.current.update();
		}
	}, [activeGame]);

	return (
		<div className={mergeClassNames("vs--rules", activeGame === GAME_TYPE.KENO && "vs--rules-keno", "vs--flex vs--flex-col")}>
			{isMobile ? null : (
				<div className="vs--topBar vs--rules-topbar vs--flex vs--flex-col">
					<WrappedGames selectedGame={activeGame} {...(isMobile ? undefined : { setSelectedGame: handleGameChange })} />
				</div>
			)}
			<div className={mergeClassNames("vs--rules-content vs--flex-equal vs--flex vs--flex-col", isLoading && "vs--rules-content-loading")}>
				{isLoading ? (
					<Loader />
				) : (
					<Fragment>
						<h3 className="vs--rules-content-inner-title">
							{isMobile ? (
								<Tooltip title={<span className="vs--title vs--font-normal vs--font-medium">{getHeaderTitle()}</span>}>
									<span className="vs--rules-content-inner-title-text">{getHeaderTitle()}</span>
								</Tooltip>
							) : (
								<span className="vs--rules-content-inner-title-text">{getHeaderTitle()}</span>
							)}
							{isPreviousRule ? null : <i className="ic_download vs--font-bigest" onClick={handleDownload} title={t("common.downloadRules")}></i>}
						</h3>
						<ScrollBar className="vs--rules-content-inner vs--scrollbar-thin" vertical={true} ref={_scrollBarRef}>
							<div ref={contentContainerRef} className="vs--rules-content-inner-text vs--pb-8" style={{ height: "100%" }}>
								<iframe style={{ height: "100%", width: "100%" }} ref={iframeRef} />
							</div>
						</ScrollBar>
						{footerContent ? (
							<div className={`vs--rules-content-inner-footer vs--mt-10`}>
								<span className="vs--rules-content-inner-footer-state vs--ml-16 vs--font-normal">{`${isPreviousRule ? `${t("common.active")}:` : `${t("common.previous")}:`}`} &nbsp;</span>
								<span className="vs--rules-content-inner-footer-version vs--font-normal" onClick={() => setIsPreviousRule((prev) => !prev)}>{`${t("common.version")}: ${formatDateTime(footerContent)}`}</span>
							</div>
						) : null}
					</Fragment>
				)}
			</div>
		</div>
	);
};

export default Rules;
