import { useSwipeable } from "react-swipeable";
import PropTypes from "prop-types";

const Swipeable = ({ children, containerProps = {}, ...configs }) => {
	const handlers = useSwipeable({ ...configs });
	return (
		<div {...containerProps} {...handlers}>
			{children}
		</div>
	);
};

Swipeable.propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
	containerProps: PropTypes.object
};

export default Swipeable;
