import { useMemo } from "react";
import PropTypes from "prop-types";
import TreeSimpleCup from "./treeStructureComponents/treeSimpleCup";
import TreeChampionsCup from "./treeStructureComponents/treeChampionsCup";

import useAppSelector from "hooks/store/useAppSelector";

import { selectCurrentGameType } from "store/slices/game/selectors";

import { getSeasonNameByOrderNumber, isSimpleCupGame, isChampionsCupGame } from "utils/common";
import { PREDICATE_MULTIPLIER_OF_SEASON_GAMES, GAME_STATUSES } from "constants/game.constants";
import eventType from "types/event.type";

/** Tree Structure Component */
const TreeStructure = ({ seasonStructure }) => {
	const currentGameType = useAppSelector(selectCurrentGameType);
	
	const treeData = useMemo(() => {
		const roundsCount = PREDICATE_MULTIPLIER_OF_SEASON_GAMES[currentGameType];
		const mapper = {};

		for (let i = 1; i <= roundsCount; i++) {
			const roundName = getSeasonNameByOrderNumber(i, currentGameType);
			if (!Array.isArray(mapper[roundName])) {
				mapper[roundName] = [];
			}
		}

		(seasonStructure?.events ?? []).forEach((rounds) => {
			const roundName = getSeasonNameByOrderNumber(rounds.orderNumber, currentGameType);
			if (isSimpleCupGame(currentGameType)) {
				mapper[roundName] = mapper[roundName].concat(rounds?.events ?? []);
				return;
			}
			if (isChampionsCupGame(currentGameType)) {
				const headerVisibility = rounds.status === GAME_STATUSES.FINISHED;
				mapper[roundName].push({
					orderNumber: rounds.orderNumber,
					data: rounds?.events ?? [],
					headerVisibility
				});
				return;
			}
		});
		return mapper;
	}, [seasonStructure, currentGameType]);

	return (
		<div className="vs--cup-result" data-type={currentGameType}>
			<div className="vs--cup-result-bg vs--flex vs--justify-start">
				<div className="vs--cup-result-structure vs--flex vs--justify-start">
					{(() => {
						switch (true) {
							case isSimpleCupGame(currentGameType):
								return <TreeSimpleCup treeData={treeData} />;
							case isChampionsCupGame(currentGameType):
								return <TreeChampionsCup treeData={treeData} />;
							default:
								return null;
						}
					})()}
				</div>
			</div>
		</div>
	);
};

/** TreeStructure propTypes
 * PropTypes
 */
TreeStructure.propTypes = {
	/** Redux state property, the season data */
	seasonStructure: eventType
};

export default TreeStructure;
