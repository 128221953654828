import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";

import { clearSeasonStructureAndStandingsAction } from "store/slices/season/actions";
import { getSeasonStructureThunk } from "store/slices/season/thunks";
import { selectCurrentGameType } from "store/slices/game/selectors";
import { selectSeasonStructure, selectIsSeasonLoading } from "store/slices/season/selectors";

import { getEventsQty, callbackForEventSortingbyOrderNumber } from "utils/common";
import { GAME_EVENT_TYPE, GAME_STATUSES, PREDICATE_MULTIPLIER_OF_SEASON_GAMES } from "constants/game.constants.js";

const getMissingEventsQty = (orderNumber, currentQty, gameType) => {
	const retVal = getEventsQty(orderNumber, gameType) - currentQty;
	return retVal < 0 ? 0 : retVal;
};

const getDefaultEvent = (orderNumber, type, gameType) => ({
	id: type !== GAME_EVENT_TYPE.LEAGUE ? Math.random() * Date.now() : null,
	orderNumber,
	type,
	events: type !== GAME_EVENT_TYPE.EVENT ? [] : null,
	gameData: null,
	markets: null,
	status: GAME_STATUSES.NEW,
	gameType
});

const CupGamesResultsHoc = (ReactComponent) => {
	const WrappedReactComponentOfCupGamesResultsHoc = ({ id, ...props }) => {
		const currentGameType = useAppSelector(selectCurrentGameType);
		const structure = useAppSelector(selectSeasonStructure);
		const loading = useAppSelector(selectIsSeasonLoading);
		const [seasonStructure, setStructure] = useState(getDefaultEvent(null, GAME_EVENT_TYPE.LEAGUE, null));

		const dispatch = useAppDispatch();
		const getStructure = useCallback((id) => dispatch(getSeasonStructureThunk(id)), [dispatch]);
		const clearStructureAndStandings = useCallback(() => dispatch(clearSeasonStructureAndStandingsAction()), [dispatch]);

		useEffect(() => {
			getStructure(id);
			return () => {
				clearStructureAndStandings();
			};
		}, [id, getStructure, clearStructureAndStandings]);

		useEffect(() => {
			const newStructure = structuredClone(Object.keys(structure).length > 0 ? structure : seasonStructure);
			if (!newStructure.id) {
				return;
			}
			if (currentGameType) {
				newStructure.gameType = currentGameType;
			}
			if (!Array.isArray(newStructure.events)) {
				newStructure.events = [];
			}
			if (!Object.prototype.hasOwnProperty.call(newStructure, "type")) {
				newStructure.type = GAME_EVENT_TYPE.LEAGUE;
			}
			if (!Object.prototype.hasOwnProperty.call(newStructure, "markets")) {
				newStructure.markets = null;
			}
			if (!Object.prototype.hasOwnProperty.call(newStructure, "gameData")) {
				newStructure.gameData = null;
			}
			if (!Object.prototype.hasOwnProperty.call(newStructure, "status")) {
				newStructure.status = GAME_STATUSES.NEW;
			}
			const missingRoundsQty = PREDICATE_MULTIPLIER_OF_SEASON_GAMES[currentGameType] - newStructure.events.length;

			newStructure.events.sort(callbackForEventSortingbyOrderNumber);

			newStructure.events = newStructure.events.concat(Array.from({ length: missingRoundsQty }, (_, i) => getDefaultEvent(PREDICATE_MULTIPLIER_OF_SEASON_GAMES[currentGameType] - missingRoundsQty + i + 1, GAME_EVENT_TYPE.WEEK, currentGameType)));
			newStructure.events = newStructure.events.map((round) => {
				const missingEventsQty = getMissingEventsQty(round.orderNumber, round.events.length, currentGameType);
				round.events = round.events.concat(Array.from({ length: missingEventsQty }, (_, i) => getDefaultEvent(getEventsQty(round.orderNumber, currentGameType) - missingEventsQty + i + 1, GAME_EVENT_TYPE.EVENT, currentGameType)));
				return { ...round };
			});

			setStructure(newStructure);
			return () => {
				setStructure(getDefaultEvent(null, GAME_EVENT_TYPE.LEAGUE, null));
			};
		}, [loading, structure, currentGameType]);

		return <ReactComponent structure={seasonStructure} loading={loading} isFromResults={true} currentGameType={currentGameType} id={id} {...props} />;
	};

	WrappedReactComponentOfCupGamesResultsHoc.propTypes = {
		id: PropTypes.number
	};

	return WrappedReactComponentOfCupGamesResultsHoc;
};

export default CupGamesResultsHoc;
