import axios from 'axios';
import { sha256 } from "js-sha256";

import Methods from 'constants/methods.constants';
import ApiUrls from 'constants/api.constants';
import { CLIENT_API } from "constants/integration.constants";
import store from 'store/configureStore';
import { setPlayerAction } from 'store/slices/auth/actions';

import { getUrlVars } from "utils/common";
import { sendPostMessageToParent } from "utils/iframe";

export const exitFromSession = () => {
	const state = store.getState();
	const { exitUrl } = state.auth.session;

	if (exitUrl) {
		sendPostMessageToParent({ type: CLIENT_API.BACK_TO_HOME, value: exitUrl, mainDomain: exitUrl });
	}
	else {
		location.reload();
	}
}

export const logout = () => {
	exitFromSession();
}

let isRefreshing = false;

// TODO: discussed with Narek, will be handled with Tokenmanager service in near future
/** Sends request to server to refresh token
	* @function
	* @param {string} refresh_token - refresh token
	* @param {Object} requestConfig - the request configuration which will be sends, when new tokens got from server
	* @returns {Promise}
 */
export const refreshToken = (refresh_token, requestConfig) => {
	if (isRefreshing) return Promise.reject();
	isRefreshing = true;
	return axios({
		url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.REFRESH_TOKEN}`,
		method: Methods.POST,
		data: { refreshToken: refresh_token },
		headers: { grant_type: "refresh_token" }
	}).then(({ status, data: { value: authData } }) => {
		if (status === 200) {
			store.dispatch(setPlayerAction(authData));
			isRefreshing = false;
			return requestConfig && axios.request(requestConfig);
		} else {
			logout();
			isRefreshing = false;
		}
	}).catch(err => { isRefreshing = false; console.log(err) })
}

// TODO: discussed with Narek, will be handled with Tokenmanager service in near future
/** Sends request to server to refresh stream acesss token
	* @function
	* @param {string} refresh_token - refresh token
	* @param {Object} requestConfig - the request configuration which will be sends, when new tokens got from server
	* @returns {Promise}
 */
export const refreshStreamAccess = (refresh_token, requestConfig) => {
	if (isRefreshing) return Promise.reject();
	isRefreshing = true;
	return axios({
		url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.REFRESH_STREAM_ACCESS}`,
		method: Methods.POST,
		data: { refreshToken: refresh_token },
		headers: { grant_type: "refresh_token" }
	}).then(({ status, data: authData }) => {
		if (status === 200) {
			store.dispatch(setPlayerAction(authData));
			isRefreshing = false;
			return requestConfig && axios.request(requestConfig);
		} else {
			logout();
			isRefreshing = false;
		}
	}).catch(err => { isRefreshing = false; console.log(err) })
}

export const getUser = (argUrlVars) => {
	const urlVars = argUrlVars ?? getUrlVars();

	const userName = urlVars["user"] ?? "Aram";
	const id = urlVars["id"] ?? userName + "_id";
	const apiKey = urlVars["apiKey"] ?? "1060c7b828414c498066cea8e53435e7";
	const secret = urlVars["secret"] ?? "902374A1C2";
	const currency = urlVars["currency"] ?? "USD";
	const language = urlVars["language"] ?? "EN";
	const balance = urlVars["balance"] ?? 10000;
	const isDemo = urlVars["isDemo"] ?? true;

	const user = {
		ProjectId: apiKey,
		LanguageId: language,
		IsDemo: isDemo,
		User:
			urlVars["user"] !== "Guest"
				? {
					Id: id,
					UserName: userName,
					CurrencyId: currency,
					Balance: balance
				}
				: null,
		Urls: {
			ExitUrl: "return_url",
			DepositUrl: "deposit_url",
			LoginUrl: "Login_url"
		}
	};

	const sign = sha256.hmac(secret, JSON.stringify(user));

	return { user, sign };
};