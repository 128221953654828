import PropTypes from "prop-types";

const InputTextArea = ({ className = "", rows = 3, ...props }) => {
	return <textarea className={`vs--ui-input-textarea ${className}`} rows={rows} {...props} />;
};

/** InputTextArea propTypes
 * PropTypes
 */
InputTextArea.propTypes = {
	/** Class to rewrite default styles of textarea */
	className: PropTypes.string,
	/** Rows of textarea */
	rows: PropTypes.number
};

export default InputTextArea;
