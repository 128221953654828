import ApiUrls from "constants/api.constants";

import httpService from "../http";

const rulesService = {
	rules: (gameType, signal) => {
		return httpService.get({
			path: ApiUrls.GET_GAME_RULE,
			queryData: { gameType },
			signal
		});
	}
};

export default rulesService;
