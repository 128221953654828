import PropTypes from "prop-types";

export default PropTypes.shape({
	participants: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			jockeyName: PropTypes.string,
			age: PropTypes.number,
			place: PropTypes.number,
			rating: PropTypes.number,
			number: PropTypes.number,
			trainer: PropTypes.string
		})
	),
	venue: PropTypes.string
});
