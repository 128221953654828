export const NAVBAR_INTERACTIVE_ELEMENT_KEYS = {
	PREV: "PREV",
	NEXT: "NEXT",
	MENU: "MENU"
};

export const NAVBAR_ICONS = {
	rtl: {
		[NAVBAR_INTERACTIVE_ELEMENT_KEYS.NEXT]: <i className="ic_double--left vs--font-bigest" />,
		[NAVBAR_INTERACTIVE_ELEMENT_KEYS.PREV]: <i className="ic_double--right vs--font-bigest" />
	},
	ltr: {
		[NAVBAR_INTERACTIVE_ELEMENT_KEYS.PREV]: <i className="ic_double--left vs--font-bigest" />,
		[NAVBAR_INTERACTIVE_ELEMENT_KEYS.NEXT]: <i className="ic_double--right vs--font-bigest" />
	},
	[NAVBAR_INTERACTIVE_ELEMENT_KEYS.MENU]: "more"
};

export const ELEMENT_VISIBILITY_CLASS_NAMES = {
	VISIBLE: "vs--navbar-element-visible",
	SEMI_VISIBLE: "vs--navbar-element-semi-visible",
	HIDDEN: "vs--navbar-element-hidden"
};

export const NAVBAR_EVENT_TYPES = {
	ARROW_NAVIGATION: "ARROW_NAVIGATION",
	INTO_VIEW: "INTO_VIEW",
	ACTIVE_ELEMENT: "ACTIVE_ELEMENT",
	ELEMENTS_SCROLL_ON_START: "ELEMENTS_SCROLL_ON_START",
	ELEMENTS_SCROLL_ON_END: "ELEMENTS_SCROLL_ON_END",
	ELEMENTS_ON_SCROLL: "ELEMENTS_ON_SCROLL",
	THERE_IS_NOT_ELEMENTS_OR_ALL_VISIBLE: "THERE_IS_NOT_ELEMENTS_OR_ALL_VISIBLE",
	ELEMENTS_VISIBILITY: "ELEMENTS_VISIBILITY",
	MESSAGE_TO_ELEMENT: "MESSAGE_TO_ELEMENT"
};

export const NAVBAR_SUBEVENT_TYPES = {
	[NAVBAR_EVENT_TYPES.MESSAGE_TO_ELEMENT]: {
		MOVE_TO_VIEW: "MOVE_TO_VIEW",
		UPDATE_ELEMENT: "UPDATE_ELEMENT"
	}
};

export const ARROWS_LISTENIN_EVENT_TYPES = {
	[NAVBAR_INTERACTIVE_ELEMENT_KEYS.PREV]: NAVBAR_EVENT_TYPES.ELEMENTS_SCROLL_ON_START,
	[NAVBAR_INTERACTIVE_ELEMENT_KEYS.NEXT]: NAVBAR_EVENT_TYPES.ELEMENTS_SCROLL_ON_END
};
