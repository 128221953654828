import { getEventByIdThunk } from "store/slices/lucky-six/thunks.js";

export const addEventByIdCases = (builder) => {
	builder.addCase(getEventByIdThunk.pending, (state) => {
		state.marketsLoading = true;
	});

	builder.addCase(getEventByIdThunk.fulfilled, (state, { payload: event }) => {
		state.marketsLoading = false;
		state.resultMarkets = {
			...state.resultMarkets,
			[event.id]: event.markets
		};
	});

	builder.addCase(getEventByIdThunk.rejected, (state) => {
		state.marketsLoading = true;
	});
};
