import { useEffect } from "react";
import useEvent from "./useEvent";

const useDocumentListener = (eventName, handler) => {
	const eventHandler = useEvent(handler);
	useEffect(() => {
		document.addEventListener(eventName, eventHandler);
		return () => document.removeEventListener(eventName, eventHandler);
	}, []);
	return eventHandler;
};
export default useDocumentListener;
