import { copyAdditionalInfo, copyTitle } from "utils/table";
export class TableColumnNode {
	/*
		Name of field of record list object,
		which value will be used on each iteration
		for drow column data
	*/
	name;

	/*
		Tile of fields column
	*/
	title;

	/*
		Optiona function to render content
		of each cell of head or body of table
	*/
	renderFn;

	/*
		Optional data with additional information object,
		it will pass to every column
	*/
	additionalInfo;

	constructor(name, title = name, renderFn = null, additionalInfo = null) {
		this.name = name;
		this.title = title;
		this.renderFn = renderFn;
		this.additionalInfo = additionalInfo;
	}

	clone() {
		const newName = this.name;
		const newTitle = copyTitle(this.title);
		const newRenderFn = this.renderFn;
		const newAdditionalInfo = copyAdditionalInfo(this.additionalInfo);

		return new TableColumnNode(newName, newTitle, newRenderFn, newAdditionalInfo);
	}
}
