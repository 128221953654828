import { callbackForEventSortingbyOrderNumber } from "utils/common";
import { GAME_EVENT_TYPE, GAME_STATUSES } from "constants/game.constants";

const generateEventUpdateCallback = (payloadData) => (event) => {
	if (payloadData.id !== event.id) {
		return event;
	}
	return {
		...event,
		...payloadData,
		orderNumber: event.orderNumber,
		events: Array.isArray(payloadData.events) ? payloadData.events : Array.isArray(event.events) ? event.events : []
	};
};

export const updateSeasonStatisticsStructureSeason = (currentStructure, payloadData) => {
	if (payloadData.status === GAME_STATUSES.NEW) {
		return currentStructure;
	}
	const newStructure = Object.assign({}, currentStructure, payloadData);
	if (!Array.isArray(newStructure.events)) {
		newStructure.events = [];
	}
	newStructure.events = Array.isArray(payloadData.events) ? payloadData.events : currentStructure.events;
	return newStructure;
};

export const updateSeasonStatisticsStructureRound = (currentStructure, payloadData, calledFromSimpleEventUpdate = false) => {
	if (!calledFromSimpleEventUpdate && (payloadData.parentEventId !== currentStructure.id || payloadData.gameType !== currentStructure.gameType)) {
		return currentStructure;
	}
	const newStructure = updateSeasonStatisticsStructureSeason(currentStructure, {});
	if (!Array.isArray(newStructure.events)) {
		newStructure.events = [];
	}
	if (payloadData.status === GAME_STATUSES.NEW && newStructure.id === payloadData.seasonId && !calledFromSimpleEventUpdate) {
		const currentRound = newStructure.events.find((round) => payloadData.id === round.id);
		if (!currentRound) {
			const newArrayOfRounds = [].concat(newStructure.events, payloadData);
			newArrayOfRounds.sort(callbackForEventSortingbyOrderNumber);
			newStructure.events = newArrayOfRounds;
			return newStructure;
		}
	}
	if (!calledFromSimpleEventUpdate) {
		newStructure.events = newStructure.events.map(generateEventUpdateCallback(payloadData));
	}
	return newStructure;
};

export const updateSeasonStatisticsStructureEvent = (currentStructure, payloadData) => {
	const newStructure = updateSeasonStatisticsStructureRound(currentStructure, {}, true);
	newStructure.events = newStructure.events.map((round) => {
		if (payloadData.parentEventId !== round.id) {
			return round;
		}
		const newRound = { ...round };
		if (!Array.isArray(newRound.events)) {
			newRound.events = [];
		}
		if (payloadData.status === GAME_STATUSES.NEW) {
			const currentEvent = newRound.events.find((event) => payloadData.id === event.id);
			if (!currentEvent) {
				newRound.events = [...newRound.events, payloadData].sort(callbackForEventSortingbyOrderNumber);
				return newRound;
			}
		}
		newRound.events = newRound.events.map(generateEventUpdateCallback(payloadData));
		return newRound;
	});
	return newStructure;
};

export const updateSeasonStatisticsStructure = (currentStructure, payloadData) => {
	switch (payloadData.type) {
		case GAME_EVENT_TYPE.LEAGUE:
			return updateSeasonStatisticsStructureSeason(currentStructure, payloadData);
		case GAME_EVENT_TYPE.WEEK:
			return updateSeasonStatisticsStructureRound(currentStructure, payloadData, false);
		case GAME_EVENT_TYPE.EVENT:
			return updateSeasonStatisticsStructureEvent(currentStructure, payloadData);
		default:
			return {};
	}
};
