import { cloneElement, useEffect, useMemo, forwardRef } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import Swipeable from "components/ui/swipeable";

const SwipeableModal = forwardRef(({ children, onClose, title, closeElement, actionComponents, className, wrapperProps = {}, headerProps = {}, bodyProps = {}, ...props }, ref) => {
	const element = useMemo(() => {
		const element = document.createElement("div");
		element.classList.add("vs--modal-swipable");
		if (typeof className === "string" && className !== "") {
			element.classList.add(className);
		}
		return element;
	}, [className]);

	useEffect(() => {
		let modalRootElement = document.querySelector("#modal");
		if (!modalRootElement) {
			modalRootElement = document.createElement("div");
			modalRootElement.id = "modal";
			document.body.appendChild(modalRootElement);
		}
		modalRootElement.appendChild(element);
		/* Hide page scroll when modal is opened */
		document.body.classList.add("vs--overflow-hidden");
		return () => {
			if (modalRootElement) {
				modalRootElement.removeChild(element);
				if (modalRootElement.children.length === 0) {
					document.body.removeChild(modalRootElement);
					/* Show page scroll when modal is opened */
					document.body.classList.remove("vs--overflow-hidden");
				}
			}
		};
	}, [element]);

	const handleOnSwipedDown = (e) => {
		if (!(e?.event?.target ?? { closest: Function.prototype }).closest(".vs--modal-swipable-body")) {
			onClose();
		}
	};

	const renderClosingElement = () => {
		const elem = !closeElement ? (
			<i className="ic_close vs--font-bigest" />
		) : typeof closeElement === "string" ? (
			<i className="ic_close vs--font-bigest" />
		) : (
			// <span className="vs--font-big" style={{ position: 'absolute', left: 15 }} >{closeElement}</span>
			closeElement
		);
		return cloneElement(elem, { onClick: onClose });
	};

	return createPortal(
		<>
			<Swipeable onSwipedDown={handleOnSwipedDown}>
				<div {...props} className={"vs--modal-swipable-background vs--modal-swipable-background-animation"} ref={ref} />
				<div {...wrapperProps} className={"vs--modal-swipable-wrapper vs--modal-swipable-wrapper-animation" + (wrapperProps.className ? " " + wrapperProps.className : "")} onClick={onClose}>
					<div {...headerProps} className={"vs--modal-swipable-header" + (headerProps.className ? " " + headerProps.className : "")} onClick={(e) => e.stopPropagation()}>
						<div>{renderClosingElement()}</div>
						<div className="vs--modal-swipable-title-section">
							<div className="vs--modal-swipable-control" />
							{typeof title === "string" ? (
								<span className="vs--modal-swipable-title vs--flex vs--flex-row vs--justify-center vs--align-center vs--font-big vs--font-medium">{title}</span>
							) : (
								<div className="vs--modal-swipable-title vs--flex vs--flex-row vs--justify-center vs--align-center">{title}</div>
							)}
						</div>
						<div>{actionComponents}</div>
					</div>
					<div {...bodyProps} className={"vs--modal-swipable-body" + (bodyProps.className ? " " + bodyProps.className : "")} onClick={(e) => e.stopPropagation()}>
						{children}
					</div>
				</div>
			</Swipeable>
		</>,
		element
	);
});

SwipeableModal.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	onClose: PropTypes.func,
	closeElement: PropTypes.node,
	className: PropTypes.string,
	wrapperProps: PropTypes.object,
	headerProps: PropTypes.object,
	bodyProps: PropTypes.object,
	actionComponents: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default SwipeableModal;
