import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useCurrentPath from "hooks/useCurrentPath";

import { selectCurrentTime } from "store/slices/common/selectors";
import { selectSessionGames } from "store/slices/auth/selectors";

import PATHS from "constants/path.constants";
import {
	GAME_TYPE,
	GAME_LABEL_TYPE,
	GAME_TYPE_MAPPER,
	GAME_TYPE_ICON_MAPPER,
} from "constants/game.constants";
import { redirectToPage } from "utils/navigate";
import { buildPathToStaticFolderOfCDN, isMobile, isRacingGame, mergeClassNames } from "utils/common";
import { binaryToFlags } from "utils/binaryCalculations";
import useGlobalVariables from "hooks/useGlobalVariables";
import GameCountDown from "./gameCountDown";
import sessionType from "types/session.type";
import GameType from "types/game.type";
import useAppSelector from "hooks/store/useAppSelector";

/* Single Game Component in topBar */
const Game = ({
	game,
	isFromModal = false,
	onClickCallback = null,
	showCountdown = true,
	selectedGame,
	setSelectedGame
}) => {
	const games = useAppSelector(selectSessionGames);
	const currentTime = useAppSelector(selectCurrentTime);

	const { t } = useTranslation();
	const { isMobile } = useGlobalVariables();
	const navigate = useNavigate();
	const path = useCurrentPath();

	/** Fires on match click
	 * @function
	 * @param {number} type - type of clicked game
	 * @memberOf Game
	 */
	const handleClick = (type) => {
		if (selectedGame !== type) {
			setSelectedGame(type);
			if (path !== PATHS.RESULTS && path !== PATHS.STATISTICS && path !== PATHS.RULES) {
				redirectToPage(navigate, PATHS.HOME);
			}
			if (typeof onClickCallback === "function") {
				onClickCallback(type);
			}
		}
	};

	const renderGameName = () => {
		return <span className="vs--topBar-game-name vs--title-gray-50 vs--font-normal vs--font-regular">{GAME_TYPE_MAPPER[game.type] ? t(`common.${GAME_TYPE_MAPPER[game.type]}`) : null}</span>;
	};

	return (
		<div className={mergeClassNames("vs--topBar-game", selectedGame === game.type && "vs--topBar-game-active")} onClick={() => handleClick(game.type)} data-unique={game.type}>
			<div className="vs--topBar-game-content vs--flex vs--flex-row vs--align-center">
				<div className="vs--topBar-game-icon-wrapper">
					<i className={mergeClassNames(GAME_TYPE_ICON_MAPPER[game.type] && GAME_TYPE_ICON_MAPPER[game.type], "vs--topBar-game-icon vs--font-bigest")} />
				</div>
				{isFromModal ? null : renderGameName()}
				{showCountdown && games.length > 1 && game.nextEventStartTime !== null ? <GameCountDown game={game} currentTime={currentTime} isFromModal={isFromModal} /> : null}
			</div>
			{
				isFromModal
					? renderGameName()
					: null
			}
			{
				binaryToFlags(
					Object.values(GAME_LABEL_TYPE),
					game.gameLabel
				).includes(GAME_LABEL_TYPE.NEW)
					? (
						<img
							className="vs--topBar-game-new-badge"
							src={buildPathToStaticFolderOfCDN("images/misc/new.svg")}
							alt="new"
						/>
					)
					: null
			}
		</div>
	);
};

/** Game propTypes
 * PropTypes
 */
Game.propTypes = {
	/** Game object for the component */
	game: GameType,
	/** React property, is component mount from modal */
	isFromModal: PropTypes.bool,
	/** React property, when it's mount from modal */
	onClickCallback: PropTypes.func,
	/** React property, whether show or hide game countdown */
	showCountdown: PropTypes.bool,
	/** React property, given selected game from parent component or currentGameType by default */
	selectedGame: PropTypes.oneOf(Object.values(GAME_TYPE)),
	/** React property, given callback from parent component or setCurrentGame by default */
	setSelectedGame: PropTypes.func
};


export default Game;
