import PropTypes from "prop-types";
import gameType from "types/game.type";
import ruleType from "types/rule.type";
import currencyType from "types/currency.type";
import analyticalToolType from "types/analyticalTool.type";
import loginConfigurationType from "types/loginConfiguration.type";
import securitySettingsType from "types/securitySettings.type";

export default PropTypes.shape({
	isAuthorized: PropTypes.bool,
	loginUrl: PropTypes.string,
	isDemo: PropTypes.bool,
	languageId: PropTypes.string,
	languageFilePath: PropTypes.string,
	languageRulesFilePath: PropTypes.string,
	exitUrl: PropTypes.string,
	depositUrl: PropTypes.string,
	balance: PropTypes.number,
	currency: currencyType,
	games: PropTypes.arrayOf(gameType),
	rules: PropTypes.objectOf(ruleType),
	projectId: PropTypes.string,
	projectName: PropTypes.string,
	sessionId: PropTypes.string,
	partnerId: PropTypes.string,
	analyticalTools: PropTypes.arrayOf(analyticalToolType),
	loginConfiguration: loginConfigurationType,
	securitySettings: securitySettingsType,
	logoId: PropTypes.number,
	cssId: PropTypes.number,
	isSplitStakeEnabled: PropTypes.bool
});
