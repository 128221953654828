import PropTypes from "prop-types";

import moment from "moment";

import useAppSelector from "hooks/store/useAppSelector";

import { selectCurrentTime } from "store/slices/common/selectors";

/* Clock Component at right of Header */
const Clock = () => {
	const currentTime = useAppSelector(selectCurrentTime);

	return (
		<div className="vs--header-clock vs--flex vs--align-center vs--pl-16">
			<span className="vs--header-clock-title vs--title-gray-80 vs--font-normal vs--font-regular ">{moment.unix(currentTime).format("HH:mm:ss")}</span>
		</div>
	);
};

export default Clock;
