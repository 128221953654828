import { useReducer } from "react";

const initialValue = 0;
const reducer = (v) => v + 1;

/**
 * React Hook that forces your function component to re-render
 * @returns {[function, number]}
 */
const useForceUpdate = () => {
	const [updateValue, updateFunc] = useReducer(reducer, initialValue);
	return [updateFunc, updateValue];
};

export default useForceUpdate;
