import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";

import { setSeasonResultModalMobileAction } from "store/slices/season/actions";
import { selectSeasonShowResultModalBySeasonId } from "store/slices/season/selectors";
import { selectCurrentGameType } from "store/slices/game/selectors";

import Standings from "components/ui/season/standings";
import CupStructure from "components/ui/season/cupStructure";
import LeagueGamesResultsHoc from "hocs/seasonGamesInfo/leagueGamesResultsHoc";
import CupGamesResultsHoc from "hocs/seasonGamesInfo/cupGamesResultsHoc";
import useSwipeableModal from "hooks/useSwipeableModal";
import { isCupGame, isLeagueGame } from "utils/common";

const WrappedReactComponentOfLeagueGamesResultSeasonHoc = LeagueGamesResultsHoc(Standings);
const WrappedReactComponentOfCupGamesResultSeasonHoc = CupGamesResultsHoc(CupStructure);

/** Season Result Modal Component */
const SeasonResultModal = () => {
	const showSeasonResultModalBySeasonId = useAppSelector(selectSeasonShowResultModalBySeasonId);
	const currentGameType = useAppSelector(selectCurrentGameType);
	const { t } = useTranslation();

	const dispatch = useAppDispatch();

	const data = useSwipeableModal({
		title: isLeagueGame(currentGameType) ? t("common.teamStandings") : isCupGame(currentGameType) ? t("common.cupStructure") : "",
		onClose: () => dispatch(setSeasonResultModalMobileAction(null)),
		visibilityState: !!showSeasonResultModalBySeasonId,
		children: isLeagueGame(currentGameType) ? <WrappedReactComponentOfLeagueGamesResultSeasonHoc id={showSeasonResultModalBySeasonId} gameType={currentGameType} /> : isCupGame(currentGameType) ? <WrappedReactComponentOfCupGamesResultSeasonHoc id={showSeasonResultModalBySeasonId} /> : null
	});

	return data?.component;
};

export default SeasonResultModal;
