import { Fragment, useMemo, useState } from "react";
import "prop-types";
import ScrollBar from "components/ui/scrollBar";
import TabsComponent from "./tabStructureComponents/tabsComponent";
import TabStructureTitle from "./tabStructureComponents/tabStructureTitle";
import TabStructureBody from "./tabStructureComponents/tabStructureBody";
import eventType from "types/event.type";
import useGlobalVariables from "hooks/useGlobalVariables";
import { mergeClassNames } from "utils/common";

/** Tab Structure Component */
const TabStructure = ({ seasonStructure }) => {
	const { isMobile } = useGlobalVariables();
	const [activeTab, setActiveTab] = useState(1);
	const { round, prevRound } = useMemo(() => {
		const retVal = { round: null, prevRound: null };
		if (!seasonStructure.events) return retVal;
		retVal.round = seasonStructure.events.find((e) => e.orderNumber === activeTab);
		if (activeTab > 1) {
			retVal.prevRound = seasonStructure.events.find((e) => e.orderNumber === activeTab - 1);
		}
		return retVal;
	}, [seasonStructure.events, activeTab]);

	return (
		<Fragment>
			<div className={mergeClassNames("vs--game-live-result-sctructure-inner vs--flex vs--flex-col", isMobile && "vs--pb-0")}>
				{isMobile ? null : <TabsComponent seasonStructure={seasonStructure} activeTab={activeTab} setActiveTab={setActiveTab} />}
				{isMobile ? null : <TabStructureTitle seasonStructure={seasonStructure} round={round} activeTab={activeTab} />}
				<ScrollBar className="vs--season-structure-list vs--flex-equal vs--scrollbar-thin vs--flex vs--flex-col vs--justify-between vs--flex-equal" vertical={true}>
					{isMobile ? <TabStructureTitle seasonStructure={seasonStructure} round={round} activeTab={activeTab} /> : null}
					<div className={mergeClassNames(isMobile && "vs--overflow-auto")}>
						<TabStructureBody prevRound={prevRound} round={round} />
					</div>
					{isMobile ? <TabsComponent seasonStructure={seasonStructure} activeTab={activeTab} setActiveTab={setActiveTab} /> : null}
				</ScrollBar>
			</div>
		</Fragment>
	);
};

/** TabStructure propTypes
 * PropTypes
 */
TabStructure.propTypes = {
	/** Redux state property, the season data */
	seasonStructure: eventType
};

export default TabStructure;
