import PropTypes from "prop-types";
import useGlobalVariables from "hooks/useGlobalVariables";
import { mergeClassNames } from "utils/common";

const StructureContainer = ({ children }) => {
	const { isMobile } = useGlobalVariables();
	return <div className={mergeClassNames("vs--game-live-result-sctructure", isMobile && "vs--flex vs--flex-col")}>{children}</div>;
};

StructureContainer.propTypes = {
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.node])
};

export default StructureContainer;
