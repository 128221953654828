import { TableColumnNode } from "helpers/table.halper";
import { isNullish, mergeClassNames, isChampionsCupGame, isLeagueGame } from "utils/common";
import i18n from "translations/config";
import Flag from "components/ui/flag";
import LiveScoreAction from "components/ui/season/liveScores/liveScoreAction";
import FootballColumnComponent from "components/pages/home/partials/markets/partials/football/footballColumnComponent";
import PenaltyColumn from "components/pages/home/partials/markets/partials/penaltyShootout/penaltyColumn";
import PenaltyShotMarketGroupColumn from "components/pages/home/partials/markets/partials/penaltyShootout/expandContent/penaltyShotMarketColumn";

export const BLUNK_COLUM = new TableColumnNode(null, null, null, null);

export const COLLAPSE_ROW_CONFIG = {
	ADD_HIDEN_CLASS_TIMEOUT: 333,
	REMOVE_HIDEN_CLASS_TIMEOUT: 0
};

export const DEFAULT_CLASSES = {
	TABLE_WRAPPER: "vs--table-wrapper",
	TABLE_WRAPPER_SCROLLABLE: "vs--table-wrapper-scrollable",
	TABLE_SCROLLBAR_CONTAINER: "vs--table-scrollbar-container",
	TABLE_SCROLLBAR_CONTAINER_SCROLLABLE: "vs--table-scrollbar-container-scrollable",
	TABLE_SCROLLBAR_CONTAINER_SCROLLABLE_MOBILE: "vs--table-scrollbar-container-scrollable-mobile",
	MAIN_CLASS: "vs--table",
	TABLE_SCROLLABLE: "vs--table-scrollable",
	EXPANDABLE_CLASS: "vs--table-expandable",
	ALWAYS_EXPANDED: "vs--table-expandable-always",
	HEAD_CLASS: "vs--table-head",
	BODY_CLASS: "vs--table-body",
	HEAD_ROW_CLASS: "vs--table-row",
	BODY_ROW_CLASS: "vs--table-row",
	COLUMN_CLASS: "vs--table-column",
	COLUMN_EXPANDABLE_CLASS: "vs--table-column-expandable",
	TEXT_CLASS: "vs--table-text",
	BODY_ROW_SELECTED_CLASS: "vs--table-row-selected",
	ROW_EXPANDED: "vs--table-row-expanded",
	ROW_COLLAPSE: "vs--table-row-collapse",
	ROW_COLLAPSE_HIDDEN: "vs--table-row-collapse-hidden",
	ROW_COLLAPSE_VISIBLE: "vs--table-row-collapse-visible",
	ROW_COLLAPSE_WRAPPER: "vs--table-row-collapse-wrapper",
	ROW_COLLAPSE_WRAPPER_HIDDEN: "vs--table-row-collapse-wrapper-hidden",
	ROW_COLLAPSE_WRAPPER_VISIBLE: "vs--table-row-collapse-wrapper-visible",
	ROW_COLLAPSE_CONTENT: "vs--table-row-collapse-content"
};

export const SEPARATE_CLASSES = {
	true: "vs--table-border-separate",
	false: "vs--table-border-collapse"
};

export const LAYOUT_CLASSES = {
	auto: "vs--table-layout-auto",
	fixed: "vs--table-layout-fixed",
	revert: "vs--table-layout-revert",
	"revert-layer": "vs--table-layout-revert-layer"
};

export const TEXT_ALIGN_CLASSES = {
	left: "vs--text-left",
	center: "vs--text-center",
	right: "vs--text-right"
};

export const VERTICAL_ALIGN = {
	baseline: "vs--table-vertical-align-baseline",
	sub: "vs--table-vertical-align-sub",
	super: "vs--table-vertical-align-super",
	"text-top": "vs--table-vertical-align-text-top",
	"text-bottom": "vs--table-vertical-align-text-bottom",
	middle: "vs--table-vertical-align-middle",
	top: "vs--table-vertical-align-unset",
	bottom: "vs--table-vertical-align-bottom",
	inherit: "vs--table-vertical-align-inherit",
	initial: "vs--table-vertical-align-initial",
	revert: "vs--table-vertical-align-revert",
	"revert-layer": "vs--table-vertical-align-revert-layer",
	unset: "vs--table-vertical-align-unset"
};

export const EVENTS_TABLE_COLUMNS_WIDTH = {
	ICON: 0,
	TEAM: 76,
	TEAM_MOBILE_NOT_FINISHED: 140,
	VS: 50,
	RESULT_TYPE: 65,
	SIZELESS: "auto"
};

export const BET_HISTORY_TABLE_OPTIONS = {
	WEB: {
		DETAILS_COLUMNS: Array.from({ length: 9 }, (_, i) => i)
	},
	MOBILE: {
		COLUMNS: Array.from({ length: 4 }, (_, i) => i),
		COLUMNS_WIDTH: [125, 40, null, 40],
		COLUMNS_ALIGNMENT: ["left", "center", "right", "center"],
		SINGLE: {
			COLUMNS: Array.from({ length: 2 }, (_, i) => i),
			COLUMNS_WIDTH: ["50%", "50%"],
			COLUMNS_ALIGNMENT: ["left", "right"]
		}
	}
};

export const RESULTS_TABLE_OPTIONS = {
	WEB: {
		REGULAR_RESULTS_DETAILS: {
			COLUMNS: Array.from({ length: 8 }, (_, i) => i),
			COLUMNS_WIDTH: Array.from({ length: 8 }, (_, i) => (i % 2 === 1 ? "calc( calc(100% - 300px) / 4 )" : 100)),
			COLUMNS_ALIGNMENT: Array.from({ length: 8 }, (_, i) => /* i % 2 === 1 ? */ "left" /* : "center" */)
		}
	},
	MOBILE: {
		COLUMNS: Array.from({ length: 4 }, (_, i) => i),
		COLUMNS_WIDTH: ["calc(calc(100% - 90px) / 2)", 45, "calc(calc(100% - 90px) / 2)", 45],
		COLUMNS_ALIGNMENT: ["left", "center", "right", "center"],
		SINGLE: {
			COLUMNS: Array.from({ length: 2 }, (_, i) => i),
			COLUMNS_WIDTH: ["50%", "50%"],
			COLUMNS_ALIGNMENT: ["left", "right"]
		}
	}
};

export const BET_HISTORY_TABLE_STATIC_COLUMNS = {
	WEB_DETAILS: BET_HISTORY_TABLE_OPTIONS.WEB.DETAILS_COLUMNS.map((num) => {
		return new TableColumnNode(
			num,
			null,
			(...args) => {
				const value = args.at(0);
				const { cellIndex } = args.at(3);
				const { tableTextClassName } = args.at(-1);
				const drowDots = cellIndex % 2 === 0 && BET_HISTORY_TABLE_OPTIONS.WEB.DETAILS_COLUMNS.length - 1 !== cellIndex;
				return (
					<span className={tableTextClassName}>
						{value}
						{drowDots ? ":" : null}
					</span>
				);
			},
			{ width: num % 2 === 0 ? "7%" : "18%" }
		);
	}),
	MOBILE: BET_HISTORY_TABLE_OPTIONS.MOBILE.COLUMNS.map((num) => {
		return new TableColumnNode(
			num,
			null,
			(...args) => {
				const value = args.at(0);
				const { tableTextClassName } = args.at(-1);
				return <span className={tableTextClassName}>{value}</span>;
			},
			{
				width: BET_HISTORY_TABLE_OPTIONS.MOBILE.COLUMNS_WIDTH[num],
				alignment: BET_HISTORY_TABLE_OPTIONS.MOBILE.COLUMNS_ALIGNMENT[num]
			}
		);
	}),
	SINGLE: BET_HISTORY_TABLE_OPTIONS.MOBILE.SINGLE.COLUMNS.map((num) => {
		return new TableColumnNode(
			num,
			null,
			(...args) => {
				const value = args.at(0);
				const { tableTextClassName } = args.at(-1);
				return <span className={tableTextClassName}>{value}</span>;
			},
			{
				width: BET_HISTORY_TABLE_OPTIONS.MOBILE.SINGLE.COLUMNS_WIDTH[num],
				alignment: BET_HISTORY_TABLE_OPTIONS.MOBILE.SINGLE.COLUMNS_ALIGNMENT[num]
			}
		);
	})
};

export const RESULTS_TABLE_STATIC_COLUMNS = {
	WEB_REGULAR_RESULTS_DETAILS: RESULTS_TABLE_OPTIONS.WEB.REGULAR_RESULTS_DETAILS.COLUMNS.map((num) => {
		return new TableColumnNode(
			num,
			null,
			(...args) => {
				const value = args.at(0);
				const { cellIndex } = args.at(3);
				const { tableTextClassName } = args.at(-1);
				const drowDots = cellIndex % 2 === 0;
				return (
					<span className={tableTextClassName}>
						{value}
						{!isNullish(value) && drowDots ? <span className="vs--title-gray-50">:</span> : null}
					</span>
				);
			},
			{
				width: RESULTS_TABLE_OPTIONS.WEB.REGULAR_RESULTS_DETAILS.COLUMNS_WIDTH[num],
				alignment: RESULTS_TABLE_OPTIONS.WEB.REGULAR_RESULTS_DETAILS.COLUMNS_ALIGNMENT[num]
			}
		);
	})
};

export const LIVE_SCORE_TABLE_STATIC_COLUMNS = [
	new TableColumnNode(
		null,
		null,
		(...args) => {
			const { tableTextClassName, rowIndex } = args.at(-1);
			return (
				<span className={mergeClassNames(tableTextClassName, "vs--table-live-score-row-number", "vs--table-column-border vs--table-column-border-right", "vs--flex vs--justify-center vs--align-center")}>
					{rowIndex === 0 ? <i className="ic_watch vs--font-bigest vs--color-green" /> : <span className="vs--title-white vs--font-regular vs--font-normal">{rowIndex + 1}</span>}
				</span>
			);
		},
		{ width: 40, colProps: { style: { minWidth: 40 } } }
	),
	new TableColumnNode(null, null, (...args) => {
		const record = args.at(1);
		const { tableTextClassName } = args.at(-1);

		const getTeamCountryCode = (record, teamIndex) => record?.gameData?.[`team${teamIndex}`]?.countryCode ?? "";

		return (
			<span className={mergeClassNames(tableTextClassName, "vs--live-score-team-container vs--live-score-team-container-left vs--pl-8 vs--flex vs--justify-start vs--align-center")}>
				<Flag code={getTeamCountryCode(record, 1)} gameType={record.gameType} />
				<span className="vs--live-score-team-name vs--title-white vs--font-regular vs--font-normal vs--pl-8 vs--pr-8">
					{isLeagueGame(record.gameType) || isChampionsCupGame(record.gameType) ? getTeamCountryCode(record, 1) : i18n.t(`countries.${getTeamCountryCode(record, 1)}`)}
				</span>
			</span>
		);
	}),
	new TableColumnNode(null, null, (...args) => {
		const record = args.at(1);
		const { tableTextClassName } = args.at(-1);

		const getTeamGoalCount = (record, teamIndex) => record?.gameData?.[`team${teamIndex}`]?.goalCount ?? 0;

		return (
			<span className={mergeClassNames(tableTextClassName, "vs--flex vs--justify-center vs--align-center")}>
				<span className="vs--title-white vs--font-medium vs--font-big">
					{getTeamGoalCount(record, 1)}
					&nbsp;-&nbsp;
					{getTeamGoalCount(record, 2)}
				</span>
			</span>
		);
	}),
	new TableColumnNode(null, null, (...args) => {
		const record = args.at(1);
		const { tableTextClassName } = args.at(-1);

		const getTeamCountryCode = (record, teamIndex) => record?.gameData?.[`team${teamIndex}`]?.countryCode ?? "";

		return (
			<span className={mergeClassNames(tableTextClassName, "vs--live-score-team-container vs--live-score-team-container-right vs--pr-8 vs--flex vs--justify-end vs--align-center")}>
				<span className="vs--live-score-team-name vs--title-white vs--font-regular vs--font-normal vs--pl-8 vs--pr-8">
					{isLeagueGame(record.gameType) || isChampionsCupGame(record.gameType) ? getTeamCountryCode(record, 2) : i18n.t(`countries.${getTeamCountryCode(record, 2)}`)}
				</span>
				<Flag code={getTeamCountryCode(record, 2)} gameType={record.gameType} />
			</span>
		);
	}),
	new TableColumnNode(
		null,
		null,
		(...args) => {
			const { tableTextClassName, rowIndex } = args.at(-1);
			return (
				<span className={mergeClassNames(tableTextClassName, "vs--table-column-border vs--table-column-border-left", "vs--table-live-score-action-column", "vs--flex vs--justify-center vs--align-center")}>
					<LiveScoreAction rowIndex={rowIndex} />
				</span>
			);
		},
		{ width: 40, colProps: { style: { minWidth: 40 } } }
	)
];

export const FOOTBALL_MARKETS_COLUMNS = [
	new TableColumnNode(null, null, (...args) => {
		const market = args.at(1);
		return <FootballColumnComponent market={market} />;
	})
];

export const PENALTY_SHOOTOUT_MARKETS_COLUMNS = [
	new TableColumnNode(null, null, (...args) => {
		const market = args.at(1);
		return <PenaltyColumn market={market} />;
	})
];

export const PENALTY_SHOT_MARKET_GROUP_COLUMNS = Array.from({ length: 3 }, (_, index) => {
	return new TableColumnNode(
		null,
		index === 1 && "",
		(...args) => {
			const markets = args.at(1);
			const { rowIndex, cellIndex } = args.at(-1);

			return <PenaltyShotMarketGroupColumn markets={markets} rowIndex={rowIndex} cellIndex={cellIndex} />;
		},
		{ width: "33%" }
	);
});
