import { useRef } from "react";
import PropTypes from "prop-types";

import { CollapseProvider } from "providers/collapse";

import useMergedState from "hooks/useMergedState";

import { updateExpandedRowIds } from "utils/ui";

import { COLLAPSE_MODE } from "constants/ui.constants";

const Collapse = ({
	children,
	mode = COLLAPSE_MODE.COLLAPSE,
	expandRowItems = false,
	defaultValue = [],
	value,
	onChange,
	expandIconRenderer,
	expandIconPosition,
	expandAll,
	className = ""
}) => {
	const [expandedRowIds, setExpandedRowIds] = useMergedState(defaultValue, {
		value,
		onChange
	});

	const containerRef = useRef();

	const handleCollapseChange = (rowId) => {
		const updatedExpandedRowIds = updateExpandedRowIds({
			expandedRowIds,
			mode,
			currentRowId: rowId
		});

		setExpandedRowIds(updatedExpandedRowIds);
	};

	return (
		<div className={`vs--ui-collapse-container ${className}`} ref={containerRef}>
			<CollapseProvider
				expandAll={expandAll} 
				handleCollapseChange={handleCollapseChange}
				expandedRowIds={expandedRowIds}
			>
				{children}
			</CollapseProvider>
		</div>
	);
};

/** Collapse propTypes
 * PropTypes
 */
Collapse.propTypes = {
	/** Function which will fire on change of collapse panel */
	onChange: PropTypes.func,
	/** Arrow icon renderer */
	arrowIconRenderer: PropTypes.func,
	/** Class to rewrite default styles of collapse */
	className: PropTypes.string,
	/** Expand icon renderer */
	expandIconRenderer: PropTypes.func,
	/** Specified expand icon position */
	expandIconPosition: PropTypes.string,
	/** Elements to render  */
	children: PropTypes.node,
	/** Specified which mode is current */
	mode: PropTypes.string,
	/** Default expanded rows */
	defaultValue: PropTypes.array,
	/** Expanded row ids controlled from outside  */
	value: PropTypes.array
};

export default Collapse;
