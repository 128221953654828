import { getDefaultDate } from "utils/dateTime";

const sliceName = "betHistory";
const initialState = {
	loaded: false,
	loading: false,
	isAdding: false,
	data: [],
	filters: {
		limit: 20,
		page: 1,
		...getDefaultDate()
	},
	filtersReset: false,
	total: 0,
	openedBetSlipId: null
}

export { sliceName, initialState };