import { useState, useEffect } from "react";
import ComboBoostStepProgress from "./comboBoostStepProgress";
import ComboBoostTitle from "./comboBoostTitle";
import useComboBoost from "hooks/useComboBoost";
import ComboBoostBoostText from "./comboBoostBoostText";
import useGlobalVariables from "hooks/useGlobalVariables";
import { mergeClassNames } from "utils/common";
import ComboBoostMinOddFactor from "./comboBoostMinOddFactor";
import ComboBoostDescription from "./comboBoostDescription";

const BetSlipComboBoost = ({ className }) => {
	const { isMobile } = useGlobalVariables();
	const {
		isComboBoostActive,
		comboBoostSettings,
		checkBoostIsActive,
		checkBoostIsCompleted,
		getBoostDescriptionText,
		getCurrentBoostFactor,
		calculatePossibleWin,
		stepsForDraw,
		currency,
	} = useComboBoost();

	const [isVisible, setIsVisible] = useState(isComboBoostActive);
	const [shouldFadeOut, setShouldFadeOut] = useState(false);


	useEffect(() => {
		if (isComboBoostActive) {
			setIsVisible(true);
			setShouldFadeOut(false);
		} else {
			setShouldFadeOut(false);
			const timer = setTimeout(() => {
				setIsVisible(false);
			}, 400);
			return () => clearTimeout(timer);
		}
	}, [isComboBoostActive]);

	if (!isVisible && !shouldFadeOut) {
		return null;
	}

	return (
		<div
			className={mergeClassNames(
				"vs--bet-slip-combo-boost vs--bet-slip-combo-boost-grey",
				className,
				isComboBoostActive ? "fade-in-boost" : "fade-out-boost"
			)}
		>
			<div className="vs--bet-slip-combo-boost-header">
				<ComboBoostTitle minOddFactor={comboBoostSettings.minOddFactor}>
					<ComboBoostBoostText
						getCurrentBoostFactor={getCurrentBoostFactor}
						calculatePossibleWin={calculatePossibleWin}
						currency={currency}
					/>
				</ComboBoostTitle>
			</div>
			<div className="vs--bet-slip-combo-boost-body vs--bet-slip-combo-boost-body-gray">
				<ComboBoostStepProgress
					boostFactors={comboBoostSettings.boostFactors}
					checkBoostIsActive={checkBoostIsActive}
					checkBoostIsCompleted={checkBoostIsCompleted}
					stepsForDraw={stepsForDraw}
				>
					<ComboBoostMinOddFactor minOddFactor={comboBoostSettings.minOddFactor} />
				</ComboBoostStepProgress>
			</div>
			<div className="vs--bet-slip-combo-boost-footer vs--bet-slip-combo-boost-footer-gray">
				<ComboBoostDescription boostDescriptionText={getBoostDescriptionText({ hideAlways: isMobile, showMaxCaseText: false })} />
			</div>
		</div>
	);
};

export default BetSlipComboBoost;
