import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import Collapse from "components/ui/collapse";
import PanelWrapper from "components/ui/collapse/panelWrapper";

import useAppSelector from "hooks/store/useAppSelector";

import { selectOddFormat } from "store/slices/auth/selectors";

import {
	isChampionsCupGame,
	isLeagueGame,
	isRacingGame,
	mergeClassNames,
	numberWithSpaces
} from "utils/common";
import { formatDateTime } from "utils/dateTime";
import { getBetStateText, getBetEventName } from "utils/bets";
import { GAME_TYPE, GAME_TYPE_ICON_MAPPER, GAME_TYPE_MAPPER, KENO_BALL_SIZE } from "constants/game.constants";
import KenoBalls from "components/keno/bets/kenoBalls";
import runMarketUtilsFunction from "utils/markets/run";
import { ODD_FORMATS } from "constants/market.constants";

const BetDetail = ({ bet }) => {
	const { t } = useTranslation();

	const oddFormat = useAppSelector(selectOddFormat);

	const betElement = runMarketUtilsFunction("makeBetText", [{ ...bet, group: bet.market }], bet.gameType);
	const oddText = numberWithSpaces(runMarketUtilsFunction("convertToFormat", [bet.factor, oddFormat])) + (oddFormat === ODD_FORMATS.DECIMAL ? "x" : "");

	const wrapDangerouslySetInnerHTML = (maxWidth, innerHTML) => (`<div style="max-width: ${maxWidth}px; overflow: hidden; text-overflow: ellipsis;">${innerHTML}</div>`);

	return (
		<div className="vs--pending-item-expand-content-bet-detail vs--flex vs--flex-col vs--title-white vs--font-small">
			<div className="vs--pending-item-expand-content-bet-detail-row vs--flex vs--justify-between vs--align-center vs--pl-8 vs--pr-8 vs--pt-8 vs--pb-8">
				<div className="vs--flex vs--align-center">
					<i className={mergeClassNames(GAME_TYPE_ICON_MAPPER[bet.gameType], "vs--mr-4 vs--font-20")} />
					{(bet.gameType === GAME_TYPE.KENO || isRacingGame(bet.gameType)) ? (
						<span>
							{t(`common.${GAME_TYPE_MAPPER[bet.gameType]}`)}
						</span>
					) : (
						<span>
							{getBetEventName(bet)}
						</span>
					)}
				</div>
				<div
					className={mergeClassNames(
						"vs--pl-4 vs--pr-4 vs--pending-item-expand-content-bet-detail-row-status vs--title-white",
						`vs--pending-item-expand-content-bet-detail-row-status-${bet.status}`
					)}
				>
					<span className="vs--pending-item-expand-content-bet-detail-row-status-text vs--font-small vs--font-bold">{getBetStateText(bet.status)}</span>
				</div>
			</div>
			<div className="vs--pending-item-expand-content-bet-detail-row vs--flex vs--justify-between vs--align-center vs--pl-8 vs--pr-8 vs--pt-8 vs--pb-8">
				<div>{t("common.eventId")}:&nbsp;{bet.eventId}</div>
				<div>{formatDateTime(bet.eventStartTime)}</div>
			</div>
			<div className="vs--pending-item-expand-content-bet-detail-row vs--flex vs--justify-between vs--align-center vs--pl-8 vs--pr-8 vs--pt-8 vs--pb-8">
				<div>{t("bet.betId")}:&nbsp;{bet.betId}</div>
				<div>{formatDateTime(bet.betTime)}</div>
			</div>
			{bet.gameType === GAME_TYPE.KENO && (
				<div
					className="vs--pending-item-expand-content-bet-detail-row vs--flex vs--justify-between vs--align-center vs--pt-8 vs--pb-8"
				>
					<div className="vs--pending-item-expand-content-bet-detail-row-keno-result vs--flex vs--flex-col">
						<div className="vs--flex vs--justify-between vs--align-center vs--pl-8 vs--pr-8">
							<KenoBalls
								balls={bet.outcome.split(":").map((b) => Number(b))}
								size={KENO_BALL_SIZE.SMALL}
							/>
							<span>{oddText}</span>
						</div>
					</div>
				</div>
			)}
			{bet.gameType !== GAME_TYPE.KENO && (
				<div className="vs--pending-item-expand-content-bet-detail-row vs--flex vs--justify-between vs--align-center vs--pl-8 vs--pr-8 vs--pt-8 vs--pb-8">
					<div
						className="vs--pending-item-expand-content-bet-detail-row-item-value vs--font-regular vs--font-small"
						title={betElement}
						dangerouslySetInnerHTML={{ __html: wrapDangerouslySetInnerHTML(250, betElement) }}
					/>
					<div dir="ltr">{oddText}</div>
				</div>
			)}
		</div>
	)
};

export default BetDetail;