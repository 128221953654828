import { useEffect, useRef } from "react";

const useUpdateEffect = (effect, deps) => {
	const isMountedRef = useRef(false);
	useEffect(() => {
		if (!isMountedRef.current) {
			isMountedRef.current = true;
			return;
		}
		return effect();
	}, deps);
};

export default useUpdateEffect;
