import moment from "moment";

import { gameRulesThunk } from "./thunks";

import {
	setCurrentTimeAction,
	setShowLiveInfoEventAction,
	setLiveInfoEventAction,
	updateLiveInfoEventAction,
	setEventPositionsAction,
	setShowRulesAction,
	toggleShowRulesAction,
	setVideoMinimizedAction,
	toggleFeedbackVisibilityAction,
	setErrorMessageAction,
	setBlockedMessageAction
} from "./actions";

import { getDefaultLiveInfoPositions } from "utils/common";
import LocalStorageUtils from "utils/localStorage";

import { GAME_STATUSES } from "constants/game.constants";

export const addCurrentTimeCases = (builder) => {
	builder.addCase(setCurrentTimeAction, (state) => {
		state.currentTime = moment().unix();
	});
}

export const addEventLiveInfoCases = (builder) => {
	builder.addCase(setShowLiveInfoEventAction, (state, { payload }) => {
		state.showLiveInfoEvent = payload.show;
	});

	builder.addCase(setLiveInfoEventAction, (state, { payload }) => {
		state.liveInfoEvent = payload.event;
		state.showLiveInfoEvent = Boolean(payload.event);
		const isSameState = !payload.event || payload.event.id === state?.liveInfoPositions?.[payload.event.gameType]?.eventId;
		if (!isSameState) {
			state.liveInfoPositions = getDefaultLiveInfoPositions()
		}
	});

	builder.addCase(updateLiveInfoEventAction, (state, { payload }) => {
		let needUpdateLiveInfo = false;
		let updatedLiveInfoEvent = state.liveInfoEvent ? state.liveInfoEvent : null;
		if (state.liveInfoEvent && state.liveInfoEvent.id === payload.event.id && payload.event.status !== GAME_STATUSES.FINISHED) {
			updatedLiveInfoEvent = {
				...payload.event,
				events: state.liveInfoEvent.events
			};
			needUpdateLiveInfo = true;
		} else if (state.liveInfoEvent && payload.event.status !== GAME_STATUSES.FINISHED && state.liveInfoEvent.events) {
			updatedLiveInfoEvent = {
				...state.liveInfoEvent,
				events: state.liveInfoEvent.events.map((e) => (e.id === payload.event.id ? payload.event : e))
			};
			needUpdateLiveInfo = true;
		}

		if (!needUpdateLiveInfo) {
			return
		}

		state.liveInfoEvent = updatedLiveInfoEvent;

		const isNeedUpdate_showLiveInfoEvent = (
			state.liveInfoEvent && 
			state.liveInfoEvent.id === payload.event.id && 
			payload.event.status === GAME_STATUSES.FINISHED
		)

		if (isNeedUpdate_showLiveInfoEvent) {
			state.showLiveInfoEvent = false
		}
	});

	builder.addCase(setEventPositionsAction, (state, { payload }) => {
		state.liveInfoPositions[payload.positions.gameType] = payload.positions;
	});
}

export const addRulesCases = (builder) => {
	builder.addCase(gameRulesThunk.pending, (state) => {
		state.rules.isLoading = true;
	});

	builder.addCase(gameRulesThunk.fulfilled, (state, { payload: data }) => {
		state.rules.isLoading = false;
		state.rules.prev[data?.[1]?.gameType] = data?.[1] ?? null;
		state.rules.current[data?.[0]?.gameType] = data?.[0] ?? null;
	});

	builder.addCase(gameRulesThunk.rejected, (state, { error, meta: { arg } }) => {
		state.rules.isLoading = false;
	});

	builder.addCase(setShowRulesAction, (state, { payload }) => {
		state.rules.showRules = payload.showRules;
	});

	builder.addCase(toggleShowRulesAction, (state) => {
		state.rules.showRules = !state.showRules;
	});
}

export const addStreamCases = (builder) => {
	builder.addCase(setVideoMinimizedAction, (state, { payload }) => {
		state.videoMinimized = payload.minimized;
	});
}

export const addFeedbackCases = (builder) => {
	builder.addCase(toggleFeedbackVisibilityAction, (state, { payload }) => {
		state.isFeedbackVisible = !LocalStorageUtils.get(payload.storageKey);
	});
}

export const addMessageCases = (builder) => {
	builder.addCase(setErrorMessageAction, (state, { payload }) => {
		state.errorMessage = payload.message;
	});

	builder.addCase(setBlockedMessageAction, (state, { payload }) => {
		state.blockedMessage = payload.message;
	});
}