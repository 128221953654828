export const BETSLIP_TABS = {
	BETSLIP: 1,
	PENDING_BETS: 2
};

export const BETSLIP_MODES = {
	SINGLE: 1,
	MULTI: 2
}

export const DISABLED_BETSLIP_MODES = [];

export const BETSLIP_STAKE_MODES = {
	PER_BET: 0,
	SPLIT: 1
}

export const BET_STATE = {
	PENDING: 1,
	RETURN: 2,
	WON: 4,
	SEMIWON: 8,
	SEMILOST: 16,
	LOST: 32,
	CANCELLED: 64,
	REJECTED_BY_OPERATOR: 128
}

export const ODD_STATE = {
	PENDING: 0,
	WON: 1,
	LOST: 2,
	SEMIWON: 3,
	RETURN: 4,
	SEMILOST: 5
}

export const MULTI_MODE_BETS_MAX_FACTORS = {
	WITH_KENO_BET: 11001,
	WITHOUT_KENO_BET: 1001
};

export const BETSLIP_BETS_LIMIT = 30;

export const BETSLIP_KEEP_FOCUSED_STAKE_DATA_ATTRS = {
	BET_STAKE: "data-is-bet-stake",
	BETSLIP_STAKE: "data-is-betslip-stake",
	FAV_BET_ITEM: "data-is-fav-bet-item",
	KEYBOARD: "data-is-keyboard"
}

export const QUICK_BET_ANIMATABLE_EL_DATA_ATTRS = {
	BET_HISTORY: "history",
	BADGE: "badge",
	BETSLIP_HEADER: "betslip-header",
	RIGHTBAR_CONTENT: "RightBarContent",
	BETSLIP: "betslip"
}

export const COMBO_BOOST_STEPS = {
	FOUR_BETS: 4,
	FIVE_BETS: 5,
	SIX_BETS: 6,
	SEVEN_BETS: 7,
	EIGHT_BETS: 8,
	NINE_BETS: 9,
	TEN_BETS: 10
}

export const COMBO_BOOST_STEP_FORM_NAMES = {
	[COMBO_BOOST_STEPS.FOUR_BETS]: "fourBets",
	[COMBO_BOOST_STEPS.FIVE_BETS]: "fiveBets",
	[COMBO_BOOST_STEPS.SIX_BETS]: "sixBets",
	[COMBO_BOOST_STEPS.SEVEN_BETS]: "sevenBets",
	[COMBO_BOOST_STEPS.EIGHT_BETS]: "eightBets",
	[COMBO_BOOST_STEPS.NINE_BETS]: "nineBets",
	[COMBO_BOOST_STEPS.TEN_BETS]: "tenBets",
}