import React, { useEffect, useState, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import useEvent from "hooks/useEvent";
import { mergeClassNames } from "utils/common";
import useGlobalVariables from 'hooks/useGlobalVariables';

const AnimatedDiv = ({
  children = null,
  className = "",
  startTransition = false,
  targetSelector,
  onAnimationEnd: onAnimationEndProp,
  containerClass = '.vs--wrapper-section-inner',
  stickyHeight = 150,
  position = 'center',
  targetAnimationClass = 'vs--target-animation'
}) => {
  const [animationQueue, setAnimationQueue] = useState([]);
  const [isAnimating, setIsAnimating] = useState(false);
  const childRef = useRef(null);
  const onAnimationEnd = useEvent(onAnimationEndProp);
  const { isMobile } = useGlobalVariables()

  useEffect(() => {
    if (childRef.current) {
      const initialRect = childRef.current.getBoundingClientRect();

      if (targetSelector && !isAnimating && startTransition) {
        setAnimationQueue((prevQueue) => [...prevQueue, { initialRect, targetSelector, onAnimationEnd }]);
      }
    }
  }, [startTransition, targetSelector, isAnimating]);

  useEffect(() => {
    if (!isAnimating && animationQueue.length > 0) {
      setIsAnimating(true);
      const nextAnimation = animationQueue[0];
      animate(nextAnimation);

      setAnimationQueue((prevQueue) => prevQueue.slice(1)); // Dequeue next animation
    }
  }, [animationQueue, isAnimating]);

  const animate = ({ initialRect, targetSelector, onAnimationEnd }) => {
    const { height, width, top, left } = initialRect;

    const targetElement = document.querySelector(targetSelector);
    if (!targetElement) return;

    const { top: targetTop, left: targetLeft, width: targetWidth, height: targetHeight } = targetElement.getBoundingClientRect();

    let translateX = 0;
    let translateY = 0;

    switch (position) {
      case 'top':
        translateX = targetLeft - left;
        translateY = targetTop - top;
        break;
      case 'bottom':
        translateX = targetLeft - left;
        translateY = targetTop + targetHeight - (top + height);
        break;
      case 'left':
        translateX = targetLeft - left;
        translateY = targetTop - top;
        break;
      case 'right':
        translateX = targetLeft + targetWidth - (left + width);
        translateY = targetTop - top;
        break;
      case 'center':
      default:
        const divCenterX = left + width / 2;
        const divCenterY = top + height / 2;
        const targetCenterX = targetLeft + targetWidth / 2;
        const targetCenterY = targetTop + targetHeight / 2;

        translateX = targetCenterX - divCenterX;
        translateY = targetCenterY - divCenterY;
        break;
    }

    const newDiv = document.createElement("div");
    newDiv.style.position = "absolute";
    newDiv.style.height = `${height}px`;
    newDiv.style.width = `${width}px`;
    newDiv.style.top = `${top}px`;
    newDiv.style.left = `${left}px`;
    newDiv.style.willChange = "transform"; // Hint to browser to optimize for animations
    newDiv.className = mergeClassNames("vs--animatable-container", className);

    document.body.appendChild(newDiv);

	 const performAnimation = () => {
      newDiv.style.transform = `translate(${translateX}px, ${translateY}px) scale(0.1)`;
      newDiv.classList.add("vs--animatable-container-start-animation");
     
      const handleTransitionEnd = () => {
        if (targetElement.getAttribute('data-element-id') === 'badge') {
          // Apply animation class to target element
          targetElement.classList.add(targetAnimationClass);
     
          // Remove animation class after animation completes
          setTimeout(() => {
            targetElement.classList.remove(targetAnimationClass);
          }, 200); // Adjust this timeout to match the duration of your CSS animation
        }

        // Check for mobile and apply animation to .vs--mobile-menu-item-count if necessary
        if (isMobile) {
          const mobileMenuItemCount = document.querySelector('.vs--mobile-menu-item-count');
          if (mobileMenuItemCount) {
            const mobileMenuItemCountRect = mobileMenuItemCount.getBoundingClientRect();
            const newDivRect = newDiv.getBoundingClientRect();

            const isMobileMenuItemCountVisible = (
              newDivRect.top < mobileMenuItemCountRect.bottom &&
              newDivRect.bottom > mobileMenuItemCountRect.top &&
              newDivRect.left < mobileMenuItemCountRect.right &&
              newDivRect.right > mobileMenuItemCountRect.left
            );

            if (isMobileMenuItemCountVisible) {
              mobileMenuItemCount.classList.add(targetAnimationClass);
              setTimeout(() => {
                mobileMenuItemCount.classList.remove(targetAnimationClass);
              }, 200); // Adjust this timeout to match the duration of your CSS animation
            }
          }
        }
        
        newDiv.remove();
        if (onAnimationEnd) onAnimationEnd();
        setIsAnimating(false);
      };
     
      newDiv.addEventListener("transitionend", handleTransitionEnd);
     
      return () => {
        if (newDiv) {
          newDiv.removeEventListener("transitionend", handleTransitionEnd);
          newDiv.remove();
        }
        // Ensure animation class is removed if the component unmounts or if the transition does not end
        if (targetElement.getAttribute('data-element-id') === 'badge') {
          targetElement.classList.remove(targetAnimationClass);
        }
      };
    };

    requestAnimationFrame(() => {
      const container = document.querySelector(containerClass);
      
      if (!container) {
        performAnimation();
        return;
      }

      const containerRect = container.getBoundingClientRect();
      const adjustedContainerRect = {
        top: containerRect.top + stickyHeight,
        bottom: containerRect.bottom,
        left: containerRect.left,
        right: containerRect.right
      };

      const newDivRect = newDiv.getBoundingClientRect();

      const isVisible = (
        newDivRect.top < adjustedContainerRect.bottom &&
        newDivRect.bottom > adjustedContainerRect.top &&
        newDivRect.left < adjustedContainerRect.right &&
        newDivRect.right > adjustedContainerRect.left
      );

      if (!isVisible) {
        newDiv.remove();
        setIsAnimating(false);
        if (onAnimationEnd) onAnimationEnd();
        return;
      }

      performAnimation();
    });
  };

  const clonedChildren = children ? React.cloneElement(children, { ref: childRef }) : null;

  return clonedChildren;
};

export default AnimatedDiv;