// Importing React and the canUseDom function
import { useEffect, useLayoutEffect, useRef } from "react";

import { canUseDom } from "utils/dom";

/**
 * Wrap `React.useLayoutEffect` which will not throw warning message in test env
 */
// Determining whether to use useLayoutEffect or useEffect based on environment
const useInternalLayoutEffect = process.env.NODE_ENV !== "test" && canUseDom() ? useLayoutEffect : useEffect;

// Defining useLayoutEffect function
const useLayoutEffectDefined = (callback, deps) => {
	// Creating a ref to keep track of first mount
	const firstMountRef = useRef(true);

	// Running the callback using useLayoutEffect
	useInternalLayoutEffect(() => {
		return callback(firstMountRef.current);
	}, deps);

	// Updating the firstMountRef to false after first mount
	useInternalLayoutEffect(() => {
		firstMountRef.current = false;
		return () => {
			firstMountRef.current = true;
		};
	}, []);
};

// Defining useLayoutUpdateEffect function
const useLayoutUpdateEffect = (callback, deps) => {
	// Running the callback using useLayoutEffect and checking if it's not the first mount
	useLayoutEffectDefined((firstMount) => {
		if (firstMount) {
			return;
		}
		callback();
	}, deps);
};

// Exporting useLayoutEffect
export default useLayoutUpdateEffect;
