import PropTypes from "prop-types";
import { mergeClassNames } from "utils/common";

/** Loader Component */
const Loader = ({ className, full, style, fixed }) => {
	return (
		<div
			className={mergeClassNames(
				"vs--loader vs--flex vs--flex-row vs--align-center vs--justify-center", 
				fixed && " vs--loader-fixed",
				className
			)}
			style={style ? style : full ? { height: "100vh" } : null}
		>
			<div className="vs--loader-inner" />
		</div>
	);
};

/** Loader propTypes
 * PropTypes
 */
Loader.propTypes = {
	/** Should loader be centered on screen */
	full: PropTypes.bool,
	/** Css style of loader */
	style: PropTypes.object,
	/** Should loader be full page fixed, with overlay */
	fixed: PropTypes.bool
};

export default Loader;
