import { useEffect } from "react";

const useAsyncEffect = (asyncfn, dependencies, cleanUpFn) => {
	useEffect(() => {
		asyncfn();
		return () => {
			if (typeof cleanUpFn === "function") {
				cleanUpFn();
			}
		};
	}, dependencies);
};

export default useAsyncEffect;
