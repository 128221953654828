import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useGlobalVariables from "hooks/useGlobalVariables";
import { mergeClassNames } from "utils/common";

/** Blocked Message Component */
const BlockedMessage = ({ title, message, imageSrc }) => {
	const { t } = useTranslation();
	const { isMobile } = useGlobalVariables();

	return (
		<div className={mergeClassNames("vs--blocked", imageSrc && "vs--deactivated")}>
			<div className="vs--blocked-inner vs--flex vs--flex-col vs--align-center vs--justify-center">
				{imageSrc ? <img className="vs--mb-16" src={imageSrc} /> : <i className="ic_alert vs--mb-16" />}
				<span className={mergeClassNames("vs--title-white vs--blocked-title", isMobile ? "vs--mb-4 vs--font-big" : "vs--mb-8 vs--font-extra-big")}>{t(title)}</span>
				<span className={mergeClassNames("vs--title-white vs--blocked-desc vs--pl-16 vs--pr-16", isMobile ? "vs--font-normal" : "vs--font-big")}>{t(message)}</span>
			</div>
		</div>
	);
};

/** BlockedMessage propTypes
 * PropTypes
 */
BlockedMessage.propTypes = {
	/** Current block title to show */
	title: PropTypes.string,
	/** Current block message to show */
	message: PropTypes.string,
	/** Current block image to show */
	imageSrc: PropTypes.string
};

export default BlockedMessage;
