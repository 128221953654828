import httpService from "services/http/index.js";
import ApiUrls from "constants/api.constants";

const luckySixService = {
	getEventById: (id, signal) => {
		return httpService.get({
			path: ApiUrls.GET_FOOTBALL_EVENT,
			signal,
			queryData: { id }
		});
	}
};

export default luckySixService;
