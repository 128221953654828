import ApiUrls from "constants/api.constants";

import httpService from "../http";

const feedbackService = {
	leaveFeedback: ({ surveyId, grade, message }, signal) => {
		return httpService.get({
			path: ApiUrls.LEAVE_FEEDBACK,
			queryData: { surveyId, grade, message },
			signal
		});
	}
};

export default feedbackService;
