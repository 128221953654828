import { Fragment } from "react";
import i18n from "translations/config";

import KenoBalls from "components/keno/bets/kenoBalls";

import { toFixed, isRacingGame, isSeasonGame, isLeagueGame, isCupGame, getRoundNameOfCupGame, isSimpleCupGame, getTeamTranslation } from "utils/common";
import { getCurrentSettings } from "utils/settings";
import runMarketUtilsFunction from "utils/markets/run";
import { formatDateTime } from "utils/dateTime";

import { BET_STATE, ODD_STATE, BETSLIP_MODES } from "constants/betslip.constants";
import { GAME_EVENT_TYPE, GAME_TYPE, GAME_TYPE_MAPPER, KENO_BALL_SIZE } from "constants/game.constants";

/** Calculate total odds from bets
 * @function
 * @param {array} bets - array of bets
 * @param {object} object - session
 * @returns {number}
 */
export const calculateTotalOdds = (bets, session) =>
	runMarketUtilsFunction("convertToFormat", [
		toFixed(
			bets.reduce((total, bet) => total * bet.factor, 1),
			2
		),
		getCurrentSettings(session).oddFormat
	]);

/** Calculate total staje from bets
 * @function
 * @param {array} bets - array of bets
 * @param {number} decimalCount - currency decimal count
 * @returns {number}
 */
export const calculateTotalStake = (bets, decimalCount, stake) =>
	toFixed(
		stake !== null ? Number(stake) : bets.reduce((total, bet) => total + Number(bet.stake), 0),
		decimalCount ?? 2
	);

/** Get the text of odd state
 * @function
 * @param {number} state - the state enum value
 * @returns {string}
 */
export const getOddStateText = (state) => {
	switch (state) {
		case ODD_STATE.PENDING:
			return i18n.t("bet.pending");
		case ODD_STATE.RETURN:
			return i18n.t("bet.return");
		case ODD_STATE.WON:
			return i18n.t("bet.won");
		case ODD_STATE.SEMIWON:
			return i18n.t("bet.semiWon");
		case ODD_STATE.LOST:
			return i18n.t("bet.lost");
		case ODD_STATE.SEMILOST:
			return i18n.t("bet.semiLost");
		default:
			return "";
	}
};

/** Get the text of bet state
 * @function
 * @param {number} state - the state enum value
 * @returns {string}
 */
export const getBetStateText = (state, payoutTime) => {
	if (payoutTime) {
		return <span title={`${i18n.t("bet.winningWasPaidoutAt")} ${formatDateTime(payoutTime)}`}>{i18n.t("bet.paidout")}</span>;
	}
	switch (state) {
		case BET_STATE.PENDING:
			return i18n.t("bet.pending");
		case BET_STATE.RETURN:
			return i18n.t("bet.return");
		case BET_STATE.WON:
			return i18n.t("bet.won");
		case BET_STATE.SEMIWON:
			return i18n.t("bet.semiWon");
		case BET_STATE.LOST:
			return i18n.t("bet.lost");
		case BET_STATE.SEMILOST:
			return i18n.t("bet.semiLost");
		case BET_STATE.CANCELLED:
			return i18n.t("bet.cancelled");
		case BET_STATE.REJECTED_BY_OPERATOR:
			return i18n.t("bet.rejected");
		default:
			return "";
	}
};

export const generateBetKey = (bet) => `${Date.now()}_${bet?.eventId}_${bet?.betId}_${bet?.factor}`;

/** Checks if the betslip can be canceled
 * @function
 * @param {object} betslip
 * @returns {boolean}
 */
export const isBetslipCancelable = (betslip) => {
	return (betslip?.bets ?? []).every((b) => b.allowCancel || (b.status === BET_STATE.CANCELLED && betslip.type === BETSLIP_MODES.SINGLE)) && betslip.status === BET_STATE.PENDING;
};

/** Get the event name of bet
 * @function
 * @param {object} bet
 * @returns {string}
 */
export const getBetEventName = (bet) => {
	const gameType = bet?.gameType;
	const eventType = bet?.eventType;
	const team1 = bet?.gameData?.team1 ?? {};
	const team2 = bet?.gameData?.team2 ?? {};
	const teamFormat = bet?.gameData?.teamFormat;

	if(gameType === GAME_TYPE.PENALTY_SHOOTOUT) {
		return `${getTeamTranslation(team1.countryCode, teamFormat)} - ${getTeamTranslation(team2.countryCode, teamFormat)}`;
	}
	else if(gameType === GAME_TYPE.FOOTBALL_SINGLE_MATCH) {
		return `${i18n.t(`countries.${team1.countryCode ?? ""}`)} - ${i18n.t(`countries.${team2.countryCode ?? ""}`)}`;
	} 
	else if (isSeasonGame(gameType)) {
		if (eventType === GAME_EVENT_TYPE.LEAGUE) {
			return GAME_TYPE_MAPPER[gameType] ? i18n.t(`common.${GAME_TYPE_MAPPER[gameType]}`) : " - ";
		} else if (eventType === GAME_EVENT_TYPE.WEEK && (bet?.market === "RoundTotal" || bet?.group === "RoundTotal")) {
			let str = i18n.t(`markets.${gameType}.RoundSpecial`) + ": ";
			const orderNumber = bet?.orderNumber || bet?.eventOrderNumber;
			if (orderNumber) {
				str += getRoundNameOfCupGame(orderNumber, gameType);
			}
			return str;
		}

		if (bet?.gameData) {
			return `${team1.countryCode ?? ""} - ${team2.countryCode ?? ""}`;
		}
		return GAME_TYPE_MAPPER[gameType] ? i18n.t(`common.${GAME_TYPE_MAPPER[gameType]}`) : " - ";
	} else if (isRacingGame(gameType)) {
		return bet?.gameData?.venue;
	} else if (gameType === GAME_TYPE.KENO) {
		return i18n.t("common.keno");
	} else if(team1.countryCode && team2.countryCode) {
		return `${team1.countryCode ?? ""} - ${team2.countryCode ?? ""}`;
	}

	return "";
};

/** Get the event result of bet
 * @function
 * @param {object} bet
 * @returns {JSX}
 */
export const getBetEventResult = (bet, showWinner = false) => {
	const gameType = bet?.gameType;
	const gameData = bet?.gameData;
	const eventType = bet?.eventType;

	const team1 = gameData?.team1 ?? {};
	const team2 = gameData?.team2 ?? {};

	if (eventType === GAME_EVENT_TYPE.LEAGUE) {
		if (isLeagueGame(gameType) && gameData) {
			const group = bet?.group;
			const groupResult = group === "Winner" ? gameData.markets.winner || undefined : group === "Loser" ? gameData.markets.loser || undefined : undefined;
			if (typeof groupResult === "string" && groupResult.length > 0) {
				return (
					<div className="vs--flex vs--flex-row vs--align-center">
						<b>{`${i18n.t(`markets.${bet?.gameType ?? 0}.${group}`)}: ${groupResult}`}</b>
					</div>
				);
			}
			return " - ";
		}
		if (isCupGame(gameType) && gameData) {
			const group = bet?.group;
			const groupResult = group === "Champion" ? gameData?.markets?.champion : group === "Finalist" ? gameData?.markets?.finalist : undefined;
			if (typeof groupResult === "string" && groupResult.length > 0) {
				const fnForName = isSimpleCupGame(gameType) ? (team) => i18n.t(`countries.${team}`) : (team) => team;

				return (
					<div className="vs--flex vs--flex-row vs--align-center">
						<b>{`${i18n.t(`markets.${bet?.gameType ?? 0}.${group}`)}: ${groupResult.split(",").map(fnForName).join(", ")}`}</b>
					</div>
				);
			}
			return " - ";
		}
	}

	if (gameType === GAME_TYPE.FOOTBALL_SINGLE_MATCH || (isSeasonGame(gameType) && bet?.gameData)) {
		const team1GoalCount = team1.goalCount ?? 0;
		const team2GoalCount = team2.goalCount ?? 0;
		const team1GoalCount1 = team1.goalCount1 ?? 0;
		const team2GoalCount1 = team2.goalCount1 ?? 0;

		return (
			<Fragment>
				<span>{team1GoalCount}</span>
				<span>{" - "}</span>
				<span>{team2GoalCount}</span>
				<span className="vs--ml-4">{"("}</span>
				<span>{team1GoalCount1}</span>
				<span>{" - "}</span>
				<span>{team2GoalCount1}</span>
				<span>{")"}</span>
			</Fragment>
		);
	} else if (gameType === GAME_TYPE.PENALTY_SHOOTOUT) {
		const team1GoalCount = team1.goalCount ?? 0;
		const team2GoalCount = team2.goalCount ?? 0;

		return (
			<Fragment>
				<span>{team1GoalCount}</span>
				<span>{" - "}</span>
				<span>{team2GoalCount}</span>
			</Fragment>
		);
	} else if (isRacingGame(gameType)) {
		const participants = bet?.gameData?.participants ?? [];
		const winnerIndex = participants.findIndex((p) => p.place === 1) + 1;
		const winner = participants.find((p) => p.place === 1);
		if (winner) {
			return (
				<div className="vs--flex vs--flex-row vs--align-center">
					<span className={"vs--place vs--font-bold vs--place-" + winnerIndex}>{winnerIndex}</span>
					<b className="vs--pl-8">{winner.name}</b>
				</div>
			);
		}
	} else if (gameType === GAME_TYPE.KENO) {
		return bet.status === BET_STATE.PENDING ? "-" : (
			<KenoBalls
				balls={(bet?.gameData?.scenes ?? []).map((sc) => Number(sc.number))}
				winnerBalls={bet?.gameData?.scenes ?? []}
				size={KENO_BALL_SIZE.SMALL}
				showWinner={showWinner}
				splited={true}
				wrapperClassname="vs--keno-balls-single-history"
			/>
		);
	}
	return "-";
};

/** Function which checks if there are another bet with same match in betslip for multi mode
	* @function
	* @param {object} bet - bet to check
	* @param {array} bets - array of bets
	* @param {mode} number - the betslip mode
	* @returns {boolean}
	*/
export const getEventsRepeatedBets = (bets, mode) => {
	if (mode !== BETSLIP_MODES.MULTI) {
		return null;
	}

	const { groupedEvents, groupedWeekEvents, groupedSeasonEvents } = bets.reduce((acc, bet) => {
		acc.groupedEvents[bet.eventId] = bet;

		if (bet.weekId) {
			acc.groupedWeekEvents[bet.weekId] = bet;
		}
		if (bet.seasonId) {
			acc.groupedSeasonEvents[bet.seasonId] = bet;
		}

		return acc;
	}, { groupedEvents: {}, groupedWeekEvents: {}, groupedSeasonEvents: {} });

	const { repeatedBets } = bets.reduce((acc, bet) => {
		if (acc[bet.eventId] && groupedEvents[bet.eventId]) {
			acc.repeatedBets[bet.eventId] = bet;
		}
		if (groupedSeasonEvents[bet.eventId] || groupedEvents[bet.seasonId] || groupedWeekEvents[bet.eventId] || groupedEvents[bet.weekId]) {
			// setting LEAGUE/WEEK/EVENT type event in repeated bets when there are LEAGUE/WEEK/EVENT type events related to it in Betslip
			acc.repeatedBets[bet.eventId] = bet;
		}

		acc[bet.eventId] = bet;

		return acc;
	}, { repeatedBets: {} });

	return repeatedBets;
}

/** Function which checking is calculated fav bet value valid or not
 * @function
 * @param {number} calculatedValue - favorite bet value
 * @param {object} configs - object with configs
 * @returns {boolean}
 */
export const checkingFavBetCalculatedValue = (
	calculatedValue = 0,
	configs = {
		stake: 0,
		mode: BETSLIP_MODES.SINGLE,
		currency: { singleMax: 0, multiMax: 0, singleMin: 0, multiMin: 0 }
	}
) => {
	const { mode, session } = configs;
	const singleMax = session?.currency?.singleMax ?? calculatedValue;
	const multiMax = session?.currency?.multiMax ?? calculatedValue;

	let retVal = false;
	switch (mode) {
		case BETSLIP_MODES.SINGLE:
			if (calculatedValue <= singleMax) {
				retVal = true;
			}
			break;
		case BETSLIP_MODES.MULTI:
			if (calculatedValue <= multiMax) {
				retVal = true;
			}
			break;
		default:
			break;
	}
	return retVal;
};

/** Function which calculate fav bet value
 * @function
 * @param {string} favBetType - favorite bet type
 * @param {object} configs - object with configs
 * @returns {number}
 */
export const calculatedFavBetValue = (
	favBetType = 0,
	configs = {
		stake: 0,
		mode: BETSLIP_MODES.SINGLE,
		currency: { singleMax: 0, multiMax: 0, singleMin: 0, multiMin: 0 }
	}
) => {
	const { stake, mode, session } = configs;
	let update = stake;
	const singleMax = session?.currency?.singleMax ?? update;
	const multiMax = session?.currency?.multiMax ?? update;
	const singleMin = session?.currency?.singleMin ?? update;
	const multiMin = session?.currency?.multiMin ?? update;
	if (favBetType.startsWith("+")) {
		const sum = Number(favBetType.slice(1));
		if (!isNaN(sum)) {
			update = Number(stake) + sum;
		}
	} else if (favBetType.startsWith("x")) {
		const multiple = Number(favBetType.slice(1));
		if (!isNaN(multiple)) {
			update = Number(stake) * multiple;
		}
	} else if (!isNaN(favBetType) && favBetType !== "" && favBetType !== null) {
		update = Number(favBetType);
	} else if (favBetType === "Max") {
		if (mode === BETSLIP_MODES.SINGLE) {
			update = singleMax;
		} else if (mode === BETSLIP_MODES.MULTI) {
			update = multiMax;
		}
	} else if (favBetType === "Min") {
		if (mode === BETSLIP_MODES.SINGLE) {
			update = singleMin;
		} else if (mode === BETSLIP_MODES.MULTI) {
			update = multiMin;
		}
	}
	return update;
};

/** Function which fires on favorite bet click
 * @function
 * @param {string} fav - favorite bet value
 * @param {object} configs - object with configs
 * @param {function} callback - function to set calculated value
 * @returns {undefined}
 */
export const onFavoriteCalculation = (
	callback = Function.prototype,
	configs = {
		stake: 0,
		mode: BETSLIP_MODES.SINGLE,
		currency: { singleMax: 0, multiMax: 0, singleMin: 0, multiMin: 0 }
	}
) => {
	return (fav) => {
		const updated = calculatedFavBetValue(fav, configs);
		if (checkingFavBetCalculatedValue(updated, configs)) {
			callback(updated);
		}
	};
};

export const getNoMoreBetsTime = (condition, currentEvent) => (condition ? currentEvent?.noMoreBetsTime : null);

export const filterFnUpdateMatchBets = (isFinished, state, payload) => {
	if (isFinished) {
		const tmp_filtered_updateMatchBets = state.bets.filter((b) => b.eventId !== payload.event.id);
		if (tmp_filtered_updateMatchBets.length !== state.bets.length) {
			return tmp_filtered_updateMatchBets;
		}
	}
	return state.bets;
};
export const mapperFnUpdateMatchBets = (isExpired, filteredBets, payload) => {
	if (isExpired) {
		let updated = false;
		const mappedBets = filteredBets.map((b) => {
			if (b.eventId === payload.event.id) {
				updated = true;
				return { ...b, isExpired: true };
			}
			return b;
		});
		if (updated) {
			return mappedBets;
		}
	}
	return filteredBets;
};

export const mapperFnUpdateBets = (data) => {
	const changedKeys = {
		calculationTime: "calculateTime",
		state: "status",
		totalAmount: "amount",
		totalFactor: "factor",
		reason: "cancelReason",
		paidoutTime: "payoutTime",
		gameCategoryType: "gameCategory",
		group: "market",
		betDate: "betTime",
		startTime: "eventStartTime",
		cancelReason: "reason"
	};

	const mutateOldKeys = (mappedKeys, obj) => {
		Object.keys(mappedKeys).forEach((changedKey) => {
			if (Object.hasOwn(obj, changedKey)) {
				obj[mappedKeys[changedKey]] = obj[changedKey];
				delete obj[changedKey];
			}
		});
	}

	if (data.type === BETSLIP_MODES.MULTI) {
		const { id, bets, ...rest } = data;

		mutateOldKeys(changedKeys, rest);

		const betslipData = {};

		if (bets) {
			betslipData.bets = bets.map((bet) => {
				const { id: betId, ...rest } = bet;

				mutateOldKeys(changedKeys, rest);

				return { betId, ...rest };
			})
		}

		const idKey = bets ? "betSlipId" : "betId";
		if (!rest[idKey]) {
			betslipData[idKey] = id;
		}

		return { ...betslipData, ...rest };
	}

	const { id: betSlipId, bets, ...betslipData } = data;

	mutateOldKeys(changedKeys, betslipData);

	const groupedBets = data.bets.reduce((acc, bet) => {
		const { id: betId, ...rest } = bet;

		mutateOldKeys(changedKeys, rest);

		acc[betId] = { betSlipId, betId, ...betslipData, ...rest };

		return acc;
	}, {});

	return groupedBets;
};