import { Fragment, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import Game from "components/topBar/game";
import GamesModal from "components/topBar/gamesModal";

import useAppSelector from "hooks/store/useAppSelector";

import { selectIsRtl, selectSessionGames, selectSessionProjectId } from "store/slices/auth/selectors";

import { getUrlVars, isNullish, mergeClassNames } from "utils/common";
import LocalStorageUtils from "utils/localStorage";

import { GAME_TYPE, GAME_ACTIVITY_STATE } from "constants/game.constants";

import useGlobalVariables from "hooks/useGlobalVariables";
import Navbar from "components/ui/navbar";
import TopBarGamesContext from "contexts/topBarGamesContext";
import { EventTargetContext } from "contexts/navbar";
import { NAVBAR_EVENT_TYPES, NAVBAR_SUBEVENT_TYPES } from "constants/navbar.constants";
import { VSEvent } from "helpers/customEvents";

/* Games List Component in topBar */
const Games = () => {
	const isRtl = useAppSelector(selectIsRtl);
	const games = useAppSelector(selectSessionGames);
	const projectId = useAppSelector(selectSessionProjectId);

	const { showCountdown, selectedGame, setSelectedGame } = useContext(TopBarGamesContext);
	const { isMobile } = useGlobalVariables();

	/** Set default game */
	useEffect(() => {
		if (!showCountdown) {
			return;
		}
		let type = null;
		const activeGames = games.filter((game) => game.state !== GAME_ACTIVITY_STATE.INACTIVE);

		if (activeGames.length > 0) {
			type = activeGames[0].type;
		}

		const typeInCache = LocalStorageUtils.get("vs__current_game_" + projectId);

		if (typeInCache && activeGames.some((g) => g.type === typeInCache)) {
			type = typeInCache;
		}
		const gameTypeInUrl = Number(getUrlVars()["gameType"]);
		if (gameTypeInUrl && games.some((g) => Number(g.type) === gameTypeInUrl)) {
			type = gameTypeInUrl;
		}

		if (!type) {
			return;
		}

		setSelectedGame(type);
	}, [showCountdown]);

	/** Update current game in local storage */
	useEffect(() => {
		if (showCountdown && selectedGame && projectId) {
			LocalStorageUtils.set("vs__current_game_" + projectId, selectedGame);
		}
	}, [selectedGame, showCountdown]);

	return (
		<Fragment>
			<div className={mergeClassNames("vs--topBar-games", isMobile && "vs--flex vs--pl-4")}>
				<Navbar dir={isRtl ? "rtl" : "ltr"} className="vs--topBar-games" menuButton={isMobile} arrowButtons={isMobile ? false : null} elementId="type" dataList={games} elementInnerComponent={Games.elementInnerComponent} navbarMenu={Games.navbarMenu} />
			</div>
		</Fragment>
	);
};

Games.elementInnerComponent = ({ data: game, elementId }) => {
	const { showCountdown, selectedGame, setSelectedGame } = useContext(TopBarGamesContext);
	return <Game key={elementId} game={game} setSelectedGame={setSelectedGame} showCountdown={showCountdown} selectedGame={selectedGame} />;
};
Games.navbarMenu = () => {
	const { isMobile } = useGlobalVariables();
	const [isGameModalVisible, setIsGameModalVisible] = useState(false);
	const eventTarget = useContext(EventTargetContext);
	if (!isMobile) {
		return null;
	}

	return (
		<Fragment>
			<div className="vs--topBar-game-more vs--flex vs--justify-center vs--align-center" onClick={() => setIsGameModalVisible(true)}>
				<i className="ic_moreGames vs--font-bigest"></i>
			</div>
			<GamesModal
				title="All Games"
				isVisible={isGameModalVisible}
				onClickCallback={(arg /* arg can be selected game type or syntetic react event when modal just closed swipeable */) => {
					if (Object.values(GAME_TYPE).includes(arg)) {
						const eventType = NAVBAR_EVENT_TYPES.MESSAGE_TO_ELEMENT + arg;
						const { MOVE_TO_VIEW: subEventType } = NAVBAR_SUBEVENT_TYPES[NAVBAR_EVENT_TYPES.MESSAGE_TO_ELEMENT];
						eventTarget.dispatchEvent(new VSEvent(eventType, [{ type: subEventType }]));
					}
					setIsGameModalVisible(false);
				}}
			/>
		</Fragment>
	);
};

Games.elementInnerComponent.displayName = "GamesElementInnerComponent";
Games.navbarMenu.displayName = "GamesNavbarMenu";

Games.elementInnerComponent.propTypes = {
	data: PropTypes.object,
	elementId: PropTypes.any
};
Games.navbarMenu.propTypes = {
	data: PropTypes.object,
	elementId: PropTypes.any
};

/** Games propTypes
 * PropTypes
 */
Games.propTypes = {
	/** React property, given selected game from parent component or currentGameType by default */
	selectedGame: PropTypes.oneOf(Object.values(GAME_TYPE)),
	/** React property, given callback from parent component or setCurrentGame by default */
	setSelectedGame: PropTypes.func,
	/** React property, whether show or hide game countdown */
	showCountdown: PropTypes.bool
};

export default Games;
