import { useRef } from "react";
import DefaultContainer from "components/ui/navbar/defaultContainer";
import DefaultMenuWrapper from "components/ui/navbar/defaultMenuWrapper";
import DefaultMenu from "components/ui/navbar/defaultMenu";
import DefaultArrow from "components/ui/navbar/defaultArrow";
import DefaultArrowInner from "components/ui/navbar/defaultArrowInner";
import DefaultElements from "components/ui/navbar/defaultElements";
import DefaultElement from "components/ui/navbar/defaultElement";
import DefaultMenuDropdown from "components/ui/navbar/defaultMenuDropdown";
import DefaultMenuItem from "components/ui/navbar/defaultMenuItem";
import { NAVBAR_INTERACTIVE_ELEMENT_KEYS } from "constants/navbar.constants";
import useElementsObserver from "hooks/navbar/useElementsObserver";
import { mergeClassNames } from "utils/common";

const NavbarCoreComponent = ({
	dir = "ltr",
	dataList = [],
	elementId = null,
	flexElements = false,
	navbarContainer: NavbarContainer = DefaultContainer,
	navbarMenuWrapper: NavbarMenuWrapper = DefaultMenuWrapper,
	navbarMenu = DefaultMenu,
	navbarArrow: NavbarArrow = DefaultArrow,
	navbarArrowInner = DefaultArrowInner,
	navbarElements: NavbarElements = DefaultElements,
	navbarElement: NavbarElement = DefaultElement,
	navbarMenuDropdown = DefaultMenuDropdown,
	navbarMenuItem = DefaultMenuItem,
	elementInnerComponent: ElementInnerComponent = Function.prototype,
	menuItemInnerComponent: MenuItemInnerComponent = Function.prototype,
	itemsFilteringFn = () => true,
	className = null,
	containerClassName = null,
	menuClassName = null,
	arrowClassName = null,
	elementsClassName = null,
	elementClassName = null,
	activeElementClassName = null,
	menuDropdownClassName = null,
	menuItemClassName = null,
	...otherProps
}) => {
	const elementsRef = useRef();
	useElementsObserver(elementsRef, dir);
	return (
		<div className={mergeClassNames("vs--navbar", className)} dir={dir} {...otherProps}>
			<NavbarContainer containerClassName={containerClassName}>
				<NavbarArrow key={NAVBAR_INTERACTIVE_ELEMENT_KEYS.PREV} dir={dir} type={NAVBAR_INTERACTIVE_ELEMENT_KEYS.PREV} arrowClassName={arrowClassName} navbarArrowInner={navbarArrowInner} />
				<NavbarElements ref={elementsRef} dir={dir} elementsClassName={elementsClassName}>
					{dataList.map((data, index, arr) => {
						const key = typeof elementId === "function" ? elementId(data, index, arr, false) : typeof elementId === "string" ? data[elementId] : index;
						return (
							<NavbarElement key={key} elementId={key} index={index} dir={dir} elementClassName={elementClassName} activeElementClassName={activeElementClassName} flexElements={flexElements}>
								<ElementInnerComponent data={data} elementId={key} index={index} dir={dir} dataList={arr} />
							</NavbarElement>
						);
					})}
				</NavbarElements>
				<NavbarArrow key={NAVBAR_INTERACTIVE_ELEMENT_KEYS.NEXT} dir={dir} type={NAVBAR_INTERACTIVE_ELEMENT_KEYS.NEXT} arrowClassName={arrowClassName} navbarArrowInner={navbarArrowInner} />
			</NavbarContainer>
			<NavbarMenuWrapper
				elementId={elementId}
				navbarMenu={navbarMenu}
				menuClassName={menuClassName}
				dataList={dataList}
				navbarMenuDropdown={navbarMenuDropdown}
				navbarMenuItem={navbarMenuItem}
				menuItemInnerComponent={MenuItemInnerComponent}
				itemsFilteringFn={itemsFilteringFn}
				menuDropdownClassName={menuDropdownClassName}
				menuItemClassName={menuItemClassName}
			/>
		</div>
	);
};

export default NavbarCoreComponent;
