import PropTypes from "prop-types";
import Tooltip from "components/ui/tooltip";

const InfoTooltip = ({ content = [], ...rest }) => {
	if (content.length === 0) {
		return null;
	}

	return (
		<Tooltip
			overlayClassName="vs--info-tooltip"
			title={
				<div className="vs--title vs--font-normal vs--font-regular">
					{content.map((item, index) => (
						<div key={index}>{item}</div>
					))}
				</div>
			}
			{...rest}
		>
			<i className="ic_information vs--font-bigest"></i>
		</Tooltip>
	);
};

InfoTooltip.propTypes = {
	content: PropTypes.array
};

export default InfoTooltip;
