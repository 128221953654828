import { useRef } from "react";
import PropTypes from "prop-types";

import useOutsideAlerter from "hooks/useOutsideAlerter";

/** Component to add "click outside" event on wrapped component */
const OutsideAlerter = ({ preventId, children, callback, preventClassName, outsideRef, ...props }) => {
	const wrapperRef = useRef(null);

	useOutsideAlerter({ ref: outsideRef ?? wrapperRef, callback, preventClassName, currentOutsideId: preventId });

	return (
		<div data-outside-id={preventId} data-outside-container="true" ref={wrapperRef} {...props}>
			{children}
		</div>
	);
};

/** OutsideAlerter propTypes
 * PropTypes
 */
OutsideAlerter.propTypes = {
	/** Wrapped component */
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
	/** Function to call when clicked outside */
	callback: PropTypes.func,
	/** The classname of elements to prevent click */
	preventClassName: PropTypes.string,
	/** React ref object */
	outsideRef: PropTypes.object
};

export default OutsideAlerter;
