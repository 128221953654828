import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const Emoji = ({
	emoji: { className = "", imgSrc, name },
	onClick
}) => {
	const { t } = useTranslation();

	return (
		<div className={`${className} vs--flex vs--flex-col vs--align-center`}>
			<div className='vs--modal-feedback-emoji vs--mb-8' onClick={onClick}>
				<img src={imgSrc} alt={`emoji_${name}`} />
			</div>
			<span className='vs--title-white vs--font-small'>{t(`common.${name}`)}</span>
		</div>
	)
};

Emoji.propTypes = {
	emoji: PropTypes.object,
	onClick: PropTypes.func
};

export default Emoji;
