import { isValidElement, cloneElement } from "react";

export const toggleExpandRow = (collapsebaleRow, state, { collapseSelector, collapseContentSelector, collapseWrapperHideClass } = {}, callback) => {
	if (!collapsebaleRow) {
		return;
	}
	const collapse = collapsebaleRow.querySelector(collapseSelector);
	if (!collapse) {
		return;
	}
	const collapseContent = collapse.querySelector(collapseContentSelector);
	if (!collapseContent) {
		return;
	}
	const { height } = collapseContent.getBoundingClientRect();
	if (state) {
		collapse.style.height = height + "px";
		collapse.classList.remove(collapseWrapperHideClass);
		return null;
	}
	collapse.style.height = 0;
	collapse.classList.add(collapseWrapperHideClass);
	if (typeof callback === "function") {
		callback();
	}
};

export const copyTitle = (title) => {
	if (!(title instanceof Object)) {
		return title;
	}
	if (isValidElement(title)) {
		return cloneElement(title);
	}
	return Object.assign({}, title);
};

export const copyAdditionalInfo = (additionalInfo) => {
	if (!(additionalInfo instanceof Object)) {
		return additionalInfo;
	}
	let newAdditionalInfo = Object.assign({}, additionalInfo);
	if ("colProps" in newAdditionalInfo) {
		const newColProps = {};
		Object.keys(newAdditionalInfo).forEach((key) => {
			if (newAdditionalInfo[key] instanceof Object) {
				newColProps[key] = Object.assign({}, newAdditionalInfo[key]);
				return;
			}
			newColProps[key] = newAdditionalInfo[key];
		});
		newAdditionalInfo.colProps = newColProps;
	}
	return newAdditionalInfo;
};
