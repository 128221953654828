import { useEffect, Fragment, useRef, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { liveAndUpcomingsThunk, getEventFromCacheThunk } from "store/slices/game/thunks";
import {
	removeFromLiveAndUpcomingsAction,
	removeFromLiveAndUpcomingsBySeasonAction,
	addLiveAndUpcomingsAction,
	updateFromLiveAndUpcomingsAction,
	addLastResultAction,
	setGameCountDownAction,
	setCurrentGameBonusBetDisabledAction,
	updateEventAction
} from "store/slices/game/actions";
import {
	setLiveInfoEventAction,
	updateLiveInfoEventAction,
	setEventPositionsAction,
	setCurrentTimeAction
} from "store/slices/common/actions";
import { updateHistoryBetSlipBetAction, updateHistoryBetSlipAction } from "store/slices/betHistory/actions";
import {
	removePendingAction,
	updatePendingAction,
	updatePendingBetAction,
	updateMatchBetsAction,
	addBetslipResultAction,
	setBetslipAction
} from "store/slices/betslip/actions";
import { updateSeasonMarketsAction, clearSeasonMarketsAction } from "store/slices/season/actions";
import { setBalanceAction, setLogoIdAction, updateGameStateAction, updateCurrencyAction } from "store/slices/auth/actions";
import { setBonusThunk } from "store/slices/auth/thunks";
import { setKenoStatisticsAction, updateSeasonStructureAction, updateSeasonStatisticsAction } from "store/slices/statistics/actions";
import { getLastBetsThunk } from "store/slices/keno/thunks";
import {
	selectSessionId,
	selectSessionLoaded,
	selectSessionFailed,
	selectSessionGames,
	selectSessionBonuses,
	selectSessionProjectId,
	selectPlayer,
	selectIsSplitStakeEnabled,
	selectAnalyticalTools,
	selectSessionCurrency
} from "store/slices/auth/selectors";
import { selectSeasonCurrentEventId, selectSpecialMarketsSeasonId } from "store/slices/season/selectors";
import { selectUseBonus } from "store/slices/bonus/selectors";
import { selectCurrentGameType, selectCurrentEventId, selectLiveAndUpcomingsData, selectMatchesData } from "store/slices/game/selectors";
import { selectLiveInfoEvent } from "store/slices/common/selectors";
import { selectBetslipMode, selectBets, selectBetslipStake, selectBetslipStakeMode } from "store/slices/betslip/selectors";

import { CLIENT_API } from "constants/integration.constants";
import { GAME_STATUSES, GAME_TYPE, GAME_EVENT_TYPE } from "constants/game.constants";
import { BET_STATE, BETSLIP_STAKE_MODES, BETSLIP_MODES } from "constants/betslip.constants";
import { ANALYTICAL_TOOL_TYPE, CONNECTION_STATE, PROJECT_TYPE, TASK_SCHEDULER_TIME_PERIODS } from "constants/common.constants";

import LocalStorageUtils from "utils/localStorage";
import { isSeasonGame, isCupGame, isNullish } from "utils/common";
import { refreshToken } from "utils/auth";
import { binaryToFlags } from "utils/binaryCalculations";
import { initializeGA, sendGAPageView } from "utils/ga";
import { initializeHotjar } from "utils/hotjar";
import { initializeWVO } from "utils/vwo";
import { initializeYandexMetrica } from "utils/yandexMetrica";
import { sendPostMessageToParent } from "utils/iframe";
import { generateCssFromProperties } from "utils/css";

import useGlobalVariables from "hooks/useGlobalVariables";
import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";
import useTaskScheduler from "hooks/useTaskScheduler";
import useEvent from "hooks/useEvent";
import useGetEvent from "hooks/game/useGetEvent";
import useExitFromSession from "hooks/auth/useExitFromSession";
import { REFRESH_TOKEN_UPDATE } from "constants/date.constants";
import WebSocketService from "services/webSocket";
import { ADMIN_API_INVOKE_EVENTS, ADMIN_API_LISTENER_EVENTS, JOB_API_INVOKE_EVENTS, JOB_API_LISTENER_EVENTS, SITE_API_INVOKE_EVENTS, SITE_API_LISTENER_EVENTS, UPDATE_SESSION_INTERVAL_MILLISECONDS } from "constants/webSocket.contants";
import useForceUpdate from "hooks/useForceUpdate";

/** SignalR WebSocket setup function
 * @callback WebSocketSetupCallback
 * @param {WebSocketService} wsServiceInstance
 * @returns {void}
 */

/** SignalR WebSocket connection type
 * @typedef {WebSocketService | null} WebSocketConnection
 */

/** SignalR WebSocket connections
 * @typedef {Object} WebSocketConnections
 * @property {WebSocketConnection} adminWS
 * @property {WebSocketConnection} siteWS
 * @property {WebSocketConnection} jobWS
 */

let refreshTokenTimer = null;

/* Main functional Component - Initial functionality for all pages */
const Main = () => {
	const globalVariables = useGlobalVariables();

	const games = useAppSelector(selectSessionGames);
	const bonuses = useAppSelector(selectSessionBonuses);
	const projectId = useAppSelector(selectSessionProjectId);
	const isSplitStakeEnabled = useAppSelector(selectIsSplitStakeEnabled);
	const analyticalTools = useAppSelector(selectAnalyticalTools);
	const mode = useAppSelector(selectBetslipMode);
	const bets = useAppSelector(selectBets);
	const stake = useAppSelector(selectBetslipStake);
	const stakeMode = useAppSelector(selectBetslipStakeMode);
	const sessionId = useAppSelector(selectSessionId);
	const sessionLoaded = useAppSelector(selectSessionLoaded);
	const sessionFailed = useAppSelector(selectSessionFailed);
	const seasonCurrentEventId = useAppSelector(selectSeasonCurrentEventId);
	const player = useAppSelector(selectPlayer);
	const useBonus = useAppSelector(selectUseBonus);
	const currentGameType = useAppSelector(selectCurrentGameType);
	const current = useAppSelector(selectCurrentEventId);
	const liveAndUpcomings = useAppSelector(selectLiveAndUpcomingsData);
	const liveInfoEvent = useAppSelector(selectLiveInfoEvent);
	const matches = useAppSelector(selectMatchesData);
	const specialMarketsSeasonId = useAppSelector(selectSpecialMarketsSeasonId);
	const currency = useAppSelector(selectSessionCurrency)

	const { getEvent, getEventInBackground } = useGetEvent();
	const exitFromSession = useExitFromSession();
	const [jobForceUpdate, jobForceUpdateState] = useForceUpdate()
	const [adminForceUpdate, adminForceUpdateState] = useForceUpdate()
	const [siteForceUpdate, siteForceUpdateState] = useForceUpdate()

	const dispatch = useAppDispatch();

	const variableRef = useRef();
	variableRef.current = globalVariables;
	const currentGameTypeRef = useRef(currentGameType);

	/*
		get active event and next upcoming event from live and upcomings events array
	*/
	const { activeEventId, upcomingEventId } = useMemo(() => {
		let _activeEventId = null;
		let _upcomingEventId = null;

		const statusesForActive = [GAME_STATUSES.STARTED, GAME_STATUSES.PREAMBLE_STARTED, GAME_STATUSES.CLOSE_FOR_BETTING];
		const statusesForUpcoming = [GAME_STATUSES.PREAMBLE_STARTED, GAME_STATUSES.NEW];

		liveAndUpcomings.some((lau) => {
			if (_activeEventId === null && statusesForActive.includes(lau.status)) {
				_activeEventId = lau.id;
			}

			if (_upcomingEventId === null && statusesForUpcoming.includes(lau.status)) {
				_upcomingEventId = lau.id;
			}

			return _activeEventId !== null && _upcomingEventId !== null;
		});

		return { activeEventId: _activeEventId, upcomingEventId: _upcomingEventId };
	}, [liveAndUpcomings]);

	const onEvent = useEvent((data) => {
		try {
			const d = JSON.parse(data);
			console.vsLogger("Events", d);
			if (d.gameType === currentGameTypeRef.current) {
				sendEventStatusToRGS(d);
				if (d.status === GAME_STATUSES.FINISHED) {
					if (!isSeasonGame(d.gameType)) {
						dispatch(removeFromLiveAndUpcomingsAction(d.id));
						dispatch(addLastResultAction(d));
					} else if (d.type === GAME_EVENT_TYPE.LEAGUE) {
						dispatch(removeFromLiveAndUpcomingsBySeasonAction(d.id));
						dispatch(clearSeasonMarketsAction());
					} else {
						dispatch(updateFromLiveAndUpcomingsAction(d));
					}
				} else if (
					[
						GAME_STATUSES.STARTED,
						GAME_STATUSES.CLOSE_FOR_BETTING,
						GAME_STATUSES.PREAMBLE_STARTED,
					].includes(d.status)
				) {
					if (!isSeasonGame(d.gameType) || d.type === GAME_EVENT_TYPE.WEEK) {
						dispatch(updateFromLiveAndUpcomingsAction(d));
					}
					dispatch(updateLiveInfoEventAction(d));
				} else if (d.status === GAME_STATUSES.NEW) {
					if (!isSeasonGame(d.gameType)) {
						dispatch(addLiveAndUpcomingsAction(d));
					} else {
						if (d.type === GAME_EVENT_TYPE.LEAGUE) {
							dispatch(addLiveAndUpcomingsAction(d));
						}
					}
				}

				/** If season markets are updated */
				if (isSeasonGame(d.gameType) && d.markets) {
					if (d.id === specialMarketsSeasonId) {
						const currentGame = games.find((g) => g.type === currentGameTypeRef.current);
						const rtps = currentGame?.rtPs ?? [];
						const index = liveAndUpcomings.findIndex((lau) => lau.seasonId === d.id);

						dispatch(updateSeasonMarketsAction({ index, season: d, rtps, gameType: currentGameTypeRef.current }));
					}
				}
			}
			dispatch(updateEventAction({ id: d.id, data: d, rtps: getCurrentGameRtps() }));
			dispatch(updateMatchBetsAction(d));
			if (isCupGame(d.gameType) && d.gameType === currentGameTypeRef.current) {
				dispatch(updateSeasonStructureAction(d));
			}
		} catch (err) {
			console.log(err)
		}
	});

	const onEventState = useCallback((data) => {

		const d = JSON.parse(data);

		console.vsLogger("EventState", d);

		dispatch(updateMatchBetsAction({
			id: d.eventId,
			status: d.eventState,
			type: d.eventType
		}));

		if (!isNullish(d.nextEventStartTime)) {
			dispatch(setGameCountDownAction({
				gameId: d.gameId,
				gameType: d.gameType,
				nextEventStartTime: d.nextEventStartTime,
				seconds: d.seconds
			}));
		}

	}, [dispatch])

	const onEventPositions = useCallback((data) => {
		const d = JSON.parse(data);
		console.vsLogger("EventPositions", d);
		dispatch(setEventPositionsAction(d));
	}, [dispatch])

	const onTeamStandings = useCallback((data) => {
		const d = JSON.parse(data);
		console.vsLogger("TeamStandings", d);
		if (d.gameType === currentGameTypeRef.current) {
			dispatch(updateSeasonStatisticsAction(d));
		}
	}, [dispatch])

	const onKenoStatistics = useCallback((data) => {
		if (currentGameTypeRef.current === GAME_TYPE.KENO) {
			const d = JSON.parse(data);
			dispatch(setKenoStatisticsAction(d));
			dispatch(getLastBetsThunk());
		}
	}, [dispatch])

	const onWonPopup = useCallback((data) => {
		const d = JSON.parse(data);
		console.vsLogger("WonPopup", d);
		dispatch(addBetslipResultAction(d.bets));
	}, [dispatch])

	const onBetSlip = useCallback((data) => {
		const d = JSON.parse(data);
		console.vsLogger("BetSlip", d);
		dispatch(updateHistoryBetSlipAction(d));
		if (d.state !== BET_STATE.PENDING) {
			dispatch(removePendingAction(d));
		} else {
			dispatch(updatePendingAction(d));
		}
	}, [dispatch])

	const onBetSlipBet = useCallback((data) => {
		const d = JSON.parse(data);
		console.vsLogger("BetSlipBet", d);
		dispatch(updateHistoryBetSlipBetAction(d));
		dispatch(updatePendingBetAction(d));
	}, [dispatch])

	const onBonus = useCallback((data) => {
		const bonus = JSON.parse(data);
		console.vsLogger("Bonus", bonus);
		if (bonus === null) {
			return;
		}
		if (moment.utc(bonus.endDate) <= moment.utc(Date.now())) {
			bonus.roundCount = 0;
			bonus.amount = 0;
		}
		dispatch(setBonusThunk({ bonus, sessionId, options: variableRef.current }));
	}, [dispatch])

	const onBalance = useCallback((data) => {
		const { bonus, balance } = JSON.parse(data);
		dispatch(setBalanceAction(Number(balance)));
		if (bonus === null) {
			return;
		}
		if (moment.utc(bonus.endDate) <= moment.utc(Date.now())) {
			bonus.roundCount = 0;
			bonus.amount = 0;
		}
		dispatch(setBonusThunk({ bonus, sessionId, options: variableRef.current }));
	}, [dispatch])

	const onLogout = useCallback(() => {
		exitFromSession();
	}, [dispatch])

	const onCurrencyUpdate = useCallback((data) => {
		const currency = JSON.parse(data);
		console.vsLogger("UpdateProjectCurrency", currency);
		const { betLimits, ...rest } = currency;
		dispatch(updateCurrencyAction({ ...rest, ...betLimits }));

	}, [dispatch])

	const onGameStateChange = useCallback((data) => {
		const game = JSON.parse(data);
		console.vsLogger("GameStateChanged", game);
		dispatch(updateGameStateAction(game));
	}, [dispatch])

	/** Admin API SignalR WebSocket post connection setup function */
	const handleAdminWSEvent = useEvent(/** @type {WebSocketSetupCallback} */(wsServiceInstance) => {
		if (!wsServiceInstance.isConnected) {
			return;
		}

		wsServiceInstance.off(ADMIN_API_LISTENER_EVENTS.BALANCE);
		wsServiceInstance.off(ADMIN_API_LISTENER_EVENTS.UPDATE_PROJECT_CURRENCY);
		wsServiceInstance.off(ADMIN_API_LISTENER_EVENTS.GAME_STATE_CHANGE);
		wsServiceInstance.off(ADMIN_API_LISTENER_EVENTS.LOGOUT);
		wsServiceInstance.off(ADMIN_API_LISTENER_EVENTS.BET_SLIP);
		wsServiceInstance.off(ADMIN_API_LISTENER_EVENTS.BET_SLIP_BET);
		wsServiceInstance.off(ADMIN_API_LISTENER_EVENTS.BONUS);

		wsServiceInstance.on(ADMIN_API_LISTENER_EVENTS.BALANCE, onBalance);
		wsServiceInstance.on(ADMIN_API_LISTENER_EVENTS.UPDATE_PROJECT_CURRENCY, onCurrencyUpdate);
		wsServiceInstance.on(ADMIN_API_LISTENER_EVENTS.GAME_STATE_CHANGE, onGameStateChange);
		wsServiceInstance.on(ADMIN_API_LISTENER_EVENTS.LOGOUT, onLogout);
		wsServiceInstance.on(ADMIN_API_LISTENER_EVENTS.BET_SLIP, onBetSlip);
		wsServiceInstance.on(ADMIN_API_LISTENER_EVENTS.BET_SLIP_BET, onBetSlipBet);
		wsServiceInstance.on(ADMIN_API_LISTENER_EVENTS.BONUS, onBonus);

		adminForceUpdate();
	})

	/** Site API SignalR WebSocket post connection setup function */
	const handleSiteWSEvent = useEvent(/** @type {WebSocketSetupCallback} */(wsServiceInstance) => {
		if (!wsServiceInstance.isConnected) {
			return;
		}

		wsServiceInstance.off(SITE_API_LISTENER_EVENTS.BALANCE);
		wsServiceInstance.off(SITE_API_LISTENER_EVENTS.BET_SLIP);
		wsServiceInstance.off(SITE_API_LISTENER_EVENTS.BET_SLIP_BET);
		wsServiceInstance.off(SITE_API_LISTENER_EVENTS.BONUS);

		wsServiceInstance.on(SITE_API_LISTENER_EVENTS.BALANCE, onBalance);
		wsServiceInstance.on(SITE_API_LISTENER_EVENTS.BET_SLIP, onBetSlip);
		wsServiceInstance.on(SITE_API_LISTENER_EVENTS.BET_SLIP_BET, onBetSlipBet);
		wsServiceInstance.on(SITE_API_LISTENER_EVENTS.BONUS, onBonus);

		siteForceUpdate();
	})

	/** Job API SignalR WebSocket post connection setup function */
	const handleJobWSEvent = useEvent(/** @type {WebSocketSetupCallback} */(wsServiceInstance) => {
		if (!wsServiceInstance.isConnected) {
			return;
		}

		wsServiceInstance.off(JOB_API_LISTENER_EVENTS.BALANCE);
		wsServiceInstance.off(JOB_API_LISTENER_EVENTS.WON_POPUP);
		wsServiceInstance.off(JOB_API_LISTENER_EVENTS.EVENTS);
		wsServiceInstance.off(JOB_API_LISTENER_EVENTS.EVENT_STATE);
		wsServiceInstance.off(JOB_API_LISTENER_EVENTS.EVENT_POSITIONS);
		wsServiceInstance.off(JOB_API_LISTENER_EVENTS.TEAM_STANDINGS);
		wsServiceInstance.off(JOB_API_LISTENER_EVENTS.KENO_STATISTICS);
		wsServiceInstance.off(JOB_API_LISTENER_EVENTS.BET_SLIP);
		wsServiceInstance.off(JOB_API_LISTENER_EVENTS.BET_SLIP_BET);

		wsServiceInstance.on(JOB_API_LISTENER_EVENTS.BALANCE, onBalance);
		wsServiceInstance.on(JOB_API_LISTENER_EVENTS.WON_POPUP, onWonPopup);
		wsServiceInstance.on(JOB_API_LISTENER_EVENTS.EVENTS, onEvent);
		wsServiceInstance.on(JOB_API_LISTENER_EVENTS.EVENT_STATE, onEventState);
		wsServiceInstance.on(JOB_API_LISTENER_EVENTS.EVENT_POSITIONS, onEventPositions);
		wsServiceInstance.on(JOB_API_LISTENER_EVENTS.TEAM_STANDINGS, onTeamStandings);
		wsServiceInstance.on(JOB_API_LISTENER_EVENTS.KENO_STATISTICS, onKenoStatistics);
		wsServiceInstance.on(JOB_API_LISTENER_EVENTS.BET_SLIP, onBetSlip);
		wsServiceInstance.on(JOB_API_LISTENER_EVENTS.BET_SLIP_BET, onBetSlipBet);

		jobForceUpdate()
	})

	// Create connections
	const { adminWS, siteWS, jobWS } = useMemo(/** @return {WebSocketConnections} */() => {

		/** @type {WebSocketConnections} */
		const wsServices = {
			adminWS: null,
			siteWS: null,
			jobWS: null
		};

		if (sessionFailed || !sessionLoaded || !player?.wsToken) {
			return wsServices
		}

		const urlAdminWS = `${import.meta.env.SYSTEM_WS_URL_ADMIN}?accessToken=${player.wsToken}`;
		const urlSiteWS = `${import.meta.env.SYSTEM_WS_URL_SITE}?accessToken=${player.wsToken}`;
		const urlJobWS = `${import.meta.env.SYSTEM_WS_URL_JOBS}?accessToken=${player.wsToken}`;

		wsServices.jobWS = new WebSocketService(urlJobWS, handleJobWSEvent)
		wsServices.adminWS = new WebSocketService(urlAdminWS, handleAdminWSEvent)
		wsServices.siteWS = new WebSocketService(urlSiteWS, handleSiteWSEvent)

		return wsServices

	}, [sessionLoaded, sessionFailed, player?.wsToken])

	//#region Subscribe / Unsubscribe signalR

	// start / stop connection by document visibility
	useEffect(() => {
		if (!player?.wsToken || isNullish(adminWS) || isNullish(siteWS) || isNullish(jobWS)) {
			return;
		}

		const visibilityChangeListener = () => {
			if (document.hidden) {
				return;
			}
			adminWS.startConnection();
			siteWS.startConnection();
			jobWS.startConnection();
		};

		document.addEventListener("visibilitychange", visibilityChangeListener);

		return () => {
			adminWS.stopConnection();
			siteWS.stopConnection();
			jobWS.stopConnection();

			document.removeEventListener("visibilitychange", visibilityChangeListener);
		};
	}, [player?.wsToken, adminWS, siteWS, jobWS]);

	// Watch all event status updates
	useEffect(() => {
		if (!player?.wsToken || !games?.length || !jobWS?.isConnected) {
			return;
		}

		games.forEach(game => {
			const invokeMessage = `${JOB_API_LISTENER_EVENTS.EVENT_STATE}_${game.type}_${game.id}`;
			jobWS.invoke(JOB_API_INVOKE_EVENTS.SUBSCRIBE, invokeMessage);
		})

		return () => {
			if (!jobWS.isConnected) {
				return;
			}

			games.forEach(game => {
				const invokeMessage = `${JOB_API_LISTENER_EVENTS.EVENT_STATE}_${game.type}_${game.id}`;
				jobWS.invoke(JOB_API_INVOKE_EVENTS.UNSUBSCRIBE, invokeMessage);
			})

		};
	}, [player?.wsToken, jobWS, games, jobForceUpdateState]);

	// Watch events and their statistics updates
	useEffect(() => {
		if (!player?.wsToken || !games?.length || !jobWS?.isConnected) {
			return;
		}

		const game = games.find(game => game.type === currentGameType);

		if (!game) {
			return
		}

		const invokeMessage = `${JOB_API_LISTENER_EVENTS.EVENTS}_${game.type}_${game.id}`;

		jobWS.invoke(JOB_API_INVOKE_EVENTS.SUBSCRIBE, invokeMessage);

		return () => {
			if (!jobWS.isConnected) {
				return;
			}

			jobWS.invoke(JOB_API_INVOKE_EVENTS.UNSUBSCRIBE, invokeMessage);
		};
	}, [player?.wsToken, jobWS, games, jobForceUpdateState, currentGameType]);

	// Watch currency limits updates
	useEffect(() => {
		if (!player?.wsToken || !currency?.code || !adminWS?.isConnected) {
			return;
		}

		const invokeProjectCurrencyMessage = `${projectId}_${currency.code}`;

		adminWS.invoke(ADMIN_API_INVOKE_EVENTS.SUBSCRIBE, invokeProjectCurrencyMessage);

		return () => {
			if (!adminWS?.isConnected) {
				return;
			}

			adminWS.invoke(ADMIN_API_INVOKE_EVENTS.UNSUBSCRIBE, invokeProjectCurrencyMessage);
		};
	}, [player?.wsToken, adminWS, projectId, currency, adminForceUpdateState]);

	// Watch game status updates
	useEffect(() => {
		if (!player?.wsToken || !adminWS?.isConnected) {
			return;
		}

		games.forEach(game => {
			adminWS.invoke(ADMIN_API_INVOKE_EVENTS.SUBSCRIBE, game.id);
		})

		return () => {
			if (!adminWS?.isConnected) {
				return;
			}

			games.forEach(game => {
				adminWS.invoke(ADMIN_API_INVOKE_EVENTS.UNSUBSCRIBE, game.id);
			})

		};
	}, [player?.wsToken, adminWS, games, adminForceUpdateState]);

	// Execute player activity
	useEffect(() => {
		if (!player?.wsToken || !sessionId || !player.userId || !siteWS?.isConnected) {
			return;
		}

		const intervalId = setInterval(() => {
			siteWS.invoke(SITE_API_INVOKE_EVENTS.UPDATE_SESSION, sessionId, PROJECT_TYPE.ONLINE, player.userId);
		}, UPDATE_SESSION_INTERVAL_MILLISECONDS);

		return () => {
			clearInterval(intervalId);
		};
	}, [player?.wsToken, siteWS, sessionId, player?.userId, siteForceUpdateState]);
	//#endregion

	/** Function to get current game rtps
	 * @function
	 * @returns {array}
	 * @memberOf MarketsTabs
	 */
	const getCurrentGameRtps = () => {
		let rtps = [];
		let game = games.find((g) => g.type === currentGameType);
		if (game) {
			rtps = game.rtPs;
		}
		return rtps;
	};

	/** Sending event/week status to the RGS, when getting upgrade from SignalR */
	const sendEventStatusToRGS = (event) => {
		if ((event.status === GAME_STATUSES.FINISHED || event.status === GAME_STATUSES.STARTED) && event.type !== GAME_EVENT_TYPE.LEAGUE) {
			if (isSeasonGame(event.gameType)) {
				if (event.type === GAME_EVENT_TYPE.WEEK) {
					sendPostMessageToParent({ type: CLIENT_API.EVENT, state: event.status === GAME_STATUSES.STARTED ? "start" : "finish" });
				}
			} else {
				sendPostMessageToParent({ type: CLIENT_API.EVENT, state: event.status === GAME_STATUSES.STARTED ? "start" : "finish" });
			}
		}
	};

	/** Keep current time */
	useEffect(() => {
		dispatch(setCurrentTimeAction());
		setInterval(() => {
			dispatch(setCurrentTimeAction());
		}, 1000);
	}, []);

	/** Load Live and Upcomings Matches */
	useEffect(() => {
		if (currentGameType !== null) {
			dispatch(liveAndUpcomingsThunk(currentGameType));
		}
		currentGameTypeRef.current = currentGameType;
		const containerElem = document.getElementsByClassName("vs--container")[0];
		containerElem && containerElem.setAttribute("data-game", currentGameType);
	}, [currentGameType]);

	/** Enable/Disable bonus bet for current game when useBonus is activated by user */
	useEffect(() => {
		if (!useBonus) {
			dispatch(setCurrentGameBonusBetDisabledAction(useBonus));
		} else {
			const availableGamesTypes = games.map((game) => game.type);
			const bonusGamesTypes = binaryToFlags(availableGamesTypes, bonuses[0].gameType);

			dispatch(setCurrentGameBonusBetDisabledAction(!bonusGamesTypes.includes(currentGameType)));
		}
	}, [currentGameType, useBonus]);

	/** Always get next upcoming event, to have its markets */
	useEffect(() => {
		if (upcomingEventId) {
			if (!matches?.[upcomingEventId]) {
				getEventInBackground(upcomingEventId, false, variableRef.current);
			}
		}
	}, [upcomingEventId]);

	/** Always get current active event, to keep liveInfo updated */
	useEffect(() => {
		if (activeEventId) {
			if (!matches?.[activeEventId]) {
				getEventInBackground(activeEventId, true, variableRef.current);
			} else {
				if (liveInfoEvent && liveInfoEvent.status !== GAME_STATUSES.FINISHED) {
					dispatch(setLiveInfoEventAction(matches?.[activeEventId]?.event ?? null));
				}
			}
		}
	}, [activeEventId]);

	/** Load current match data and markets */
	useEffect(() => {
		if (current) {
			if (!matches?.[current]) {
				getEvent(current, variableRef.current);
			} else {
				dispatch(getEventFromCacheThunk());
			}
		}
	}, [current]);

	/** Load current event data and markets for season */
	useEffect(() => {
		if (isSeasonGame(currentGameType) && seasonCurrentEventId) {
			if (!matches?.[seasonCurrentEventId]) {
				getEvent(seasonCurrentEventId, variableRef.current);
			} else {
				dispatch(getEventFromCacheThunk());
			}
		}
	}, [seasonCurrentEventId, currentGameType]);

	/** If less then 2 minute left to refresh token expiration, then refresh it */
	useEffect(() => {
		clearTimeout(refreshTokenTimer);
		if (player.refreshToken) {
			refreshTokenTimer = setTimeout(() => {
				refreshToken(player.refreshToken);
			}, REFRESH_TOKEN_UPDATE * 1000);
		}
	}, [player.refreshToken]);

	useEffect(() => {
		if (!sessionLoaded || sessionFailed) {
			return;
		}
		const betslip = LocalStorageUtils.get("vs__" + projectId);
		if (betslip) {
			dispatch(setBetslipAction({
				...betslip,
				stakeMode: isSplitStakeEnabled ? betslip.stakeMode : BETSLIP_STAKE_MODES.PER_BET
			}));
		}
	}, [sessionLoaded]);

	/** keep redux sync with localstorage, for the data which need to be saved in browser */
	useEffect(() => {
		if (projectId) {
			LocalStorageUtils.set("vs__" + projectId, {
				bets: bets,
				stake: stake,
				stakeMode: stakeMode,
				mode: mode
			});
		}
	}, [bets, stake, stakeMode, mode]);

	/** Initialize analytical tools */
	useEffect(() => {
		if (sessionLoaded) {
			analyticalTools.forEach((tool) => {
				if (tool.integrationId) {
					if (tool.type === ANALYTICAL_TOOL_TYPE.GOOGLE_ANALYTICS) {
						initializeGA(tool.integrationId);
						sendGAPageView();
					} else if (tool.type === ANALYTICAL_TOOL_TYPE.HOTJAR) {
						initializeHotjar(tool.integrationId);
					} else if (tool.type === ANALYTICAL_TOOL_TYPE.VWO) {
						initializeWVO(tool.integrationId);
					} else if (tool.type === ANALYTICAL_TOOL_TYPE.YANDEX_METRICA) {
						initializeYandexMetrica(tool.integrationId);
					}
				}
			});
		}
	}, [sessionLoaded]);

	/** Initialize message events */
	useEffect(() => {
		try {
			sendPostMessageToParent({ eventName: "vs--customization-ready" });
		} catch (ex) {
			console.log(ex);
		}
		window.addEventListener(
			"message",
			(e) => {
				const d = e.data;
				if (d) {
					if (d.eventName === "vs--customization") {
						let properties = d.data;
						if (!Array.isArray(properties) && typeof properties === "object") {
							const arr = [];
							Object.keys(properties).forEach((prop) => {
								arr.push({ key: prop, value: properties[prop] });
							});
							properties = arr;
						}
						generateCssFromProperties(properties, "vs--customization-css");
					} else if (d.eventName === "vs--customization-mobile-logo") {
						dispatch(setLogoIdAction(d.data));
					}
				}
			},
			false
		);
	}, []);

	/** Detect online/offline connection */
	useEffect(() => {
		const onOnline = () => {
			console.log("online");
		};

		const onOffline = () => {
			console.log("offline");
		};

		window.addEventListener("online", onOnline);
		window.addEventListener("offline", onOffline);

		return () => {
			window.removeEventListener("online", onOnline);
			window.removeEventListener("offline", onOffline);
		};
	}, []);

	/** Check if live and upcomings contains duplicates, then reload */
	useTaskScheduler(() => {
		let hasDuplicate = false;

		const { possibleSameStartTime, possibleSameEventId } = liveAndUpcomings.reduce(
			(acc, lau) => {
				acc.possibleSameStartTime.push(lau.startTime);
				acc.possibleSameEventId.push(lau.id);
				return acc;
			},
			{ possibleSameStartTime: [], possibleSameEventId: [] }
		);

		const hasEventsWithSameStartTime = new Set(possibleSameStartTime).size < liveAndUpcomings.length;
		const hasEventsWithSameEventId = new Set(possibleSameEventId).size < liveAndUpcomings.length;
		if (hasEventsWithSameStartTime || hasEventsWithSameEventId) {
			hasDuplicate = true;
		}

		if (!hasDuplicate) {
			const duplicateStatuses = [GAME_STATUSES.STARTED, GAME_STATUSES.CLOSE_FOR_BETTING, GAME_STATUSES.PREAMBLE_STARTED];
			const hasMultipleEventsWithNewOrPreambleState = liveAndUpcomings.filter((e) => duplicateStatuses.includes(e.status)).length > 1;
			if (hasMultipleEventsWithNewOrPreambleState) {
				hasDuplicate = true;
			}
		}

		if (hasDuplicate) {
			dispatch(liveAndUpcomingsThunk(currentGameType));
		}
	}, TASK_SCHEDULER_TIME_PERIODS.LIVE_AND_UPCOMMINGS);

	return <Fragment />;
};

export default Main;
