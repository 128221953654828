import { Fragment } from "react";
import "prop-types";
import { useTranslation } from "react-i18next";
import TabStructureRow from "./tabStructureRow";
import { GAME_STATUSES } from "constants/game.constants";
import { isChampionsCupGame, isSimpleCupGame, textForSimpleCup, textForChampionsCup } from "utils/common";
import eventType from "types/event.type";

const TabStructureBody = ({ prevRound, round }) => {
	const { t } = useTranslation();
	if (!round || !round.events) {
		return null;
	}

	/** Function , to get Teams data
	 * @function
	 * @param {object} event
	 * @returns {Object}
	 * @memberOf TabStructureBody
	 */
	const getTeamsData = (event) => {
		const data = { team1: {}, team2: {}, complitedScenes: [] };
		if (!round) return data;

		const getTeamName = (index) => {
			const countryCode = event?.gameData?.[`team${index}`]?.countryCode ?? null;
			if (countryCode !== null) {
				if (isChampionsCupGame(round.gameType)) {
					return countryCode;
				}
				return t(`countries.${countryCode}`);
			}
			if (isSimpleCupGame(round.gameType)) {
				return textForSimpleCup(round.orderNumber, event.orderNumber);
			}
			if (isChampionsCupGame(round.gameType)) {
				return textForChampionsCup(round.orderNumber);
			}
			return "";
		};

		const calculateTeamData = (index) => {
			const teamKey = `team${index}`;

			if (!event.gameData[teamKey]) {
				return;
			}
			data[teamKey].countryCode = event.gameData[teamKey].countryCode;
			if (round.status === GAME_STATUSES.NEW || round.status === GAME_STATUSES.PREAMBLE_STARTED) {
				data[teamKey].goalCount = null;
				data[teamKey].yellowCardCount = null;
				data[teamKey].redCardCount = null;
				data[teamKey].penaltyCount = null;
				data[teamKey].cornerCount = null;
			} else {
				data[teamKey].goalCount = event.gameData[teamKey].goalCount;
				data[teamKey].yellowCardCount = event.gameData[teamKey].yellowCardCount;
				data[teamKey].redCardCount = event.gameData[teamKey].redCardCount;
				data[teamKey].penaltyCount = event.gameData[teamKey].penaltyCount;
				data[teamKey].cornerCount = event.gameData[teamKey].cornerCount;
			}
			if (prevRound !== null && prevRound.status !== GAME_STATUSES.NEW && prevRound.status !== GAME_STATUSES.PREAMBLE_STARTED) {
				data[teamKey].aggGoalCount = event.gameData[teamKey].aggGoalCount;
			}

			if (event.gameData[teamKey].overTimeGoalCount === null) {
				return;
			}
			data[teamKey].overTimeGoalCount = event.gameData[teamKey].overTimeGoalCount;

			if (event.gameData[teamKey].penaltyShootoutGoalCount === null) {
				return;
			}
			data[teamKey].penaltyShootoutGoalCount = event.gameData[teamKey].penaltyShootoutGoalCount;
		};

		data.team1.name = getTeamName(1);
		data.team2.name = getTeamName(2);

		if (!event.gameData) {
			return data;
		}

		data.complitedScenes = event.gameData.complitedScenes ? event.gameData.complitedScenes : [];
		calculateTeamData(1);
		calculateTeamData(2);
		return data;
	};

	return (
		<Fragment>
			{round.events.map((event, i) => {
				const teamData = getTeamsData(event);
				return <TabStructureRow key={event.id} round={round} event={event} teamData={teamData} index={i} />;
			})}
		</Fragment>
	);
};

TabStructureBody.propTypes = {
	prevRound: eventType,
	round: eventType
};

export default TabStructureBody;
