import BetDetail from "./betDetail";

import { BETSLIP_MODES } from "constants/betslip.constants";

const PendingBetExpandContent = ({ data }) => {
	if(data.type === BETSLIP_MODES.SINGLE) {
		return null;
	}

	return (
		<div className="vs--pending-item-expand-content vs--flex vs--flex-col">
			{data.bets?.map((bet) => (
				<BetDetail key={`${bet.betSlipId}_${bet.betId}`} bet={bet} />
			))}
		</div>
	)
};

export default PendingBetExpandContent;