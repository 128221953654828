export const ON_BOARDING_TYPES = {
	QUICK_BETTING: 1
}

export const ON_BOARDING_COOKIE_NAMES = {
	[ON_BOARDING_TYPES.QUICK_BETTING]: "quickBetting-onboarding"
}

export const ON_BOARDING_SESSION_STORAGE_NAMES = {
	[ON_BOARDING_TYPES.QUICK_BETTING]: "quickBetting-onboarding"
}

export const ON_BOARDING_COOKIE_VALUES = {
	[ON_BOARDING_TYPES.QUICK_BETTING]: { showModal: true }
}

export const ON_BOARDING_EXPIRES_STRING = {
	[ON_BOARDING_TYPES.QUICK_BETTING]: "Fri, 06 Sep 2024 20:00:00 GMT"
}

export const ON_BOARDING_TOOLTIP_VISIBILITY_EVENT = "onboarding-tooltip-visibility"