import { useEffect, useContext } from "react";
import { EventTargetContext, StoreContext } from "contexts/navbar";
import { checkPossibleElementsThatInterfereWithTheView } from "utils/navbar";
import { VSEvent } from "helpers/customEvents";
import { NAVBAR_EVENT_TYPES, NAVBAR_INTERACTIVE_ELEMENT_KEYS } from "constants/navbar.constants";

const sum = (a, b) => a + b;
const sub = (a, b) => a - b;

const method = {
	ltr: {
		[NAVBAR_INTERACTIVE_ELEMENT_KEYS.NEXT]: sum,
		[NAVBAR_INTERACTIVE_ELEMENT_KEYS.PREV]: sub
	},
	rtl: {
		[NAVBAR_INTERACTIVE_ELEMENT_KEYS.NEXT]: sub,
		[NAVBAR_INTERACTIVE_ELEMENT_KEYS.PREV]: sum
	}
};

const useElementsObserver = (elementsRef, dir) => {
	const [{ allowIntoView }] = useContext(StoreContext);
	const eventTarget = useContext(EventTargetContext);
	const elements = elementsRef?.current || null;

	useEffect(() => {
		if (!elements) {
			return;
		}
		const intoViewListener = ({ data: { element } }) => {
			if (!elements || !element) {
				return;
			}

			const interfereInfo = checkPossibleElementsThatInterfereWithTheView(element, elements);

			if (interfereInfo.isVisible) {
				return;
			}

			if (dir === "rtl") {
				if (!interfereInfo.prevArrowDoesNotInterfereWithTheView) {
					elements.scrollLeft += Math.ceil(interfereInfo.coords.elementCoords.x2 - interfereInfo.coords.prevArrowCoords.x1);
				}
				if (!interfereInfo.nextArrowDoesNotInterfereWithTheView) {
					elements.scrollLeft -= Math.ceil(interfereInfo.coords.nextArrowCoords.x2 - interfereInfo.coords.elementCoords.x1);
				}
				if (!interfereInfo.containerDoesNotInterfereWithTheView && interfereInfo.prevArrowDoesNotInterfereWithTheView && interfereInfo.nextArrowDoesNotInterfereWithTheView) {
					if (interfereInfo.coords.containerCoords.x1 > interfereInfo.coords.elementCoords.x1) {
						elements.scrollLeft -= element.offsetWidth;
					}
					if (interfereInfo.coords.containerCoords.x2 < interfereInfo.coords.elementCoords.x2) {
						elements.scrollLeft += element.offsetWidth;
					}
				}
			} else {
				if (!interfereInfo.prevArrowDoesNotInterfereWithTheView) {
					elements.scrollLeft -= Math.ceil(interfereInfo.coords.prevArrowCoords.x2 - interfereInfo.coords.elementCoords.x1);
				}
				if (!interfereInfo.nextArrowDoesNotInterfereWithTheView) {
					elements.scrollLeft += Math.ceil(interfereInfo.coords.elementCoords.x2 - interfereInfo.coords.nextArrowCoords.x1);
				}
				if (!interfereInfo.containerDoesNotInterfereWithTheView && interfereInfo.prevArrowDoesNotInterfereWithTheView && interfereInfo.nextArrowDoesNotInterfereWithTheView) {
					if (interfereInfo.coords.containerCoords.x1 > interfereInfo.coords.elementCoords.x1) {
						elements.scrollLeft -= element.offsetWidth;
					}
					if (interfereInfo.coords.containerCoords.x2 < interfereInfo.coords.elementCoords.x2) {
						elements.scrollLeft += element.offsetWidth;
					}
				}
			}
		};

		const arrowNavigationListener = (event) => {
			if (!elements) {
				return;
			}
			const calcMethod = method[dir][event.data];
			const toScroll = elements.offsetWidth * 0.75;
			elements.scrollLeft = calcMethod(elements.scrollLeft, toScroll);
		};

		const emitArrowVisibility = ({ target: elements }) => {
			const childsTotalWidth = Array.prototype.reduce.call(elements.children, (acc, elem) => acc + elem.offsetWidth, 0);
			if (elements.childElementCount === 0 || childsTotalWidth <= elements.offsetWidth) {
				eventTarget.dispatchEvent(new VSEvent(NAVBAR_EVENT_TYPES.ELEMENTS_SCROLL_ON_START, true));
				eventTarget.dispatchEvent(new VSEvent(NAVBAR_EVENT_TYPES.ELEMENTS_SCROLL_ON_END, true));
				return;
			}

			const deviationFactor = 1;
			const boundingClientRect = elements.getBoundingClientRect();
			const fractionalPartOfWidth = boundingClientRect.width - parseInt(boundingClientRect.width);
			const pxDifference = Math.round(fractionalPartOfWidth);
			const scrollLength = elements.scrollWidth - elements.clientWidth;
			const minScrollRange = [-deviationFactor - pxDifference, deviationFactor + pxDifference];
			const maxScrollRange = [scrollLength - deviationFactor - pxDifference, scrollLength + deviationFactor + pxDifference];

			const isInRange = (arr, value) => {
				const minValue = Math.min(...arr);
				const maxValue = Math.max(...arr);
				return minValue <= value && value <= maxValue;
			};

			const scrollAbsoluteValue = Math.abs(elements.scrollLeft);
			const minScroll = isInRange(minScrollRange, scrollAbsoluteValue);
			const maxScroll = isInRange(maxScrollRange, scrollAbsoluteValue);

			eventTarget.dispatchEvent(new VSEvent(NAVBAR_EVENT_TYPES.ELEMENTS_SCROLL_ON_START, minScroll));
			eventTarget.dispatchEvent(new VSEvent(NAVBAR_EVENT_TYPES.ELEMENTS_SCROLL_ON_END, maxScroll));
		};

		const emitMoreVisibility = ({ target: { childElementCount, scrollWidth, clientWidth } }) => {
			eventTarget.dispatchEvent(new VSEvent(NAVBAR_EVENT_TYPES.THERE_IS_NOT_ELEMENTS_OR_ALL_VISIBLE, childElementCount === 0 || scrollWidth === clientWidth));
		};

		const emitElementClassNameChange = ({ target: elements }) => {
			if (elements.childElementCount === 0) {
				return;
			}

			const boundingClientRect = elements.getBoundingClientRect();
			const prevArrow = elements.previousElementSibling?.getBoundingClientRect();
			const nextArrow = elements.nextElementSibling?.getBoundingClientRect();

			eventTarget.dispatchEvent(
				new VSEvent(NAVBAR_EVENT_TYPES.ELEMENTS_ON_SCROLL, {
					parentBoundingClientRect: boundingClientRect,
					prevArrowBoundingClientRect: prevArrow || null,
					nextArrowBoundingClientRect: nextArrow || null
				})
			);
		};

		const observerCallback = ([entry]) => {
			emitMoreVisibility(entry);
			emitElementClassNameChange(entry);
			emitArrowVisibility(entry);
		};

		const onScroll = (scrollEvent) => {
			emitElementClassNameChange(scrollEvent);
			emitArrowVisibility(scrollEvent);
		};

		eventTarget.addEventListener(NAVBAR_EVENT_TYPES.ARROW_NAVIGATION, arrowNavigationListener);
		allowIntoView && eventTarget.addEventListener(NAVBAR_EVENT_TYPES.INTO_VIEW, intoViewListener);
		elements.addEventListener("scroll", onScroll);
		const resizeObserver = new ResizeObserver(observerCallback);
		const mutationObserver = new MutationObserver(observerCallback);
		resizeObserver.observe(elements);
		mutationObserver.observe(elements, { attributes: true, characterData: true, childList: true, subtree: false });
		return () => {
			eventTarget.removeEventListener(NAVBAR_EVENT_TYPES.ARROW_NAVIGATION, arrowNavigationListener);
			allowIntoView && eventTarget.removeEventListener(NAVBAR_EVENT_TYPES.INTO_VIEW, intoViewListener);
			elements && elements.removeEventListener("scroll", onScroll);
			resizeObserver.disconnect();
			mutationObserver.disconnect();
		};
	}, [elements, dir, eventTarget, allowIntoView]);
};

export default useElementsObserver;
